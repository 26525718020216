import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import moment from 'moment';

class Profile extends React.Component {
    state = { data: null }

    componentWillReceiveProps = nextProps => {
        const { profile } = nextProps;
        if (profile) {
            this.setState({ profile });
        }
    }
    toggleQuestion = (i, j) => {
        const { profile } = this.state;
        profile.tactical[i].questions[j].yes = profile.tactical[i].questions[j].parent ? profile.tactical[i].questions[j].yes : !profile.tactical[i].questions[j].yes;
        this.setState({ profile, saved: false });
    }
    onSave = () => {       

        const { profile } = this.state;
        let payload = {
            ...profile,            
            EvaluationDate: moment().format(),
        }        
        this.props.scout_actions && this.props.scout_actions.saveCustomPlayerReview(payload);
        this.setState({ saved: true });

    }
    render() {
        const { profile } = this.state;
        return <section>
            <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="slideInRight" animationOut="slideOutRight" isVisible={this.props.profile ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200 }}>
                <section className="bg-whiteish w-100 p-4" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, paddingTop: 70, overflowY: 'auto' }}>
                    {profile && <section className="w3-container" style={{ marginTop: 80 }}>
                        <div className="w3-content row mx-auto">
                            {profile.tactical.map((tactical, index) => {
                                return (<div className="col-md-6 col-sm-12 mb-3" key={index}>
                                    <div className="w3-container bg-white w3-card p-0">
                                        <div className="px-2 py-1 w3-gray white">
                                            <h2 className="sqwad-font m-0">{tactical.name}</h2>
                                        </div>
                                        <div className="w3-container d-flex flex-column p-4">
                                            {tactical.questions.map((question, j) => {
                                                return (<button key={j} className={`btn btn-block text-left btn-${question.yes ? 'success' : `default ${question.parent ? 'toUpper sqwad-font' : 'bordered'}`}`} onClick={() => this.toggleQuestion(index, j)}>{question.text}</button>)
                                            })}
                                        </div>
                                    </div>
                                </div>)
                            })}

                            <button className={`btn btn-${this.state.saved ? 'success' : 'info'} mt-4 w-100`} type="button" onClick={this.onSave}>{this.state.saved ? 'Profile saved!' : 'Save Profile'}</button>
                        </div>
                    </section>}
                </section>
                <div onClick={this.props.toggle} style={{ width: 50, height: 50, borderRadius: 25, position: 'fixed', top: 15, left: 15 }} className="bg-gray d-flex flex-column justify-content-center">
                    <i className="fas fa-times black font-30 align-self-center" />
                </div>
            </Animated>
        </section>
    }
}

export default Profile;