export function fetchFamilyTeams() { return { type: 'TEAMS/FAMILY/FETCH' } }
export function fetchTeamSeasons(idTeam) { return { type: 'TEAMS/SEASONS/FETCH', idTeam } }
export function fetchRoster(idTeam, idSeason) { return { type: 'TEAMS/ROSTER/SEASON/FETCH', idTeam, idSeason } }
export function clearFamilyTeams() { return { type: 'TEAMS/CLEAR' } }
export function fetchTeam(idTeam) { return { type: 'TEAM/FETCH', idTeam } }
export function selectTeam(team) { localStorage.setItem('sportslogic.team', JSON.stringify(team)); return { type: 'SELECTED_TEAM/SET', team } }
export function clearTeams() { return { type: 'TEAMS/CLEAR' } }
export function clearTeamSelected() { return { type: 'SELECTED_TEAM/CLEAR' } }
export function toggleTeamSelection(isTeamSelectionOpen) { return { type: 'IS_TEAM_SELECTION_OPEN/SET', isTeamSelectionOpen } }
export function updateTeam(idTeamDivision, name, idGender) { return { type: 'TEAM/UPDATE', idTeamDivision, name, idGender } }
export function updateName(idTeam, name) { return { type: 'TEAM/UPDATE/NAME', idTeam, name } }
export function updateResults(idTeamDivision, idTournamentTypeDivisionPoints, bonus, idSeason) { return { type: 'TEAM/UPDATE/RESULTS', idTeamDivision, idTournamentTypeDivisionPoints, bonus, idSeason } }
export function updateAwards(team) { return { type: 'AWARDS/UPDATE', team } }
export function updateUniformSwag(team) { return { type: 'SHIRTS/SIGNATURE/UPDATE', team } }
export function setRankingAgeGroup(idTeam, idTournamentSeason, idTournamentRankAgeGroup, idTournamentRank, randomId) { return { type: 'TEAM/RANKING_AGE_GROUP/SET', idTeam, idTournamentSeason, idTournamentRankAgeGroup, idTournamentRank, randomId } }
export function fetchSchedule(idTeamDivision) { return { type: 'TEAM/SCHEDULE/FETCH', idTeamDivision } }
export function fetchVideos(idTeam) { return { type: 'TEAM/VIDEOS/FETCH', idTeam } }
export function setVideo(video) { return { type: 'TEAM/VIDEO/SET', video } }
export function fetchVideo(idVideo) { return { type: 'TEAM/VIDEO/FETCH', idVideo } }
export function updateVideo(video) { return { type: 'VIDEOS/UPDATE', video } }
export function deleteVideo(video, idTeam) { return { type: 'VIDEOS/DELETE', video, idTeam } }
export function fetchSubscriptions(idTeam) { return { type: 'TEAM/SUBSCRIPTIONS/FETCH', idTeam } }
export function subscribeToSQWADTrial(IdTeam, IdPlan, card) { return { type: 'TEAM/SUBSCRIBE/SQWAD', IdTeam, IdPlan, card } }
export function clearFiles() { return { type: 'FILES/CLEAR' } }
export function clearSelectedTeam() { localStorage.removeItem('sportslogic.team'); return { type: 'SELECTED_TEAM/CLEAR' } }
export function clearSeasons() { return { type: 'SEASONS/CLEAR' } }