import React from 'react';
import { Animated } from 'react-animated-css';
import { CopyToClipboard } from 'react-copy-to-clipboard';

class ShareBrochure extends React.Component {
    state = { data: null }

    componentWillReceiveProps = nextProps => {
        const { brochure } = nextProps;
        if (brochure) {
            let clip = `https://www.thesqwad.com/master_brochure/${brochure.IdTeam}/${(brochure.Name || brochure.TeamName)
                .split(' ').join('_')
                .split('/').join('_')}`,
                image = `https://api.sportslogic.net/api/v5/qrcodes/brochure/master/${brochure.IdTeam}/${(brochure.Name || brochure.TeamName)
                    .split(' ').join('_')
                    .split('/').join('_')}`;
            this.setState({ brochure, image, clip });
        }
    }

    render() {
        const { brochure, image, clip } = this.state;
        return <section>
            <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="slideInRight" animationOut="slideOutRight" isVisible={this.props.brochure ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, width: 400, right: 0, paddingTop: 70, zIndex: 200 }}>
                <section className="bg-whiteish w-100 p-4" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, paddingTop: 70, overflowY: 'auto' }}>
                    {brochure && <section className="w3-container d-flex flex-column">

                        <div onClick={this.props.toggle} style={{ width: 50, height: 50, borderRadius: 25 }} className="bg-gray d-flex flex-column justify-content-center align-self-start">
                            <i className="fas fa-times black font-30 align-self-center" />
                        </div>
                        <img src={image} className="w-100 mt-3" />

                        <span className="mt-2 text-center">Scan this code with</span>
                        <img src="/images/logos/rc.png" className="m-auto" style={{ height: 50, width: 50 }} />
                        <div style={{ height: 30 }} />
                        <span className="mt-2 text-center">- OR -</span>
                        <CopyToClipboard text={clip}
                            onCopy={() => this.setState({ copySuccess: true })}>
                            <button className={`btn btn-${this.state.copySuccess ? 'success' : 'link bordered'} m-auto`}>
                                <i className="fas fa-clipboard blue" /> Copy link to your clipboard</button>
                        </CopyToClipboard>
                        <div style={{ height: 30 }} />
                    </section>}
                </section>
            </Animated>
        </section>
    }
}

export default ShareBrochure;
