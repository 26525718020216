import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';

class Players extends React.Component {
    state = {

    }


    render() {
        const { followees, folder = {}, router, search_text } = this.props, { params } = router;
        let pendings = null, prospects = null;
        if (folder) {
            prospects = _.filter(folder.users, f => !f.Blocked);
            pendings = [];
        } else if (followees && !params.id) {
            pendings = _.filter(followees || [], f => f.Blocked && !f.IdMatch),
                prospects = _.reject(followees, f => f.Blocked && !f.IdMatch);
        }


        prospects && prospects.forEach(p => {
            if (!p.IdFollowLevel) p.FollowLevelName = 'Prospect';
        });

        if (search_text && prospects) {
            prospects = _.filter(prospects, f => `${f.NameFirst}${f.NameLast}${f.FollowLevelName}`.toLowerCase().indexOf(search_text.toLowerCase()) !== -1);
        }

        return (
            <section>

                {/* PENDINGS */}
                {_.chain(pendings).groupBy(u => u.FollowLevelName).values().value().map((group, i) =>
                    <section key={i}>
                        <h3 className="gray syncopate mt-4">PENDINGS</h3>
                        <div className="row m-0 p-0">
                            {followees && pendings.map((user, index) =>
                                <Animated animationInDelay={50} animationIn="fadeIn" className="col-md-6 p-1" key={index}>
                                    <div className="px-4 py-3 tile card  d-flex flex-row" >
                                        <div className="align-self-center cover" style={{ background: `url(${user.UserImage || user.Image}) no-repeat center center black`, width: 60, height: 60, borderRadius: 30, border: `1px solid gray` }}></div>
                                        <div className="d-flex flex-column black ms-4 align-self-center">
                                            <span className="line1">{user.NameFirst}</span>
                                            <span className="line1">{user.NameLast}</span>
                                            <span className="red font-8">Pending for Approval</span>
                                        </div>
                                        <i className="ms-auto black icon-more-vertical"></i>
                                    </div>
                                </Animated>
                            )}
                        </div>
                    </section>
                )}

                {/* PROSPECTS */}
                {_.chain(prospects).groupBy(u => u.IdFollowLevel).values().value().map((group, i) =>
                    <section key={i}>
                        <h3 className="gray syncopate mt-4">{(group[0].FollowLevelName || 'PROSPECTS').toUpperCase()}</h3>
                        <div className="row m-0 p-0">
                            {followees && _.chain(group).sortBy('NameLast').value().map((user, index) => {

                                let nat_1 = _.find(this.props.countries || [], c => c.CountryName === (user.Nationality1 || user.Nationality)),
                                    nat_2 = _.find(this.props.countries || [], c => c.CountryName === user.Nationality2);

                                let soccer = _.find(user.sports, s => s.IdCommunity === 1), position = '';

                                if (soccer) {
                                    position = `${[soccer.Position1, soccer.Position2].join(' - ')}`;
                                }

                                return (<Animated animationIn="fadeIn" className="col-md-6 p-1" key={index}>
                                    <Link className="px-4 py-3 tile card h-100 w-100" to={user.IdMatch ? `/custom_player/${user.IdMatch}` : `/prospect/${user.IdUserFollow}`}>
                                        <div className="d-flex flex-row w-100 h-100">
                                            <div className="align-self-center cover" style={{ background: `url(${user.UserImage || user.Image}) no-repeat center center black`, width: 60, height: 60, borderRadius: 30, border: `1px solid gray` }}></div>
                                            <div className="d-flex flex-column black ms-4 align-self-center">
                                                <span className="line1">{user.NameFirst}</span>
                                                <span className="line1 font-14 toBold">{user.NameLast}</span>
                                                <span className="line1 ">{position || '-'}</span>
                                                <span className="black font-8 mt-2">{user.GraduationYear}{user.IsCommited ? <span className="red"> - Commited</span> : (user.IsLookingForTransfer ? <span> - Looking for Transfer</span> : '-')}</span>
                                            </div>
                                            <div className="ms-auto">
                                                <div className="d-flex flex-row mb-2">
                                                    {nat_2 && <img src={nat_2.image} style={{ height: 15 }} className="me-1" />}
                                                    {nat_1 && <img src={nat_1.image} style={{ height: 15 }} />}
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </Animated>);
                            })}
                        </div>
                    </section>
                )}

                {/* NO */}
                {!this.props.loading && pendings && prospects && [...pendings, ...prospects].length === 0 && <div className="d-flex w-100 flex-column justify-content-center">
                    <img src="/images/icons/red_card.png" className="w-25 align-self-center mt-4" />
                    <span className="black font-30 align-self-center text-center mx-4 px-4 line1 mt-4">Sorry, no recruits found<br />in this folder</span>
                </div>}

            </section>
        )
    }
}

export default Players;
