import React from 'react';
import Layout from '../layouts/claire';
import { preload } from '../../helpers';
import { Animated } from 'react-animated-css';
import Menu from '../home/partials/menu';
import { Link } from 'react-router';
import Players from './partials/players';
import Teams from './partials/teams';
import LineLoader from '../partials/line_loader';
import Note from '../network/partials/note';
import ShareMatch from '../network/partials/share';
import AddNote from '../network/partials/add_note';
import moment from 'moment';
import MoveToFolder from './partials/move_to_folder';
import fakeAuth from '../../fakeAuth';

class NotesFolder extends React.Component {
    state = {
        menu_toggled: false,
        mode: 2
    }
    componentWillMount() {
        this.props.scout_actions && this.props.scout_actions.fetchCountries();
        this.props.scout_actions && this.props.scout_actions.fetchCommunities();
        this.props.scout_actions && this.props.scout_actions.clearUser();
    }
    componentDidMount() {
        this.props.scout_actions && this.props.scout_actions.fetchFollowees();
        this.props.scout_actions.fetch_notes();
        this.props.scout_actions.fetch_folders();

        const { router } = this.props, { params } = router;
        if (params.id) {
            this.props.scout_actions && this.props.scout_actions.fetchFolder(params.id);
        }
    }
    componentWillReceiveProps = nextProps => {
        const { notes, router, folders } = nextProps, { params } = router, { id } = params;
        this.setState({
            notes: _.filter(notes, f => f.IdFolder === id),
            folders: _.filter(folders, f => f.IdFolderType === 6)
        });
    }

    onDelete = (IdMatch) => {
        const { notes } = this.state;
        this.setState({ notes: _.reject(notes, e => e.IdMatch === IdMatch) });
        this.props.scout_actions && this.props.scout_actions.delete_match(IdMatch);
    }

    toggleMenu = index => {
        const { notes } = this.state;
        _.chain(notes)
            .sortBy('EvaluationDate')
            .value().reverse()[index].menu = !_.chain(notes)
                .sortBy('EvaluationDate')
                .value().reverse()[index].menu;
        this.setState({ notes });
    }

    renderCommon = () => {
        const { mode, notes } = this.state, { folders, router, folder, loading } = this.props, { params } = router;

        return <div className="w3-content pl-4 container pt-4">


            <div className="d-flex flex-row w-100">
                <Link className="px-4 py-2 tile card w-100 d-flex flex-row w-100" to="/scouting_notes">
                    <i className="far fa-folder black text-center font-30 align-self-center" />
                    <span className="line1 font-10 black text-center align-self-center ms-4">{folder ? (folder.Name || folder.FolderName) : 'ALL PLAYERS'}</span>
                    <span className="ms-auto blue font-10 align-self-center">Change</span>
                </Link>
            </div>


            <section>
                <div className="mt-4">
                    <input className="w-100 mt-3 p-2 mb-3 tile card" type="text" name="email" placeholder="Search" ref={(input) => this.txtSearch = input} />
                </div>
                <div className="w3-container-fluid">
                    {/* YES +*/}
                    {notes && notes.length > 0 && <div className="row h-100 m-0 p-0">
                        {_.chain(notes)
                            .sortBy('EvaluationDate')
                            .value().reverse().map((evaluation, index) => {

                                // Notes
                                if (evaluation.ReportType === 10) {
                                    return <Animated key={index} animationInDelay={25 * index} animationIn="fadeIn" className="col-md-6 p-0 mt-2">
                                        <div className="pl-4 py-3 tile card d-flex flex-row mx-1 pointer">

                                            <div onClick={() => this.setState({ note: evaluation })} className="align-self-center d-flex flex-row justify-content-center" style={{ width: 60, height: 60 }}>
                                                <i className="align-self-center icon-bookmark1 font-50 ms-4" style={{ color: `${evaluation.Color || 'FF0000'}` }} />
                                            </div>
                                            <div onClick={() => this.setState({ note: evaluation })} className="w-100 d-flex flex-column black ms-4 align-self-center">
                                                <span className="line1 font-18">{`${evaluation.NameFirst} ${evaluation.NameLast}`}</span>
                                                <span className="max-lines-1 line1 mt-1">{evaluation.Note}</span>
                                                <span className="black font-8">by {`${evaluation.CreatorNameFirst} ${evaluation.CreatorNameLast}`} @ {moment(evaluation.EvaluationDate).format('MMM Do YYYY')}</span>
                                            </div>
                                            <div className="px-2 ms-auto pointer" style={{ width: 50 }} onClick={() => this.toggleMenu(index)}><i className="ms-auto black icon-more-vertical" /></div>

                                            <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeIn" animationOut='fadeOut' isVisible={evaluation.menu ? true : false} className="bg-white card tile shadow d-flex flex-row" style={{ position: 'absolute', top: 8, right: 8 }}>

                                                <div className="d-flex flex-column">
                                                    <a target="_blank" title="PDF Version" href={`https://www.thesqwad.com/scout/note/${evaluation.IdMatch}`} className="btn btn-default"><i className="far fa-file-pdf black" /> Download PDF</a>
                                                    {evaluation.IdUser === fakeAuth.getUser().IdUser && <button onClick={() => this.setState({ move_to_folder: evaluation, folder_type: 6 }, () => {
                                                        this.toggleMenu(index);
                                                    })} className="btn btn-default"><i className="icon-folder-plus black" /> Move to folder</button>}
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <button onClick={() => this.setState({ match: evaluation }, () => {
                                                        this.toggleMenu(index);
                                                    })} className="btn btn-default"><i className="icon-share black" /> Share Report</button>
                                                    <button onClick={() => this.onDelete(evaluation.IdMatch)} className="btn btn-default"><i className="fas fa-times red" /> Delete Report</button>
                                                </div>

                                                <button className="btn btn-default align-self-start" onClick={() => this.toggleMenu(index)}><i className="black icon-more-vertical" /></button>


                                                {/*<button onClick={() => this.setState({ note: evaluation }, () => {
                                                this.toggleMenu(index);
                                            })} className="btn btn-default"><i className="icon-arrow-right black" /></button>
                                            <button onClick={() => this.setState({ match: evaluation }, () => {
                                                this.toggleMenu(index);
                                            })} className="btn btn-default"><i className="icon-share black" /></button>

                                            {evaluation.IdUser === fakeAuth.getUser().IdUser && <button onClick={() => this.setState({ move_to_folder: evaluation, folder_type: 6 }, () => {
                                                this.toggleMenu(index);
                                            })} className="btn btn-default"><i className="icon-folder-plus black" /></button>}

                                            <button onClick={() => this.onDelete(evaluation.IdMatch)} className="btn btn-default"><i className="fas fa-times red" /></button>
                                        <button className="btn btn-default" onClick={() => this.toggleMenu(index)}><i className="black icon-more-vertical" /></button>*/}
                                            </Animated>
                                        </div>
                                    </Animated>
                                }
                            })}
                    </div>}

                    {/* NO */}
                    {!loading && notes && !notes.length && <div className="d-flex w-100 flex-column justify-content-center">
                        <img src="/images/icons/red_card.png" className="w-25 align-self-center mt-4" />
                        <span className="font-30 align-self-center text-center mx-4 px-4 line1 mt-4">Sorry, you have no notes in this area</span>
                    </div>}
                </div>

                <div style={{ height: 200 }} className="col-12 " />
            </section>


        </div >
    }
    render() {
        const { folder } = this.props;
        return (
            <Layout router={this.props.router} className="bg-white" nbs="network" title={`SQWAD SCOUT | ${folder ? (folder.Name || folder.FolderName) : ''}`} form={this.props.form} form_actions={this.props.form_actions}>
                <section>
                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small" style={{ marginLeft: 200 }}>
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                    </div>
                    {/* MOBILE */}
                    <div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                    </div>
                    <Menu {...this.state} {...this.props} />
                    <MoveToFolder {...this.state} scout_actions={this.props.scout_actions} toggle={() => this.setState({ move_to_folder: false })} />
                    <Note {...this.state} scout_actions={this.props.scout_actions} toggle={() => this.setState({ note: false })} />
                    {this.props.loading && <LineLoader />}
                </section >
            </Layout>
        )
    }
}

export default NotesFolder;
