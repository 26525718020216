function public_matches(state = [], action) {
    switch (action.type) {
        case 'PUBLIC_MATCHES/SET':              
            return action.public_matches;
        case 'PUBLIC_MATCHES/CLEAR':
            return null;
        default:
            return state;
    }
}

export default public_matches;
