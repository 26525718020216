import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import form from './form';
import roster from './roster';
import team from './team';
import video from './video';
import videos from './videos';
import player from './player';
import evaluation from './evaluation';
import evaluations from './evaluations';
import user from './user';
import prospects from './prospects';
import scout_match from './scout_match';
import communities from './communities';
import countries from './countries';
import loading from './loading';
import followees from './followees';
import follow_levels from './follow_levels';
import folders from './folders';
import folder from './folder';
import teams from './teams';
import auth from './auth';
import match from './match';
import collaborators from './collaborators';
import notes from './notes';
import questionaries from './questionaries';
import players from './players';
import topics from './topics';
import states from './states';
import disclaimer from './disclaimer';
import subscriptions from './subscriptions';
import plans from './plans';
import coach_profile from './coach_profile';
import family from './family';
import phones from './phones';
import emails from './emails';
import information from './information';
import penalties from './penalties';
import custom_pages from './custom_pages';
import follow_seasons from './follow_seasons';
import public_matches from './public_matches';
import coaches from './coaches';

const rootReducer = combineReducers({
    routing: routerReducer,
    form,
    player,
    video,
    user,
    roster,
    team,
    videos,
    evaluation,
    evaluations,
    scout_match,
    prospects,
    communities,
    countries,
    loading,
    followees,
    follow_levels,
    folders,
    folder,
    teams,
    match,
    collaborators,
    notes,
    questionaries,
    players,
    topics,
    states,
    disclaimer,
    subscriptions,
    plans,
    penalties,
    coach_profile,
    family,
    phones,
    emails,
    information,
    custom_pages,
    follow_seasons,
    public_matches,
    coaches,
});

export default rootReducer;
