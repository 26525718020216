import React from 'react';

class ContactEntity extends React.Component {
    state = {
        entity: null,
        isActive: false
    }

    // Lifecycle
    componentWillMount() {
        const { entity, isActive } = this.props;
        this.setState({ entity, isActive });
    }
    componentWillReceiveProps = (newProps) => {
        this.setState({ entity: newProps.entity });
    }
    //

    doSomething = () => {
        this.setState({ isActive: !this.state.isActive }, () => {
            // TODO: call api to 'assign | remove' phone ?
            const { IdPhoneNumber, familyActions, email } = this.props, { entity } = this.state;

            // phone ?
            IdPhoneNumber && familyActions && familyActions.assignPhone(entity.IdUser, IdPhoneNumber, this.state.isActive);
            // email ?
            email && familyActions && familyActions.assignEmail(entity.IdUser, email.IdEmailAddress, this.state.isActive);
        });
    }

    errorImage = () => {
        const { entity } = this.state;
        entity.UserImage = (entity.IdGender === 2 ? '/images/defaults/female.png' : '/images/defaults/male.png');
        this.setState({ entity });
    }

    render() {
        const { entity, isActive } = this.state;
        return (
            <div style={{ position: 'relative', width: 100, height: 130 }}>
                <div className="cover" style={{ borderRadius: 5, width: '100px', height: '100px', background: ['url(', (entity.UserImage), ') no-repeat center center'].join('') }} />
                <div style={{ lineHeight: 1 }} className={`mt-1 d-flex flex-row`}>
                    <span className="block font-10 align-self-center ">{entity.NameFirst}</span>
                    <i onClick={this.doSomething} className={`align-self-center ${isActive ? 'fas fa-check-circle green' : 'far fa-circle black'} font-18 ms-auto`} />
                </div>
                { /* This is a hack */}
                <img alt="" onError={this.errorImage} className="m-auto" src={(entity.UserImage)} style={{ width: '1px', height: '1px', position: 'absolute' }} />
            </div>)
    }
}

export default ContactEntity;
