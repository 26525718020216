function notes(state = [], action) {
    switch (action.type) {
        case 'NOTES/SET':
            return action.notes;        
        case 'NOTES/CLEAR':
            return null;
        default:
            return state;
    }
}

export default notes;
