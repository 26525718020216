function teams(state = [], action) {
    switch (action.type) {
        case 'TEAMS/SET':            
            return action.teams;
        case 'TEAMS/CLEAR':
            return null;
        default:
            return state;
    }
}

export default teams;
