import React from 'react';
import Layout from '../layouts/claire';
import { Animated } from 'react-animated-css';
import Menu from '../home/partials/menu';

import MenuDesktop from './partials/lead_menu_desktop';
import MenuMobile from './partials/lead_menu_mobile';

import LineLoader from '../partials/line_loader';
import moment from 'moment';
import Note from './partials/note';
import Attributes from './partials/attributes';
import Review from './partials/review';
import Profile from './partials/profile';
import ShareMatch from './partials/share';
import MenuProspectNotes from './partials/menu_prospect_notes';

import AddNote from './partials/add_note';
import AddAttributes from './partials/add_attributes';
import AddEvaluation from './partials/add_evaluation';
import AddReview from './partials/add_review';

class LeadEvaluations extends React.Component {
    state = {
        menu_toggled: false,
        path: []
    }
    componentWillMount() {
        this.props.scout_actions && this.props.scout_actions.fetchCountries();
        this.props.scout_actions && this.props.scout_actions.fetchCommunities();
        this.props.scout_actions && this.props.scout_actions.fetchFollowLevels();
        this.props.scout_actions && this.props.scout_actions.fetchFollowees();
        this.props.scout_actions && this.props.scout_actions.fetch_topics(1);
        this.setState({ path: _.chain(this.props.router.getCurrentLocation()).value().pathname.split('/') });
    }
    componentDidMount() {
        const { router } = this.props, { params } = router, { id } = params;
        if (id) {
            this.props.scout_actions && this.props.scout_actions.fetchUser(id);
            this.props.scout_actions && this.props.scout_actions.fetchUserEvaluations(id);
            this.props.video_actions && this.props.video_actions.fetchVideosByUser(id)
        }
    }
    componentWillReceiveProps = nextProps => {
        //if (nextProps.user && nextProps.evaluations) {
        this.setState({
            user: nextProps.user,
            selected_user: nextProps.user,
            evaluations: nextProps.evaluations, videos: nextProps.videos,
            topics: nextProps.topics,
            questionaries: nextProps.questionaries
        });
        //}
    }

    toggleMenu = index => {
        const { evaluations } = this.state;
        _.chain(evaluations)
            .sortBy('EvaluationDate')
            .value().reverse()[index].menu = !_.chain(evaluations)
                .sortBy('EvaluationDate')
                .value().reverse()[index].menu;
        this.setState({ evaluations });
    }

    onDelete = (IdMatch) => {
        const { evaluations } = this.state;
        this.setState({ evaluations: _.reject(evaluations, e => e.IdMatch === IdMatch) });
        this.props.scout_actions && this.props.scout_actions.delete_match(IdMatch);
        // TODO: call API to delete
    }

    renderCommon = () => {
        const { loading, user } = this.props, { evaluations } = this.state;

        return <div className="w3-content pl-4 container pt-4">

            {/* YES +*/}
            {evaluations && evaluations.length > 0 && <div className="row h-100 ">
                {_.chain(evaluations)
                    .sortBy('EvaluationDate')
                    .value().reverse().map((evaluation, index) => {

                        // Notes
                        if (evaluation.ReportType === 10) {
                            return <Animated key={index} animationInDelay={25 * index} animationIn="fadeInRightBig" className="col-md-6 p-0 mt-2">
                                <div className="pl-4 py-3 tile card mx-1 pointer d-flex flex-row">
                                    <div onClick={() => this.setState({ note: evaluation })} style={{ width: 60, height: 60 }} className="ms-2 align-self-center d-flex flex-row justify-content-center">
                                        <i className="align-self-center icon-bookmark1 font-40" style={{ color: `${evaluation.Color || 'FF0000'}` }} />
                                    </div>
                                    <div onClick={() => this.setState({ note: evaluation })} className="w-100 d-flex flex-column black ps-4 align-self-center">
                                        <span className="max-lines-1 line1">{evaluation.Note}</span>
                                        <span className="black font-8">by {`${evaluation.CreatorNameFirst} ${evaluation.CreatorNameLast}`} @ {moment(evaluation.EvaluationDate).format('MMM Do YYYY')}</span>
                                    </div>
                                    <div className="px-2 ms-auto pointer" style={{ width: 50 }} onClick={() => this.toggleMenu(index)}><i className="ms-auto black icon-more-vertical" /></div>

                                    {/*MENU */}
                                    <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeInUp" animationOut='fadeOut' isVisible={evaluation.menu ? true : false} className="bg-white card tile shadow d-flex flex-row" style={{ position: 'absolute', top: 10, right: 0 }}>
                                        <button onClick={() => this.setState({ note: evaluation }, () => {
                                            this.toggleMenu(index);
                                        })} className="btn btn-default"><i className="icon-arrow-right black" /></button>
                                        <button onClick={() => this.setState({ match: evaluation }, () => {
                                            this.toggleMenu(index);
                                        })} className="btn btn-default"><i className="icon-share black" /></button>
                                        <button onClick={() => this.onDelete(evaluation.IdMatch)} className="btn btn-default"><i className="fas fa-times red" /></button>
                                        <button className="btn btn-default" onClick={() => this.toggleMenu(index)}><i className="black icon-more-vertical" /></button>
                                    </Animated>
                                </div>
                            </Animated>
                        }

                        // Attributes
                        if (evaluation.ReportType === 11) {
                            return <Animated key={index} animationInDelay={25 * index} animationIn="fadeInRightBig" className="col-md-6 p-0 mt-2">
                                <div className="pl-4 py-3 tile card  d-flex flex-row mx-1">
                                    <div onClick={() => this.setState({ attributes: evaluation })} style={{ width: 60, height: 60 }} className="ms-2 align-self-center d-flex flex-row justify-content-center">
                                        <i className="black align-self-center icon-recreational font-40" />
                                    </div>
                                    <div onClick={() => this.setState({ attributes: evaluation })} className="w-100 d-flex flex-column black ms-4 align-self-center">
                                        <span className="line1 ">Evaluation</span>
                                        <span className="black font-8">by {`${evaluation.CreatorNameFirst} ${evaluation.CreatorNameLast}`} @ {moment(evaluation.EvaluationDate).format('MMM Do YYYY')}</span>
                                        <span className="line1 mt-1 font-16 syncopate">Avg. {evaluation.average.toFixed(1)}</span>
                                    </div>
                                    <div className="px-2 ms-auto pointer" style={{ width: 50 }} onClick={() => this.toggleMenu(index)}><i className="ms-auto black icon-more-vertical" /></div>

                                    <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeInUp" animationOut='fadeOut' isVisible={evaluation.menu ? true : false} className="bg-white card tile shadow d-flex flex-row" style={{ position: 'absolute', top: 10, right: 0 }}>
                                        <button onClick={() => this.setState({ attributes: evaluation }, () => {
                                            this.toggleMenu(index);
                                        })} className="btn btn-default"><i className="icon-arrow-right black" /></button>
                                        <button onClick={() => this.setState({ match: evaluation }, () => {
                                            this.toggleMenu(index);
                                        })} className="btn btn-default"><i className="icon-share black" /></button>
                                        <button onClick={() => this.onDelete(evaluation.IdMatch)} className="btn btn-default"><i className="fas fa-times red" /></button>
                                        <button className="btn btn-default" onClick={() => this.toggleMenu(index)}><i className="black icon-more-vertical" /></button>
                                    </Animated>
                                </div>
                            </Animated>
                        }


                        // Review
                        if (evaluation.ReportType === 12) {
                            return <Animated key={index} animationInDelay={25 * index} animationIn="fadeInRightBig" className="col-md-6 p-0 mt-2">
                                <div className="pl-4 py-3 tile card  d-flex flex-row mx-1" >
                                    <div onClick={() => this.setState({ review: evaluation })} className="align-self-center d-flex flex-row justify-content-center" style={{ width: 60, height: 60 }}>
                                        <i className="black align-self-center icon-bar-chart font-50" />
                                    </div>
                                    <div onClick={() => this.setState({ review: evaluation })} className="w-100 d-flex flex-column black ms-4 align-self-center ">
                                        <span className="line1 toBold">{`${evaluation.NameFirst} ${evaluation.NameLast}`}</span>
                                        <span className="line1 ">{evaluation.NextSteps || ''}</span>
                                        <span className="black font-8">by {`${evaluation.CreatorNameFirst} ${evaluation.CreatorNameLast}`} @ {moment(evaluation.EvaluationDate).format('MMM Do YYYY')}</span>
                                    </div>
                                    <div className="px-2 ms-auto pointer" style={{ width: 50 }} onClick={() => this.toggleMenu(index)}><i className="ms-auto black icon-more-vertical" /></div>

                                    <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeInUp" animationOut='fadeOut' isVisible={evaluation.menu ? true : false} className="bg-white card tile shadow d-flex flex-row" style={{ position: 'absolute', top: 10, right: 0 }}>
                                        <button onClick={() => this.setState({ review: evaluation }, () => {
                                            this.toggleMenu(index);
                                        })} className="btn btn-default"><i className="icon-arrow-right black" /></button>
                                        <button onClick={() => this.setState({ match: evaluation }, () => {
                                            this.toggleMenu(index);
                                        })} className="btn btn-default"><i className="icon-share black" /></button>
                                        <button onClick={() => this.onDelete(evaluation.IdMatch)} className="btn btn-default"><i className="fas fa-times red" /></button>
                                        <button className="btn btn-default" onClick={() => this.toggleMenu(index)}><i className="black icon-more-vertical" /></button>
                                    </Animated>
                                </div>
                            </Animated>
                        }

                        // By Position
                        if (evaluation.ReportType === 1) {
                            return <Animated key={index} animationInDelay={25 * index} animationIn="fadeInRightBig" className="col-md-6 p-0 mt-2">
                                <div className="pl-4 py-3 tile card  d-flex flex-row mx-1">
                                    <div onClick={() => this.setState({ profile: evaluation })} className="align-self-center d-flex flex-row justify-content-center" style={{ width: 60, height: 60 }}>
                                        <i className="black align-self-center icon-git-pull-request font-50" />
                                    </div>
                                    <div onClick={() => this.setState({ profile: evaluation })} className="w-100 d-flex flex-column black ms-4 align-self-center">
                                        <span className="line1 toBold">{`${evaluation.NameFirst} ${evaluation.NameLast}`}</span>
                                        <span className="line1 ">{evaluation.Position}</span>
                                        <span className="black font-8">by {`${evaluation.CreatorNameFirst} ${evaluation.CreatorNameLast}`} @ {moment(evaluation.EvaluationDate).format('MMM Do YYYY')}</span>
                                    </div>
                                    <div className="px-2 ms-auto pointer" style={{ width: 50 }} onClick={() => this.toggleMenu(index)}><i className="ms-auto black icon-more-vertical" /></div>

                                    <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeInUp" animationOut='fadeOut' isVisible={evaluation.menu ? true : false} className="bg-white card tile shadow d-flex flex-row" style={{ position: 'absolute', top: 10, right: 0 }}>
                                        <button onClick={() => this.setState({ profile: evaluation }, () => {
                                            this.toggleMenu(index);
                                        })} className="btn btn-default"><i className="icon-arrow-right black" /></button>
                                        <button onClick={() => this.setState({ match: evaluation }, () => {
                                            this.toggleMenu(index);
                                        })} className="btn btn-default"><i className="icon-share black" /></button>
                                        <button onClick={() => this.onDelete(evaluation.IdMatch)} className="btn btn-default"><i className="fas fa-times red" /></button>
                                        <button className="btn btn-default" onClick={() => this.toggleMenu(index)}><i className="black icon-more-vertical" /></button>
                                    </Animated>
                                </div>
                            </Animated>
                        }
                    })}
                <div style={{ height: 200 }} className="col-12 " />
            </div>}

            {/* NO */}
            {!loading && evaluations && !evaluations.length && user && <div className="d-flex w-100 flex-column justify-content-center">
                <img src="/images/icons/red_card.png" className="w-25 align-self-center mt-4" />
                <span className="white font-30 align-self-center text-center mx-4 px-4 line1 mt-4">Sorry, there are no evaluations<br />for {`${user.NameFirst} ${user.NameLast}`}</span>
            </div>}

            {/* DESKTOP */}
            <div onClick={() => this.setState({ menu_toggled: !this.state.menu_toggled })} style={{ width: 70, height: 70, borderRadius: 35, overflow: 'hidden', position: 'fixed', bottom: 30, right: 30 }} className=" w3-hide-small">
                <div className="bg-info d-flex flex-column justify-content-center h-100 w-100">
                    <i className="fas fa-plus white font-40 align-self-center" />
                </div>
            </div>

            {/* ADD */}
            <div onClick={() => this.setState({ menu_toggled: !this.state.menu_toggled })} style={{ width: 50, height: 50, borderRadius: 25, overflow: 'hidden', position: 'fixed', bottom: 75, right: 10 }} className="w3-hide-medium w3-hide-large">
                <div className="bg-info d-flex flex-column justify-content-center h-100 w-100">
                    <i className="fas fa-plus white font-30 align-self-center" />
                </div>
            </div>
        </div>
    }
    render() {
        const { user, topics } = this.state
        return (
            <Layout router={this.props.router} who={(user || {}).NameFirst} className="bg-blue-dark" nbs="network" title="SQWAD SCOUT | MY NETWORK" form={this.props.form} form_actions={this.props.form_actions}>
                <section className={`${user ? '' : 'hide hidden'}`}>

                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small h-100" style={{ marginLeft: 200 }}>
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                        <MenuDesktop {...this.state} {...this.props} />
                    </div>
                    {/* MOBILE */}
                    <div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                        <MenuMobile {...this.state} {...this.props} />
                    </div>

                    <Menu {...this.state} {...this.props} />
                    <MenuProspectNotes {...this.state} {...this.props}
                        toggle={() => this.setState({ menu_toggled: !this.state.menu_toggled })}
                        fnevaluation={() => this.setState({ adding_evaluation: true, menu_toggled: false })}
                        fnnote={() => this.setState({ adding_note: true, menu_toggled: false })}
                        fnattribute={() => this.setState({ adding_attributes: true, menu_toggled: false })}
                        fnreview={() => this.setState({ adding_review: true, menu_toggled: false })}
                    />


                    <Note {...this.state} scout_actions={this.props.scout_actions} toggle={() => this.setState({ note: false })} />
                    <Attributes {...this.state} scout_actions={this.props.scout_actions} toggle={() => this.setState({ attributes: false })} />
                    <Review {...this.state} scout_actions={this.props.scout_actions} toggle={() => this.setState({ review: false })} />
                    <Profile {...this.state} scout_actions={this.props.scout_actions} toggle={() => this.setState({ profile: false })} />
                    <ShareMatch {...this.state} scout_actions={this.props.scout_actions} toggle={() => this.setState({ match: false })} />

                    {user && topics && <AddAttributes {...this.state} scout_actions={this.props.scout_actions} toggle={() => this.setState({ adding_attributes: false })} />}
                    {user && <AddNote {...this.state} scout_actions={this.props.scout_actions} toggle={() => this.setState({ adding_note: false })} />}
                    {user && <AddEvaluation {...this.state} scout_actions={this.props.scout_actions} toggle={() => this.setState({ adding_evaluation: false })} />}
                    {user && <AddReview {...this.state} scout_actions={this.props.scout_actions} toggle={() => this.setState({ adding_review: false })} />}
                    {this.props.loading && <LineLoader />}
                </section >
            </Layout>
        )
    }
}

export default LeadEvaluations;
