function prospects(state = [], action) {
    switch (action.type) {
        case 'PROSPECTS/SET':              
            return action.prospects;
        case 'PROSPECTS/CLEAR':
            return null;
        default:
            return state;
    }
}

export default prospects;
