import React from 'react';
import { Animated } from 'react-animated-css';
import fakeAuth from '../../../fakeAuth';
import _ from 'lodash';

class MatchCollaborators extends React.Component {
    state = {
        match_collaborators: null,
        collaborators: null
    }

    componentWillReceiveProps = nextProps => {
        const { match_collaborators, collaborators } = nextProps;
        this.setState({ match_collaborators, collaborators });
    }

    onDown = (user) => {
        const { collaborators } = this.state;
        _.find(collaborators, c => c.IdUser === user.IdUser).Blocked = true;
        this.setState({ collaborators });
        // Call api optimistic
        this.props.scout_actions.update_match_collaborator_rights(true, user.IdMatch);
    }
    onUp = (user) => {
        const { collaborators } = this.state;
        _.find(collaborators, c => c.IdUser === user.IdUser).Blocked = false;
        this.setState({ collaborators });
        // Call api optimistic
        this.props.scout_actions.update_match_collaborator_rights(false, user.IdMatch);
    }

    render() {
        const { brochure, image, clip, match_collaborators } = this.state, { collaborators } = this.state;
        let me = fakeAuth.getUser();        
        return <section>
            <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="slideInRight" animationOut="slideOutRight" isVisible={this.props.match_collaborators ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 50, right: 0, paddingTop: 70, zIndex: 200 }}>
                <section className="bg-whiteish w-100 p-4 " style={{ position: 'absolute', top: 0, left: 0, bottom: 0, paddingTop: 70, overflowY: 'auto' }}>
                    <div onClick={this.props.toggle} style={{ width: 50, height: 50, borderRadius: 25 }} className="bg-gray d-flex flex-column justify-content-center align-self-start">
                        <i className="fas fa-times black font-30 align-self-center" />
                    </div>
                    <section className="w3-container w3-content mx-auto">

                        {match_collaborators &&
                        
                            <div className="w-100 ">

                                <div className="w-100 py-2 tile card d-flex flex-row h-100">
                                    <div className="px-2 ms-auto pointer" style={{ width: 50 }} ><i className="ms-auto white icon-more-vertical" /></div>
                                    <div className="d-flex flex-column justify-content-center w-100 py-2">
                                        <div className="align-self-center cover" style={{ width: 60, height: 60, background: `url(${match_collaborators.TeamImageUrl || match_collaborators.Logo || '/images/logos/generic_crest.png'}) no-repeat center center` }}></div>
                                        <span className="line1 font-10 black text-center mt-2">{match_collaborators.Name || match_collaborators.TeamName}</span>
                                        <span className="line1 font-10 black text-center">{match_collaborators.SeasonName}</span>
                                    </div>
                                </div>

                                <h2 className="sqwad-font mt-2">OWNERS</h2>
                                <div className="w-100 py-2 tile card d-flex flex-row " style={{ height: 80 }}>
                                    <div className="align-self-center cover ms-3" style={{ width: 60, height: 60, borderRadius: 30, background: `url(${me.UserImage}?full=80) no-repeat center center` }}></div>
                                    <span className="line1 font-16 black text-center align-self-center ms-3">{`${me.NameFirst} ${me.NameLast}`}</span>
                                    <span className="line1 font-16 black text-center align-self-center mr-3 ms-auto">Owner</span>
                                </div>

                                {_.chain(collaborators || []).filter(u => !u.Blocked).value().map((user, i) => {
                                    return <div onClick={() => this.onDown(user)} className="pointer w-100 py-2 tile card d-flex flex-row  mt-2" style={{ height: 80 }} key={i}>
                                        <div className="align-self-center cover ms-3" style={{ width: 60, height: 60, borderRadius: 30, background: `url(${user.UserImage}?full=80) no-repeat center center` }}></div>
                                        <span className="line1 font-16 black text-center align-self-center ms-3">{`${user.NameFirst} ${user.NameLast}`}</span>
                                        <span className="line1 font-16 black text-center align-self-center mr-3 ms-auto"><i className="fas fa-arrow-down red" /></span>
                                    </div>
                                })}


                                <h2 className="sqwad-font mt-2">VIEWERS</h2>
                                {_.chain(collaborators || []).filter(u => u.Blocked).value().map((user, i) => {
                                    return <div onClick={() => this.onUp(user)} className="pointer w-100 py-2 tile card d-flex flex-row  mb-2" style={{ height: 80 }} key={i}>
                                        <div className="align-self-center cover ms-3" style={{ width: 60, height: 60, borderRadius: 30, background: `url(${user.UserImage}?full=80) no-repeat center center` }}></div>
                                        <div className="d-flex flex-column align-self-center justify-content-start">
                                            <span className="line1 font-16 black ms-3">{`${user.NameFirst} ${user.NameLast}`}</span>
                                            <span className="line1 font-12 gray ms-3">{`${user.EmailAddress}`}</span>
                                        </div>
                                        <span className="line1 font-16 black text-center align-self-center mr-3 ms-auto"><i className="fas fa-arrow-up green" /></span>
                                    </div>
                                })}
                            </div>


                        }
                    </section>
                </section>
            </Animated>
        </section>
    }
}

export default MatchCollaborators;
