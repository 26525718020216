function player_Reducer(state = [], action) {
    switch (action.type) {
        case 'PLAYER/SET':            
            return action.player;
        case 'PLAYER/CLEAR':
            return null;
        default:
            return state;
    }
}

export default player_Reducer;
