import React from 'react';
import Layout from '../layouts/claire';
import { Animated } from 'react-animated-css';
import Menu from '../home/partials/menu';
import { Link } from 'react-router';
import MenuNotes from '../network/partials/menu_notes';
import moment from 'moment';
import LineLoader from '../partials/line_loader';
import Attributes from '../network/partials/attributes';
import ShareMatch from '../network/partials/share';
import Profile from '../network/partials/profile';
import fakeAuth from '../../fakeAuth';
import MoveToFolder from '../network/partials/move_to_folder';
import request from 'superagent';
import config from '../../config';
import AddEvaluation from '../network/partials/add_evaluation';

// examples: 
// https://www.thesqwad.com/scout/profile/685F1CAF-DB06-4C61-9D34-A1691AB6C74E
// https://www.thesqwad.com/scout/profile/CB2157FA-8BA3-4787-A0DC-A347E80EEE30

class EvaluationsMain extends React.Component {
    state = {
        menu_toggled: false,
        mode: parseInt(localStorage.getItem('sqwadrc.scouting.mode') || 1, 10)
    }

    componentWillMount() {
        this.props.scout_actions && this.props.scout_actions.fetchCountries();
        this.props.scout_actions && this.props.scout_actions.fetchCommunities();
        this.props.scout_actions && this.props.scout_actions.fetchFollowLevels();
        this.props.scout_actions && this.props.scout_actions.fetchFollowees();
        this.setState({ path: _.chain(this.props.router.getCurrentLocation()).value().pathname.split('/') });
    }
    componentDidMount = () => {
        this.props.scout_actions.fetch_evaluations();
        this.props.scout_actions.fetch_folders();
    }
    componentWillReceiveProps = nextProps => {
        const { notes, folders, communities, questionaries } = nextProps;
        this.setState({
            questionaries,
            notes: _.filter(notes, n => !n.IdFolder),
            folders: _.filter(folders, f => f.IdFolderType === 3)
        });
    }
    componentWillUnmount = () => {
        this.props.scout_actions.clear_notes();
    }

    toggle_evaluation_menu = index => {
        const { notes } = this.state;
        _.chain(notes)
            .sortBy('EvaluationDate')
            .value().reverse()[index].menu = !_.chain(notes)
                .sortBy('EvaluationDate')
                .value().reverse()[index].menu;
        this.setState({ notes });
    }

    toggle_folder_menu = (index, e) => {
        e && e.preventDefault && e.preventDefault();
        e && e.stopPropagation && e.stopPropagation();

        const { folders } = this.state;
        _.chain(folders).value()[index].menu =
            !_.chain(folders).value()[index].menu;
        this.setState({ folders });
    }

    onDelete = (IdMatch) => {
        const { notes } = this.state;
        this.setState({ notes: _.reject(notes, e => e.IdMatch === IdMatch) });
        this.props.scout_actions && this.props.scout_actions.delete_match(IdMatch);
    }

    onSaveFolder = () => {
        if (!this.txtFolderName.value && !this.state.folder_name) {
            alert(`Please put folder's name`);
            return;
        }
        this.props.scout_actions && this.props.scout_actions.create_folder({
            FolderName: this.txtFolderName.value || this.state.folder_name, IdFolderType: 3
        });
        this.setState({ adding_folder: false, mode: 2 }); // Mode 2 to switch to the folders tab
    }

    onEditFolder = () => {
        this.props.scout_actions && this.props.scout_actions.create_folder({
            FolderName: this.state.edit_folder_name || this.state.editing_folder.Name || this.state.editing_folder.FolderName,
            IdFolderType: 3,
            IdFolder: this.state.editing_folder.IdFolder
        });
        this.setState({ editing_folder: false, mode: 2 }); // Mode 2 to switch to the folders tab
    }

    onRemoveFolder = (folder) => {
        const { folders } = this.state;
        this.setState({ folders: _.reject(folders, f => f.IdFolder === folder.IdFolder) });
        this.props.scout_actions.delete_folder(folder.IdFolder);
    }

    onImport = () => {

        const { notes_link } = this.state;
        if (notes_link.indexOf('://www.thesqwad.com/scout/profile') !== -1) {

            let [IdMatch] = notes_link.split('/').reverse();
            this.setState({ searching: true, invalid_link: false }, () => {
                request.get(`${config.apiEndpoint}/api/v6/scout/match/${IdMatch}`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .then(({ body }) => {
                        if (body.match && body.match.ReportType === 1) {
                            this.setState({ found: body.match, searching: false });
                        } else {
                            this.setState({ invalid_link: true, searching: false });
                        }
                    });
            });

        } else {
            this.setState({ invalid_link: true, searching: false });
        }
    }

    onCopy = () => {
        const { found } = this.state;
        this.props.scout_actions.save_match({
            ...found,
            IdMatch: null,
            EvaluationDate: moment(),
            IdUser: fakeAuth.getUser().IdUser
        });
        this.setState({ importing: false, found: null });
    }

    renderTabs = () => {
        const { mode, notes, folders } = this.state;
        return <div className="row">
            <div className="d-flex flex-row syncopate text-center w-100">
                <Link className="pe-4 align-self-center" to="/scouting">
                    <i className="fas fa-arrow-left" />
                </Link>
                <div className={`w-100 p-2 ${mode === 1 ? '' : 'bg-gray'}`} onClick={() => this.setState({ mode: 1 }, () => {
                    localStorage.setItem('sqwadrc.scouting.mode', 1)
                })}>
                    <h4 className="p-0 m-0">EVALUATIONS ({(notes || []).length})</h4>
                </div>
                <div className={`w-100 p-2 ${mode === 2 ? '' : 'bg-gray'}`} onClick={() => this.setState({ mode: 2 }, () => {
                    localStorage.setItem('sqwadrc.scouting.mode', 2)
                })}>
                    <h4 className="p-0 m-0">FOLDERS ({(folders || []).length})</h4>
                </div>
            </div>
        </div>
    }
    renderCommon = () => {
        const { mode, notes, folders } = this.state, { loading } = this.props;

        let filtered_notes = _.chain(notes)
            .filter(n => {
                if (this.state.search_text)
                    return `${n.NameFirst}${n.NameLast}`.toLowerCase().indexOf(this.state.search_text.toLowerCase()) !== -1;
                return true;
            })
            .value();

        let filtered_folders = _.chain(folders)
            .filter(n => {
                if (this.state.search_text_folders)
                    return `${n.Name || n.FolderName}`.toLowerCase().indexOf(this.state.search_text_folders.toLowerCase()) !== -1;
                return true;
            })
            .value();

        return <div className="w3-content px-4 container pt-4">

            {this.renderTabs()}

            {/* EVALUATIONS */}
            {mode === 1 && <section>
                <div className="mt-4">
                    <input className="w-100 p-2 mb-4 tile card" type="text" name="email" placeholder="Search" onChange={(e) => { this.setState({ search_text: e.target.value }); }} />
                </div>
                <div className="w3-container-fluid">

                    {/* YES +*/}
                    {filtered_notes && filtered_notes.length > 0 && <div className="row h-100 m-0 p-0">
                        {_.chain(filtered_notes)
                            .sortBy('EvaluationDate')
                            .value().reverse().map((evaluation, index) => {

                                if (evaluation.ReportType === 1) {
                                    return <Animated key={index} animationInDelay={25 * index} animationIn="fadeInRightBig" className="col-md-6 p-0 mt-2">
                                        <div className="pl-4 py-3 tile card  d-flex flex-row mx-1">
                                            <div onClick={() => this.setState({ profile: evaluation })} className="align-self-center d-flex flex-row justify-content-center" style={{ width: 60, height: 60 }}>
                                                <i className="black align-self-center icon-git-pull-request font-50 ms-4" />
                                            </div>
                                            <div onClick={() => this.setState({ profile: evaluation })} className="w-100 d-flex flex-column black ms-4 align-self-center">
                                                <span className="line1 toBold">{`${evaluation.NameFirst} ${evaluation.NameLast}`}</span>
                                                <span className="line1 ">{evaluation.Position}</span>
                                                <span className="black font-8">by {`${evaluation.CreatorNameFirst} ${evaluation.CreatorNameLast}`} @ {moment(evaluation.EvaluationDate).format('MMM Do YYYY')}</span>
                                            </div>

                                            {/* 3 Dots */}
                                            <div className="px-2 ms-auto pointer" style={{ width: 50 }} onClick={() => this.toggle_evaluation_menu(index)}><i className="ms-auto black icon-more-vertical" /></div>

                                            {/* Menu */}
                                            <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeIn" animationOut='fadeOut' isVisible={evaluation.menu ? true : false}
                                                className="bg-white card tile shadow d-flex flex-row" style={{ position: 'absolute', top: 8, right: 8 }}>

                                                <div className="d-flex flex-column">
                                                    <a target="_blank" title="PDF Version" href={`https://www.thesqwad.com/scout/profile/${evaluation.IdMatch}`} className="btn btn-default"><i className="far fa-file-pdf black" /> Download PDF</a>
                                                    {evaluation.IdUser === fakeAuth.getUser().IdUser && <button onClick={() => this.setState({ move_to_folder: evaluation, folder_type: 3 }, () => {
                                                        this.toggle_evaluation_menu(index);
                                                    })} className="btn btn-default"><i className="icon-folder-plus black" /> Move to folder</button>}
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <button onClick={() => this.setState({ match: evaluation }, () => {
                                                        this.toggle_evaluation_menu(index);
                                                    })} className="btn btn-default"><i className="icon-share black" /> Share Report</button>
                                                    <button onClick={() => this.onDelete(evaluation.IdMatch)} className="btn btn-default"><i className="fas fa-times red" /> Delete Report</button>
                                                </div>

                                                <button className="btn btn-default align-self-start" onClick={() => this.toggle_evaluation_menu(index)}><i className="black icon-more-vertical" /></button>
                                            </Animated>
                                        </div>
                                    </Animated>
                                }

                            })}
                        <div style={{ height: 200 }} className="col-12 " />
                    </div>}

                    {/* NO */}
                    {!loading && filtered_notes && filtered_notes.length === 0 && <div className="d-flex w-100 flex-column justify-content-center">
                        <img src="/images/icons/red_card.png" className="w-25 align-self-center mt-4" style={{ maxWidth: 200 }} />
                        <span className="font-30 align-self-center text-center mx-4 px-4 line1 mt-4">Sorry, you have no attribute evaluations in this area{this.state.search_text ? ` for '${this.state.search_text}'` : ''}</span>
                    </div>}
                </div>
            </section>}

            {/* FOLDERS */}
            {mode === 2 && <section>
                <div className="row mt-4">
                    <input className="w-100 p-2 mb-4 tile card" type="text" name="email" placeholder="Search" onChange={(e) => { this.setState({ search_text_folders: e.target.value }); }} />
                </div>
                <div className="row">

                    {/* YES */}
                    {filtered_folders && filtered_folders.map((folder, i) =>
                        <Animated key={i} animationInDelay={25 * i} animationIn="fadeInUp" className="col-sm-6 col-md-3 p-1 w3-container w3-cell">

                            <Link className="w-100  py-2 tile card d-flex flex-row h-100" to={`/evaluations_folder/${folder.IdFolder}`}>
                                <div className="px-2 ms-auto pointer" style={{ width: 50 }} ><i className="ms-auto white icon-more-vertical" /></div>
                                <div className="d-flex flex-column justify-content-center w-100 py-2">
                                    <i className="icon-folder black text-center font-70" />
                                    <span className="line1 mt-2 font-10 black text-center">{folder.Name || folder.FolderName}</span>
                                </div>
                                <div className="px-2 ms-auto pointer" style={{ width: 50, height: 50 }} onClick={(e) => this.toggle_folder_menu(i, e)}><i className="ms-auto black icon-more-vertical" /></div>
                                {folder.shared && <div style={{ width: 30, height: 30, position: 'absolute', top: 0, left: 0 }} className="d-flex flex-row justify-content-center bg-dark-blue">
                                    <i className="white font-10 icon-user-plus align-self-center" />
                                </div>}
                            </Link>

                            <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeInUp" animationOut='fadeOut' isVisible={folder.menu ? true : false} className="bg-white card tile shadow d-flex flex-row" style={{ position: 'absolute', top: 10, right: 10 }}>
                                <button onClick={(e) => this.setState({ editing_folder: folder }, () => {
                                    this.toggle_folder_menu(i);
                                })} className="btn btn-default"><i className="icon-edit-3 black" /></button>
                                <button onClick={(e) => this.setState({ match: folder }, () => {
                                    this.toggle_folder_menu(i);
                                })} className="btn btn-default"><i className="icon-share black" /></button>
                                <button className="btn btn-default" onClick={() => this.onRemoveFolder(folder)}><i className="fas fa-times red" /></button>
                                <button className="btn btn-default" onClick={(e) => this.toggle_folder_menu(i)}><i className="black icon-more-vertical" /></button>
                            </Animated>

                        </Animated>
                    )}

                    {/* NO */}
                    {!loading && filtered_folders && !filtered_folders.length && <div className="d-flex w-100 flex-column justify-content-center">
                        <img src="/images/icons/red_card.png" className="w-25 align-self-center mt-4" />
                        <span className="font-30 align-self-center text-center mx-4 px-4 line1 mt-4">Sorry, you have no<br />folders in this area</span>
                    </div>}

                    <div style={{ height: 200 }} className="col-12 " />
                </div>
            </section>}

            {/* ADD */}
            <div onClick={() => this.setState({ menu_toggled: !this.state.menu_toggled })} style={{ width: 50, height: 50, borderRadius: 25, overflow: 'hidden', position: 'fixed', bottom: 20, right: 20 }} className="">
                <div className="bg-info d-flex flex-column justify-content-center h-100 w-100">
                    <i className="fas fa-plus white font-30 align-self-center" />
                </div>
            </div>

            {/* 1st MENU */}
            <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeInUp" animationOut='fadeOut' isVisible={this.state.menu_toggled ? true : false} className="bg-white card tile shadow d-flex flex-column" style={{ position: 'fixed', bottom: 20, right: 20 }}>
                <button className="btn btn-default" onClick={() => this.setState({ adding_evaluation: true, menu_toggled: false, mode: 1 })}>
                    <div className="d-flex flex-column">
                        <i className="icon-user-plus black font-30" />
                        <span>Profile</span>
                    </div>
                </button>
                <button className="btn btn-default" onClick={() => this.setState({ adding_folder: true, menu_toggled: false, mode: 2 })}>
                    <div className="d-flex flex-column">
                        <i className="icon-folder black font-30" />
                        <span>Folder</span>
                    </div>
                </button>
                <button className="btn btn-default" onClick={() => this.setState({ importing: true, menu_toggled: false, mode: 1 })}>
                    <div className="d-flex flex-column">
                        <i className="icon-arrow-down-circle black font-30" />
                        <span>Import</span>
                    </div>
                </button>
                <div className="align-self-center mb-2 mt-2" onClick={() => this.setState({ menu_toggled: !this.state.menu_toggled })} style={{ width: 50, height: 50, borderRadius: 25, overflow: 'hidden' }}>
                    <div className="bg-info d-flex flex-column justify-content-center h-100 w-100">
                        <i className="fas fa-times white font-30 align-self-center" />
                    </div>
                </div>
            </Animated>

            {/* ADD FOLDER */}
            <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeInUp" animationOut='fadeOut' isVisible={this.state.adding_folder ? true : false} className="bg-white card tile shadow d-flex flex-column" style={{ position: 'fixed', bottom: 20, width: 300, right: 20 }}>
                <div className="bg-white p-4 w-100">
                    <label>Folder's Name</label>
                    <input className="w3-input w-100" type="text" onChange={(e) => { this.setState({ folder_name: e.target.value }); }} ref={i => this.txtFolderName = i} />
                    <button className={`btn btn-${this.state.saved ? 'success' : 'info'} mt-4 w-100`} type="button" onClick={this.onSaveFolder}>{this.state.saved ? 'Save Folder' : 'Save Folder'}</button>
                    <button className={`btn btn-${this.state.saved ? 'link' : 'link'} mt-1 w-100`} type="button" onClick={() => this.setState({ adding_folder: false, menu_toggled: true })}>Cancel</button>
                </div>
            </Animated>

            {/* EDIT FOLDER */}
            {this.state.editing_folder && <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeInUp" animationOut='fadeOut' isVisible={this.state.editing_folder ? true : false} className="bg-white card tile shadow d-flex flex-column" style={{ position: 'fixed', bottom: 20, width: 300, right: 20 }}>
                <div className="bg-white p-4 w-100">
                    <label>Folder's Name</label>
                    <input className="w3-input w-100" type="text" defaultValue={this.state.editing_folder.Name || this.state.editing_folder.FolderName} onChange={(e) => { this.setState({ edit_folder_name: e.target.value }); }} />
                    <button className={`btn btn-${this.state.saved ? 'success' : 'info'} mt-4 w-100`} type="button" onClick={this.onEditFolder}>{this.state.saved ? 'Save Folder' : 'Save Folder'}</button>
                    <button className={`btn btn-${this.state.saved ? 'link' : 'link'} mt-1 w-100`} type="button" onClick={() => this.setState({ editing_folder: false, menu_toggled: true })}>Cancel</button>
                </div>
            </Animated>}

            {/* IMPORT */}
            <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeInUp" animationOut='fadeOut' isVisible={this.state.importing ? true : false} className="bg-white card tile shadow d-flex flex-column" style={{ position: 'fixed', bottom: 20, width: 350, right: 20 }}>
                <div className="bg-white p-4 w-100">
                    {!this.state.invalid_link && <label>Search by Shared Link</label>}
                    {this.state.invalid_link && <label className="red">Invalid Link</label>}
                    <input className="w3-input w-100" type="text" onChange={(e) => { this.setState({ notes_link: e.target.value }); }} />
                    <button className={`btn btn-${this.state.saved ? 'success' : 'info'} mt-4 w-100`} type="button" onClick={this.onImport}>{this.state.saved ? 'Import' : 'Import'}</button>
                    <button className={`btn btn-${this.state.saved ? 'link' : 'link'} mt-1 w-100`} type="button" onClick={() => this.setState({ importing: false, menu_toggled: true })}>Cancel</button>
                    {this.state.found &&
                        <section>
                            <div className="col-sm-6 col-md-3 p-1 w3-container w3-cell">
                                <div className="w-100  py-2 tile card d-flex flex-row h-100 justify-content-start">
                                    <div className="align-self-center d-flex flex-row justify-content-center" style={{ width: 60, height: 60 }}>
                                        <i className="black align-self-center icon-git-pull-request font-50" />
                                    </div>
                                    <div className="w-100 d-flex flex-column black ms-2 align-self-center">
                                        <span className="line1 toBold">{`${this.state.found.NameFirst} ${this.state.found.NameLast}`}</span>
                                        <span className="line1 ">{this.state.found.Position}</span>
                                        <span className="black font-8">by {`${this.state.found.CreatorNameFirst} ${this.state.found.CreatorNameLast}`} @ {moment(this.state.found.EvaluationDate).format('MMM Do YYYY')}</span>
                                    </div>
                                </div>
                            </div>
                            <button className={`btn btn-${this.state.saved ? 'success' : 'success'} mt-4 w-100`} type="button" onClick={this.onCopy}>{this.state.saved ? 'Get a Copy' : 'Get a Copy'}</button>
                        </section>}
                </div>
            </Animated>
        </div>
    }
    render() {
        return (
            <Layout router={this.props.router} className="bg-white" nbs="network" title="SQWAD SCOUT | SCOUTING" form={this.props.form} form_actions={this.props.form_actions}>
                <section>
                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small" style={{ marginLeft: 200 }}>
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                    </div>
                    {/* MOBILE */}
                    <div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                    </div>
                    <Menu {...this.state} {...this.props} />
                    <Profile {...this.state} scout_actions={this.props.scout_actions} toggle={() => this.setState({ profile: false })} />
                    <AddEvaluation {...this.state} scout_actions={this.props.scout_actions} toggle={() => this.setState({ adding_evaluation: false })} />
                    <ShareMatch {...this.state} scout_actions={this.props.scout_actions} toggle={() => this.setState({ match: false })} />
                    <MoveToFolder {...this.state} scout_actions={this.props.scout_actions} toggle={() => this.setState({ move_to_folder: false })} />
                    {this.props.loading && <LineLoader />}
                </section>
            </Layout>
        )
    }
}

export default EvaluationsMain;
