import React from 'react';
import Layout from '../layouts/claire';
import { preload } from '../../helpers';
import { Animated } from 'react-animated-css';
import Menu from './partials/menu';
import fakeAuth from '../../fakeAuth';
import coach_profile from '../../redux-sagas/reducers/coach_profile';
import AddCoachProfile from './partials/add_coach_profile';

class Home extends React.Component {
    state = {
        menu_toggled: false
    }
    componentWillMount() {
        this.props.scout_actions && this.props.scout_actions.fetch_coach_profile(fakeAuth.getUser().IdUser);        
    }
    componentWillReceiveProps = nextProps => {
        const { coach_profile } = nextProps;
        this.setState({ coach_profile: (coach_profile && coach_profile.IdCoachProfile) ? coach_profile : null, bootstrapped: true });
    }
    renderCommon = () => {
        const { bootstrapped, coach_profile } = this.state;

        return (
            <div className="w3-content px-4 container pt-4">
                {bootstrapped && coach_profile && <section>
                    
                    {/* NO */}
                    <div className="w3-container w3-hide-medium w3-hide-large d-flex flex-column justify-content-center" style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                        <img src="/images/icons/red_card.png" className="w-25 align-self-center mt-4" style={{ maxWidth: 150 }} />
                        <span className="font-30 align-self-center text-center mx-4 px-4 line1 mt-4">Nothing to see here yet</span>
                    </div>
                    <div className="w3-container w3-hide-small d-flex flex-column justify-content-center" style={{ position: 'absolute', top: 0, bottom: 0, left: 200, right: 0 }}>
                        <img src="/images/icons/red_card.png" className="w-25 align-self-center mt-4" style={{ maxWidth: 150 }} />
                        <span className="font-30 align-self-center text-center mx-4 px-4 line1 mt-4">Nothing to see here yet</span>
                    </div>
                    
                </section>}

                {bootstrapped && !coach_profile && <section className="p-4 w3-container">

                    <div className="row">
                        <div className="card tile bg-blue-dark font-18 line1 w-100 p-4 pointer" onClick={() => this.setState({ adding_coach_profile: true })}>
                            <div className="d-flex flex-row">
                                <div>
                                <p className="white montserrat">Complete your profile</p>
                                <p className="white montserrat font-14">You don't currently have a <strong>Scouting, Coaching or Recruiting profile.</strong> Without a prlfile you'll not be able to connect with potential recruits. Please click here to add.</p>
                                </div>
                                <i className="icon-loader blue-link font-40" />
                            </div>
                        </div>

                    </div>

                </section>}

            </div>
        )
    }
    render() {
        const form = this.props.form_actions;
        return (
            <Layout router={this.props.router} className="bg-white" nbs="home" title="SQWAD SCOUT" form={this.props.form} form_actions={this.props.form_actions}>
                <section>

                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small" style={{ marginLeft: 200 }}>
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                    </div>
                    {/* MOBILE */}
                    <div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                    </div>

                    <Menu {...this.state} {...this.props} />

                    <AddCoachProfile {...this.state} {...this.props} toggle={() => this.setState({ adding_coach_profile: false })} />

                </section>
            </Layout>
        )
    }
}

export default Home;
