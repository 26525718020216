import React from 'react';
import Layout from '../layouts/claire';
import { Animated } from 'react-animated-css';
import Menu from '../home/partials/menu';
import { Link } from 'react-router';
import Teams from './partials/teams';
import MenuDesktop from './partials/menu_team_desktop';
import MenuMobile from './partials/menu_team_mobile';
import _ from 'lodash';

class Team extends React.Component {
    state = {
        path: []
    }

    // Lifecycle
    componentWillMount() {
        this.props.scout_actions && this.props.scout_actions.fetchFollowees();
        this.setState({ path: _.chain(this.props.router.getCurrentLocation()).value().pathname.split('/') });
    }
    componentDidMount() {
        // TODO: fetch team by its id
        const { router } = this.props, { params } = router;
        this.props.scout_actions && this.props.scout_actions.fetchFolders();
        this.props.scout_actions && this.props.scout_actions.fecthTeam(params.id);
        this.props.scout_actions && this.props.scout_actions.clearFolder();
        this.props.scout_actions && this.props.scout_actions.clearUser();
    }
    componentWillUnmount = () => {
        this.props.scout_actions.clear_team();
    }
    //

    onMenu = (folder, e) => {
        e.preventDefault && e.preventDefault();
        e.stopPropagation && e.stopPropagation();        
    }

    renderCommon = () => {
        const { team = {}, roster = [], followees } = this.props;

        return <section className="w3-content">

            {team && <section>

                <div className="px-4 py-3 tile card d-flex flex-row mt-4" >
                    <div className="align-self-center cover" style={{ background: `url(${team.TeamImageUrl || team.TeamImage || team.Image}) no-repeat center center`, width: 60, height: 60 }}></div>
                    <div className="d-flex flex-column black ms-4 align-self-center">
                        <span className="line1">{team.Name || team.TeamName}</span>
                        <span className="line1">{team.SeasonName}</span>
                    </div>
                </div>

                <h3 className="gray syncopate mt-4">COACHES</h3>
                <div className="row w3-container">
                    {_.chain(roster).filter(u => u.IdRole === 4).value().map((user, index) =>
                        <Animated animationInDelay={50} animationIn="fadeIn" className="col-md-6 p-0 w3-cell p-1" key={index}>
                            <div className="px-4 py-3 tile card  d-flex flex-row" >
                                <div className="align-self-center cover" style={{ background: `url(${user.UserImage || user.Image}) no-repeat center center black`, width: 60, height: 60, borderRadius: 30, border: `1px solid gray` }}></div>
                                <div className="d-flex flex-column black ms-4 align-self-center">
                                    <span className="line1 blue font-14">{user.NameFirst} {user.NameLast}</span>
                                    <span className="line1 font-12">{user.EmailAddress}</span>
                                    <span className="line1">{user.RoleOverrideName}</span>
                                </div>
                            </div>
                        </Animated>
                    )}
                </div>

                <h3 className="gray syncopate mt-4">PLAYERS</h3>

                <div className="row w3-container">
                    {_.chain(roster).filter(u => u.IdRole === 3).value().map((user, index) =>
                        <Animated animationInDelay={50} animationIn="fadeIn" className="col-md-6 col-lg-4 p-0 w3-cell p-1" key={index}>
                            <Link className="tile card" to={`/${_.find(followees, r => r.IdUserFollow === user.IdUser) ? 'prospect' : 'lead'}/${user.IdUser}`}>
                                <div className="d-flex flex-row">
                                    <div className="align-self-center cover block" style={{ background: `url(${user.UserImage || user.Image}) no-repeat center center black`, width: 100, height: 90 }}>
                                        {_.find(followees, r => r.IdUserFollow === user.IdUser) && <i className="fas fa-check-circle green ms-1 font-12" />}
                                    </div>
                                    <div className="black align-self-center w-100 p-3 d-flex flex-column">
                                        <div className="d-flex flex-row">
                                            <span className="line1 align-self-center font-10 w-100">#{user.JerseyNumber}</span>
                                            <span style={{ minWidth: 100 }} className="line1 align-self-center font-10 ms-auto max-lines-1 text-right">{user.GraduationYear}</span>
                                        </div>
                                        <span className="line1 font-16 mt-1 text-center align-self-center w-100">{user.NameFirst}<br />{user.NameLast}</span>
                                    </div>
                                </div>
                                <div className={`p-1 bg-${user.Highlights.length ? 'success' : 'gray'} font-8 text-center white`}>HIGHLIGHTS</div>
                            </Link>
                        </Animated>
                    )}
                    <div className="col-12 p-4" />
                </div>
            </section>}
        </section>
    }
    render() {
        return (
            <Layout router={this.props.router} className="bg-white" nbs="network" title="SQWAD SCOUT | TEAM" form={this.props.form} form_actions={this.props.form_actions}>
                <section>
                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small" style={{ marginLeft: 200 }}>
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                        <MenuDesktop {...this.state} {...this.props} />
                    </div>
                    {/* MOBILE */}
                    <div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                        <MenuMobile {...this.state} {...this.props} />
                    </div>
                    <Menu hide={true} {...this.state} {...this.props} />
                </section>
            </Layout>
        )
    }
}

export default Team;
