import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';

export function* getEvaluation() {
    yield* takeEvery("EVAL/FETCH", function* (action) {
        try {
            const result = yield call(({ idEvaluation }) => {
                return request.get(`${config.apiEndpoint}/api/external/evaluations/${idEvaluation}`)
                    .set('Authorization', `Bearer ${config.apiKey}`)
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {                
                const { evaluation } = result;                                
                yield put({ type: 'EVAL/SET', evaluation });
            } else {
                yield put({
                    type: 'EVAL/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'EVAL/FETCH_FAILED',
                e
            });
        }
    });
}