import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import moment from 'moment';
import { generateRandomId } from '../../../helpers';
import { Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import fakeAuth from '../../../fakeAuth';

class FollowLevels extends React.Component {
    state = {

    }

    componentWillReceiveProps = nextProps => {
        const { followee, follow_levels } = nextProps;
        this.setState({ followee, follow_levels });
    }

    render() {
        const { followee } = this.state, { follow_levels } = this.props;        
        return <section>
            <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="slideInRight" animationOut="slideOutRight" isVisible={this.props.picking_follow_level ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, width: 400, right: 0, paddingTop: 70, zIndex: 200 }}>
                <section className="bg-whiteish d-flex flex-column w-100 p-4" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, paddingTop: 70, overflowY: 'auto' }}>
                    <div className="p-4" />
                    {follow_levels && followee && follow_levels.map((f, i) => <button onClick={() => this.props.fn(f)} key={i} className={`mx-4 btn btn-${followee.IdFollowLevel === f.IdFollowLevel ? 'success' : 'link'} bordered`}>{f.Name}</button>)}
                </section>
                <div onClick={this.props.toggle} style={{ width: 50, height: 50, borderRadius: 25, position: 'fixed', top: 15, right: 15 }} className="bg-gray d-flex flex-column justify-content-center">
                    <i className="fas fa-times black font-30 align-self-center" />
                </div>
            </Animated>
        </section>
    }
}

export default FollowLevels;