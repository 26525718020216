import React from 'react';
import Layout from '../layouts/claire';
import { Animated } from 'react-animated-css';
import Menu from '../home/partials/menu';
import { Link } from 'react-router';
import MenuNotes from '../network/partials/menu_notes';
import _ from 'lodash';
import fakeAuth from '../../fakeAuth';

class Scouting extends React.Component {
    state = {
        menu_toggled: false
    }
    componentWillMount() {
        this.props.scout_actions && this.props.scout_actions.fetch_family_subscriptions();
    }
    componentWillReceiveProps = nextProps => {
        this.setState({ subscriptions: nextProps.subscriptions }, () => {            
            this.setState({
                subscription: _.find(nextProps.subscriptions, s => s.IdPlan === '7404C09F-9E11-4FBF-BF82-B2C487A911F5' && !s.Expired && s.IdUser === fakeAuth.getUser().IdUser),
                bootrstrapped: true
            });
        });
    }

    renderCommon = () => {
        const { subscription, bootrstrapped } = this.state;
        return <div className="w3-content pt-4">

            <div className="row m-0 p-0">
                {bootrstrapped === true && <section className="d-flex flex-column justify-content-center p-0">

                    {/* SUBS */}
                    {!subscription && <Animated animationInDelay={0} animationIn="fadeInRight" className="w-100 premium-gradient px-4 py-2 rounded align-self-center pointer">
                        <Link to="/subscriptions" className="d-flex flex-row px-4 py-2">
                            <div className="d-flex flex-column w-100">
                                <span className="sqwad-font font-16 black align-self-center line1">UPGRADE</span>
                                <span className="font-12 black align-self-center line1">to SQWAD SCOUT Pro</span>
                            </div>
                        </Link>
                    </Animated>}

                    {/* EVALUATIONS */}
                    {subscription && <Animated animationInDelay={25} animationIn="fadeInRight" className="w-100 mt-3 bg-gray px-4 py-2 rounded align-self-center pointer">
                        <div onClick={() => this.setState({ menu_toggled: !this.state.menu_toggled })} className="d-flex flex-row">
                            <div className="align-self-center d-flex flex-row justify-content-center" style={{ width: 60, height: 60 }}>
                                <i className="blue align-self-center icon-bar-chart font-50" />
                            </div>
                            <div className="d-flex flex-column w-100 align-self-center ms-2">
                                <span className="font-12 black syncopate align-self-start line1 mt-1">PLAYER EVALUATIONS</span>
                                <span className="font-10 black align-self-start line1">Analyze and evaluate the traits<br />of a player</span>
                            </div>
                            <i className="black align-self-center fas fa-chevron-right font-12" />
                        </div>
                    </Animated>}
                    
                    {!subscription && <Animated animationInDelay={50} animationIn="fadeInRight" className="w-100 bg-gray mt-3 rounded align-self-center ">
                        <Link to={"/subscriptions"} className="d-flex flex-row px-4 py-2">
                            <div className="align-self-center d-flex flex-row justify-content-center" style={{ width: 60, height: 60 }}>
                                <i className="blue align-self-center icon-bar-chart font-50" />
                            </div>
                            <div className="d-flex flex-column w-100 align-self-center ms-2">
                                <span className="font-12 black syncopate align-self-start line1 mt-1">PLAYER EVALUATIONS</span>
                                <span className="font-10 black align-self-start line1">Analyze and evaluate the traits<br />of a player</span>
                            </div>
                            <i className="black align-self-center fas fa-chevron-right font-12" />
                        </Link>
                    </Animated>}

                    {/* PLAYER MATCHES */}
                    <Animated animationInDelay={50} animationIn="fadeInRight" className="w-100 bg-gray mt-3 rounded align-self-center ">
                        <Link to={subscription ? "/scouting_player_matches" : "/subscriptions"} className="d-flex flex-row px-4 py-2">
                            <div className="align-self-center d-flex flex-row justify-content-center" style={{ width: 60, height: 60 }}>
                                <i className="red align-self-center icon-user-plus font-50" />
                            </div>
                            <div className="d-flex flex-column w-100 align-self-center ms-2">
                                <span className="font-12 black syncopate align-self-start line1 mt-1">SCOUTING PLAYER</span>
                                <span className="font-10 black align-self-start line1">Templates used to scout a player<br />during a match</span>
                            </div>
                            <i className="black align-self-center fas fa-chevron-right font-12" />
                        </Link>
                    </Animated>

                    {/* TEAM MATCHES */}
                    <Animated animationInDelay={75} animationIn="fadeInRight" className="w-100 bg-gray mt-3 rounded align-self-center ">
                        <Link to={subscription ? "/scouting_team_matches" : '/subscription'} className="d-flex flex-row px-4 py-2">
                            <div className="align-self-center d-flex flex-row justify-content-center" style={{ width: 60, height: 60 }}>
                                <i className="purple align-self-center icon-CrestOnly_icon-2 font-50" />
                            </div>
                            <div className="d-flex flex-column w-100 align-self-center ms-2">
                                <span className="font-12 black syncopate align-self-start line1 mt-1">SCOUTING TEAM</span>
                                <span className="font-10 black align-self-start line1">Templates used to scout a team<br />during a match</span>
                            </div>
                            <i className="black align-self-center fas fa-chevron-right font-12" />
                        </Link>
                    </Animated>

                </section>}
            </div>

        </div>
    }
    render() {
        return (
            <Layout router={this.props.router} className="bg-white" nbs="network" title="SQWAD SCOUT | SCOUTING" form={this.props.form} form_actions={this.props.form_actions}>
                <section>
                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small" style={{ marginLeft: 200 }}>
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                    </div>
                    {/* MOBILE */}
                    <div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                    </div>
                    <Menu {...this.state} {...this.props} />
                    <MenuNotes {...this.state} toggle={() => this.setState({ menu_toggled: !this.state.menu_toggled })} />
                </section>
            </Layout>
        )
    }
}

export default Scouting;
