import React from 'react';
import _ from 'lodash';
import FamilyMember from './entity';
import { Popover, PopoverBody } from 'reactstrap';

class Email extends React.Component {
  state = {
    email: null, editing: false, deleting: false
  }

  // Lifecycle
  componentWillMount() {
    this.setState({ email: this.props.email, editing: this.props.email.IdEmailAddress ? false : true });
  }
  componentWillReceiveProps = (newProps) => {
    this.setState({ email: newProps.email });
  }
  componentDidMount() {
    this.txtEmail && this.txtEmail.focus();
  }
  //


  toggleEdit = () => this.setState(prevState => ({ editing: !prevState.editing }));
  toggleDelete = () => this.setState(prevState => ({ deleting: !prevState.deleting }));

  saveEmail = e => {
    e.preventDefault && e.preventDefault();
    const { email } = this.state;
    email.Address = this.txtEmail.value;
    !email.IdEmailAddress ? this.props.familyActions.saveEmail(email) : this.props.familyActions.updateEmail(email);
    this.setState({ email });
    this.toggleEdit();
  }

  doDelete = e => {
    const { email } = this.state;
    this.props.familyActions.deleteEmail(email.IdEmailAddress);
    this.toggleDelete();
  }

  render() {
    const { email, editing } = this.state, { family } = this.props;
    return (
      <div className={`card tile shadow-box p-2 margin-bottom-half`} style={{ position: 'relative' }}>

        {!editing && <span className="font-16 margin-bottom-half bg-darker-blue p-2 d-flex flex-row">
          {email.CanDelete === false && <i className="fas fa-lock white margin-right-half align-self-center" />}
          <span className="align-self-center margin-right-half white">{email.Address}</span>
          {email.IdEmailAddress && email.CanDelete === true && <button onClick={this.toggleEdit} className="btn btn-link btn-sm ml-auto white"><i className="fas fa-pen" /></button>}
          {email.IdEmailAddress && email.CanDelete === true && <button id={`btnDeleteEmail-${email.IdEmailAddress}`} onClick={this.toggleDelete} className="btn btn-link btn-sm white"><i className="fa fa-times" /></button>}
          {email.IdEmailAddress && email.CanDelete && <Popover placement="bottom" isOpen={this.state.deleting} target={`btnDeleteEmail-${email.IdEmailAddress}`} toggle={this.toggleDelete}>
            <PopoverBody className="d-flex flex-column justify-content-center">
              <span className="align-self-center">Are you sure?</span>
              <button className="btn btn-sm btn-danger" onClick={this.toggleDelete}>No, cancel</button>
              <button className="btn btn-sm btn-success" onClick={this.doDelete}>Yes, delete it!</button>
            </PopoverBody>
          </Popover>}
        </span>}

        {editing && <form>
          <div className="input-group margin-bottom-half">
            <input defaultValue={email.Address} className="form-control" type="text" name="email" placeholder="Enter the Email" ref={(input) => this.txtEmail = input} />
            <div className="input-group-append">
              <button onClick={this.saveEmail} className="btn btn-success" type="submit"><i className="fa fa-check" /></button>
            </div>
          </div>
        </form>}

        <div className={`clearfix ${email.IdEmailAddress ? '' : 'transparent'}`}>
          {email.IdEmailAddress && family && family.map((user, i) =>
            <div style={{ float: 'left' }} className="me-2 mb-1 fl" key={i}>
              <FamilyMember email={email} familyActions={email.IdEmailAddress ? this.props.familyActions : null} entity={user}
                isActive={_.find(email.users, p => { return p.IdUser === user.IdUser; })} />
            </div>)}
        </div>
      </div>)
  }
}

export default Email;
