import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import moment from 'moment';
import { generateRandomId } from '../../../helpers';
import fakeAuth from '../../../fakeAuth';
import { Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Slider from 'react-rangeslider';

// To include the default styles
import 'react-rangeslider/lib/index.css'

class AddCoachProfile extends React.Component {
    state = {
        bootstrapped: false,
        step: 1,
    }

    componentWillMount() {
        this.props.scout_actions && this.props.scout_actions.fetchCommunities();
        this.props.scout_actions && this.props.scout_actions.fetchCountries();
        const { coach_profile } = this.props;
        this.setState({ coach_profile });
    }
    componentWillReceiveProps = nextProps => {
        const { coach_profile, communities, countries, states } = nextProps;
        this.setState({
            //coach_profile,
            communities,
            states,
            step: this.props.adding_coach_profile ? this.state.step : ((coach_profile && coach_profile.IdCoachProfile) ? 1 : 0),
            countries
        }, () => {
            if (this.state.step === 1 && this.state.coach_profile && this.state.coach_profile.IdCountry && !this.state.states) {
                this.props.geo_actions && this.props.geo_actions.fetchStates && this.props.geo_actions.fetchStates(this.state.coach_profile.IdCountry);
            }
        });
    }

    onSport = s => {
        const { coach_profile } = this.state;
        coach_profile.IdCommunity = s ? s.IdCommunity : null;
        coach_profile.Community = s ? (s.Name || s.CommunityName) : null
        this.setState({ coach_profile });
    }
    onRole = role => {
        const { coach_profile } = this.state;
        coach_profile.RoleName = role;
        this.setState({ coach_profile });
    }
    onDivision = division => {
        const { coach_profile } = this.state;
        coach_profile.Division = division;
        this.setState({ coach_profile });
    }
    onType = t => {
        const { coach_profile } = this.state;
        coach_profile.Type = t;
        this.setState({ coach_profile });
    }
    onCountry = (c) => {
        const { coach_profile } = this.state;
        coach_profile.IdCountry = c.IdCountry;
        this.props.geo_actions && this.props.geo_actions.clearStates && this.props.geo_actions.clearStates();
        this.props.geo_actions && this.props.geo_actions.fetchStates && this.props.geo_actions.fetchStates(c.IdCountry);
        this.setState({ coach_profile });
    }
    onState = (s) => {
        const { coach_profile } = this.state;
        coach_profile.IdState = s.IdState;
        this.setState({ coach_profile });
    }

    render() {
        const { communities, coach_profile = {}, step, countries, states } = this.state;

        const splitCountries = _.partition(countries || [], c => {
            return c.ISOCode === 'US' || c.ISOCode === 'CA' || c.ISOCode === 'UK';
        });

        return <section>
            <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="slideInRight" animationOut="slideOutRight" isVisible={this.props.adding_coach_profile ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200 }}>

                {step === 0 && <section className="bg-whiteish w-100 p-4" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, paddingTop: 70, overflowY: 'auto' }}>

                    <div className="w3-content mx-auto d-flex flex-column justify-content-center">
                        <i className="icon-recreational_icon font-80 green align-self-center" />
                        <span className="align-self-center font-20">Let's start!</span>
                        <span className="align-self-center">by selecting your sport</span>
                        <button className="btn btn-success align-self-center mt-2" onClick={() => {
                            this.setState({ step: 1, pickSport: true });
                        }}>Select Sport</button>
                    </div>
                </section>}

                {step === 1 && <section className="bg-whiteish w-100 p-4" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, paddingTop: 70, overflowY: 'auto' }}>
                    <h2 className="sqwad-font text-center">SPORT</h2>
                    {coach_profile && <div style={{ maxWidth: 500 }} className="w3-content mx-auto bg-white p-4 mt-4">

                        <label className="font-10">Sport</label>
                        <Dropdown isOpen={this.state.pickSport ? true : false} toggle={() => this.setState({ pickSport: !this.state.pickSport })} className="w3-input bordered rounded">
                            <DropdownToggle style={{ borderColor: 'transparent' }} className="text-left d-flex flex-row h-100 py-0 bg-white black w3-input w-100" >
                                <span className="align-self-center w-100">{coach_profile.Community || 'Select'}</span>
                                <i className="ms-auto fas fa-caret-down align-self-center " />
                            </DropdownToggle>
                            <DropdownMenu className="w-100" style={{ overflowY: 'auto', maxHeight: 300 }}>
                                <DropdownItem onClick={() => this.onSport()}>None</DropdownItem>
                                {communities && communities.map((c, i) => <DropdownItem key={i} onClick={() => this.onSport(c)}>{c.CommunityName || c.Name}</DropdownItem>)}
                            </DropdownMenu>
                        </Dropdown>


                        <label className="mt-3 font-10">Role</label>
                        <Dropdown isOpen={this.state.pickRole ? true : false} toggle={() => this.setState({ pickRole: !this.state.pickRole })} className="w3-input w-100 bordered rounded">
                            <DropdownToggle style={{ borderColor: 'transparent' }} className="text-left d-flex flex-row h-100 py-0 bg-white black w3-input w-100" >
                                <span className="align-self-center w-100">{coach_profile.RoleName || 'Select'}</span>
                                <i className="ms-auto fas fa-caret-down align-self-center " />
                            </DropdownToggle>
                            <DropdownMenu className="w-100" style={{ overflowY: 'auto', maxHeight: 300 }}>
                                <DropdownItem onClick={() => this.onRole()}>None</DropdownItem>
                                {['Coach', 'Assistant Coach', 'Scout', 'Manager', 'Trainer', 'Recruiter', 'Other'].map((c, i) => <DropdownItem key={i} onClick={() => this.onRole(c)}>{c}</DropdownItem>)}
                            </DropdownMenu>
                        </Dropdown>


                        <label className="mt-3 font-10">Conference</label>
                        <input onChange={({ target }) => {
                            const { coach_profile } = this.state;
                            coach_profile.Conference = target.value;
                            this.setState({ coach_profile });
                        }} className="w3-input bordered rounded" defaultValue={coach_profile.Conference} type="text" ref={i => this.txtConference = i} />


                        <label className="mt-3 font-10">Division</label>
                        <Dropdown isOpen={this.state.pickDivision ? true : false} toggle={() => this.setState({ pickDivision: !this.state.pickDivision })} className="w3-input w-100 bordered rounded">
                            <DropdownToggle style={{ borderColor: 'transparent' }} className="text-left d-flex flex-row h-100 py-0 bg-white black w3-input w-100" >
                                <span className="align-self-center w-100">{coach_profile.Division || 'Select'}</span>
                                <i className="ms-auto fas fa-caret-down align-self-center " />
                            </DropdownToggle>
                            <DropdownMenu className="w-100" style={{ overflowY: 'auto', maxHeight: 300 }}>
                                <DropdownItem onClick={() => this.onDivision()}>None</DropdownItem>
                                {['Division 1', 'Division 2', 'Division 3', 'NAIA', 'NJCAA'].map((c, i) => <DropdownItem key={i} onClick={() => this.onDivision(c)}>{c}</DropdownItem>)}
                            </DropdownMenu>
                        </Dropdown>

                        {coach_profile.IdCommunity && <button className={`btn btn-${this.state.saved ? 'success' : 'info'} mt-4 w-100`} type="button" onClick={() => this.setState({ step: 2 })}>Next</button>}
                        <div className="p-4" />
                    </div>}
                </section>}



                {step === 2 && <section className="bg-whiteish w-100 p-4" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, paddingTop: 70, overflowY: 'auto' }}>
                    <h2 className="sqwad-font text-center">INSTITUTION</h2>
                    {coach_profile && <div style={{ maxWidth: 500 }} className="w3-content mx-auto  w-100 bg-white p-4 mt-4">

                        <label className="font-10">College | Academy | Organization</label>
                        <input onChange={({ target }) => {
                            const { coach_profile } = this.state;
                            coach_profile.SchoolName = target.value;
                            this.setState({ coach_profile });
                        }} className="w3-input bordered rounded" defaultValue={coach_profile.SchoolName} type="text" />


                        <label className="mt-3 font-10">Type</label>
                        <Dropdown isOpen={this.state.pickType ? true : false} toggle={() => this.setState({ pickType: !this.state.pickType })} className="w3-input w-100 bordered rounded tile">
                            <DropdownToggle style={{ borderColor: 'transparent' }} className="text-left d-flex flex-row h-100 py-0 bg-white black w3-input w-100" >
                                <span className="align-self-center w-100">{coach_profile.Type || 'Select'}</span>
                                <i className="ms-auto fas fa-caret-down align-self-center " />
                            </DropdownToggle>
                            <DropdownMenu className="w-100" style={{ overflowY: 'auto', maxHeight: 300 }}>
                                <DropdownItem onClick={() => this.onType()}>None</DropdownItem>
                                {['Public', 'Private'].map((c, i) => <DropdownItem key={i} onClick={() => this.onType(c)}>{c}</DropdownItem>)}
                            </DropdownMenu>
                        </Dropdown>


                        <label className="mt-3 font-10">Country</label>
                        <Dropdown isOpen={this.state.pickCountry ? true : false} toggle={() => this.setState({ pickCountry: !this.state.pickCountry })} className="w3-input w-100 bordered rounded">
                            <DropdownToggle style={{ borderColor: 'transparent' }} className="text-left d-flex flex-row h-100 py-0 bg-white black w3-input w-100" >
                                <span className="align-self-center w-100">{(coach_profile.IdCountry && countries) ? _.find(countries, c => c.IdCountry === coach_profile.IdCountry).CountryName : 'Select'}</span>
                                <i className="ms-auto fas fa-caret-down align-self-center " />
                            </DropdownToggle>
                            <DropdownMenu className="w-100" style={{ overflowY: 'auto', maxHeight: 300 }}>
                                {splitCountries.length >= 1 && splitCountries[0].map((c) => <DropdownItem key={c.IdCountry} onClick={() => this.onCountry(c)}>
                                    {c.CountryName}
                                </DropdownItem>)}
                                <DropdownItem divider />
                                {splitCountries.length > 1 && splitCountries[1].map((c) => <DropdownItem key={c.IdCountry} onClick={() => this.onCountry(c)}>
                                    {c.CountryName}
                                </DropdownItem>)}
                            </DropdownMenu>
                        </Dropdown>

                        <label className="mt-3 font-10">State</label>
                        <Dropdown isOpen={this.state.pickState ? true : false} toggle={() => this.setState({ pickState: !this.state.pickState })} className="w3-input w-100 bordered rounded">
                            <DropdownToggle style={{ borderColor: 'transparent' }} className="text-left d-flex flex-row h-100 py-0 bg-white black w3-input w-100" >
                                <span className="align-self-center w-100">{(coach_profile.IdState && states && _.find(states, c => c.IdState === coach_profile.IdState)) ? _.find(states, c => c.IdState === coach_profile.IdState).StateName : 'Select'}</span>
                                <i className="ms-auto fas fa-caret-down align-self-center " />
                            </DropdownToggle>
                            <DropdownMenu className="w-100" style={{ overflowY: 'auto', maxHeight: 300 }}>
                                {states && states.length >= 1 && states.map((s) => <DropdownItem key={s.IdState} onClick={() => this.onState(s)}>
                                    {s.StateName}
                                    {' ('}
                                    {s.StateCode}
                                    {') '}</DropdownItem>)}
                            </DropdownMenu>
                        </Dropdown>

                        <label className="mt-3 font-10">City</label>
                        <input onChange={({ target }) => {
                            const { coach_profile } = this.state;
                            coach_profile.City = target.value;
                            this.setState({ coach_profile });
                        }} className="w3-input bordered rounded" defaultValue={coach_profile.City} type="text" />

                        {coach_profile.IdCommunity && <button className={`btn btn-${this.state.saved ? 'success' : 'info'} mt-4 w-100`} type="button" onClick={() => this.setState({ step: 3 }, () => {
                            this.props.scout_actions.set_coach_profile({
                                ...coach_profile, Communities: [
                                    { IdCommunity: coach_profile.IdCommunity }
                                ],
                                IdUser: coach_profile.IdUser || fakeAuth.getUser().IdUser
                            });
                        })}>{`Save ${coach_profile.IdCoachProfile ? 'Profile' : 'New Profile'}`}</button>}
                        <div className="p-4" />
                    </div>}

                </section>}


                {step === 3 && <section className="bg-whiteish w-100 p-4" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, paddingTop: 70, overflowY: 'auto' }}>
                    <h2 className="sqwad-font text-center">DONE</h2>

                    <div className="w3-content mx-auto  w-100 bg-white p-4 mt-4">
                        <div className="d-flex flex-column">
                            <div style={{ height: 100 }} />
                            <i className="fas fa-check  font-80 align-self-center" />
                            <span className=" align-self-center font-40">Success!</span>
                            <span className="font-10 align-self-center ">Your profile's all set</span>
                            <button className="btn btn-link  mt-4" onClick={this.props.toggle} >Click to Continue</button>
                            <div style={{ height: 100 }} />
                        </div>
                    </div>

                </section>}

                <div onClick={this.props.toggle} style={{ width: 50, height: 50, borderRadius: 25, position: 'fixed', top: 15, left: 15 }} className="bg-gray d-flex flex-column justify-content-center">
                    <i className="fas fa-times black font-30 align-self-center" />
                </div>
            </Animated>
        </section>
    }
}

export default AddCoachProfile;