function coaches(state = [], action) {
    switch (action.type) {
        case 'COACHES/SET':
            return action.coaches;
        case 'COACHES/CLEAR':
            return null;
        case 'PROSPECTS/CLEAR':
            return null;
        default:
            return state;
    }
}

export default coaches;
