function players(state = [], action) {
    switch (action.type) {
        case 'PLAYERS/SET':
            return action.players;
        case 'PLAYERS/CLEAR':
            return null;        
        default:
            return state;
    }
}

export default players;
