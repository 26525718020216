import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';

import request, { search } from 'superagent';
import config from '../../../config';
import fakeAuth from '../../../fakeAuth';
import ShareMatch from './share';
import ShareBrochure from './share_brochure';
import MatchCollaborators from './collaborators';

class Teams extends React.Component {
    state = {
        teams: null, folders: null
    }

    componentWillMount() {
        const { teams, folders } = this.props;
        this.setState({ teams, folders });
    }
    componentWillReceiveProps = nextProps => {
        const { teams, folders } = nextProps;
        this.setState({ teams, folders });
    }


    onMenu = (folder, e) => {
        e.preventDefault && e.preventDefault();
        e.stopPropagation && e.stopPropagation();
    }

    onSaveFolder = () => {
        if (!this.txtFolderName.value) {            
            alert(`Please put folder's name`);
            return;
        }
        this.props.scout_actions && this.props.scout_actions.create_folder({
            FolderName: this.txtFolderName.value, IdFolderType: 2
        });
        this.setState({ adding_folder: false });
    }

    onFollow = () => {
        const { found_team } = this.state;
        if (found_team.IdMatch) this.props.scout_actions.follow_match(found_team.IdMatch);
        else if (found_team.IdTeamDivision) this.props.scout_actions.follow_team_division(found_team.IdTeamDivision);
        else if (found_team.IdTeam) this.props.scout_actions.follow_team(found_team.IdTeam);
        this.setState({ adding_link: false, found_team: null });
    }

    toggle_folder_menu = (index, e) => {
        e.preventDefault && e.preventDefault();
        e.stopPropagation && e.stopPropagation();

        const { folders } = this.state;
        _.chain(folders).filter(f => [2].indexOf(f.IdFolderType) !== -1).sortBy('FolderName').value()[index].menu =
            !_.chain(folders).filter(f => [2].indexOf(f.IdFolderType) !== -1).sortBy('FolderName').value()[index].menu;
        this.setState({ folders });
    }

    toggle_team_menu = (index, e) => {
        e && e.preventDefault && e.preventDefault();
        e && e.stopPropagation && e.stopPropagation();

        const { teams } = this.state, { folder } = this.props;
        _.chain(teams).filter(t => {
            if (folder) return t.IdFolder === folder.IdFolder;
            else return !t.IdFolder;
        }).value()[index].menu = !_.chain(teams).filter(t => {
            if (folder) return t.IdFolder === folder.IdFolder;
            else return !t.IdFolder;
        }).value()[index].menu;
        this.setState({ teams });
    }

    toggle_team_delete = (index, e) => {
        e.preventDefault && e.preventDefault();
        e.stopPropagation && e.stopPropagation();

        const { teams } = this.state, { folder } = this.props;
        _.chain(teams).filter(t => {
            if (folder) return t.IdFolder === folder.IdFolder;
            else return !t.IdFolder;
        }).value()[index].deleting = !_.chain(teams).filter(t => {
            if (folder) return t.IdFolder === folder.IdFolder;
            else return !t.IdFolder;
        }).value()[index].deleting;
        this.setState({ teams });
    }

    onSearchTeamLink = () => {
        this.setState({ searching: true, invalid_team_link: false }, () => {
            // CALL API
            let search_text = this.txtTeamsLink.value;
            if (search_text.indexOf('://www.thesqwad.com/brochure') !== -1) {
                let [TeamName, IdTeamDivision] = search_text.split('/').reverse();

                this.setState({
                    found_team: {
                        Logo: `https://api.sportslogic.net/api/v4/teams/image/team_division/${IdTeamDivision}`,
                        Name: TeamName.split('_').join(' ').split('%20').join(' '),
                        IdTeamDivision
                    }, searching: false
                });
            } else if (search_text.indexOf('://www.thesqwad.com/scout/personal_team') !== -1) {
                //https://www.thesqwad.com/scout/personal_team/2F5A5DD7-15E8-4F55-9A23-89668F132CFA
                let [IdMatch] = search_text.split('/').reverse();
                this.setState({ searching: true }, () => {
                    request.get(`${config.apiEndpoint}/api/v6/scout/match/${IdMatch}`)
                        .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                        .then(({ body }) => {
                            if (body.match && body.match.ReportType === 80) {
                                this.setState({ found_team: body.match, searching: false });
                            } else {
                                this.setState({ invalid_team_link: true, searching: false });
                            }
                        });
                });
            } else if (search_text.indexOf('://www.thesqwad.com/master_brochure') !== -1) {
                // https://www.thesqwad.com/master_brochure/66262/MARTIN_UNITED_07_CO_ED
                let [TeamName, IdTeam] = search_text.split('/').reverse();

                this.setState({
                    found_team: {
                        Logo: `https://api.sportslogic.net/api/v4/teams/image/decode/${IdTeam}?full=80`,
                        Name: TeamName.split('_').join(' ').split('%20').join(' '),
                        IdTeam
                    }, searching: false
                });
            } else {
                this.setState({ invalid_team_link: true });
            }
        });
    }

    onRemoveFolder = (folder) => {
        //[  DELETE  ] /api/v5/folders/7ABC2FA9-7269-4487-9EEC-E7EE67A26E91
        const { folders } = this.state;
        this.setState({ folders: _.reject(folders, f => f.IdFolder === folder.IdFolder) });
        this.props.scout_actions.delete_folder(folder.IdFolder);
    }

    onRemoveTeam = (team) => {
        const { teams } = this.state;
        if (team.IdTeamDivision) {
            this.setState({ teams: _.reject(teams, t => t.IdTeamDivision === team.IdTeamDivision) });
            this.props.scout_actions.unfollow_team(team.IdTeamDivision);
        }
        else if (team.IdMatch) {
            this.setState({ teams: _.reject(teams, t => t.IdMatch === team.IdMatch) });
            // TODO: if you're the owner, delete it, otherwise, unfollow it
            if (team.IdUser === fakeAuth.getUser().IdUser) this.props.scout_actions.delete_match(team.IdMatch);
            else this.props.scout_actions.unfollow_match(team.IdMatch);
        }
    }

    render() {
        const { teams, folders } = this.state, { folder } = this.props;        

        let the_folders = _.chain(folders)./*filter(f => f.IdUser === me.IdUser).*/filter(f => [2].indexOf(f.IdFolderType) !== -1).sortBy('FolderName').value(),
            the_shared = _.chain(folders)./*filter(f => f.IdUser !== me.IdUser).*/filter(f => [2].indexOf(f.IdFolderType) !== -1).sortBy('FolderName').value()

        return (
            <section>

                <div className="row mx-0 mt-4 pb-4">

                    {/* FOLDERs */}

                    {the_folders && the_folders.map((folder, i) =>
                        <Animated key={i} animationInDelay={25 * i} animationIn="fadeInUp" className="col-sm-6 col-md-3 p-1 w3-container w3-cell">

                            <Link className="w-100  py-2 tile card d-flex flex-row h-100" to={`/network/team_folder/${folder.IdFolder}`}>
                                <div className="px-2 ms-auto pointer" style={{ width: 50 }} ><i className="ms-auto white icon-more-vertical" /></div>
                                <div className="d-flex flex-column justify-content-center w-100 py-2">
                                    <i className="icon-folder black text-center font-70" />
                                    <span className="line1 mt-2 font-10 black text-center">{folder.Name || folder.FolderName}</span>
                                </div>
                                <div className="px-2 ms-auto pointer" style={{ width: 50, height: 50 }} onClick={(e) => this.toggle_folder_menu(i, e)}><i className="ms-auto black icon-more-vertical" /></div>
                            </Link>

                            <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeInUp" animationOut='fadeOut' isVisible={folder.menu ? true : false} className="bg-white card tile shadow d-flex flex-row" style={{ position: 'absolute', top: 10, right: 10 }}>
                                <button onClick={(e) => this.setState({ profile: folder }, () => {
                                    this.toggle_folder_menu(i, e);
                                })} className="btn btn-default"><i className="icon-edit-3 black" /></button>
                                <button onClick={(e) => this.setState({ match: folder }, () => {
                                    this.toggle_folder_menu(i, e);
                                })} className="btn btn-default"><i className="icon-share black" /></button>
                                <button className="btn btn-default" onClick={() => this.onRemoveFolder(folder)}><i className="fas fa-times red" /></button>
                                <button className="btn btn-default" onClick={(e) => this.toggle_folder_menu(i, e)}><i className="black icon-more-vertical" /></button>
                            </Animated>

                        </Animated>
                    )}

                    {/* TEAMS */}

                    {teams && _.chain(teams).filter(t => {
                        if (folder) return t.IdFolder === folder.IdFolder;
                        else return !t.IdFolder;
                    }).value().map((team, i) =>
                        <Animated key={i} animationInDelay={25 * (i + the_folders.length)} animationIn="fadeInUp" className="col-sm-6 col-md-3 p-1 w3-container w3-cell">

                            <Link className="w-100  py-2 tile card d-flex flex-row h-100" to={`/network/${team.IdTeamDivision ? `team/${team.IdTeamDivision}` : `custom_team/${team.IdMatch}`}`}>
                                <div className="px-2 ms-auto pointer" style={{ width: 50 }} ><i className="ms-auto white icon-more-vertical" /></div>
                                <div className="d-flex flex-column justify-content-center w-100 py-2">
                                    <div className="align-self-center cover" style={{ width: 60, height: 60, background: `url(${team.TeamImageUrl || team.Logo || '/images/logos/generic_crest.png'}) no-repeat center center` }}></div>
                                    <span className="line1 font-10 black text-center mt-2">{team.Name || team.TeamName}</span>
                                    <span className="line1 font-10 black text-center max-lines">{team.SeasonName}</span>
                                </div>
                                <div className="px-2 ms-auto pointer" style={{ width: 50, height: 50 }} onClick={(e) => this.toggle_team_menu(i, e)}><i className="ms-auto black icon-more-vertical" /></div>
                            </Link>

                            <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeInUp" animationOut='fadeOut' isVisible={(team.menu && !team.deleting) ? true : false} className="bg-white card tile shadow d-flex flex-row" style={{ position: 'absolute', top: 10, right: 10 }}>
                                <Link to={`/network/${team.IdTeamDivision ? `team/${team.IdTeamDivision}` : `custom_team/${team.IdMatch}`}`} title="Details" className="btn btn-default"><i className="icon-arrow-right black" /></Link>
                                <button title="Share Team"
                                    onClick={(e) => {
                                        if (team.IdTeamDivision) this.setState({ brochure: team }, () => { this.toggle_team_menu(i, e); });
                                        if (team.IdMatch) this.setState({ match: team }, () => { this.toggle_team_menu(i, e); });
                                    }}
                                    className="btn btn-default"><i className="icon-share black" /></button>

                                {team.IdUser === fakeAuth.getUser().IdUser && <button title="Collaborators" className="btn btn-default" 
                                onClick={(e) => this.setState({ match_collaborators: team }, () => { 
                                    this.props.scout_actions.fetch_match_collaborators(team.IdMatch);
                                    this.toggle_team_menu(i); })}><i className="icon-user-plus" /></button>}

                                <button title="Remove Team" className="btn btn-default" onClick={(e) => this.toggle_team_delete(i, e)}><i className="fas fa-times red" /></button>
                                <button className="btn btn-default" onClick={(e) => this.toggle_team_menu(i, e)}><i className="black icon-more-vertical" /></button>
                            </Animated>

                            <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeInUp" animationOut='fadeOut' isVisible={team.deleting ? true : false} className="bg-white card tile shadow d-flex flex-row" style={{ position: 'absolute', top: 10, right: 10 }}>
                                <span className="black align-self-center px-2">Confirm remove?</span>
                                <button className="btn btn-default" onClick={() => this.onRemoveTeam(team)}><i className="black fas fa-check" /></button>
                                <button title="Remove Team" className="btn btn-default" onClick={(e) => this.toggle_team_delete(i, e)}><i className="fas fa-times black" /></button>
                            </Animated>

                        </Animated>
                    )}
                </div>

                {/* ADD */}
                <div onClick={() => this.setState({ menu_toggled: !this.state.menu_toggled })} style={{ width: 50, height: 50, borderRadius: 25, overflow: 'hidden', position: 'fixed', bottom: 20, right: 20 }} className="">
                    <div className="bg-info d-flex flex-column justify-content-center h-100 w-100">
                        <i className="fas fa-plus white font-30 align-self-center" />
                    </div>
                </div>

                {/* 1st MENU */}
                <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeInUp" animationOut='fadeOut' isVisible={this.state.menu_toggled ? true : false} className="bg-white card tile shadow d-flex flex-column" style={{ position: 'fixed', bottom: 20, right: 20 }}>
                    <button className="btn btn-default" onClick={() => this.setState({ adding_folder: true, menu_toggled: false })}>
                        <div className="d-flex flex-column">
                            <i className="icon-folder black font-30" />
                            <span>Folder</span>
                        </div>
                    </button>
                    <button className="btn btn-default" onClick={() => this.setState({ adding_team: true, menu_toggled: false })}>
                        <div className="d-flex flex-column">
                            <i className="icon-shield black font-30" />
                            <span>Team</span>
                        </div>
                    </button>
                    <div className="align-self-center mb-2 mt-2" onClick={() => this.setState({ menu_toggled: !this.state.menu_toggled })} style={{ width: 50, height: 50, borderRadius: 25, overflow: 'hidden' }}>
                        <div className="bg-info d-flex flex-column justify-content-center h-100 w-100">
                            <i className="fas fa-times white font-30 align-self-center" />
                        </div>
                    </div>
                </Animated>

                {/* TEAM OPTIONS */}
                <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeInUp" animationOut='fadeOut' isVisible={this.state.adding_team ? true : false} className="bg-white card tile shadow d-flex flex-column" style={{ position: 'fixed', bottom: 20, right: 20 }}>
                    <button className="btn btn-default" onClick={() => this.setState({ adding_link: true, adding_team: false })}>
                        <div className="d-flex flex-column">
                            <i className="icon-link black font-30" />
                            <span className="line1">Team's<br />Link</span>
                        </div>
                    </button>
                    <Link to="/network/custom_team" className="btn btn-default">
                        <div className="d-flex flex-column">
                            <i className="icon-shield black font-30" />
                            <span className="line1">Custom<br />Team</span>
                        </div>
                    </Link>
                    <div className="align-self-center mb-2 mt-2" onClick={() => this.setState({ menu_toggled: true, adding_team: false })} style={{ width: 50, height: 50, borderRadius: 25, overflow: 'hidden' }}>
                        <div className="bg-info d-flex flex-column justify-content-center h-100 w-100">
                            <i className="fas fa-arrow-left white font-30 align-self-center" />
                        </div>
                    </div>
                </Animated>

                {/* ADD TEAM BY LINK */}
                <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeInUp" animationOut='fadeOut' isVisible={this.state.adding_link ? true : false} className="bg-white card tile shadow d-flex flex-column" style={{ position: 'fixed', bottom: 20, width: 300, right: 20 }}>
                    <div className="bg-white p-4 w-100">
                        <label className={`${this.state.invalid_team_link ? 'red' : 'black'}`}>{this.state.invalid_team_link ? 'Invalid ' : ''}Team's Link</label>
                        <input className="w3-input w-100" type="text" ref={i => this.txtTeamsLink = i} />
                        <button className={`btn btn-${this.state.saved ? 'success' : 'info'} mt-2 w-100`} type="button" onClick={this.onSearchTeamLink}>{this.state.searching ? 'Searching...' : 'Search'}</button>
                        <button className={`btn btn-${this.state.saved ? 'link' : 'link'} mt-1 w-100`} type="button" onClick={() => this.setState({
                            adding_team: true, adding_link: false, invalid_team_link: false, found_team: null
                        })}>Cancel</button>

                        {this.state.found_team && <section>
                            <div className="p-4 tile card d-flex flex-column h-100">
                                <div className="align-self-center cover" style={{ width: 60, height: 60, background: `url(${this.state.found_team.TeamImageUrl || this.state.found_team.Logo}) no-repeat center center` }}></div>
                                <span className="line1 font-10 black text-center mt-2">{this.state.found_team.Name || this.state.found_team.TeamName}</span>
                                <span className="line1 font-10 black text-center">{this.state.found_team.SeasonName}</span>
                            </div>
                        </section>}
                        {this.state.found_team &&
                            <button onClick={this.onFollow} className={`btn btn-${this.state.saved ? 'success' : 'success'} mt-4 w-100`} type="button">{this.state.saved ? 'Follow' : 'Follow'}</button>
                        }
                    </div>
                </Animated>

                {/* ADD FOLDER */}
                <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeInUp" animationOut='fadeOut' isVisible={this.state.adding_folder ? true : false} className="bg-white card tile shadow d-flex flex-column" style={{ position: 'fixed', bottom: 20, width: 300, right: 20 }}>
                    <div className="bg-white p-4 w-100">
                        <label>Folder's Name</label>
                        <input className="w3-input w-100" type="text" ref={i => this.txtFolderName = i} />
                        <button className={`btn btn-${this.state.saved ? 'success' : 'info'} mt-4 w-100`} type="button" onClick={this.onSaveFolder}>{this.state.saved ? 'Save Folder' : 'Save Folder'}</button>
                        <button className={`btn btn-${this.state.saved ? 'link' : 'link'} mt-1 w-100`} type="button" onClick={() => this.setState({ adding_folder: false, menu_toggled: true })}>Cancel</button>
                    </div>
                </Animated>

                <ShareMatch {...this.state} scout_actions={this.props.scout_actions} toggle={() => this.setState({ match: false })} />
                <ShareBrochure {...this.state} scout_actions={this.props.scout_actions} toggle={() => this.setState({ brochure: false })} />
                <MatchCollaborators {...this.state} {...this.props} toggle={() => this.setState({ match_collaborators: false })} />

            </section>
        )
    }
}

export default Teams;
