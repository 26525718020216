export function fetchTeams() { return { type: 'TEAMS/FAMILY/FETCH' } }
export function fetchArchivedTeams() { return { type: 'TEAMS/FAMILY/ARCHIVED/FETCH' }}
export function fetchTeamsWithAppPlans() { return { type: 'TEAMS/APP_PLANS/FETCH' } }
export function fetchMembers() { return { type: 'MEMBERS/FAMILY/FETCH' } }
export function fetchContact() { return { type: 'CONTACT/FAMILY/FETCH' } }
export function fetchAgeGroups() { return { type: 'AGE_GROUPS/FETCH' } }
export function fetchCommunities() { return { type: 'COMMUNITIES/FETCH' } }
export function toggleUserSelection(isUserSelectionOpen) { return { type: 'IS_USER_SELECTION_OPEN/SET', isUserSelectionOpen } }
export function selectUser(user) { return { type: 'SELECTED_USER/SET', user } }
export function clearSelectedUser() { return { type: 'SELECTED_USER/CLEAR' } }
export function addFamilyMember(user) { return { type: 'MEMBERS/FAMILY/ADD', user } }
export function updateFamilyMember(user) { return { type: 'MEMBERS/FAMILY/UPDATE', user } }
export function clearFamily() { return { type: 'FAMILY/CLEAR' } }
export function removeFamilyMember(user) { return { type: 'MEMBERS/FAMILY/REMOVE', user } }
export function assignPhone(idUser, idPhoneNumber, selected) { return { type: 'FAMILY/PHONE/ASSIGN', idUser, idPhoneNumber, selected } }
export function assignEmail(idUser, idEmailAddress, selected) { return { type: 'FAMILY/EMAIL/ASSIGN', idUser, idEmailAddress, selected } }
export function addPhone(phone) { return { type: 'PHONES/ADD', phone } }
export function updatePhone(phone, newId) { return { type: 'PHONES/UPDATE', phone: { ...phone }, newId, oldId: phone.IdPhoneNumber } }
export function savePhone(phone) { return { type: 'PHONES/SAVE', phone } }
export function deletePhone(idPhoneNumber) { return { type: 'PHONES/DELETE', idPhoneNumber } }
export function addEmail(email) { return { type: 'EMAILS/ADD', email } }
export function saveEmail(email) { return { type: 'EMAILS/SAVE', email } }
export function updateEmail(email) { return { type: 'EMAILS/UPDATE', email } }
export function deleteEmail(idEmailAddress) { return { type: 'EMAILS/DELETE', idEmailAddress } }
export function getEmailsToUnsubscribe(idFamily, idMessage) { return { type: 'FAMILY/UNSUBSCRIBE/EMAIL/FETCH', idFamily, idMessage } }
export function getFamilyInformation() { return { type: 'FAMILY/INFORMATION/FETCH' } }
export function clearInfo() { return { type: 'FAMILY/INFORMATION/CLEAR' } }
export function getFamilySubscriptions() { return { type: 'FAMILY/SUBSCRIPTIONS/FETCH' } }
export function getTeamSubscriptions(idTeam) { return { type: 'TEAM/SUBSCRIPTIONS/FETCH', idTeam } }
export function subscribeToSQWADTrial(IdPlan, card) { return { type: 'TEAM/SUBSCRIBE/SQWAD', IdPlan, card }}
export function updateSelectedTeam(team) { return { type: 'SELECTED_TEAM/SET', team }}
export function fetchCollegeProfile(idUser) { return { type: 'COLLEGE_PROFILE/FETCH', idUser }}
export function togglePersonalInfo({IsHidden, IdTeamUserRole}) { return { type: 'FAMILY/PERSONAL_INFO/TOGGLE', IsHidden, IdTeamUserRole }}
export function login(args) {
    return {
        type: "AUTH/LOGIN",
        token: args.token,
        isAdmin: args.isAdmin
    }
}