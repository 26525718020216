import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import MaskedInput from 'react-text-mask';
import FamilyMember from './entity';
import { Input, Popover, PopoverBody } from 'reactstrap';

class Phone extends React.Component {
  state = {
    phone: null, editing: false, deleting: false
  }

  // Lifecycle
  componentWillMount() {
    this.setState({ phone: this.props.phone, editing: this.props.phone.IdPhoneNumber ? false : true });
  }
  componentWillReceiveProps = newProps => this.setState({ phone: newProps.phone });

  componentDidUpdate = () => {
    if (this.txtPhone) {
      ReactDOM.findDOMNode(this.txtPhone).focus();
      ReactDOM.findDOMNode(this.txtPhone).select();
    }
  }
  componentDidMount() {
    ReactDOM.findDOMNode(this.txtPhone)
      && ReactDOM.findDOMNode(this.txtPhone).focus();
  }
  //

  toggleEdit = () => this.setState(prevState => ({ editing: !prevState.editing }));
  toggleDelete = () => this.setState(prevState => ({ deleting: !prevState.deleting }));

  saveNumber = e => {
    e.preventDefault && e.preventDefault();
    const { phone } = this.state, { familyActions } = this.props;
    phone.PhoneNumber = ReactDOM.findDOMNode(this.txtPhone).value;

    if (phone.IdPhoneNumber) {      
      familyActions && familyActions.updatePhone(phone, ReactDOM.findDOMNode(this.txtPhone).value.match(/\d+/g).map(Number).join(''))
    } else {      
      phone.IdPhoneNumber = ReactDOM.findDOMNode(this.txtPhone).value.match(/\d+/g).map(Number).join('');
      familyActions && familyActions.savePhone(phone);
    }
    // TODO:


    this.setState({ phone });
    this.toggleEdit();
  }

  selectCarrier = e => {
    const { carriers } = this.props, { phone } = this.state;
    const carrier = _.find(carriers, carrier => carrier.IdCarrier === parseInt(e.target.value, 10));
    if (carrier) {
      phone.CarrierName = carrier.Name || null;
      phone.IdCarrier = carrier ? carrier.IdCarrier : 0;
    } else {
      phone.CarrierName = null;
      phone.IdCarrier = null;
    }
    this.setState({ phone });
  }

  doDelete = e => {
    const { phone } = this.state;
    this.props.familyActions.deletePhone(phone.IdPhoneNumber);
    this.toggleDelete();
  }

  render() {
    const { phone, editing } = this.state,
      { family, carriers } = this.props;

    return (
      <div className={`card tile shadow-box p-2 margin-bottom-half`} style={{ position: 'relative' }}>

        {!editing && <span className="font-16 margin-bottom-half bg-darker-blue white p-2 d-flex flex-row">
          {phone.CanDelete === false && <i className="fas fa-lock margin-right-half  align-self-center" />}
          <span className="align-self-center margin-right-half">{phone.PhoneNumber}</span>
          {phone.CarrierName && <span className="font-10 align-self-center">({phone.CarrierName})</span>}
          {phone.IdPhoneNumber && phone.CanDelete && <button onClick={this.toggleEdit} className="btn btn-link btn-sm ml-auto"><i className="fas fa-pen" /></button>}
          {phone.IdPhoneNumber && phone.CanDelete === true && <button id={`btnDeletePhone-${phone.IdPhoneNumber}`} onClick={this.toggleDelete} className="btn btn-link btn-sm white"><i className="fa fa-times" /></button>}
          {phone.IdPhoneNumber && phone.CanDelete === true && <Popover placement="bottom" isOpen={this.state.deleting} target={`btnDeletePhone-${phone.IdPhoneNumber}`} toggle={this.toggleDelete}>
            <PopoverBody className="d-flex flex-column justify-content-center">
              <span className="align-self-center">Are you sure?</span>
              <button className="btn btn-sm btn-danger" onClick={this.toggleDelete}>No, cancel</button>
              <button className="btn btn-sm btn-success" onClick={this.doDelete}>Yes, delete it!</button>
            </PopoverBody>
          </Popover>}
        </span>}

        {editing && <form>
          <div className="input-group margin-bottom-half">
            <MaskedInput defaultValue={phone.PhoneNumber} guide={true} ref={(i) => this.txtPhone = i}
              className="form-control" placeholder="Enter the Phone Number"
              mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} />
            <div className="input-group-append">
              <Input style={{ width: 140, borderRadius: 0 }} className="form-control" type="select" value={phone.IdCarrier || ''} onChange={this.selectCarrier}>
                <option value={null}>Carrier</option>
                {carriers && carriers.map((carrier, i) => <option key={i} value={carrier.IdCarrier}>{carrier.Name}</option>)}
              </Input>
              <button onClick={this.saveNumber} className="btn btn-success" type="submit"><i className="fa fa-check" /></button>
            </div>
          </div>
        </form>}

        <div className={`clearfix ${phone.IdPhoneNumber ? '' : 'transparent'}`}>
          {phone.IdPhoneNumber && family.map((user, i) =>
            <div style={{ float: 'left'}} className="me-2 mb-2" key={i}>
              <FamilyMember phone IdPhoneNumber={phone.IdPhoneNumber} familyActions={this.props.familyActions} entity={user}
                isActive={_.find(phone.users, p => { return p.IdUser === user.IdUser; })} />
            </div>)}
        </div>
      </div>)
  }
}

export default Phone;
