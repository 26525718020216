import React from 'react';
import { getMaskedValue, height_units } from '../../../helpers';

const Sport = ({ sport, communities, user }) => {
    let community = _.find(communities, c => c.IdCommunity === sport.IdCommunity) || {};
    return (
        <div className="tile card d-flex flex-column mb-3" >
            <div className="px-4 py-2 bg-whiteish">{community.CommunityName}</div>
            <hr className="m-0 p-0" />
            <div className="d-flex flex-row px-3">
                <div className="w-100 d-flex flex-column pl-3 py-2">
                    <span className="font-8">Primary Position</span>
                    <span>{sport.Position1 || 'N/A'}</span>
                    <span className="font-8 mt-1">Secondary Position</span>
                    <span>{sport.Position2 || 'N/A'}</span>
                    <span className="font-8 mt-1">Highest Level Played</span>
                    <span>{sport.LevelPlayed || 'N/A'}</span>
                    <span className="font-8 mt-1">Height</span>
                    <span>{ `${(user.Height && user.HeightUnit) ? getMaskedValue(user.Height, _.find(height_units, u => u.value === parseInt(user.HeightUnit, 10)).mask) : 'N/A'}`}</span>

                </div>
                <div className="w-25 d-flex flex-column py-2" style={{ position: 'relative' }}>
                    <i className="icon-apparel white font-60 align-self-center mt-2" />
                    <span className="font-8 align-self-center mt-4">Foot</span>
                    <span className="align-self-center">{sport.DominantFoot}</span>
                    <span className="font-8 mt-1 align-self-center">Weight</span>
                    <span className="align-self-center">{user.Weight || 'N/A'}{user.WeightUnit === 2 ? 'Lb.' : 'Kg'}</span>
                    <div style={{ height: 100, position: 'absolute', top: 0, }} className="w-100 d-flex flex-row justify-content-center">
                        <i className="icon-apparel blue font-60 align-self-center" />
                    </div>
                    <div style={{ height: 100, position: 'absolute', top: 0, }} className="w-100 d-flex flex-row justify-content-center">
                        <span className="white font-24 align-self-center">{sport.JerseyNumber}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sport;
