import React from 'react';
import { Link } from 'react-router';

const MenuDesktop = ({ path = [], router, team, match }) => {
    
    return (
        <div style={{ position: 'fixed', left: 0, top: 70, width: 200, zIndex: 100 }} className="py-2 px-2 bg-whiteish  w3-hide-small">
            {(team || match) && <div className="d-flex flex-column">
                {team && !team.IdMatch && <section className="d-flex flex-column">
                    <a onClick={() => { router.goBack && router.goBack() }} className={`bg-${path.indexOf('nope') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-row justify-content-start`} style={{ height: 50 }}>
                        <i className="fas fa-arrow-left font-10 align-self-center ms-2" />
                        <span className="align-self-center font-8 syncopate ms-2">BACK</span>
                    </a>
                    <Link to={`/network/team/${team.IdTeam}`} className={`bg-${path.indexOf('team') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-row justify-content-start mt-2`} style={{ height: 50 }}>
                        <i className="icon-sportsman font-10 align-self-center ms-2" />
                        <span className="align-self-center font-8 syncopate ms-2">ROSTER</span>
                    </Link>
                    <Link to={`/network/team/${team.IdTeam}`} className={`bg-${path.indexOf('team_schedule') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-row justify-content-start mt-2`} style={{ height: 50 }}>
                        <i className="icon-calendar font-10 align-self-center ms-2" />
                        <span className="align-self-center font-8 syncopate ms-2">SCHEDULE</span>
                    </Link>
                </section>}

                {match && <section className="d-flex flex-column">
                    <a onClick={() => { router.goBack && router.goBack() }} className={`bg-${path.indexOf('nope') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-row justify-content-start`} style={{ height: 50 }}>
                        <i className="fas fa-arrow-left font-10 align-self-center ms-2" />
                        <span className="align-self-center font-8 syncopate ms-2">BACK</span>
                    </a>
                    <Link to={`/network/custom_team/${match.IdMatch}`} className={`bg-${path.indexOf('custom_team') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-row justify-content-start mt-2`} style={{ height: 50 }}>
                        <i className="icon-sportsman font-10 align-self-center ms-2" />
                        <span className="align-self-center font-8 syncopate ms-2">ROSTER</span>
                    </Link>
                </section>}
            </div>}

            {!(team || match) && <div className="d-flex flex-column">
                <a onClick={() => { router.goBack && router.goBack() }} className={`bg-${path.indexOf('nope') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-row justify-content-start`} style={{ height: 50 }}>
                    <i className="fas fa-arrow-left font-10 align-self-center ms-2" />
                    <span className="align-self-center font-8 syncopate ms-2">BACK</span>
                </a>
            </div>}
        </div>
    );
}

export default MenuDesktop;
