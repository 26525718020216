import React from 'react';
import { Link } from 'react-router';


const MenuDesktop = ({ path = [], router, user = {} }) => {
    return (
        <div style={{ position: 'fixed', left: 0, top: 70, width: 200, zIndex: 100 }} className="py-2 px-2 bg-whiteish  w3-hide-small">
            {user && <div className="d-flex flex-column">
                <a onClick={() => { router.goBack && router.goBack() }} className={`bg-${path.indexOf('nope') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-row justify-content-start`} style={{ height: 50 }}>
                    <i className="fas fa-arrow-left font-10 align-self-center ms-2" />
                    <span className="align-self-center font-8 syncopate ms-2">BACK</span>
                </a>
                {!user.IdMatch && <section className="d-flex flex-column">
                    <Link to={`/prospect/${user.IdUser || user.IdUserFollow}`} className={`bg-${path.indexOf('prospect') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-row justify-content-start mt-2`} style={{ height: 50 }}>
                        <i className="icon-info font-10 align-self-center ms-2" />
                        <span className="align-self-center font-8 syncopate ms-2">INFO</span>
                    </Link>
                    <Link to={`/prospect_highlights/${user.IdUser || user.IdUserFollow}`} className={`bg-${path.indexOf('prospect_highlights') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-row justify-content-start mt-2`} style={{ height: 50 }}>
                        <i className="icon-film-reel-1 font-10 align-self-center ms-2" />
                        <span className="align-self-center font-8 syncopate ms-2">HIGHLIGHTS</span>
                    </Link>
                    <Link to={`/prospect_evaluations/${user.IdUser || user.IdUserFollow}`} className={`bg-${path.indexOf('prospect_evaluations') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-row justify-content-start mt-2`} style={{ height: 50 }}>
                        <i className="icon-checklist font-10 align-self-center ms-2" />
                        <span className="align-self-center font-8 syncopate ms-2">EVALUATIONS</span>
                    </Link>
                    {/*<Link to="/prospect_calendar" className={`bg-${path.indexOf('prospect_calendar') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-row justify-content-start mt-2`} style={{ height: 50 }}>
                        <i className="icon-calendar font-10 align-self-center ms-2" />
                        <span className="align-self-center font-8 syncopate ms-2">UPCOMING</span>
    </Link>*/}
                </section>}

                {user.IdMatch && <section className="d-flex flex-column">
                    <Link to={`/custom_player/${user.IdMatch}`} className={`bg-${path.indexOf('custom_player') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-row justify-content-start mt-2`} style={{ height: 50 }}>
                        <i className="icon-info font-10 align-self-center ms-2" />
                        <span className="align-self-center font-8 syncopate ms-2">INFO</span>
                    </Link>
                    <Link to={`/custom_player_evaluations/${user.IdMatch}`} className={`bg-${path.indexOf('custom_player_evaluations') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-row justify-content-start mt-2`} style={{ height: 50 }}>
                        <i className="icon-checklist font-10 align-self-center ms-2" />
                        <span className="align-self-center font-8 syncopate ms-2">EVALUATIONS</span>
                    </Link>
                    <div className={`rounded d-flex flex-row justify-content-start mt-2`} style={{ height: 50 }}>
                        {/* This is to hide the underneath main menu */}
                    </div>
                </section>}
            </div>}
        </div>
    );
}

export default MenuDesktop;
