import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import { humanize_user } from '../../helpers';
import request from 'superagent';


export function* fetch_notes_saga() {
    yield* takeEvery("NOTES/FETCH", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(() => {
                return request.get(`${config.apiEndpoint}/api/v6/scout/notes`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);
            if (result && result.success) {
                const { notes } = result;
                yield put({ type: 'NOTES/SET', notes });
            } else {
                yield put({
                    type: 'NOTES/FETCH_FAILED',
                    result
                });
            }
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({
                type: 'COUNTRIES/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* fetch_attributes_saga() {
    yield* takeEvery("ATTRIBUTES/FETCH", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(() => {
                return request.get(`${config.apiEndpoint}/api/v6/scout/attributes`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);
            if (result && result.success) {
                const { attributes } = result;
                yield put({ type: 'NOTES/SET', notes: attributes });
            } else {
                yield put({
                    type: 'NOTES/FETCH_FAILED',
                    result
                });
            }
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({
                type: 'COUNTRIES/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* fetch_evaluations_saga() {
    yield* takeEvery("EVALUATIONS/FETCH", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(() => {
                return request.get(`${config.apiEndpoint}/api/v6/scout/player_profiles`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);
            if (result && result.success) {
                const { profiles, topics = [] } = result;                
                yield put({ type: 'NOTES/SET', notes: profiles });
                yield put({ type: 'TOPICS/SET', topics });
            } else {
                yield put({
                    type: 'NOTES/FETCH_FAILED',
                    result
                });
            }
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({
                type: 'COUNTRIES/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* fetch_reviews_saga() {
    yield* takeEvery("REVIEWS/FETCH", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            const result = yield call(() => {
                return request.get(`${config.apiEndpoint}/api/v6/scout/reviews`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);
            if (result && result.success) {
                const { reviews } = result;
                yield put({ type: 'NOTES/SET', notes: reviews });
            } else {
                yield put({
                    type: 'NOTES/FETCH_FAILED',
                    result
                });
            }
            yield put({ type: 'LOADING/SET', loading: false });
        } catch (e) {
            yield put({
                type: 'COUNTRIES/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* fetch_questionaries() {
    yield* takeEvery("EVALUATIONS/FETCH", function* (action) {
        try {            
            const result = yield call(() => {
                return request.get(`${config.apiEndpoint}/api/v6/scout/questionaries`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);
            if (result && result.success) {
                const { questionaries } = result;
                yield put({ type: 'QUESTIONARIES/SET', questionaries });
            } else {
                yield put({
                    type: 'QUESTIONARIES/FETCH_FAILED',
                    result
                });
            }            
        } catch (e) {
            yield put({
                type: 'QUESTIONARIES/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* fetch_questionaries_2() {
    yield* takeEvery("USER/EVALUATIONS/FETCH", function* (action) {
        try {            
            const result = yield call(() => {
                return request.get(`${config.apiEndpoint}/api/v6/scout/questionaries`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);
            if (result && result.success) {
                const { questionaries } = result;
                yield put({ type: 'QUESTIONARIES/SET', questionaries });
            } else {
                yield put({
                    type: 'QUESTIONARIES/FETCH_FAILED',
                    result
                });
            }            
        } catch (e) {
            yield put({
                type: 'QUESTIONARIES/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* fetch_player_matches() {
    yield* takeEvery("PLAYER_MATCHES/FETCH", function* (action) {
        try {            
            const result = yield call(() => {
                return request.get(`${config.apiEndpoint}/api/v6/scout/player_matches`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);
            if (result && result.success) {
                const { matches } = result;
                yield put({ type: 'NOTES/SET', notes: matches });
            } else {
                yield put({
                    type: 'PLAYER_MATCHES/FETCH_FAILED',
                    result
                });
            }            
        } catch (e) {
            yield put({
                type: 'PLAYER_MATCHES/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* fetch_player_matches_players() {
    yield* takeEvery("PLAYER_MATCHES/FETCH", function* (action) {
        try {            
            const result = yield call(() => {
                return request.get(`${config.apiEndpoint}/api/v6/scout/player_match_evaluations/by_user`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);
            if (result && result.success) {
                const { players } = result;
                yield put({ type: 'PLAYERS/SET', players });
            } else {
                yield put({
                    type: 'PLAYER_MATCHES/FETCH_FAILED',
                    result
                });
            }            
        } catch (e) {
            yield put({
                type: 'PLAYER_MATCHES/FETCH_FAILED',
                result: e
            });
        }
    });
}

export function* fetch_team_matches() {
    yield* takeEvery("TEAM_MATCHES/FETCH", function* (action) {
        try {            
            const result = yield call(() => {
                return request.get(`${config.apiEndpoint}/api/v6/scout/team_matches`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .then(data => (JSON.parse(data.text)));
            }, action);
            if (result && result.success) {
                const { matches } = result;
                yield put({ type: 'NOTES/SET', notes: matches });
            } else {
                yield put({
                    type: 'PLAYER_MATCHES/FETCH_FAILED',
                    result
                });
            }            
        } catch (e) {
            yield put({
                type: 'PLAYER_MATCHES/FETCH_FAILED',
                result: e
            });
        }
    });
}

