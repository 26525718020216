function custom_pages(state = [], action) {
    switch (action.type) {
        case 'CUSTOM_PAGES/SET':
            return action.custom_pages;
        case 'CUSTOM_PAGES/CLEAR':
            return null;
        default:
            return state;
    }
}

export default custom_pages;