import React from 'react';
import moment from 'moment';

class CustomDatePickerLabel extends React.Component {

    render() {
        const { value, className } = this.props;
        return (
            <span onClick={this.props.onClick} className={`${className}`}>{value ? moment(this.props.value).format('ddd, MMM Do YYYY') : 'Select Date'}</span>
        )
    }
}

/*CustomDatePicker.propTypes = {
    onClick: PropTypes.func,
    value: PropTypes.string
};*/

export default CustomDatePickerLabel;