import React from 'react';
import { Link } from 'react-router';

const MenuFamilyMobile = ({ path = [], user = {}, router }) => {
    return (
        <div style={{ position: 'fixed', bottom: 0, left: 10, right: 10, borderTopRightRadius: 5, borderTopLeftRadius: 5, height: 65 }} className="py-2 px-2 bg-whiteish w3-hide-medium w3-hide-large">
            <div className="d-flex flex-row w-100 justify-content-center">
                <section className="d-flex flex-row w-100 justify-content-center">
                    <a onClick={() => { router.goBack && router.goBack() }} className={`bg-${path.indexOf('nope') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-row justify-content-start`} style={{ height: 50, width: 'auto' }}>
                        <i className="fas fa-arrow-left font-10 align-self-center ms-2" />
                        <span className="align-self-center font-8 mx-2">BACK</span>
                    </a>
                    <Link to={`/family`} className={`bg-${path.indexOf('family') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-column justify-content-center w-100 align-self-center`} style={{ height: 50 }}>
                        <i className="icon-users font-20 align-self-center" />
                        <span className="align-self-center font-8 ms-2">MEMBERS</span>
                    </Link>                   
                    <Link to={`/family_contact`} className={`bg-${path.indexOf('family_contact') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-column justify-content-center w-100 align-self-center`} style={{ height: 50 }}>
                        <i className="icon-phone-call font-20 align-self-center ms-2" />
                        <span className="align-self-center font-8 ms-2">CONTACT</span>
                    </Link> 
                    <Link to={`/family_info`} className={`bg-${path.indexOf('family_info') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-column justify-content-center w-100 align-self-center`} style={{ height: 50 }}>
                        <i className="icon-hybrid font-20 align-self-center ms-2" />
                        <span className="align-self-center font-8 ms-2">ACCOUNT INFO</span>
                    </Link>                  
                </section>
            </div>
        </div>
    );
}

export default MenuFamilyMobile;
