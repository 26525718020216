function team_Reducer(state = [], action) {
    switch (action.type) {
        case 'TEAM/SET':
            return action.team;
        case 'TEAM/CLEAR':
            return null;
        case 'MATCH/CLEAR':
            return null;
        default:
            return state;
    }
}

export default team_Reducer;
