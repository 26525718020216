import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import moment from 'moment';
import { generateRandomId } from '../../../helpers';
import fakeAuth from '../../../fakeAuth';
import Slider from 'react-rangeslider';

// To include the default styles
import 'react-rangeslider/lib/index.css'

class AddAttributes extends React.Component {
    state = {
        bootstrapped: false,
        volume: 5,
        match: {
            CreatorNameFirst: fakeAuth.getUser().NameFirst,
            CreatorNameLast: fakeAuth.getUser().NameLast,
            EvaluationDate: moment(),
            IdFolder: null, // TODO
            IdGender: 1,
            IdPlayerEvaluation: generateRandomId(10),
            IdUser: fakeAuth.getUser().IdUser,
            NameFirst: this.props.selected_user ? this.props.selected_user.NameFirst : '',
            NameLast: this.props.selected_user ? this.props.selected_user.NameLast : '',
            ReportType: 11,
            Topics: [],
            IdUserTarget: this.props.selected_user ? (this.props.selected_user.IdUserFollow || this.props.selected_user.IdUser) : null,
            IdUserFollow: this.props.selected_user ? (this.props.selected_user.IdUserFollow || this.props.selected_user.IdUser) : null,
        }
    }

    componentWillReceiveProps = nextProps => {
        const { topics, selected_user, user } = nextProps;
        const { match } = this.state;
        if (!this.state.bootstrapped && topics) {
            this.setState({
                match: {
                    ...match,
                    Topics: _.chain(topics)
                        .map(t => {
                            return {
                                IdTopic: t.IdTopic,
                                Value: 0,
                                TopicName: t.TopicName,
                                SubTopics: _.map(t.subtopics, s => {
                                    return {
                                        IdTopic: s.IdTopic,
                                        Value: 0,
                                        TopicName: s.TopicName,
                                    }
                                })
                            }
                        }).value()
                }, bootstrapped: true
            });
        }
    }

    onColor = (color) => {
        const { match } = this.state;
        match.Color = color;
        this.setState({ match });
    }

    onSave = () => {
        this.setState({ dirty: true }, () => {
            const { match } = this.state;
            if (!this.txtName.value) {
                alert('Please complete the missing fields');
                return;
            }
            this.props.scout_actions.save_match({
                ...match,
                NameFirst: this.txtName.value.trim(),
                NameLast: '',
            });
            this.props.toggle();
        });
    }

    onToggleSubTopic = index => {
        const { match } = this.state;
        match.Topics[index].toggled = !match.Topics[index].toggled;
        if (match.Topics[index].toggled) {
            // Recalculate full topics value
            match.Topics[index].Value = _.chain(match.Topics[index].SubTopics)
                .map(i => i.Value)
                .reduce((a, b) => a + b, 0)
                .value() / match.Topics[index].SubTopics.length;
        }
        this.setState({ match });
    }

    onTopicChange = (index, value) => {
        const { match } = this.state;
        if (match.Topics[index].toggled) { return; }
        match.Topics[index].Value = value;
        this.setState({ match });
    }

    onSubTopicChange = (i, j, value) => {
        const { match } = this.state;
        match.Topics[i].SubTopics[j].Value = value;

        // Recalculate full topics value
        match.Topics[i].Value = _.chain(match.Topics[i].SubTopics)
            .map(i => i.Value)
            .reduce((a, b) => a + b, 0)
            .value() / match.Topics[i].SubTopics.length;

        this.setState({ match });
    }

    render() {
        const { match, colors, profile, volume, dirty } = this.state;
        return <section>
            <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="slideInRight" animationOut="slideOutRight" isVisible={this.props.adding_attributes ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200 }}>
                <section className="bg-whiteish d-flex flex-row w-100 p-4" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, paddingTop: 70, overflowY: 'auto' }}>

                    {match && false && <div className="align-self-start d-flex flex-column justify-content-center" style={{ width: 60, paddingLeft: 80 }}>
                        {_.values(colors).map((color, i) => <i onClick={() => this.onColor(color)} key={i} className={`align-self-center icon-bookmark1 mb-2 font-${color === match.Color ? '50' : '30'}`} style={{ color: `${color}` }} />)}
                    </div>}
                    {match && <section className="w3-container w3-content mx-auto w-100">
                        <div className="d-flex flex-column black align-self-start w-100">

                            <div className="w3-container bg-white p-4">
                                <label>Athlete's Name</label>
                                <input type="text" defaultValue={match.NameFirst ? `${match.NameFirst} ${match.NameLast}` : match.NameFirst} ref={i => this.txtName = i} className={`w3-input ${(dirty && !this.txtName.value) ? 'bg-danger' : ''}`} />
                            </div>

                            {match.Topics && match.Topics.map((topic, i) => <section key={i}>
                                <Slider
                                    min={0}
                                    max={10}
                                    step={0.1}
                                    value={topic.Value}
                                    orientation="horizontal"
                                    onChange={(value) => this.onTopicChange(i, value)}
                                    tooltip={false}
                                />
                                <div className="d-flex flex-row">
                                    <span className="align-self-center">{topic.TopicName}</span>
                                    {topic.SubTopics.length > 0 && <i onClick={() => this.onToggleSubTopic(i)} className={`pointer ms-2 align-self-center fas fa-chevron-${topic.toggled ? 'up' : 'down'}`} />}
                                    <span className="ms-auto align-self-center">{topic.Value.toFixed(1)}</span>
                                </div>
                                {topic.toggled && <section className="pl-4 ms-4">
                                    {topic.SubTopics && topic.SubTopics.map((subtopic, j) => <section key={j}>
                                        <Slider
                                            min={0}
                                            max={10}
                                            step={0.1}
                                            value={subtopic.Value}
                                            orientation="horizontal"
                                            onChange={(value) => this.onSubTopicChange(i, j, value)}
                                            tooltip={false}
                                        />
                                        <div className="d-flex flex-row">
                                            <span className="align-self-center">{subtopic.TopicName}</span>
                                            <span className="ms-auto align-self-center">{subtopic.Value.toFixed(1)}</span>
                                        </div>
                                    </section>
                                    )}
                                </section>}
                            </section>
                            )}
                            <button className={`btn btn-${this.state.saved ? 'success' : 'info'} mt-4 w-100`} type="button" onClick={this.onSave}>{this.state.saved ? 'Attributes saved!' : 'Save Attributes'}</button>
                            <div className="p-4" />
                        </div>
                    </section>}

                </section>
                <div onClick={this.props.toggle} style={{ width: 50, height: 50, borderRadius: 25, position: 'fixed', top: 15, left: 15 }} className="bg-gray d-flex flex-column justify-content-center">
                    <i className="fas fa-times black font-30 align-self-center" />
                </div>
            </Animated>
        </section>
    }
}

export default AddAttributes;