import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';

class ShareMatch extends React.Component {
    state = { data: null }

    componentWillReceiveProps = nextProps => {
        const { match } = nextProps;
        if (match) {

            let image = '', clip = '';
            switch (match.ReportType) {
                case 1:
                    image = `https://api.sportslogic.net/api/v5/qrcodes/scout/profile/${match.IdMatch}`;
                    clip = `https://www.thesqwad.com/scout/profile/${match.IdMatch}`;
                    break;
                case 2:
                    image = `https://api.sportslogic.net/api/v5/qrcodes/scout/match/${match.IdMatch}`;
                    clip = `https://www.thesqwad.com/scout/match/${match.IdMatch}`;
                    break;
                case 3:
                    image = `https://api.sportslogic.net/api/v5/qrcodes/scout/team/${match.IdMatch}`;
                    clip = `https://www.thesqwad.com/scout/team/${match.IdMatch}`;
                    break;
                case 10: // Notes (GOOD)
                    image = `https://api.sportslogic.net/api/v5/qrcodes/scout/note/${match.IdMatch}`;
                    clip = `https://www.thesqwad.com/scout/note/${match.IdMatch}`;
                    break;
                case 11: // Attributes (GOOD)
                    image = `https://api.sportslogic.net/api/v5/qrcodes/scout/attributes/${match.IdMatch}`;
                    clip = `https://www.thesqwad.com/scout/attributes/${match.IdMatch}`;
                    break;
                case 12: // Player Review
                    image = `https://api.sportslogic.net/api/v5/qrcodes/scout/pr/${match.IdMatch}`;
                    clip = `https://www.thesqwad.com/scout/pr/${match.IdMatch}`;
                    break;
                case 80: // Custom TEam                    
                    image = `https://api.sportslogic.net/api/v5/qrcodes/scout/personal_team/${match.IdMatch}`;
                    clip = `https://www.thesqwad.com/scout/personal_team/${match.IdMatch}`;
                    break;
                case 90: // Custom Player                    
                    image = `https://api.sportslogic.net/api/v5/qrcodes/scout/personal_player/${match.IdMatch}`;
                    clip = `https://www.thesqwad.com/scout/personal_player/${match.IdMatch}`;
                    break;
                default:
                    clip = `https://www.thesqwad.com/scout/match/${match.IdMatch}`;
                    image = `https://api.sportslogic.net/api/v5/qrcodes/scout/match/${match.IdMatch}`;
                    break;
            }

            this.setState({ match, image, clip });
        }
    }

    render() {
        const { match, image, clip } = this.state;
        return <section>
            <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="slideInRight" animationOut="slideOutRight" isVisible={this.props.match ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, width: 400, right: 0, paddingTop: 70, zIndex: 200 }}>
                <section className="bg-whiteish w-100 p-4" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, paddingTop: 70, overflowY: 'auto' }}>
                    {match && <section className="w3-container d-flex flex-column">

                        <div onClick={this.props.toggle} style={{ width: 50, height: 50, borderRadius: 25 }} className="bg-gray d-flex flex-column justify-content-center align-self-start">
                            <i className="fas fa-times black font-30 align-self-center" />
                        </div>
                        <img src={image} className="w-100 mt-3" />

                        <span className="mt-2 text-center">Scan this code with</span>
                        <img src="/images/logos/rc.png" className="m-auto" style={{ height: 50, width: 50 }} />
                        <div style={{ height: 30 }} />
                        <span className="mt-2 text-center">- OR -</span>
                        <CopyToClipboard text={clip}
                            onCopy={() => this.setState({ copySuccess: true })}>
                            <button className={`btn btn-${this.state.copySuccess ? 'success' : 'link bordered'} m-auto`}>
                                <i className="fas fa-clipboard blue" /> Copy link to your clipboard</button>
                        </CopyToClipboard>
                        <div style={{ height: 30 }} />
                    </section>}
                </section>

            </Animated>
        </section>
    }
}

export default ShareMatch;