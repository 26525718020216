import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import moment from 'moment';
import { generateRandomId } from '../../../helpers';
import fakeAuth from '../../../fakeAuth';

class MoveToFolder extends React.Component {
    state = {

    }

    onSave = (IdFolder) => {        
        const { move_to_folder } = this.props;
        this.props.scout_actions && this.props.scout_actions.move_match_to_folder(move_to_folder.IdMatch, IdFolder, move_to_folder.ReportType);
        this.props.toggle();
    }

    render() {
        const { match, colors } = this.state, { folders, folder_type, move_to_folder } = this.props;
        let filtered_folders = _.filter(folders, f => f.IdFolderType === folder_type);
        return <section>
            <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="slideInRight" animationOut="slideOutRight" isVisible={move_to_folder ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200 }}>
                {true && <section className="bg-whiteish w3-container w-100 p-4" style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, paddingTop: 70 }}>

                    <h3 className="sqwad-font col-12 text-right">PICK A FOLDER</h3>
                    <div className="w3-content mx-auto row">

                        <div className="col-sm-6 col-md-3 p-1 w3-container w3-cell" onClick={() => this.onSave()}>
                            <div className="w-100 py-2 tile card d-flex flex-row h-100">
                                <div className="d-flex flex-column justify-content-center w-100 py-2">
                                    <i className="icon-folder-minus black text-center font-70" />
                                    <span className="line1 mt-2 font-10 black text-center">NO FOLDER</span>
                                </div>
                            </div>
                        </div>

                        {/* YES */}
                        {filtered_folders && filtered_folders.map((folder, i) =>
                            <div key={i} className="col-sm-6 col-md-3 p-1 w3-container w3-cell" onClick={() => this.onSave(folder.IdFolder)}>
                                <div className="w-100 py-2 tile card d-flex flex-row h-100">
                                    <div className="d-flex flex-column justify-content-center w-100 py-2">
                                        <i className="icon-folder black text-center font-70" />
                                        <span className="line1 mt-2 font-10 black text-center">{folder.Name || folder.FolderName}</span>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* NO */}
                        {/*filtered_folders && !filtered_folders.length && <div className="d-flex w-100 flex-column justify-content-center">
                            <img src="/images/icons/red_card.png" className="w-25 align-self-center mt-4" />
                            <span className="font-30 align-self-center text-center mx-4 px-4 line1 mt-4">Sorry, you have no<br />folders in this area</span>
                        </div>*/}

                        <div style={{ height: 200 }} className="col-12 " />
                    </div>

                    <div onClick={this.props.toggle} style={{ width: 50, height: 50, borderRadius: 25, position: 'absolute', top: 15, left: 15 }} className="bg-gray d-flex flex-column justify-content-center">
                        <i className="fas fa-times black font-30 align-self-center" />
                    </div>

                </section>}

            </Animated>
        </section>
    }
}

export default MoveToFolder;