import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import moment from 'moment';
import { generateRandomId } from '../../../helpers';
import { Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import fakeAuth from '../../../fakeAuth';
import formations from '../../../formations';


class PlayerOnMatch extends React.Component {

    state = {
        bootstrapped: false,
    }

    componentWillReceiveProps = nextProps => {

    }

    onRemove = () => {
        const { player_on_match } = this.props;

        player_on_match.Id = null;
        player_on_match.NameFirst = null;
        player_on_match.NameLast = null;
        player_on_match.JerseyNumber = null;
        player_on_match.Subbed = null;
        player_on_match.Score = null;
        player_on_match.Rating = null;
        player_on_match.PitchPosition = null;

        this.forceUpdate();
        this.props.toggle();
    }

    onPenalty = (e) => {        
        const { player_on_match } = this.props;
        player_on_match.Violation = e;
        this.forceUpdate();
     }

    onSave = () => {
        const { player_on_match } = this.props;

        if (!this.txtNameFirst.value ||
            !this.txtNameLast.value ||
            !this.txtJerseyNumber.value) {
            // TODO: 

        }
        player_on_match.Id = player_on_match.Id || generateRandomId(9);
        player_on_match.NameFirst = this.txtNameFirst.value;
        player_on_match.NameLast = this.txtNameLast.value;
        player_on_match.JerseyNumber = this.txtJerseyNumber.value;
        player_on_match.Subbed = this.txtSubbed.value;
        player_on_match.Score = this.txtScore.value;
        player_on_match.Rating = this.txtRating.value;

        this.forceUpdate();
        this.props.toggle();
    }

    render() {
        const { player_on_match, penalties } = this.props;
        return <section>
            <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="slideInRight" animationOut="slideOutRight" isVisible={this.props.player_on_match ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200, }}>
                <section ref={i => this.section = i} className="bg-whiteish d-flex flex-row w-100 p-4 ease" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, paddingTop: 70, overflowY: 'auto' }}>
                    {player_on_match && <section className="w3-container w3-content mx-auto w-100">

                        {/* PAGE 1 */}
                        <div className="d-flex flex-column black align-self-start w-100 w3-container">
                            <div className="w3-container bg-white p-4 tile card">
                                <label>First Name</label>
                                <input className="w3-input" type="text" defaultValue={`${player_on_match.NameFirst || ''}`} ref={i => this.txtNameFirst = i} />
                                <label>Last Name</label>
                                <input className="w3-input" type="text" defaultValue={`${player_on_match.NameLast || ''}`} ref={i => this.txtNameLast = i} />
                                <label>Jersey Number</label>
                                <input className="w3-input" type="text" defaultValue={`${player_on_match.JerseyNumber || ''}`} ref={i => this.txtJerseyNumber = i} />
                                <label>Subbed</label>
                                <input className="w3-input" type="text" defaultValue={`${player_on_match.Subbed || ''}`} ref={i => this.txtSubbed = i} />
                                <label>Score</label>
                                <input className="w3-input" type="text" defaultValue={`${player_on_match.Score || ''}`} ref={i => this.txtScore = i} />
                                <label>Rating</label>
                                <input className="w3-input" type="number" min="0" max="10" defaultValue={`${player_on_match.Rating || ''}`} ref={i => this.txtRating = i} />

                                <label>Infraction</label>
                                <Dropdown isOpen={this.state.pickingHomeFormation ? true : false} toggle={() => this.setState({ pickingHomeFormation: !this.state.pickingHomeFormation })} className="form-control p-0 black mt-2">
                                    <DropdownToggle className="text-left btn btn-default w-100 tile card" >
                                        <div className="d-flex w-100">
                                            <span className="align-self-center">{player_on_match.Violation ? player_on_match.Violation.Name : 'NONE'}</span>
                                            <i className="ms-auto fas fa-caret-down align-self-center " />
                                        </div>
                                    </DropdownToggle>
                                    <DropdownMenu className="w-100" style={{ maxHeight: 200, overflowY: 'auto' }}>
                                        <DropdownItem onClick={() => this.onPenalty()}>None</DropdownItem>
                                        {penalties && penalties.map((c, index) => <DropdownItem key={index} onClick={() => this.onPenalty(c)}><i className="fas fa-square me-2" style={{ color: c.Color }} />{c.Name}</DropdownItem>)}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>

                            <button className="btn w3-blue btn-block mt-4" onClick={this.onSave}>Add Player</button>
                            <button className="btn btn-link btn-block red" onClick={this.onRemove}>Remove</button>
                            <div className="p-4" />
                            <div className="p-4" />
                        </div>

                    </section>}
                </section>

                {/* X */}
                <div onClick={this.props.toggle} style={{ width: 50, height: 50, borderRadius: 25, position: 'fixed', top: 15, left: 15 }} className="bg-gray d-flex flex-column justify-content-center">
                    <i className="fas fa-times black font-30 align-self-center" />
                </div>

            </Animated>
        </section>
    }
}

export default PlayerOnMatch;