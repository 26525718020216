function folder(state = [], action) {
    switch (action.type) {
        case 'FOLDER/SET':
            return action.folder;
        case 'FOLDER/CLEAR':
            return null;
        default:
            return state;
    }
}

export default folder;
