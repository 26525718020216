function follow_seasons(state = [], action) {
    switch (action.type) {
        case 'FOLLOW/SEASONS/SET':            
            return action.seasons;
        case 'FOLLOW/SEASONS/CLEAR':
            return null;
        default:
            return state;
    }
}

export default follow_seasons