import React from 'react';
import { Link } from 'react-router';

const MenuMobile = ({ path = [], user = {} }) => {
    return (
        <div style={{ position: 'fixed', bottom: 0, left: 10, right: 10, borderTopRightRadius: 5, borderTopLeftRadius: 5 }} className="py-2 px-2 bg-whiteish w3-hide-medium w3-hide-large">
            {user && <div className="d-flex flex-row w-100 justify-content-center">
                <Link to={`/lead/${user.IdUser || user.IdUserFollow}`} className={`bg-${path.indexOf('prospect') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-column justify-content-center w-100 align-self-center`} style={{ height: 50 }}>
                    <i className="icon-info font-16 align-self-center" />
                    <span className="align-self-center font-8">Info</span>
                </Link>
                <Link to="/prospect_highlights" className={`bg-${path.indexOf('prospect_highlights') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-column justify-content-center w-100 align-self-center`} style={{ height: 50 }}>
                    <i className="icon-film-reel-1 font-16 align-self-center" />
                    <span className="align-self-center font-8">Highlight</span>
                </Link>
                <Link to="/prospect_evaluations" className="bg-white rounded d-flex flex-column justify-content-center w-100 align-self-center" style={{ height: 50 }}>
                    <i className="icon-checklist font-16 jeff_blue align-self-center" />
                    <span className="align-self-center black font-8">Evaluation</span>
                </Link>
            </div>}
        </div>
    );
}

export default MenuMobile;
