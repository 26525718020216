function folders(state = [], action) {
    switch (action.type) {
        case 'FOLDERS/SET':
            return action.folders;
        case 'FOLDERS/CLEAR':
            return null;
        default:
            return state;
    }
}

export default folders;
