import React from 'react';
import Layout from '../layouts/claire';
import { Animated } from 'react-animated-css';
import Menu from '../home/partials/menu';
import { Link } from 'react-router';
import { Input, } from 'reactstrap';
import _ from 'lodash';
import LineLoader from '../partials/line_loader';
import moment from 'moment';
import fakeAuth from '../../fakeAuth';
import BlackOverlay from '../partials/black_overlay';
import config from '../../config';
import request from 'superagent';
import Team from './partials/team';
import TeamQR from './partials/team_qr';
import SeasonQR from './partials/season_qr';
import AddCoachProfile from '../home/partials/add_coach_profile';

class ScoutingEvents extends React.Component {
    state = {
        menu_toggled: false,
        mode: 1
    }
    componentWillMount() {
        this.props.scout_actions && this.props.scout_actions.fetch_family_subscriptions();
        this.props.scout_actions && this.props.scout_actions.fetch_coach_profile(fakeAuth.getUser().IdUser);
        this.props.scout_actions && this.props.scout_actions.fetchFollowees();
        this.props.scout_actions && this.props.scout_actions.fetchTeams();
        this.props.scout_actions && this.props.scout_actions.fetchFollowSeasons();
        this.props.custom_pages_actions && this.props.custom_pages_actions.fetch_all();
        this.props.custom_pages_actions && this.props.custom_pages_actions.fetch_public_match_reports();
    }
    componentWillReceiveProps = nextProps => {
        const { coach_profile, custom_pages } = nextProps;
        let items = [], old = [];
        _.chain(custom_pages)
            .filter(season => season.IsPublic)
            .filter(season => moment(season.DateMatch || season.MatchDate).isAfter(moment()))
            .filter(season => {
                const filterText = this.state.search;
                if (filterText) {
                    return `${season.LeagueName}_${season.SeasonName}_${season.Venue}`.toLowerCase().indexOf(filterText.toLowerCase()) !== -1;
                }
                return true;
            })
            .value()
            .forEach(season => {

                items.push({
                    following_dot: {
                        visible: _.find([] /*STORE.following_seasons*/, s => parseInt(s, 10) === parseInt(season.IdSeason)) ? true : false,
                        /*color: COLORS.green,
                        text: CONFIG.keys.dot*/
                    },
                    season,
                    seasonType: { text: season.LeagueName },
                    seasonName: { text: season.SeasonName.toUpperCase(), font: { fontSize: season.SeasonName.length > 30 ? 12 : 16 } },
                    seasonDates: { text: moment(season.MatchDate || season.DateMatch).format('MMM Do YYYY') },
                    idSeason: { text: `(ID: ${season.IdSeason})`, bottom: 15 },
                    img: { image: season.LeagueImage || `/images/defaults/generic_crest.png`, defaultImage: `/images/defaults/generic_crest.png` },
                    qr: {
                        visible: season.IdSeason ? true : false,
                        image: season.IdSeason ? `https://api.sportslogic.net/api/v5/qrcodes/scout/season_brochure/${season.IdSeason}` : `/images/defaults/generic_crest.png`,
                        defaultImage: `/images/defaults/generic_crest.png`
                    },
                });
            });

        _.chain(custom_pages)
            .filter(season => season.IsPublic)
            .filter(season => !moment(season.DateMatch || season.MatchDate).isAfter(moment()))
            .filter(season => {
                const filterText = this.state.search;
                if (filterText) {
                    return `${season.LeagueName}_${season.SeasonName}_${season.Venue}`.toLowerCase().indexOf(filterText.toLowerCase()) !== -1;
                }
                return true;
            })
            .value()
            .forEach(season => {
                old.push({
                    season,
                    seasonType: { text: season.LeagueName },
                    seasonName: { text: season.SeasonName.toUpperCase(), font: { fontSize: season.SeasonName.length > 30 ? 12 : 16 } },
                    seasonDates: { text: moment(season.MatchDate || season.DateMatch).format('MMM Do YYYY') },
                    idSeason: { text: `(ID: ${season.IdSeason})`, bottom: 15 },
                    img: { image: season.LeagueImage || `/images/defaults/generic_crest.png`, defaultImage: `/images/defaults/generic_crest.png` },
                    qr: {
                        visible: season.IdSeason ? true : false,
                        image: season.IdSeason ? `https://api.sportslogic.net/api/v5/qrcodes/scout/season_brochure/${season.IdSeason}` : `/images/defaults/generic_crest.png`,
                        defaultImage: `/images/defaults/generic_crest.png`
                    },
                });
            });

        this.setState({
            coach_profile: (coach_profile && coach_profile.IdCoachProfile) ? coach_profile : null,
            items, old
        });

        this.setState({ subscriptions: nextProps.subscriptions }, () => {
            this.setState({
                subscription: _.find(nextProps.subscriptions, s => s.IdPlan === '7404C09F-9E11-4FBF-BF82-B2C487A911F5' && !s.Expired && s.IdUser === fakeAuth.getUser().IdUser),
                bootstrapped: true
            });
        });

    }

    onSearch = (e) => {
        e && e.preventDefault && e.preventDefault();
    }
    handleChangeSearch = event => { this.setState({ search: event.target.value }); }

    onMode = ({ mode }) => {
        if (this.state.mode === mode) return;
        this.setState({ mode, page: 0, nope: false });
    }
    onFetchGames = () => {
        const { element } = this.state;
        request.get(`${config.apiEndpoint}/api/v6/custom_pages/${element.IdMatch}`)
            .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
            .then(data => {
                let payload = JSON.parse(data.text), { custom_page } = payload;
                this.setState({ games: custom_page.Games });
            });
    }
    onCopy = () => {
        const { found, copied } = this.state;
        if (!copied) {
            this.props.scout_actions.save_match({
                ...found,
                IdMatch: null,
                EvaluationDate: moment(),
                IdUser: fakeAuth.getUser().IdUser
            });
            this.setState({ copied: true });
        }
    }
    onFetchMatchReport = (IdMatch) => {
        this.setState({ microTransac: true }, () => {
            request.get(`${config.apiEndpoint}/api/v6/scout/match/${IdMatch}`)
                .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                .then(data => {
                    let payload = JSON.parse(data.text), { match } = payload;
                    this.setState({ microTransac: false, found: match });
                }, e => {
                    alert('Sorry, invalid Match Report');
                });
        });
    }

    renderSeason(evaluation, index) {
        const { follow_seasons } = this.props, { subscription } = this.state;
        return <Animated key={index} animationInDelay={25 * index} animationIn="fadeInRightBig" className="col-md-6 p-0 mt-2" style={{ position: 'relative' }}>
            <div onClick={() => {
                if (subscription) {
                    this.setState({ element: evaluation.season }, () => {
                        this.onFetchGames();
                    });
                } else {
                    this.props.router.push('/subscriptions');
                }
            }} className="pl-4 py-3 tile card mx-1 px-2 pointer h-100">
                <div className="w-100 d-flex flex-row">
                    <div className="w-25 align-self-center">
                        <img className="w-100" src={evaluation.img.image} />
                    </div>
                    <div className="w-75 px-4">
                        <div className="montserrat black font-16">{evaluation.seasonType.text}</div>
                        <div className="montserrat black font-12">{evaluation.seasonName.text}</div>
                        <div className="montserrat black font-10">{evaluation.seasonDates.text}</div>
                    </div>
                </div>
            </div>
            {evaluation.qr.visible && <img style={{ position: 'absolute', bottom: 5, right: 5, width: 30, height: 30 }} src={evaluation.qr.image} />}
            {/* GREEN DOT */}
            {_.find(follow_seasons || [], s => parseInt(s.IdSeason, 10) === parseInt(evaluation.season.IdSeason, 10)) && <i className="fas fa-circle green font-8" style={{ position: 'absolute', top: 10, right: 10 }} />}
        </Animated >
    }
    renderUpcoming = () => {
        const { items = [] } = this.state;
        if (items.length) {
            return <section>
                <h1>UPCOMING SEASONS</h1>
                <div className="d-flex flex-wrap">
                    {items.map((evaluation, index) => this.renderSeason(evaluation, index))}
                </div>
                <hr />
            </section>
        } else return null;

    }
    renderOld = () => {
        const { old = [] } = this.state;
        if (old.length) {
            return <section>
                <h1>PAST SEASONS</h1>
                <div className="d-flex flex-wrap">
                    {old.map((evaluation, index) => this.renderSeason(evaluation, index))}
                </div>
            </section>
        } else return null;
    }
    renderMatchReport = (game, index) => {
        const { subscription } = this.state;
        return <Animated animationIn='slideInRight' animationInDelay={50 * index} className={`bg-${index % 2 ? 'gray' : 'white'} p-4`} style={{ minHeight: 300, position: 'relative' }} key={index}>
            <div className="w3-content d-flex flex-row">
                {/* HOME */}
                <div className="w-100 d-flex flex-column">
                    <div onClick={() => {
                        if (subscription) {
                            this.setState({ team_qr: game.Home });
                        } else {
                            this.props.router.push('/subscriptions');
                        }
                    }} className="pointer align-self-center bordered contains contain align-self-center" style={{ borderRadius: 5, width: 100, height: 100, background: `url(${game.Home.Logo || `/images/defaults/generic_crest.png`}) no-repeat center center white` }} />
                    <span className="font-14 blue underline montserrat align-self-center">{game.Home.Name}</span>
                </div>
                {/* MID */}
                <div className="w-100 d-flex flex-column">
                    <span className="text-center font-12 black">{game.Title}</span>
                    <span className="text-center font-10 black">By: {game.CreatorNameFirst} {game.CreatorNameLast}</span>
                    <span className="blue font-40 text-center">{`${game.Home.Score}  VS  ${game.Away.Score}`}</span>
                    <hr className="m-0" />

                    <span className="text-center font-16 black">{game.DateString}</span>
                    <span className="text-center font-14 black">{game.Venue}</span>
                    <span className="text-center font-12 black">{game.TimeString}</span>
                    {game.ExternalVideoLink && <a href={game.ExternalVideoLink} target="_blank" className="text-center align-self-center">
                        <i className="fab fa-youtube red" />
                    </a>}
                    {game.MatchReportLink && <div onClick={() => {
                        if (subscription) {
                            this.onFetchMatchReport(_.last(game.MatchReportLink.split('/')));
                        } else {
                            this.props.router.push('/subscriptions');
                        }
                    }} className="d-flex align-self-center flex-column justify-content-center">
                        <img src={game.MatchReportQR} className="align-self-center" style={{ width: 60, height: 60 }} />
                        <span className="black font-8 align-self-center">MATCH REPORT</span>
                    </div>}
                </div>
                {/* AWAY */}
                <div className="w-100 d-flex flex-column">
                    <div onClick={() => {
                        if (subscription) {
                            this.setState({ team_qr: game.Away });
                        } else {
                            this.props.router.push('/subscriptions');
                        }
                    }} className="pointer align-self-center bordered contains contain align-self-center" style={{ borderRadius: 5, width: 100, height: 100, background: `url(${game.Away.Logo || `/images/defaults/generic_crest.png`}) no-repeat center center white` }} />
                    <span className="font-14 blue underline montserrat align-self-center">{game.Away.Name}</span>
                </div>
            </div>
            {(!index % 2 !== 0) && <span className="gray font-60 line1" style={{ opacity: 0.2, position: 'absolute', bottom: -5, left: 0 }}>{`GAME ${index + 1}`}</span>}
            {(index % 2 !== 0) && <span className="gray font-60 line1" style={{ opacity: 0.2, position: 'absolute', bottom: -5, right: 0 }}>{`GAME ${index + 1}`}</span>}
        </Animated>
    }
    renderMatchReports = () => {
        const { public_matches } = this.props;
        return <section>
            <h1>MATCH REPORTS</h1>
            {public_matches && _.chain(public_matches)
                .filter(season => {
                    const filterText = this.state.search;
                    if (filterText) {
                        return `${season.Home.Name}_${season.Away.Name}`.toLowerCase().indexOf(filterText.toLowerCase()) !== -1;
                    }
                    return true;
                })
                .value().map((game, index) => this.renderMatchReport(game, index))}
        </section>
    }

    renderCommon = () => {
        const { bootstrapped, mode, coach_profile, subscription } = this.state;

        return (
            <div className="w3-content px-4 container pt-4">


                {bootstrapped && !coach_profile && <section>

                    <div className="row">
                        <div className="card tile bg-blue-dark font-18 line1 w-100 p-4 pointer" onClick={() => this.setState({ adding_coach_profile: true })}>
                            <div className="d-flex flex-row">
                                <div>
                                    <p className="white montserrat">Complete your profile</p>
                                    <p className="white montserrat font-14">You don't currently have a <strong>Scouting, Coaching or Recruiting profile.</strong> Without a prlfile you'll not be able to connect with potential recruits. Please click here to add.</p>
                                </div>
                                <i className="icon-loader blue-link font-40" />
                            </div>
                        </div>

                    </div>
                </section>}

                {bootstrapped && coach_profile && <section>

                    {/* UPGRADE TO PRO BANNER */}
                    {!subscription && <div className="w-100 premium-gradient px-4 py-2 mb-3 rounded align-self-center pointer">
                        <Link to="/subscriptions" className="d-flex flex-row px-4 py-2 ">
                            <div className="d-flex flex-column w-100">
                                <span className="sqwad-font font-16 black align-self-center line1">UPGRADE</span>
                                <span className="font-12 black align-self-center line1">to SQWAD SCOUT Pro</span>
                            </div>
                        </Link>
                    </div>}

                    {/* SEARCH AND TABS */}
                    <div className="row">
                        <div className="d-flex flex-row w-100">
                            <div className="tile  w-100 w-100" >
                                <form key="search-form">
                                    <Input className="w-100 p-3 tile card" defaultValue={this.state.search} placeholder="Search" onChange={this.handleChangeSearch} />
                                    <button type="submit" onClick={this.onSearch} className="w3-hide" />
                                </form>
                            </div>
                        </div>
                        <div className="d-flex flex-row syncopate text-center w-100 my-3">
                            <div className={`w-100 p-2 ${mode === 1 ? '' : 'bg-gray'}`} onClick={() => this.onMode({ mode: 1 })}>
                                <h4 className="p-0 m-0">CUSTOM EVENTS</h4>
                            </div>
                            <div className={`w-100 p-2 ${mode === 2 ? '' : 'bg-gray'}`} onClick={() => this.onMode({ mode: 2 })}>
                                <h4 className="p-0 m-0">MATCH REPORTS</h4>
                            </div>
                        </div>
                    </div>

                    {mode === 1 && this.renderUpcoming()}
                    {mode === 1 && this.renderOld()}
                    {mode === 2 && this.renderMatchReports()}

                </section>}

            </div>
        )
    }
    render() {
        const form = this.props.form_actions;
        return (
            <Layout router={this.props.router} className="bg-white" nbs="home" title="SQWAD SCOUT" form={this.props.form} form_actions={this.props.form_actions}>
                <section>

                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small" style={{ marginLeft: 200 }}>
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                    </div>
                    {/* MOBILE */}
                    <div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                    </div>

                    <Menu {...this.state} {...this.props} />

                    {/* SEASON */}
                    <Animated animationIn="slideInRight" animationOut="slideOutRight"
                        animationInDuration={200} animateOnMount={false} animationOutDuration={200}
                        style={{ position: 'fixed', top: 70, right: 0, left: 0, bottom: 0 }}
                        className="" isVisible={this.state.element ? true : false}>
                        <BlackOverlay />
                        <div style={{ position: 'fixed', top: 0, bottom: 0, left: 0, width: 100 }} onClick={() => this.setState({ element: null })} />
                        <div style={{ position: 'fixed', top: 0, bottom: 0, right: 0, left: 100, overflowY: 'auto' }} className="bg-darker-blue">
                            {this.state.element && <div className="w-100">
                                <div className="mt-2">
                                    <i className="fas pointer ms-4 fa-arrow-left white font-20" onClick={() => this.setState({ element: null })} />
                                    <span className="ms-4 montserrat white font-20">GAMES</span>
                                </div>
                                <div className="d-flex flex-column justify-content-center" style={{ minHeight: 300, position: 'relative' }}>
                                    <div className="align-self-center bordered contains contain" style={{ borderRadius: 5, width: 100, height: 100, background: `url(${this.state.element.LeagueImage || `/images/defaults/generic_crest.png`}) no-repeat center center white` }} />
                                    <div className="montserrat white font-16 align-self-center mt-2">{this.state.element.SeasonName ? this.state.element.SeasonName.toUpperCase() : ''}</div>
                                    <div className="montserrat white font-12 align-self-center">{this.state.element.LeagueName}</div>
                                    <div className="montserrat white font-10 align-self-center">{this.state.element.Venue}</div>
                                    {/*                                     
                                    // NEW: the idea is that now this QR belongs to the Custom Page (match), and now
    // scout can follow the page itself, to be listed in their Network/Events    
    `https://api.sportslogic.net/api/v5/qrcodes/scout/custom_page/${match.IdMatch} to 
    https://www.thesqwad.com/scout/custom_page/AF30107D-4D32-441E-9C40-14343A63F745`
                                    */}
                                    {this.state.element.IdSeason && (
                                        <div
                                            onClick={() => { this.setState({ season_qr: true }); }}
                                            className="d-flex flex-column justify-content-center"
                                            style={{ position: 'absolute', bottom: 5, right: 10 }}>

                                            <img src={`https://api.sportslogic.net/api/v5/qrcodes/scout/custom_page/${this.state.element.IdMatch}`} style={{ width: 60, height: 60 }} />
                                            <span className="white font-8 align-self-center">BROCHURE</span>
                                        </div>)}
                                </div>
                            </div>}
                            {/* GAMES */}
                            {this.state.games && <div className="">
                                {this.state.games.map((game, index) => <div className={`bg-${index % 2 ? 'gray' : 'white'} p-4`} style={{ minHeight: 300, position: 'relative' }} key={index}>
                                    <div className="w3-content d-flex flex-row">
                                        {/* HOME */}
                                        <div className="w-100 d-flex flex-column">
                                            <div onClick={() => {
                                                this.setState({ team_qr: game.Home });
                                            }} className="pointer align-self-center bordered contains contain align-self-center" style={{ borderRadius: 5, width: 100, height: 100, background: `url(${game.Home.Logo || `/images/defaults/generic_crest.png`}) no-repeat center center white` }} />
                                            <span className="font-14 blue underline montserrat align-self-center">{game.Home.Name}</span>
                                        </div>
                                        {/* MID */}
                                        <div className="w-100 d-flex flex-column">
                                            <span className="blue font-40 text-center">{`${game.Home.Score}  VS  ${game.Away.Score}`}</span>
                                            <hr className="m-0" />
                                            <span className="text-center font-16 black">{game.DateString}</span>
                                            <span className="text-center font-14 black">{game.Venue}</span>
                                            <span className="text-center font-12 black">{game.TimeString}</span>
                                            {game.ExternalVideoLink && <a href={game.ExternalVideoLink} target="_blank" className="text-center align-self-center">
                                                <i className="fab fa-youtube red" />
                                            </a>}
                                            {game.MatchReportLink && <div onClick={() => this.onFetchMatchReport(_.last(game.MatchReportLink.split('/')))} className="d-flex align-self-center flex-column justify-content-center">
                                                <img src={game.MatchReportQR} className="align-self-center" style={{ width: 60, height: 60 }} />
                                                <span className="black font-8 align-self-center">MATCH REPORT</span>
                                            </div>}
                                        </div>
                                        {/* AWAY */}
                                        <div className="w-100 d-flex flex-column">
                                            <div onClick={() => {
                                                this.setState({ team_qr: game.Away });
                                            }} className="pointer align-self-center bordered contains contain align-self-center" style={{ borderRadius: 5, width: 100, height: 100, background: `url(${game.Away.Logo || `/images/defaults/generic_crest.png`}) no-repeat center center white` }} />
                                            <span className="font-14 blue underline montserrat align-self-center">{game.Away.Name}</span>
                                        </div>
                                    </div>
                                    {(!index % 2 !== 0) && <span className="gray font-60 line1" style={{ opacity: 0.2, position: 'absolute', bottom: -5, left: 0 }}>{`GAME ${index + 1}`}</span>}
                                    {(index % 2 !== 0) && <span className="gray font-60 line1" style={{ opacity: 0.2, position: 'absolute', bottom: -5, right: 0 }}>{`GAME ${index + 1}`}</span>}
                                </div>)}
                            </div>}
                        </div>
                    </Animated>


                    {/* TEAM QR */}
                    <Animated animationIn="slideInRight" animationOut="slideOutRight" animationInDuration={200} animateOnMount={false} animationOutDuration={200} style={{ position: 'fixed', top: 70, right: 0, left: 0, bottom: 0 }} className="" isVisible={this.state.team_qr ? true : false}>
                        <BlackOverlay />
                        <div style={{ position: 'fixed', top: 0, bottom: 0, left: 0, width: 100 }}
                            onClick={() => this.setState({ team_qr: null })} />
                        <div style={{ position: 'fixed', top: 0, bottom: 0, right: 0, left: 100, overflowY: 'auto' }} className="bg-gray">
                            {this.state.team_qr && <TeamQR view={() => {
                                if (this.state.team_qr.IdTeamDivision) this.setState({ team: this.state.team_qr });
                            }} {...this.props} season={this.state.element ? this.state.element.SeasonName.toUpperCase() : ''}
                                team={this.state.team_qr}
                                toggle={() => this.setState({ team_qr: null })} />}
                        </div>
                    </Animated>

                    {/* TEAM */}
                    <Animated animationIn="slideInRight" animationOut="slideOutRight" animationInDuration={200} animateOnMount={false} animationOutDuration={200} style={{ position: 'fixed', top: 70, right: 0, left: 0, bottom: 0 }} className="" isVisible={this.state.team ? true : false}>
                        <div style={{ position: 'fixed', top: 0, bottom: 0, left: 0, width: 100 }}
                            onClick={() => this.setState({ team: null })} />
                        <div style={{ position: 'fixed', top: 0, bottom: 0, right: 0, left: 100, overflowY: 'auto' }} className="bg-gray">
                            {this.state.team && <Team {...this.props}
                                season={this.state.element ? this.state.element.SeasonName.toUpperCase() : ''}
                                team={this.state.team}
                                toggle={() => this.setState({ team: null })} />}
                        </div>
                    </Animated>

                    {/* SEASON QR */}
                    <Animated animationIn="slideInRight" animationOut="slideOutRight" animationInDuration={200} animateOnMount={false} animationOutDuration={200} style={{ position: 'fixed', top: 70, right: 0, left: 0, bottom: 0 }} className="" isVisible={this.state.season_qr ? true : false}>
                        <div style={{ position: 'fixed', top: 0, bottom: 0, left: 0, width: 100 }}
                            onClick={() => this.setState({ season_qr: null })} />
                        <div style={{ position: 'fixed', top: 0, bottom: 0, right: 0, left: 100, overflowY: 'auto' }} className="bg-gray">
                            {this.state.season_qr && <SeasonQR {...this.props} season={this.state.element}
                                toggle={() => this.setState({ season_qr: null })} />}
                        </div>
                    </Animated>

                    {/* MATCH QR */}

                    <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeInUp" animationOut='fadeOut' isVisible={this.state.found ? true : false} className="bg-white card tile shadow d-flex flex-column" style={{ position: 'fixed', bottom: 20, width: 350, right: 20 }}>
                        <div className="bg-white p-4 w-100">
                            {this.state.found &&
                                <section>

                                    <div className="pl-4 py-3 tile card d-flex flex-row mx-1 pointer h-100">
                                        <div className="w-100 d-flex flex-row ">
                                            <div className="w-50  text-center">
                                                <div className="blue toUpper sqwad-font">Home</div>
                                                <div className="mx-auto cover" style={{ width: 60, height: 60, background: `url(${this.state.found.Home.Image || this.state.found.Home.TeamImage || this.state.found.Home.Logo || '/images/logos/generic_crest.png'}?full=80) no-repeat center center` }} />
                                                <div className="max-lines line1 font-8 mt-2 mx-2 toBold">{this.state.found.Home.Name || 'TBD'}</div>
                                                <div className="font-10">{_.filter(this.state.found.Home.Roster, r => r.PlayerReviewed).length}<i className="ms-1 icon-user-check" /></div>
                                            </div>
                                            <div className="w-100  text-center">
                                                <div className="font-8 max-lines-1">{this.state.found.Title || '-'}</div>
                                                <div className="font-30">{this.state.found.Home.Score || 0}-{this.state.found.Away.Score || 0}</div>
                                                <div className="font-10">{this.state.found.Date ? moment(this.state.found.Date).utc().format('ddd, MMM Do YYYY') : 'Time: TBD'}</div>
                                                <div className="toBold font-8">{this.state.found.Venue || 'Location: TBD'}</div>
                                                <div>{this.state.found.TimeString || 'Time: TBD'}</div>
                                                <div className="font-8">Updated {moment(this.state.found.EvaluationDate || this.state.found.Date).format('MMM Do YYYY')}</div>
                                                <div className={`font-8 ${this.state.found.ISCOPY ? 'blue' : 'white'}`}>(Scanned)</div>
                                            </div>
                                            <div className="w-50  text-center">
                                                <div className="blue toUpper sqwad-font">Away</div>
                                                <div className="mx-auto cover" style={{ width: 60, height: 60, background: `url(${this.state.found.Away.Image || this.state.found.Away.TeamImage || this.state.found.Away.Logo || '/images/logos/generic_crest.png'}?full=80) no-repeat center center` }} />
                                                <div className="max-lines line1 font-8 mt-2 mx-2 toBold">{this.state.found.Away.Name || 'TBD'}</div>
                                                <div className="font-10">{_.filter(this.state.found.Away.Roster, r => r.PlayerReviewed).length}<i className="ms-1 icon-user-check" /></div>
                                            </div>
                                        </div>
                                    </div>

                                    <button className={`btn btn-${this.state.copied ? 'info' : 'success'} mt-4 w-100`} type="button" onClick={this.onCopy}>{!this.state.copied ? 'Get a Copy' : 'Added to your network'}</button>
                                    <button className="btn btn-link w-100 text-center" onClick={() => this.setState({ found: null, copied: false })}>Close</button>
                                </section>}
                        </div>
                    </Animated>

                    <AddCoachProfile {...this.state} {...this.props} toggle={() => this.setState({ adding_coach_profile: false })} />


                </section>
            </Layout>
        )
    }
}

export default ScoutingEvents;
