import React from 'react';
import Layout from '../layouts/claire';
import { Animated } from 'react-animated-css';
import Menu from '../home/partials/menu';
import { Link } from 'react-router';
import LineLoader from '../partials/line_loader';
import ShareMatch from '../network/partials/share';
import moment from 'moment';
import MoveToFolder from './partials/move_to_folder';
import fakeAuth from '../../fakeAuth';
import PlayerOnMatch from '../network/partials/player_on_match';
import PlayerOnPitch from '../network/partials/player_on_pitch';
import PlayersForReview from '../network/partials/players_for_review';
import PlayerReview from '../network/partials/player_review';
import TeamMatch from './partials/team_match';

class TeamMatchFolder extends React.Component {
    state = {
        menu_toggled: false,
        mode: 2
    }
    componentWillMount() {
        this.props.scout_actions && this.props.scout_actions.fetchCountries();
        this.props.scout_actions && this.props.scout_actions.fetchCommunities();
        this.props.scout_actions && this.props.scout_actions.clearUser();
    }
    componentDidMount() {
        this.props.scout_actions.fetch_team_matches();
        this.props.scout_actions.fetch_folders();

        const { router } = this.props, { params } = router;
        if (params.id) {
            this.props.scout_actions && this.props.scout_actions.fetchFolder(params.id);
        }
    }
    componentWillReceiveProps = nextProps => {
        const { notes, router, folders } = nextProps, { params } = router, { id } = params;
        this.setState({
            notes: _.filter(notes, f => f.IdFolder === id),
            folders: _.filter(folders, f => f.IdFolderType === 5)
        });
    }

    onDelete = (IdMatch) => {
        const { notes } = this.state;
        this.setState({ notes: _.reject(notes, e => e.IdMatch === IdMatch) });
        this.props.scout_actions && this.props.scout_actions.delete_match(IdMatch);
    }

    toggle_evaluation_menu = index => {
        const { notes } = this.state;
        _.chain(notes)
            .sortBy(s => s.EvaluationDate || s.Date)
            .value().reverse()[index].menu = !_.chain(notes)
                .sortBy(s => s.EvaluationDate || s.Date)
                .value().reverse()[index].menu;
        this.setState({ notes });
    }

    toggleMenu = index => {
        const { notes } = this.state;
        _.chain(notes)
            .sortBy(s => s.EvaluationDate || s.Date)
            .value().reverse()[index].menu = !_.chain(notes)
                .sortBy(s => s.EvaluationDate || s.Date)
                .value().reverse()[index].menu;
        this.setState({ notes });
    }

    toggle_review_menu = index => {
        const { notes } = this.state;
        _.chain(notes)
            .sortBy(s => s.EvaluationDate || s.Date)
            .value().reverse()[index].menu = !_.chain(notes)
                .sortBy(s => s.EvaluationDate || s.Date)
                .value().reverse()[index].menu;
        this.setState({ notes });
    }

    onPlayerOnMatch = (player) => {
        this.setState({ player_on_match: player });
    }
    onPlayerOnPitch = (roster, i, formation) => {
        this.setState({ roster_on_pitch: roster, position: i });
    }
    onPlayersForReview = (match) => {
        this.setState({ rosters_for_review: match });
    }
    onPlayerReview = (player) => {
        this.setState({ player_review: player, rosters_for_review: null });
    }
    onSavePlayer = (player) => {
        // TODO
    }

    renderCommon = () => {
        const { mode, notes } = this.state, { folders, router, folder, loading } = this.props, { params } = router;

        return <div className="w3-content pl-4 container pt-4">

            <div className="w-100">
                <Link className="px-4 py-2 tile card w-100 d-flex flex-row w-100" to="/scouting_team_matches">
                    <i className="far fa-folder black text-center font-30 align-self-center" />
                    <span className="line1 font-10 black text-center align-self-center ms-4">{folder ? (folder.Name || folder.FolderName) : 'ALL PLAYERS'}</span>
                    <span className="ms-auto blue font-10 align-self-center">Change</span>
                </Link>
            </div>

            <div className="w-100">
                <input className="m-0 w-100 mt-3 p-2 mb-3 tile card" type="text" name="email" placeholder="Search" ref={(input) => this.txtSearch = input} />
                {/* YES +*/}
                {notes && notes.length > 0 && <div className="row m-0 p-0 h-100">
                    {_.chain(notes)
                        .sortBy(s => s.EvaluationDate || s.Date)
                        .value().reverse().map((evaluation, index) => {

                            // Review
                            return <Animated key={index} animationInDelay={25 * index} animationIn="fadeInRightBig" className="col-md-6 p-0 mt-2">
                                <div className="pl-4 py-3 tile card d-flex flex-row mx-1 pointer">
                                    <div onClick={() => this.setState({ player_match: evaluation })} className="w-100 d-flex flex-row ">
                                        <div className="w-75  text-center">
                                            <div className="blue toUpper sqwad-font">Home</div>
                                            <div className="mx-auto cover" style={{ width: 60, height: 60, background: `url(${evaluation.Home.Image || evaluation.Home.TeamImage || evaluation.Home.Logo || '/images/logos/generic_crest.png'}?full=80) no-repeat center center` }} />
                                            <div className="max-lines line1 font-8 mt-2 mx-2 toBold">{evaluation.Home.Name || 'TBD'}</div>
                                            <div className="font-10">{_.filter(evaluation.Home.Roster, r => r.PlayerReviewed).length}<i className="ms-1 icon-user-check" /></div>
                                            <i className={`icon-star ${evaluation.Home.Focus ? 'yellow' : 'white'}`} />

                                        </div>
                                        <div className="w-100  text-center">
                                            <div className="font-8 max-lines-1">{`Report: ${_.find([evaluation.Home, evaluation.Away], f => f.Focus).Name || 'TBD'}`}</div>
                                            <div className="font-30">{evaluation.Home.Score || 0}-{evaluation.Away.Score || 0}</div>
                                            <div className="font-8">{evaluation.Date ? moment(evaluation.Date).utc().format('ddd, MMM Do YYYY') : 'Time: TBD'}</div>
                                            <div className="toBold font-8">{evaluation.Venue || 'Location: TBD'}</div>
                                            <div>{evaluation.TimeString || 'Time: TBD'}</div>
                                            <div className="font-8">Updated {moment(evaluation.EvaluationDate || evaluation.Date).format('MMM Do YYYY')}</div>
                                            <div className={`font-8 ${evaluation.ISCOPY ? 'blue' : 'white'}`}>(Scanned)</div>
                                        </div>
                                        <div className="w-75  text-center">
                                            <div className="blue toUpper sqwad-font">Away</div>
                                            <div className="mx-auto cover" style={{ width: 60, height: 60, background: `url(${evaluation.Away.Image || evaluation.Away.TeamImage || evaluation.Away.Logo || '/images/logos/generic_crest.png'}?full=80) no-repeat center center` }} />
                                            <div className="max-lines line1 font-8 mt-2 mx-2 toBold">{evaluation.Away.Name || 'TBD'}</div>
                                            <div className="font-10">{_.filter(evaluation.Away.Roster, r => r.PlayerReviewed).length}<i className="ms-1 icon-user-check" /></div>
                                            <i className={`icon-star ${evaluation.Away.Focus ? 'yellow' : 'white'}`} />
                                        </div>
                                    </div>
                                    <div className="px-2 ms-auto pointer" style={{ width: 50 }} onClick={() => this.toggle_evaluation_menu(index)}><i className="ms-auto black icon-more-vertical" /></div>

                                    {/* MENU */}
                                    <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeInUp" animationOut='fadeOut' isVisible={evaluation.menu ? true : false} className="bg-white card tile shadow d-flex flex-row" style={{ position: 'absolute', top: 10, right: 0 }}>
                                        
                                    <a target="_blank" title="PDF Version" href={`https://www.thesqwad.com/scout/team/${evaluation.IdMatch}`} className="btn btn-default"><i className="far fa-file-pdf black" /></a>
                                        <button onClick={() => this.setState({ note: evaluation }, () => {
                                            this.toggle_evaluation_menu(index);
                                        })} className="btn btn-default"><i className="icon-arrow-right black" /></button>
                                        <button onClick={() => this.setState({ match: evaluation }, () => {
                                            this.toggle_evaluation_menu(index);
                                        })} className="btn btn-default"><i className="icon-share black" /></button>

                                        {evaluation.IdUser === fakeAuth.getUser().IdUser && <button onClick={() => this.setState({ move_to_folder: evaluation, folder_type: 5 }, () => {
                                            this.toggle_evaluation_menu(index);
                                        })} className="btn btn-default"><i className="icon-folder-plus black" /></button>}

                                        <button onClick={() => this.onDelete(evaluation.IdMatch)} className="btn btn-default"><i className="fas fa-times red" /></button>
                                        <button className="btn btn-default" onClick={() => this.toggle_evaluation_menu(index)}><i className="black icon-more-vertical" /></button>
                                    </Animated>
                                </div>
                                {evaluation.ExternalLinks && evaluation.ExternalLinks.length > 0 && <i className="fab fa-youtube red me-2" style={{ position: 'absolute', bottom: 10, right: 10 }} />}
                            </Animated>

                        })}
                </div>}

                {/* NO */}
                {!loading && notes && !notes.length && <div className="d-flex w-100 flex-column justify-content-center">
                    <img src="/images/icons/red_card.png" className="w-25 align-self-center mt-4" />
                    <span className="font-30 align-self-center text-center mx-4 px-4 line1 mt-4">Sorry, you have no notes in this area</span>
                </div>}

                <div style={{ height: 200 }} className="col-12 " />

            </div>

            <div className="p-4" />

        </div >
    }
    render() {
        const { folder } = this.props;
        return (
            <Layout router={this.props.router} className="bg-white" nbs="network" title={`SQWAD SCOUT | ${folder ? (folder.Name || folder.FolderName) : ''}`} form={this.props.form} form_actions={this.props.form_actions}>
                <section>

                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small" style={{ marginLeft: 200 }}>
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                    </div>
                    {/* MOBILE */}
                    <div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                    </div>

                    <Menu {...this.state} {...this.props} />
                    <MoveToFolder {...this.state} scout_actions={this.props.scout_actions} toggle={() => this.setState({ move_to_folder: false })} />
                    <TeamMatch pr={this.onPlayerReview} pfr={this.onPlayersForReview} pom={this.onPlayerOnMatch} pop={this.onPlayerOnPitch} {...this.state} scout_actions={this.props.scout_actions} toggle={() => this.setState({ player_match: false })} />
                    <PlayerOnMatch ok={this.onSavePlayer} {...this.state} scout_actions={this.props.scout_actions} toggle={() => this.setState({ player_on_match: false })} />
                    <PlayerOnPitch {...this.state} scout_actions={this.props.scout_actions} toggle={() => this.setState({ roster_on_pitch: false })} />
                    <PlayersForReview pr={this.onPlayerReview} {...this.state} scout_actions={this.props.scout_actions} toggle={() => this.setState({ rosters_for_review: false })} />
                    <PlayerReview {...this.state} scout_actions={this.props.scout_actions} toggle={() => this.setState({ player_review: false })} />
                    <ShareMatch {...this.state} scout_actions={this.props.scout_actions} toggle={() => this.setState({ match: false })} />
                    <ShareMatch {...this.state} scout_actions={this.props.scout_actions} toggle={() => this.setState({ match: false })} />

                    {this.props.loading && <LineLoader />}

                </section >
            </Layout>
        )
    }
}

export default TeamMatchFolder;
