const config = {
    apiEndpoint: process.env.REACT_APP_API_HOST,
    apiKey: process.env.REACT_APP_API_KEY,
    wowzaEndpoint: process.env.REACT_APP_WOWZA_HOST,
    publicPageEndpoint: process.env.REACT_APP_STANDINGS_PAGE_HOST,
    idTournamentSeason: 'D51C54DE-9424-49AC-AF3A-B7E57E2BC9A9',
    wildcard: 'abc123',
    palette: {
        green: '#2cad3c',
        blue: '#008db5',
        yellow: '#fdee21',
        purple: '#a713a2'
    },
    wheel: [
        '#000000',
        '#FFFFFF',
        '#FF0000',
        '#FF4000',
        '#FF8000',
        '#FFBF00',
        '#FFFF00',
        '#BFFF00',
        '#80FF00',
        '#40FF00',
        '#00FF00',
        '#00FF40',
        '#00FF80',
        '#00FFFF',
        '#00BFFF',
        '#0080FF',
        '#0040FF',
        '#0000FF',
        '#4000FF',
        '#8000FF',
        '#BF00FF',
        '#FF00FF',
        '#FF00BF',
        '#FF0080',
        '#FF0040',
        '#FFB6C1',
    ],
    seasonColors: {
        Recreational: '#259bd9',
        Competitive: '#a713a2',
        Tournament: '#4fb03c',
        Camp: '#fd930c'
    },
    country_codes: [
        { title: 'United States', value: '+1', image: '/images/flags/us.png', mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/] },
        { title: 'Canada', value: '+1', image: '/images/flags/ca.png', mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/] },
        { title: 'United Kingdom', value: '+44', image: '/images/flags/gb.png', mask: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/] },
        { title: 'Australia', value: '+61', image: '/images/flags/au.png', mask: ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/] },
        { title: 'Austria', value: '+43', image: '/images/flags/at.png', mask: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/] },
        { title: 'Bermuda', value: '+1-441', image: '/images/flags/ber.png', mask: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/] },
        { title: 'Denmark', value: '+45', image: '/images/flags/dk.png', mask: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/] },
        { title: 'France', value: '+33', image: '/images/flags/fr.png', mask: [/\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/] },
        { title: 'Germany', value: '+49', image: '/images/flags/de.png', mask: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/] },
        { title: 'New Zealand', value: '+64', image: '/images/flags/nz.png', mask: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/] },
        { title: 'Norway', value: '+47', image: '/images/flags/no.png', mask: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/] },
        { title: 'Panama', value: '+507', image: '/images/flags/pa.png', mask: [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/] },
        { title: 'Scotland', value: '+44', image: '/images/flags/sct.png', mask: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/] },
        { title: 'Sweden', value: '+46', image: '/images/flags/sw.png', mask: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/] },
        { title: 'Switzerland', value: '+41', image: '/images/flags/sw.png', mask: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/] },
        { title: 'Wales', value: '+44', image: '/images/flags/wa.png', mask: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/] },
    ],
    bgs: ['bg-pic-1', 'bg-pic-2', 'bg-pic-3', 'bg-pic-4', 'bg-pic-5', 'bg-pic-6', 'bg-pic-7', 'bg-pic-8', 'bg-pic-9', 'bg-pic-10', 'bg-pic-11', 'bg-pic-12', 'bg-pic-13'],
    seasonTypes: [
        {
            Id: 1,
            Name: 'Recreational'
        },
        {
            Id: 2,
            Name: 'Competitive'
        },
        {
            Id: 3,
            Name: 'Tournament'
        },
        {
            Id: 4,
            Name: 'Camp'
        }
    ],
    tournamentPointsSystemTypes: [
        {
            Id: 1,
            Name: 'Cooper'
        },
        {
            Id: 2,
            Name: 'Silver'
        },
        {
            Id: 3,
            Name: 'Gold'
        }
    ],
    clothingSizes: [
        'Youth Small',
        'Youth Medium',
        'Youth Large',
        'Youth XL',
        'Small',
        'Medium',
        'Large',
        'X-Large',
        'XX-Large',
    ],
    divisionStatuses: [
        { IdRoleStatus: 1, RoleStatusName: 'Open' },
        { IdRoleStatus: 2, RoleStatusName: 'Closed' },
        { IdRoleStatus: 3, RoleStatusName: 'Waitlist' },
        { IdRoleStatus: 4, RoleStatusName: 'Tryout' },
    ],
    roleStatuses: [
        {
            IdRoleStatus: 1,
            RoleStatusName: "Pending",
            color: '#008db5'
        },
        {
            IdRoleStatus: 2,
            RoleStatusName: "Active",
            color: '#2cad3c'
        },
        {
            IdRoleStatus: 3,
            RoleStatusName: "Denied",
            color: '#dc3545'
        },
        {
            IdRoleStatus: 4,
            RoleStatusName: "Waiting",
            color: '#fdee21'
        },
        {
            IdRoleStatus: 5,
            RoleStatusName: "Inactive",
            color: '#dc3545'
        },
        {
            IdRoleStatus: 6,
            RoleStatusName: "Tryout",
            color: '#a713a2'
        }
    ],
    tieBreakers: [{
        label: 'Head to Head',
        value: 1
    }, {
        label: 'Goal Differential',
        value: 2
    }, {
        label: 'Goals For',
        value: 3
    }, {
        label: 'Goals Against',
        value: 4
    }, {
        label: 'Wins Percent',
        value: 5
    }],
    capActions: [
        {
            IdCapAction: 0,
            Name: 'Nothing'
        },
        {
            IdCapAction: 1,
            Name: 'Close and waitlist when reaches cap'
        },
        {
            IdCapAction: 2,
            Name: 'Close when reaches cap'
        },
    ],
    genders: [
        {
            IdGender: 1,
            Name: 'Male'
        },
        {
            IdGender: 2,
            Name: 'Female'
        },
        {
            IdGender: 3,
            Name: 'COED'
        }
    ],
    realGenders: [
        {
            IdGender: 1,
            Name: 'Male'
        },
        {
            IdGender: 2,
            Name: 'Female'
        },
    ],    
}

export default config;
