import React from 'react';
import Layout from '../layouts/claire';
import { preload } from '../../helpers';
import { Animated } from 'react-animated-css';
import Menu from '../home/partials/menu';
import MenuDesktop from './partials/menu_family_desktop';
import MenuMobile from './partials/menu_family_mobile';
import { Link } from 'react-router';
import LineLoader from '../partials/line_loader';
import Email from './email';
import Phone from './phone';
import fakeAuth from '../../fakeAuth';
import { generateRandomId } from '../../helpers';


class FamilyContact extends React.Component {
    state = {
        path: [],
        menu_toggled: false,
        mode: parseInt(localStorage.getItem('sqwadrc.contact.mode') || 1, 10),
    }
    componentWillMount() {
        this.setState({ path: _.chain(this.props.router.getCurrentLocation()).value().pathname.split('/') });
        this.props.scout_actions && this.props.scout_actions.fetchCountries();
        this.props.scout_actions && this.props.scout_actions.fetchCommunities();        
        this.props.scout_actions && this.props.scout_actions.fetch_family();

    }
    componentDidMount() {
        this.props.family_actions && this.props.family_actions.fetchContact();
    }


    addEmail = () => {
        this.props.family_actions &&
            this.props.family_actions.addEmail({
                Address: '',
                CanDelete: true,
                users: [],
                temporalId: generateRandomId(10)
            });
    }

    addPhone = () => {
        this.props.family_actions &&
            this.props.family_actions.addPhone({
                CanDelete: true,
                users: [{ IdUser: fakeAuth.getUser().IdUser }],
                temporalId: generateRandomId(10)
            });
    }


    renderCommon = () => {
        const { mode } = this.state, { phones, router, emails, family } = this.props, { params } = router;

        return <div className="w3-content px-0 container pt-4">
            <div className="p-0">
                <div className="p-3 d-flex flex-row font-10 white bg-danger" key="leftActions">
                    <i className="align-self-center fas fa-exclamation-triangle margin-right-half text-warning font-20" />
                    <span className="align-self-center">Only select roster athletes, coaches and admins for communication. If you're not part of a team, even though you might be adding 'your' email and/or phone number, do NOT select yourself but rather the person in your family that's rostered to a team</span>
                </div>

                <div className="d-flex flex-row syncopate text-center w-100">
                    <div className={`w-100 p-2 ${mode === 1 ? '' : 'bg-gray'}`} onClick={() => this.setState({ mode: 1 }, () => {
                        localStorage.setItem('sqwadrc.contact.mode', 1)
                    })}>
                        <h4 className="p-0 m-0">EMAILS ({emails.length})</h4>
                    </div>
                    <div className={`w-100 p-2 ${mode === 2 ? '' : 'bg-gray'}`} onClick={() => this.setState({ mode: 2 }, () => {
                        localStorage.setItem('sqwadrc.contact.mode', 2)
                    })}>
                        <h4 className="p-0 m-0">PHONES ({phones.length})</h4>
                    </div>
                </div>
            </div>
            {mode === 1 && <section className="d-flex flex-column">
                <button onClick={this.addEmail} className="btn btn-info align-self-end my-2"><i className="icon-plus me-2" />Add Email</button>
                {emails && emails.map((email, i) => <Email email={email} key={i} family={family} familyActions={this.props.family_actions} />)}
            </section>}

            {mode === 2 && <section className="d-flex flex-column">
                <button onClick={this.addPhone} className="btn btn-info align-self-end my-2"><i className="icon-plus me-2" />Add Phone</button>
                {phones && phones.map((phone, i) => <Phone carriers={this.props.carriers} family={family} phone={phone} key={i} familyActions={this.props.family_actions} />)}
            </section>}

            <div className="p-4" />
            <div className="p-4" />

        </div >
    }
    render() {

        return (
            <Layout router={this.props.router} className="bg-white" nbs="network" title="SQWAD SCOUT | MY NETWORK" form={this.props.form} form_actions={this.props.form_actions}>
                <section>
                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small" style={{ marginLeft: 200 }}>
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                        <MenuDesktop {...this.state} {...this.props} />
                    </div>
                    {/* MOBILE */}
                    <div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                        <MenuMobile {...this.state} {...this.props} />
                    </div>
                    <Menu {...this.state} {...this.props} />

                    {this.props.loading && <LineLoader />}
                </section >
            </Layout>
        )
    }
}

export default FamilyContact;
