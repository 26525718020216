function follow_levels(state = [], action) {
    switch (action.type) {
        case 'FOLLOW_LEVELS/SET':            
            return action.levels;
        case 'FOLLOW_LEVELS/CLEAR':
            return null;
        default:
            return state;
    }
}

export default follow_levels;
