
const canvas_width = 380 * 0.95, canvas_height = 570 * 0.95, w = canvas_width * 0.20;
// factor: 4.75 (80 * 120 aspect ratio)
const formations = {
    pitch: {
        width: canvas_width,
        height: canvas_height
    },
    soccer: [
        {
            name: '4-4-2 (A)', positions: [
                { top: '85%', left: (canvas_width / 2) - (w / 2), name: 'GK' },

                { top: '64%', left: (canvas_width * 0.25 * 0.5) - (w / 2), name: 'LB' },
                { top: '65%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.25), name: 'LCB' },
                { top: '65%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.5), name: 'RCB' },
                { top: '64%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.75), name: 'RB' },

                { top: '34%', left: (canvas_width * 0.25 * 0.5) - (w / 2), name: 'LMF' },
                { top: '35%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.25), name: 'CMF' },
                { top: '35%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.5), name: 'CMF' },
                { top: '34%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.75), name: 'RMF' },

                { top: '15%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.25), name: 'CF' },
                { top: '15%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.5), name: 'CF' },
            ]
        },
        {
            name: '4-4-2 (B)', positions: [
                { top: '85%', left: (canvas_width / 2) - (w / 2), name: 'GK' },

                { top: '64%', left: (canvas_width * 0.25 * 0.5) - (w / 2), name: 'LB' },
                { top: '65%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.25), name: 'LCB' },
                { top: '65%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.5), name: 'RCB' },
                { top: '64%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.75), name: 'RB' },

                { top: '35%', left: (canvas_width * 0.33 * 0.5) - (w / 2), name: 'LMF' },
                { top: '45%', left: (canvas_width * 0.33 * 0.5) - (w / 2) + (canvas_width * 0.33), name: 'DMF' },
                { top: '35%', left: (canvas_width * 0.33 * 0.5) - (w / 2) + (canvas_width * 0.66), name: 'RMF' },

                { top: '15%', left: (canvas_width * 0.50 * 0.5) - (w / 2), name: 'CF' },
                { top: '25%', left: (canvas_width * 0.33 * 0.5) - (w / 2) + (canvas_width * 0.33), name: 'AMF' },
                { top: '15%', left: (canvas_width * 0.50 * 0.5) - (w / 2) + (canvas_width * 0.50), name: 'CF' },
            ]
        },
        {
            name: '4-5-1', positions: [
                { top: '85%', left: (canvas_width / 2) - (w / 2), name: 'GK' },

                { top: '64%', left: (canvas_width * 0.25 * 0.5) - (w / 2), name: 'LB' },
                { top: '65%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.25), name: 'LCB' },
                { top: '65%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.5), name: 'RCB' },
                { top: '64%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.75), name: 'RB' },

                { top: '34%', left: (canvas_width * 0.20 * 0.5) - (w / 2), name: 'LMF' },
                { top: '35%', left: (canvas_width * 0.20 * 0.5) - (w / 2) + (canvas_width * 0.2), name: 'CMF' },
                { top: '35%', left: (canvas_width * 0.20 * 0.5) - (w / 2) + (canvas_width * 0.4), name: 'CMF' },
                { top: '35%', left: (canvas_width * 0.20 * 0.5) - (w / 2) + (canvas_width * 0.6), name: 'CMF' },
                { top: '34%', left: (canvas_width * 0.20 * 0.5) - (w / 2) + (canvas_width * 0.8), name: 'RMF' },

                { top: '15%', left: (canvas_width * 1.0 * 0.5) - (w / 2), name: 'CF' },
            ]
        },
        {
            name: '4-1-4-1', positions: [
                { top: '85%', left: (canvas_width / 2) - (w / 2), name: 'GK' },

                { top: '64%', left: (canvas_width * 0.25 * 0.5) - (w / 2), name: 'LB' },
                { top: '65%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.25), name: 'LCB' },
                { top: '65%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.5), name: 'RCB' },
                { top: '64%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.75), name: 'RB' },

                { top: '50%', left: (canvas_width * 1.0 * 0.5) - (w / 2), name: 'DMF' },

                { top: '34%', left: (canvas_width * 0.25 * 0.5) - (w / 2), name: 'LMF' },
                { top: '35%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.25), name: 'CMF' },
                { top: '35%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.5), name: 'CMF' },
                { top: '34%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.75), name: 'RMF' },

                { top: '15%', left: (canvas_width * 1.0 * 0.5) - (w / 2), name: 'CF' },
            ]
        },
        {
            name: '4-4-1-1', positions: [
                { top: '85%', left: (canvas_width / 2) - (w / 2), name: 'GK' },

                { top: '64%', left: (canvas_width * 0.25 * 0.5) - (w / 2), name: 'LB' },
                { top: '65%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.25), name: 'LCB' },
                { top: '65%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.5), name: 'RCB' },
                { top: '64%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.75), name: 'RB' },

                { top: '40%', left: (canvas_width * 0.25 * 0.5) - (w / 2), name: 'LMF' },
                { top: '41%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.25), name: 'CMF' },
                { top: '41%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.5), name: 'CMF' },
                { top: '40%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.75), name: 'RMF' },

                { top: '25%', left: (canvas_width * 1.0 * 0.5) - (w / 2), name: 'AMF' },

                { top: '12%', left: (canvas_width * 1.0 * 0.5) - (w / 2), name: 'CF' },
            ]
        },
        {
            name: '4-2-4', positions: [
                { top: '85%', left: (canvas_width / 2) - (w / 2), name: 'GK' },

                { top: '64%', left: (canvas_width * 0.25 * 0.5) - (w / 2), name: 'LB' },
                { top: '65%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.25), name: 'LCB' },
                { top: '65%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.5), name: 'RCB' },
                { top: '64%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.75), name: 'RB' },

                { top: '35%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.25), name: 'DMF' },
                { top: '35%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.5), name: 'DMF' },

                { top: '14%', left: (canvas_width * 0.25 * 0.5) - (w / 2), name: 'LW' },
                { top: '15%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.25), name: 'CF' },
                { top: '15%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.5), name: 'CF' },
                { top: '14%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.75), name: 'RW' },
            ]
        },
        {
            name: '4-2-3-1', positions: [
                { top: '85%', left: (canvas_width / 2) - (w / 2), name: 'GK' },

                { top: '64%', left: (canvas_width * 0.25 * 0.5) - (w / 2), name: 'LB' },
                { top: '65%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.25), name: 'LCB' },
                { top: '65%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.5), name: 'RCB' },
                { top: '64%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.75), name: 'RB' },

                { top: '45%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.25), name: 'DMF' },
                { top: '45%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.5), name: 'DMF' },

                { top: '27%', left: (canvas_width * 0.33 * 0.5) - (w / 2), name: 'LW' },
                { top: '28%', left: (canvas_width * 1.0 * 0.5) - (w / 2), name: 'AMF' },
                { top: '27%', left: (canvas_width * 0.33 * 0.5) - (w / 2) + (canvas_width * 0.66), name: 'RW' },

                { top: '12%', left: (canvas_width * 1.0 * 0.5) - (w / 2), name: 'CF' },
            ]
        },
        {
            name: '4-3-3', positions: [
                { top: '85%', left: (canvas_width / 2) - (w / 2), name: 'GK' },

                { top: '64%', left: (canvas_width * 0.25 * 0.5) - (w / 2), name: 'LB' },
                { top: '65%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.25), name: 'LCB' },
                { top: '65%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.5), name: 'RCB' },
                { top: '64%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.75), name: 'RB' },

                { top: '35%', left: (canvas_width * 0.33 * 0.5) - (w / 2), name: 'LMF' },
                { top: '45%', left: (canvas_width * 0.33 * 0.5) - (w / 2) + (canvas_width * 0.33), name: 'DMF' },
                { top: '35%', left: (canvas_width * 0.33 * 0.5) - (w / 2) + (canvas_width * 0.66), name: 'RMF' },

                { top: '15%', left: (canvas_width * 0.33 * 0.5) - (w / 2), name: 'LW' },
                { top: '18%', left: (canvas_width * 1.0 * 0.5) - (w / 2), name: 'CF' },
                { top: '15%', left: (canvas_width * 0.33 * 0.5) - (w / 2) + (canvas_width * 0.66), name: 'RW' },
            ]
        },
        {
            name: '3-4-3 (A)', positions: [
                { top: '85%', left: (canvas_width / 2) - (w / 2), name: 'GK' },

                { top: '64%', left: (canvas_width * 0.33 * 0.5) - (w / 2), name: 'LCB' },
                { top: '65%', left: (canvas_width * 1.0 * 0.5) - (w / 2), name: 'CB' },
                { top: '64%', left: (canvas_width * 0.33 * 0.5) - (w / 2) + (canvas_width * 0.66), name: 'RCB' },

                { top: '43%', left: (canvas_width * 0.25 * 0.5) - (w / 2), name: 'LWB' },
                { top: '44%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.25), name: 'CMF' },
                { top: '44%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.5), name: 'CMF' },
                { top: '43%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.75), name: 'RWB' },

                { top: '25%', left: (canvas_width * 0.33 * 0.5) - (w / 2), name: 'FWD' },
                { top: '15%', left: (canvas_width * 1.0 * 0.5) - (w / 2), name: 'CF' },
                { top: '25%', left: (canvas_width * 0.33 * 0.5) - (w / 2) + (canvas_width * 0.66), name: 'FWD' },
            ]
        },
        {
            name: '3-4-3 (B)', positions: [
                { top: '85%', left: (canvas_width / 2) - (w / 2), name: 'GK' },

                { top: '64%', left: (canvas_width * 0.33 * 0.5) - (w / 2), name: 'LCB' },
                { top: '65%', left: (canvas_width * 1.0 * 0.5) - (w / 2), name: 'CB' },
                { top: '64%', left: (canvas_width * 0.33 * 0.5) - (w / 2) + (canvas_width * 0.66), name: 'RCB' },

                { top: '35%', left: (canvas_width * 0.25 * 0.5) - (w / 2), name: 'LWB' },
                { top: '36%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.25), name: 'CMF' },
                { top: '36%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.5), name: 'CMF' },
                { top: '35%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.75), name: 'RWB' },

                { top: '15%', left: (canvas_width * 0.33 * 0.5) - (w / 2), name: 'LW' },
                { top: '16%', left: (canvas_width * 1.0 * 0.5) - (w / 2), name: 'CF' },
                { top: '15%', left: (canvas_width * 0.33 * 0.5) - (w / 2) + (canvas_width * 0.66), name: 'RW' },
            ]
        },
        {
            name: '3-5-2 (A)', positions: [
                { top: '85%', left: (canvas_width / 2) - (w / 2), name: 'GK' },

                { top: '64%', left: (canvas_width * 0.33 * 0.5) - (w / 2), name: 'LCB' },
                { top: '65%', left: (canvas_width * 1.0 * 0.5) - (w / 2), name: 'CB' },
                { top: '64%', left: (canvas_width * 0.33 * 0.5) - (w / 2) + (canvas_width * 0.66), name: 'RCB' },

                { top: '40%', left: (canvas_width * 0.20 * 0.5) - (w / 2), name: 'LWB' },
                { top: '41%', left: (canvas_width * 0.20 * 0.5) - (w / 2) + (canvas_width * 0.2), name: 'CMF' },
                { top: '41%', left: (canvas_width * 0.20 * 0.5) - (w / 2) + (canvas_width * 0.4), name: 'CMF' },
                { top: '41%', left: (canvas_width * 0.20 * 0.5) - (w / 2) + (canvas_width * 0.6), name: 'CMF' },
                { top: '40%', left: (canvas_width * 0.20 * 0.5) - (w / 2) + (canvas_width * 0.8), name: 'RWB' },


                { top: '15%', left: (canvas_width * 0.5 * 0.5) - (w / 2), name: 'CF' },
                { top: '15%', left: (canvas_width * 0.5 * 0.5) - (w / 2) + (canvas_width * 0.5), name: 'CF' },
            ]
        },
        {
            name: '3-5-2 (B)', positions: [
                { top: '85%', left: (canvas_width / 2) - (w / 2), name: 'GK' },

                { top: '64%', left: (canvas_width * 0.33 * 0.5) - (w / 2), name: 'LCB' },
                { top: '65%', left: (canvas_width * 1.0 * 0.5) - (w / 2), name: 'CB' },
                { top: '64%', left: (canvas_width * 0.33 * 0.5) - (w / 2) + (canvas_width * 0.66), name: 'RCB' },

                { top: '40%', left: (canvas_width * 0.20 * 0.5) - (w / 2), name: 'LWB' },
                { top: '30%', left: (canvas_width * 0.20 * 0.5) - (w / 2) + (canvas_width * 0.2), name: 'CMF' },
                { top: '41%', left: (canvas_width * 0.20 * 0.5) - (w / 2) + (canvas_width * 0.4), name: 'DMF' },
                { top: '30%', left: (canvas_width * 0.20 * 0.5) - (w / 2) + (canvas_width * 0.6), name: 'DMF' },
                { top: '40%', left: (canvas_width * 0.20 * 0.5) - (w / 2) + (canvas_width * 0.8), name: 'RWB' },


                { top: '15%', left: (canvas_width * 0.5 * 0.5) - (w / 2), name: 'CF' },
                { top: '15%', left: (canvas_width * 0.5 * 0.5) - (w / 2) + (canvas_width * 0.5), name: 'CF' },
            ]
        },
        {
            name: '3-5-2 (C)', positions: [
                { top: '85%', left: (canvas_width / 2) - (w / 2), name: 'GK' },

                { top: '64%', left: (canvas_width * 0.33 * 0.5) - (w / 2), name: 'LCB' },
                { top: '65%', left: (canvas_width * 1.0 * 0.5) - (w / 2), name: 'CB' },
                { top: '64%', left: (canvas_width * 0.33 * 0.5) - (w / 2) + (canvas_width * 0.66), name: 'RCB' },

                { top: '40%', left: (canvas_width * 0.20 * 0.5) - (w / 2), name: 'LWB' },
                { top: '41%', left: (canvas_width * 0.20 * 0.5) - (w / 2) + (canvas_width * 0.2), name: 'DWF' },
                { top: '30%', left: (canvas_width * 0.20 * 0.5) - (w / 2) + (canvas_width * 0.4), name: 'AMF' },
                { top: '41%', left: (canvas_width * 0.20 * 0.5) - (w / 2) + (canvas_width * 0.6), name: 'DMF' },
                { top: '40%', left: (canvas_width * 0.20 * 0.5) - (w / 2) + (canvas_width * 0.8), name: 'RWB' },


                { top: '15%', left: (canvas_width * 0.5 * 0.5) - (w / 2), name: 'CF' },
                { top: '15%', left: (canvas_width * 0.5 * 0.5) - (w / 2) + (canvas_width * 0.5), name: 'CF' },
            ]
        },
        {
            name: '5-4-1 (A)', positions: [
                { top: '85%', left: (canvas_width / 2) - (w / 2), name: 'GK' },

                { top: '64%', left: (canvas_width * 0.2 * 0.5) - (w / 2), name: 'LWB' },
                { top: '65%', left: (canvas_width * 0.2 * 0.5) - (w / 2) + (canvas_width * 0.2), name: 'LCB' },
                { top: '65%', left: (canvas_width * 0.2 * 0.5) - (w / 2) + (canvas_width * 0.4), name: 'CB' },
                { top: '65%', left: (canvas_width * 0.2 * 0.5) - (w / 2) + (canvas_width * 0.6), name: 'RCB' },
                { top: '64%', left: (canvas_width * 0.2 * 0.5) - (w / 2) + (canvas_width * 0.8), name: 'RWB' },

                { top: '37%', left: (canvas_width * 0.25 * 0.5) - (w / 2), name: 'RMF' },
                { top: '38%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.25), name: 'CMF' },
                { top: '38%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.50), name: 'CMF' },
                { top: '37%', left: (canvas_width * 0.25 * 0.5) - (w / 2) + (canvas_width * 0.75), name: 'LMF' },

                { top: '15%', left: (canvas_width * 1 * 0.5) - (w / 2), name: 'CF' },
            ]
        },
        {
            name: '5-4-1 (B)', positions: [
                { top: '85%', left: (canvas_width / 2) - (w / 2), name: 'GK' },

                { top: '64%', left: (canvas_width * 0.2 * 0.5) - (w / 2), name: 'LWB' },
                { top: '65%', left: (canvas_width * 0.2 * 0.5) - (w / 2) + (canvas_width * 0.2), name: 'LCB' },
                { top: '65%', left: (canvas_width * 0.2 * 0.5) - (w / 2) + (canvas_width * 0.4), name: 'CB' },
                { top: '65%', left: (canvas_width * 0.2 * 0.5) - (w / 2) + (canvas_width * 0.6), name: 'RCB' },
                { top: '64%', left: (canvas_width * 0.2 * 0.5) - (w / 2) + (canvas_width * 0.8), name: 'RWB' },

                { top: '45%', left: (canvas_width * 0.2 * 0.5) - (w / 2) + (canvas_width * 0.2), name: 'LMF' },
                { top: '50%', left: (canvas_width * 0.2 * 0.5) - (w / 2) + (canvas_width * 0.4), name: 'DMF' },
                { top: '45%', left: (canvas_width * 0.2 * 0.5) - (w / 2) + (canvas_width * 0.6), name: 'RMF' },

                { top: '40%', left: (canvas_width * 1 * 0.5) - (w / 2), name: 'AMF' },

                { top: '15%', left: (canvas_width * 1 * 0.5) - (w / 2), name: 'CF' },
            ]
        },
    ]

}

export default formations;