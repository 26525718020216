import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';

export function* getVideosByUser() {
    yield* takeEvery("VIDEOS/USER/FETCH", function* (action) {
        try {
            const result = yield call(({ idUser }) => {
                return request.get(`${config.apiEndpoint}/api/external/videos/by_user/${idUser}`)
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { videos } = result;                
                yield put({ type: 'VIDEOS/SET', videos });
            } else {
                yield put({
                    type: 'ROSTER/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'ROSTER/FETCH_FAILED',
                e
            });
        }
    });
}

export function* getVideo() {
    yield* takeEvery("VIDEO/FETCH", function* (action) {
        try {
            const result = yield call(({ id }) => {
                return request.get(`${config.apiEndpoint}/api/external/videos/${id}`)
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { video } = result;
                yield put({ type: 'VIDEO/SET', video });
            } else {
                yield put({ type: 'VIDEO/FETCH_FAILED', result });
            }
        } catch (e) {
            yield put({ type: 'VIDEO/FETCH_FAILED', e });
        }
    });
}

export function* getUser() {
    yield* takeEvery("USER/EXTERNAL/FETCH", function* (action) {
        try {
            const result = yield call(({ idUser }) => {
                return request.get(`${config.apiEndpoint}/api/external/users/${idUser}`)
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {
                const { user } = result;
                yield put({ type: 'PLAYER/SET', player: user });
            } else {
                yield put({ type: 'PLAYER/FETCH_FAILED', result });
            }
        } catch (e) {
            yield put({ type: 'PLAYER/FETCH_FAILED', e });
        }
    });
}
