import React from 'react';
import Layout from '../layouts/claire';
import { Animated } from 'react-animated-css';
import Menu from '../home/partials/menu';
import { Link } from 'react-router';
import moment from 'moment';
import Teams from './partials/teams';
import MenuDesktop from './partials/menu_team_desktop';
import MenuMobile from './partials/menu_team_mobile';
import _ from 'lodash';
import AddCustomPlayer from './partials/add_custom_player';
import { generateRandomId } from '../../helpers';
import fakeAuth from '../../fakeAuth';


class CustomTeam extends React.Component {
    state = {
        path: [],
        match: null
    }

    // Lifecycle
    componentWillMount() {
        this.props.scout_actions && this.props.scout_actions.fetchFollowees();
        this.props.scout_actions && this.props.scout_actions.fetchCountries();
        this.props.scout_actions && this.props.scout_actions.fetchCommunities();
        this.setState({
            path: _.chain(this.props.router.getCurrentLocation()).value().pathname.split('/'), match: this.props.params.id ? null : {
                Id: generateRandomId(10),
                IdUser: (fakeAuth.getUser() || {}).IdUser,
                Roster: [{
                    Index: 0
                }, {
                    Index: 1
                }, {
                    Index: 2
                }, {
                    Index: 3
                }, {
                    Index: 4
                }, {
                    Index: 5
                }, {
                    Index: 6
                }, {
                    Index: 7
                }, {
                    Index: 8
                }, {
                    Index: 9
                }, {
                    Index: 10
                }, {
                    Index: 11
                }, {
                    Index: 12
                }, {
                    Index: 13
                }, {
                    Index: 14
                }, {
                    Index: 15
                }, {
                    Index: 16
                }, {
                    Index: 17
                }, {
                    Index: 18
                }, {
                    Index: 19
                }, {
                    Index: 20
                }, {
                    Index: 21
                }, {
                    Index: 22
                }, {
                    Index: 23
                }],
                Formation: 0,
                Manager: '',
                Name: '',
                ReportType: 80,
                ExternalLinks: []
            }
        });

    }
    componentWillReceiveProps = nextProps => {
        const { match } = nextProps;
        if (this.props.params.id)
            this.setState({ match });
    }
    componentDidMount() {
        // TODO: fetch team by its id
        const { router } = this.props, { params } = router;
        this.props.scout_actions && this.props.scout_actions.fetchFolders();
        if (params.id) {
            this.props.scout_actions && this.props.scout_actions.fetch_match(params.id);
        }
        this.props.scout_actions && this.props.scout_actions.clearFolder();
        this.props.scout_actions && this.props.scout_actions.clearUser();
    }
    componentWillUnmount = () => {
        this.props.scout_actions.clear_team();
    }
    //    

    onMenu = (folder, e) => {
        e.preventDefault && e.preventDefault();
        e.stopPropagation && e.stopPropagation();
    }

    onSave = () => {
        const { match } = this.state;
        if (!this.txtName.value || !this.txtManager.value){            
            alert('Please complete the required fields');            
            return;
        }
        let payload = {
            ...match,
            Name: this.txtName.value,
            Manager: this.txtManager.value,
            Date: moment().utc().format(),
            LastUpdate: moment().format(),
        }
        this.props.scout_actions && this.props.scout_actions.save_match(payload);
        this.setState({ saved: true }, () => {
            if (!this.props.params.id) this.props.router.goBack();
        });
    }

    onAddPlayer = niu => {
        const { match } = this.state, { custom_player } = this.state;
        match.Roster[custom_player.Index] = niu;        
        this.setState({ match }, this.forceUpdate);
    }

    renderCommon = () => {
        const { match = {} } = this.state, { params } = this.props;

        return <section className="w3-content">

            {(match || !params.id) && <section>

                <div className="px-4 py-3 tile card d-flex flex-row mt-4" >
                    <div className="align-self-center cover" style={{ background: `url(${match.TeamImageUrl || match.TeamImage || match.Image || match.Logo || '/images/logos/generic_crest.png'}) no-repeat center center`, width: 80, height: 80 }}></div>
                    <div className="black w-100 ms-4 align-self-center">
                        <div className="bg-white p-2 w-100">
                            <label>Team's Name (*)</label>
                            <input className="w3-input w-100" type="text" defaultValue={match.Name || match.TeamName} ref={i => this.txtName = i} />
                        </div>
                        <div className="bg-white p-2 w-100">
                            <label>Manager (*)</label>
                            <input className="w3-input w-100" type="text" defaultValue={match.Manager} ref={i => this.txtManager = i} />
                        </div>
                    </div>
                </div>

                <h3 className="gray syncopate mt-4">PLAYERS</h3>
                <div className="px-4 py-1 tile card d-flex flex-row mt-2 bg-info white">
                    <div style={{ width: 60 }}>
                        No
                    </div>
                    <div className="w-100 toUpper">
                        Player's Name
                    </div>
                </div>

                {_.chain(match.Roster).slice(0, 11).value().map((player, index) => {
                    if (player.Id) {
                        return <div className="px-4 py-3 tile card d-flex flex-row mt-2 pointer" key={index} onClick={() => this.setState({ custom_player: player })} >
                            <div style={{ width: 60 }}>
                                {player.JerseyNumber}
                            </div>
                            <div className="w-100">
                                {`${player.NameFirst} ${player.NameLast}`}
                            </div>
                            <i className={`align-self-end far fa-arrow-alt-circle-down ${player.IdCollegeProfile ? 'blue-link' : 'whiteish'}`}></i>
                        </div>
                    } else {
                        return <div className="px-4 py-3 tile card d-flex flex-row mt-2 justify-content-center pointer" key={index} onClick={() => this.setState({ custom_player: player })}>
                            <span className="align-self-center">+ ADD</span>
                        </div>
                    }
                })}

                <div className="px-4 py-1 tile card d-flex flex-row mt-2 w3-gray white">
                    <div className="w-100">
                        BENCH
                    </div>
                </div>

                {_.chain(match.Roster).slice(11).value().map((player, index) => {
                    if (player.Id) {
                        return <div className="px-4 py-3 tile card d-flex flex-row mt-2 pointer" key={index} onClick={() => this.setState({ custom_player: player })}>
                            <div style={{ width: 60 }}>
                                {player.JerseyNumber}
                            </div>
                            <div className="w-100">
                                {`${player.NameFirst} ${player.NameLast}`}
                            </div>
                            <i className={`align-self-end far fa-arrow-alt-circle-down ${player.IdCollegeProfile ? 'blue-link' : 'whiteish'}`}></i>
                        </div>
                    } else {
                        return <div className="px-4 py-3 tile card d-flex flex-row mt-2 justify-content-center pointer" key={index} onClick={() => this.setState({ custom_player: player })}>
                            <span className="align-self-center">+ ADD</span>
                        </div>
                    }
                })}

                <button className={`btn btn-${this.state.saved ? 'success' : 'info'} mt-4 w-100`} type="button" onClick={this.onSave}>{this.state.saved ? 'Custom Team saved!' : 'Save Custom Team'}</button>

                <div style={{ height: 100 }} />

            </section>}
        </section>
    }

    render() {
        return (
            <Layout router={this.props.router} className="bg-white" nbs="network" title="SQWAD SCOUT | CUSTOM TEAM" form={this.props.form} form_actions={this.props.form_actions}>
                <section>
                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small" style={{ marginLeft: 200 }}>
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                        <MenuDesktop {...this.state} {...this.props} />
                    </div>
                    {/* MOBILE */}
                    <div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                        <MenuMobile {...this.state} {...this.props} />
                    </div>
                    <Menu hide={true} {...this.state} {...this.props} />
                    <AddCustomPlayer {...this.state} {...this.props} toggle={() => this.setState({ custom_player: false })} fnadd={this.onAddPlayer} fnremove={() => {
                        const { match = {} } = this.state, { custom_player } = this.state;
                        match.Roster[custom_player.Index] = { Index: custom_player.Index };
                        this.setState({ match });
                    }} />
                </section>
            </Layout>
        )
    }
}

export default CustomTeam;
