import _ from 'lodash';
function videos_Reducer(state = [], action) {
    switch (action.type) {
        case 'VIDEOS/SET':
            return _.filter(action.videos, a => a.isSubmittedTop10 || a.url_browser);
        case 'VIDEOS/CLEAR':
            return null;
        default:
            return state;
    }
}

export default videos_Reducer;
