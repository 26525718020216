import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import moment from 'moment';
import config from '../../../config';
import { generateRandomId, calculateBWContrastClass, getBase64 } from '../../../helpers';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import fakeAuth from '../../../fakeAuth';
import formations from '../../../formations';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../../common/CustomDatePickerLabel';
import CustomTimeScroller from '../../common/CustomTimeScroller';

class TeamMatch extends React.Component {

    state = {
        bootstrapped: false,
        page: 1,
        match: {
            CreatorNameFirst: fakeAuth.getUser().NameFirst,
            CreatorNameLast: fakeAuth.getUser().NameLast,
            EvaluationDate: moment(),
            Date: moment(),
            IdFolder: null, // TODO
            IdGender: 1,
            ExternalLinks: [],
            Home: {
                Roster: [{
                    Index: 0
                }, {
                    Index: 1
                }, {
                    Index: 2
                }, {
                    Index: 3
                }, {
                    Index: 4
                }, {
                    Index: 5
                }, {
                    Index: 6
                }, {
                    Index: 7
                }, {
                    Index: 8
                }, {
                    Index: 9
                }, {
                    Index: 10
                }, {
                    Index: 11
                }, {
                    Index: 12
                }, {
                    Index: 13
                }, {
                    Index: 14
                }, {
                    Index: 15
                }, {
                    Index: 16
                }, {
                    Index: 17
                }, {
                    Index: 18
                }, {
                    Index: 19
                }, {
                    Index: 20
                }, {
                    Index: 21
                }, {
                    Index: 22
                }, {
                    Index: 23
                }],
                Formation: 0,
                Manager: '',
                Name: '',
                Score: 0,
            },
            Away: {
                Roster: [{
                    Index: 0
                }, {
                    Index: 1
                }, {
                    Index: 2
                }, {
                    Index: 3
                }, {
                    Index: 4
                }, {
                    Index: 5
                }, {
                    Index: 6
                }, {
                    Index: 7
                }, {
                    Index: 8
                }, {
                    Index: 9
                }, {
                    Index: 10
                }, {
                    Index: 11
                }, {
                    Index: 12
                }, {
                    Index: 13
                }, {
                    Index: 14
                }, {
                    Index: 15
                }, {
                    Index: 16
                }, {
                    Index: 17
                }, {
                    Index: 18
                }, {
                    Index: 19
                }, {
                    Index: 20
                }, {
                    Index: 21
                }, {
                    Index: 22
                }, {
                    Index: 23
                }],
                Formation: 0,
                Manager: '',
                Name: '',
                Score: 0,
            },
            IdPlayerEvaluation: generateRandomId(10),
            IdUser: fakeAuth.getUser().IdUser,
            NameFirst: this.props.selected_user ? this.props.selected_user.NameFirst : '',
            NameLast: this.props.selected_user ? this.props.selected_user.NameLast : '',
            ReportType: 3,
            IdUserTarget: this.props.selected_user ? (this.props.selected_user.IdUserFollow || this.props.selected_user.IdUser) : null,
            IdUserFollow: this.props.selected_user ? (this.props.selected_user.IdUserFollow || this.props.selected_user.IdUser) : null,
        }
    }

    componentWillReceiveProps = nextProps => {
        if (nextProps.player_match) {
            this.setState({
                match: {
                    ...nextProps.player_match,
                    ExternalLinks: nextProps.player_match.ExternalLinks || []
                },
                selectedDate: moment(nextProps.player_match.Date),
                selectedTime: moment(nextProps.player_match.TimeString || '08:00AM', 'HH:mmA'),
            });
        } else this.setState({ page: 1, match: null });
    }

    onSave = () => {
        let { match, page } = this.state;
        if (page === 1) {
            match = {
                ...match,
                PitchConditions: this.txtPitch.value,
                Venue: this.txtVenue.value,
                Competition: this.txtCompetition.value,
                WeatherConditions: this.txtWeather.value,
                Home: { ...match.Home, Name: this.txtHomeName.value },
                Away: { ...match.Away, Name: this.txtAwayName.value },
            }
        }
        // TODO: Validations        
        this.props.scout_actions.save_match(match);
        this.props.toggle();
    }

    onPickHomeFormation = (formation, index) => {
        const { match } = this.state;
        match.Home.Formation = index;
        this.setState({ match });
    }

    onPickAwayFormation = (formation, index) => {
        const { match } = this.state;
        match.Away.Formation = index;
        this.setState({ match });
    }

    onHomePitch = (index) => {
        const { match } = this.state;
        let formation = formations.soccer[match.Home.Formation];
        this.props.pop && this.props.pop(match.Home.Roster, index, formation);
    }

    onAwayPitch = (index) => {
        const { match } = this.state;
        let formation = formations.soccer[match.Away.Formation];
        this.props.pop && this.props.pop(match.Away.Roster, index, formation);
    }

    renderHomeRoster = () => {
        const { match, page } = this.state;
        let f = formations.soccer[match.Home.Formation];

        return (<div className="d-flex flex-column black align-self-start w-100 w3-container">

            <h3 className="sqwad-font text-center toUpper">HOME ROSTER</h3>
            <div className="px-4 py-3 tile card d-flex flex-row mt-4" >
                <div className="align-self-center cover" style={{ background: `url(${match.Home.Logo || '/images/logos/generic_crest.png'}) no-repeat center center`, width: 80, height: 80 }}></div>
                <div className="black w-100 ms-4 align-self-center">
                    <div className="bg-white p-2 w-100">
                        <label>Team's Name (*)</label>
                        <input className="w3-input w-100" type="text" defaultValue={match.Home.Name} ref={i => this.txtHomeName2 = i} />
                    </div>
                    <div className="bg-white p-2 w-100">
                        <label>Manager (*)</label>
                        <input className="w3-input w-100" type="text" defaultValue={match.Home.Manager} ref={i => this.txtHomeManager = i} />
                    </div>
                </div>
            </div>

            <h3 className="gray syncopate mt-4">PLAYERS</h3>
            <div className="px-4 py-1 tile card d-flex flex-row mt-2 w3-blue white">
                <div style={{ width: 60 }}>
                    No
                </div>
                <div className="w-100 toUpper">
                    Player's Name
                </div>
                <div className="ms-auto text-center" style={{ width: 80 }}>
                    Subbed
                </div>
                <div className="text-center" style={{ width: 80 }}>
                    Goal
                </div>
                <div className="text-center" style={{ width: 80 }}>
                    Rating
                </div>
                <div className="text-center" style={{ width: 80 }}></div>
            </div>

            {_.chain(match.Home.Roster).slice(0, 11).value().map((player, index) => {
                if (player.Id) {
                    return <div className="px-4 py-3 tile card d-flex flex-row mt-2 pointer" key={index} onClick={() => this.props.pom(player)} >
                        <div style={{ width: 60 }}>
                            {player.JerseyNumber}
                        </div>
                        <div className="w-100">
                            {`${player.NameFirst} ${player.NameLast}`}
                        </div>
                        <div className="ms-auto text-center" style={{ width: 80 }}>
                            {player.Subbed}
                        </div>
                        <div className="text-center" style={{ width: 80 }}>
                            {player.Score}
                        </div>
                        <div className="text-center" style={{ width: 80 }}>
                            {player.Rating}
                        </div>
                        <div className="text-center" style={{ width: 80 }}>
                            {player.Violation && <i className="fas fa-square" style={{ color: player.Violation.Color }} />}
                        </div>
                        <i className={`align-self-end far fa-arrow-alt-circle-down ${player.IdCollegeProfile ? 'blue-link' : 'whiteish'}`}></i>
                    </div>
                } else {
                    return <div className="px-4 py-3 tile card d-flex flex-row mt-2 justify-content-center pointer" key={index} onClick={() => this.props.pom(player)}>
                        <span className="align-self-center">+ ADD</span>
                    </div>
                }
            })}

            {/* BENCH */}
            <div className="px-4 py-1 tile card d-flex flex-row mt-2 w3-gray white">
                <div className="w-100">
                    BENCH
                </div>
            </div>

            {_.chain(match.Home.Roster).slice(11).value().map((player, index) => {
                if (player.Id) {
                    return <div className="px-4 py-3 tile card d-flex flex-row mt-2 pointer" key={index} onClick={() => this.props.pom(player)}>
                        <div style={{ width: 60 }}>
                            {player.JerseyNumber}
                        </div>
                        <div className="w-100">
                            {`${player.NameFirst} ${player.NameLast}`}
                        </div>
                        <div className="ms-auto text-center" style={{ width: 80 }}>
                            {player.Subbed}
                        </div>
                        <div className="text-center" style={{ width: 80 }}>
                            {player.Score}
                        </div>
                        <div className="text-center" style={{ width: 80 }}>
                            {player.Rating}
                        </div>
                        <div className="text-center" style={{ width: 80 }}>
                            {player.Violation && <i className="fas fa-square" style={{ color: player.Violation.Color }} />}
                        </div>
                        <i className={`align-self-end far fa-arrow-alt-circle-down ${player.IdCollegeProfile ? 'blue-link' : 'whiteish'}`}></i>
                    </div>
                } else {
                    return <div className="px-4 py-3 tile card d-flex flex-row mt-2 justify-content-center pointer" key={index} onClick={() => this.props.pom(player)}>
                        <span className="align-self-center">+ ADD</span>
                    </div>
                }
            })}

            {match.Home.Focus === true && <section>
                <h3 className="sqwad-font mt-3">FORMATION</h3>
                <div className="d-flex flex-row">

                    <Dropdown isOpen={this.state.pickingHomeFormation ? true : false} toggle={() => this.setState({ pickingHomeFormation: !this.state.pickingHomeFormation })} className="form-control p-0 black mt-2 w-75">
                        <DropdownToggle className="text-left btn btn-default w-100 tile card" >
                            <div className="d-flex w-100">
                                <span className="align-self-center">{formations.soccer[match.Home.Formation].name}</span>
                                <i className="ms-auto fas fa-caret-down align-self-center " />
                            </div>
                        </DropdownToggle>
                        <DropdownMenu className="w-100" style={{ maxHeight: 200, overflowY: 'auto' }}>
                            <DropdownItem onClick={() => this.onPickHomeFormation()}>None</DropdownItem>
                            {formations.soccer && formations.soccer.map((c, index) => <DropdownItem key={index} onClick={() => this.onPickHomeFormation(c, index)}>{c.name}</DropdownItem>)}
                        </DropdownMenu>
                    </Dropdown>

                    <Dropdown isOpen={this.state.pickingHomeColor ? true : false} toggle={() => this.setState({ pickingHomeColor: !this.state.pickingHomeColor })} className="form-control p-0 black mt-2 w-25">
                        <DropdownToggle className="text-left btn btn-default w-100 tile card" >
                            <div className="d-flex w-100">
                                <span className="align-self-center"><i className="fas fa-square me-2" style={{ color: match.Home.COLOR || 'transparent' }} />{match.Home.COLOR || 'Color'}</span>
                                <i className="ms-auto fas fa-caret-down align-self-center " />
                            </div>
                        </DropdownToggle>
                        <DropdownMenu className="w-100" style={{ maxHeight: 200, overflowY: 'auto' }}>
                            {config.wheel.map((c, index) => <DropdownItem key={index} onClick={() => {
                                const { match } = this.state;
                                match.Home.COLOR = c;
                                this.setState({ match });
                            }}><i className="fas fa-square me-2" style={{ color: c }} />{c}</DropdownItem>)}
                        </DropdownMenu>
                    </Dropdown>
                </div>


                <div style={{ position: 'relative', width: formations.pitch.width, height: formations.pitch.height, background: `url(/images/soccer_pitch.png) no-repeat center center` }} className=" mx-auto cover mt-3 tile card">
                    {f.positions && f.positions.map((f, i) => <div key={i} style={{
                        width: formations.pitch.width * 0.20, height: formations.pitch.width * 0.20,
                        position: 'absolute',
                        top: f.top, left: f.left
                    }} className=" d-flex flex-column justify-content-center" onClick={() => this.onHomePitch(i + 1)}>
                        <div style={{ width: 40, height: 40, borderRadius: 20, border: `2px solid white`, backgroundColor: match.Home.COLOR || 'teal' }} className="d-flex flex-row justify-content-center align-self-center">
                            <span style={{ color: calculateBWContrastClass(match.Home.COLOR || '#008080') }} className="align-self-center font-16">{(_.find(match.Home.Roster, r => r.PitchPosition === i + 1) || {}).JerseyNumber}</span>
                        </div>
                        {_.find(match.Home.Roster, r => r.PitchPosition === i + 1) && <span style={{ backgroundColor: 'black', border: `1px solid white`, borderRadius: 5 }} className="px-2 white text-center w-auto font-8 mt-2 max-lines-1">{`${_.find(match.Home.Roster, r => r.PitchPosition === i + 1).NameFirst.substring(0, 1)}.${_.find(match.Home.Roster, r => r.PitchPosition === i + 1).NameLast}`}</span>}
                    </div>)}
                </div>
            </section>}

            <button className="btn w3-blue btn-block mt-4" onClick={() => this.next(3)}>Continue</button>

            <div className="p-4" />
            <div className="p-4" />
        </div>)
    }

    renderAwayRoster = () => {
        const { match, page } = this.state;
        let f = formations.soccer[match.Away.Formation];

        return (<div className="d-flex flex-column black align-self-start w-100 w3-container">

            <h3 className="sqwad-font text-center toUpper">AWAY ROSTER</h3>
            <div className="px-4 py-3 tile card d-flex flex-row mt-4" >
                <div className="align-self-center cover" style={{ background: `url(${match.Away.Logo || '/images/logos/generic_crest.png'}) no-repeat center center`, width: 80, height: 80 }}></div>
                <div className="black w-100 ms-4 align-self-center">
                    <div className="bg-white p-2 w-100">
                        <label>Team's Name (*)</label>
                        <input className="w3-input w-100" type="text" defaultValue={match.Away.Name} ref={i => this.txtAwayName2 = i} />
                    </div>
                    <div className="bg-white p-2 w-100">
                        <label>Manager (*)</label>
                        <input className="w3-input w-100" type="text" defaultValue={match.Away.Manager} ref={i => this.txtAwayManager = i} />
                    </div>
                </div>
            </div>

            <h3 className="gray syncopate mt-4">PLAYERS</h3>
            <div className="px-4 py-1 tile card d-flex flex-row mt-2 w3-blue white">
                <div style={{ width: 60 }}>
                    No
                </div>
                <div className="w-100 toUpper">
                    Player's Name
                </div>
                <div className="ms-auto text-center" style={{ width: 80 }}>
                    Subbed
                </div>
                <div className="text-center" style={{ width: 80 }}>
                    Goal
                </div>
                <div className="text-center" style={{ width: 80 }}>
                    Rating
                </div>
                <div className="text-center" style={{ width: 80 }}></div>
            </div>

            {_.chain(match.Away.Roster).slice(0, 11).value().map((player, index) => {
                if (player.Id) {
                    return <div className="px-4 py-3 tile card d-flex flex-row mt-2 pointer" key={index} onClick={() => this.props.pom(player)} >
                        <div style={{ width: 60 }}>
                            {player.JerseyNumber}
                        </div>
                        <div className="w-100">
                            {`${player.NameFirst} ${player.NameLast}`}
                        </div>
                        <div className="ms-auto text-center" style={{ width: 80 }}>
                            {player.Subbed}
                        </div>
                        <div className="text-center" style={{ width: 80 }}>
                            {player.Score}
                        </div>
                        <div className="text-center" style={{ width: 80 }}>
                            {player.Rating}
                        </div>
                        <div className="text-center" style={{ width: 80 }}>
                            {player.Violation && <i className="fas fa-square" style={{ color: player.Violation.Color }} />}
                        </div>
                        <i className={`align-self-end far fa-arrow-alt-circle-down ${player.IdCollegeProfile ? 'blue-link' : 'whiteish'}`}></i>
                    </div>
                } else {
                    return <div className="px-4 py-3 tile card d-flex flex-row mt-2 justify-content-center pointer" key={index} onClick={() => this.props.pom(player)}>
                        <span className="align-self-center">+ ADD</span>
                    </div>
                }
            })}

            <div className="px-4 py-1 tile card d-flex flex-row mt-2 w3-gray white">
                <div className="w-100">
                    BENCH
                </div>
            </div>

            {_.chain(match.Away.Roster).slice(11).value().map((player, index) => {
                if (player.Id) {
                    return <div className="px-4 py-3 tile card d-flex flex-row mt-2 pointer" key={index} onClick={() => this.props.pom(player)}>
                        <div style={{ width: 60 }}>
                            {player.JerseyNumber}
                        </div>
                        <div className="w-100">
                            {`${player.NameFirst} ${player.NameLast}`}
                        </div>
                        <div className="ms-auto text-center" style={{ width: 80 }}>
                            {player.Subbed}
                        </div>
                        <div className="text-center" style={{ width: 80 }}>
                            {player.Score}
                        </div>
                        <div className="text-center" style={{ width: 80 }}>
                            {player.Rating}
                        </div>
                        <div className="text-center" style={{ width: 80 }}>
                            {player.Violation && <i className="fas fa-square" style={{ color: player.Violation.Color }} />}
                        </div>
                        <i className={`align-self-end far fa-arrow-alt-circle-down ${player.IdCollegeProfile ? 'blue-link' : 'whiteish'}`}></i>
                    </div>
                } else {
                    return <div className="px-4 py-3 tile card d-flex flex-row mt-2 justify-content-center pointer" key={index} onClick={() => this.props.pom(player)}>
                        <span className="align-self-center">+ ADD</span>
                    </div>
                }
            })}

            {match.Away.Focus === true && <section>
                <h3 className="sqwad-font mt-3">FORMATION</h3>
                <div className="d-flex flex-row">
                    <Dropdown isOpen={this.state.pickingAwayFormation ? true : false} toggle={() => this.setState({ pickingAwayFormation: !this.state.pickingAwayFormation })} className="form-control p-0 black mt-2 w-75">
                        <DropdownToggle className="text-left btn btn-default w-100 tile card" >
                            <div className="d-flex w-100">
                                <span className="align-self-center">{formations.soccer[match.Away.Formation].name}</span>
                                <i className="ms-auto fas fa-caret-down align-self-center " />
                            </div>
                        </DropdownToggle>
                        <DropdownMenu className="w-100" style={{ maxHeight: 200, overflowY: 'auto' }}>
                            <DropdownItem onClick={() => this.onPickAwayFormation()}>None</DropdownItem>
                            {formations.soccer && formations.soccer.map((c, index) => <DropdownItem key={index} onClick={() => this.onPickAwayFormation(c, index)}>{c.name}</DropdownItem>)}
                        </DropdownMenu>
                    </Dropdown>

                    <Dropdown isOpen={this.state.pickingAwayColor ? true : false} toggle={() => this.setState({ pickingAwayColor: !this.state.pickingAwayColor })} className="form-control p-0 black mt-2 w-25">
                        <DropdownToggle className="text-left btn btn-default w-100 tile card" >
                            <div className="d-flex w-100">
                                <span className="align-self-center"><i className="fas fa-square me-2" style={{ color: match.Away.COLOR || 'transparent' }} />{match.Away.COLOR || 'Color'}</span>
                                <i className="ms-auto fas fa-caret-down align-self-center " />
                            </div>
                        </DropdownToggle>
                        <DropdownMenu className="w-100" style={{ maxHeight: 200, overflowY: 'auto' }}>
                            {config.wheel.map((c, index) => <DropdownItem key={index} onClick={() => {
                                const { match } = this.state;
                                match.Away.COLOR = c;
                                this.setState({ match });
                            }}><i className="fas fa-square me-2" style={{ color: c }} />{c}</DropdownItem>)}
                        </DropdownMenu>
                    </Dropdown>
                </div>

                <div style={{ position: 'relative', width: formations.pitch.width, height: formations.pitch.height, background: `url(/images/soccer_pitch.png) no-repeat center center` }} className=" mx-auto cover mt-3">
                    {f.positions && f.positions.map((f, i) => <div key={i} style={{
                        width: formations.pitch.width * 0.20, height: formations.pitch.width * 0.20,
                        position: 'absolute',
                        top: f.top, left: f.left
                    }} className=" d-flex flex-column justify-content-center" onClick={() => this.onAwayPitch(i + 1)}>
                        <div style={{ width: 40, height: 40, borderRadius: 20, border: `2px solid white`, backgroundColor: match.Away.COLOR || 'red' }} className="d-flex flex-row justify-content-center align-self-center">
                            <span style={{ color: calculateBWContrastClass(match.Away.COLOR || '#FF0000') }} className="align-self-center font-16">{(_.find(match.Away.Roster, r => r.PitchPosition === i + 1) || {}).JerseyNumber}</span>
                        </div>
                        {_.find(match.Away.Roster, r => r.PitchPosition === i + 1) && <span style={{ backgroundColor: 'black', border: `1px solid white`, borderRadius: 5 }} className="px-2 white text-center w-auto font-8 mt-2 max-lines-1">{`${_.find(match.Away.Roster, r => r.PitchPosition === i + 1).NameFirst.substring(0, 1)}.${_.find(match.Away.Roster, r => r.PitchPosition === i + 1).NameLast}`}</span>}
                    </div>)}
                </div>
            </section>}

            <button className="btn w3-blue btn-block mt-4" onClick={() => this.next(4)}>Continue</button>
            <div className="p-4" />
            <div className="p-4" />
        </div>)
    }

    renderPlayerReviews = () => {
        const { match } = this.state;
        let reviewd_players = _.filter([...match.Home.Roster, ...match.Away.Roster], r => r.PlayerReviewed);
        return (<div className="d-flex flex-column black align-self-start w-100 w3-container">

            <h3 className="sqwad-font text-center toUpper">PLAYER REVIEWS</h3>

            {reviewd_players.map((player, i) => <div className="w3-container bg-white p-4 d-flex mb-2" key={i} onClick={() => this.props.pr(player)}>
                <span>#{player.JerseyNumber}</span>
                <span className="ms-3">{`${player.NameFirst} ${player.NameLast}`}</span>
                <i className="ms-auto fas fa-chevron-right" />
            </div>)}

            <div className="w3-container bg-white p-3 d-flex mb-2 justify-content-center" onClick={() => this.props.pfr(match)}>
                <span className="font-16">+ Add Review</span>
            </div>

            <button className="btn w3-blue btn-block mt-4" onClick={() => this.next(5)}>Continue</button>
            <div className="p-4" />
            <div className="p-4" />
        </div >)
    }

    renderAnalysis = () => {
        const { match } = this.state;
        return <div className="d-flex flex-column black align-self-start w-100 w3-container">

            <div className="w3-card mt-3">
                <div className="px-2 py-1 w3-gray white">
                    <h2 className="sqwad-font m-0">SET PLAY ANALYSIS</h2>
                </div>
                <div className="w3-container bg-white p-4">
                    <label>Attacking</label>
                    <textarea className="w3-input" type="text" defaultValue={match.AttackingSet} ref={i => this.txtAttackingSet = i} />
                    <label>Defending</label>
                    <textarea className="w3-input" type="text" defaultValue={match.DefendingSet} ref={i => this.txtDefendingSet = i} />
                </div>
            </div>

            <div className="w3-card mt-3">
                <div className="px-2 py-1 w3-gray white">
                    <h2 className="sqwad-font m-0">SHAPES</h2>
                </div>
                <div className="w3-container bg-white p-4">
                    <label>Attacking</label>
                    <textarea className="w3-input" type="text" defaultValue={match.AttackingShape} ref={i => this.txtAttackingShape = i} />
                    <label>Defending</label>
                    <textarea className="w3-input" type="text" defaultValue={match.DefendingShape} ref={i => this.txtDefendingShape = i} />
                </div>
            </div>

            <div className="w3-card mt-3">
                <div className="px-2 py-1 w3-gray white">
                    <h2 className="sqwad-font m-0">GENERAL ANALYSIS</h2>
                </div>
                <div className="w3-container bg-white p-4">
                    <label>Overview</label>
                    <textarea className="w3-input" type="text" defaultValue={match.GeneralOverview} ref={i => this.txtGeneralOverview = i} />
                    <label>Attack</label>
                    <textarea className="w3-input" type="text" defaultValue={match.GeneralAttack} ref={i => this.txtGeneralAttack = i} />
                    <label>Defense</label>
                    <textarea className="w3-input" type="text" defaultValue={match.GeneralDefence} ref={i => this.txtGeneralDefence = i} />
                    <label>Strenghts</label>
                    <textarea className="w3-input" type="text" defaultValue={match.GeneralStrenghts} ref={i => this.txtGeneralStrenghts = i} />
                    <label>Weaknesses</label>
                    <textarea className="w3-input" type="text" defaultValue={match.GeneralWeaknesses} ref={i => this.txtGeneralWeaknesses = i} />
                    <label>How many players do they have in midfield?</label>
                    <textarea className="w3-input" type="text" defaultValue={match.GeneralQ1} ref={i => this.txtGeneralQ1 = i} />
                    <label>How deep do the flanks play? Attacking or defending?</label>
                    <textarea className="w3-input" type="text" defaultValue={match.GeneralQ2} ref={i => this.txtGeneralQ2 = i} />
                    <label>How tall is the GK and how is he with crosses?</label>
                    <textarea className="w3-input" type="text" defaultValue={match.GeneralQ3} ref={i => this.txtGeneralQ3 = i} />
                    <label>Does the GW throw the ball out and if so who is willing to accept it?</label>
                    <textarea className="w3-input" type="text" defaultValue={match.GeneralQ4} ref={i => this.txtGeneralQ4 = i} />
                    <label>Do they build up from the back, throught midfield or direct to the final 3rd?</label>
                    <textarea className="w3-input" type="text" defaultValue={match.GeneralQ5} ref={i => this.txtGeneralQ5 = i} />
                    <label>How well and early do they pressure the ball?</label>
                    <textarea className="w3-input" type="text" defaultValue={match.GeneralQ6} ref={i => this.txtGeneralQ6 = i} />
                    <label>Do they have any quick players?</label>
                    <textarea className="w3-input" type="text" defaultValue={match.GeneralQ7} ref={i => this.txtGeneralQ7 = i} />
                    <label>Do they have any aggressive players?</label>
                    <textarea className="w3-input" type="text" defaultValue={match.GeneralQ8} ref={i => this.txtGeneralQ8 = i} />
                    <label>Is there anyone frightened of a challenge?</label>
                    <textarea className="w3-input" type="text" defaultValue={match.GeneralQ9} ref={i => this.txtGeneralQ9 = i} />
                    <label>Were there any penalties and if so who took them and where the he place it?</label>
                    <textarea className="w3-input" type="text" defaultValue={match.GeneralQ10} ref={i => this.txtGeneralQ10 = i} />
                    <label>Is there anybody who did not play today that should have?</label>
                    <textarea className="w3-input" type="text" defaultValue={match.GeneralQ11} ref={i => this.txtGeneralQ11 = i} />
                </div>
            </div>

            <div className="w3-card mt-3">
                <div className="px-2 py-1 w3-gray white">
                    <h2 className="sqwad-font m-0">CONCLUSION</h2>
                </div>
                <div className="w3-container bg-white p-4">

                    <textarea className="w3-input" type="text" defaultValue={match.GeneralConclusion} ref={i => this.txtGeneralConclusion = i} />

                </div>
            </div>

            <button className="btn w3-blue btn-block mt-4" onClick={() => this.next(6)}>Continue</button>

            <div className="p-4" />
            <div className="p-4" />
        </div>
    }

    renderVideos = () => {
        // https://img.youtube.com/vi/_cVPFssz4FI/default.jpg
        const { match } = this.state;

        return (<div className="d-flex flex-column black align-self-start w-100 w3-container">

            <h3 className="sqwad-font text-center toUpper">VIDEOS</h3>
            <div className="row">
                {match.ExternalLinks.map((video, i) => {

                    // try to extract the id of the video if its youtube
                    if (video.link.indexOf('youtu.be') !== -1) {
                        let id = _.last(video.link.split('?')[0].split('/'));
                        video.thumbnail = `https://img.youtube.com/vi/${id}/default.jpg`;
                    }
                    //

                    return <Animated key={i} animationInDelay={25 * i} animationIn="fadeIn" className="col-md-6 p-0 mb-2 bg-white tile card" style={{ overflow: 'hidden' }}>
                        <div className="tile bg-black w-100 contain" style={{ height: 200, background: `url(${video.thumbnail}) no-repeat center center`, position: 'relative' }}>
                            {!video.thumbnail && <div className="w-100 h-100 d-flex flex-row justify-content-center">
                                <span className="align-self-center font-16 white sqwad-font">SQWAD</span>
                            </div>}
                        </div>
                        <div className="d-flex flex-column black ms-2 mt-2 align-self-center py-2">
                            <span className="line1 toBold">{video.name}</span>
                            {/*<span className="line1">{video.link}</span>*/}
                        </div>
                    </Animated>
                })}

                {this.state.adding_video === 1 && <Animated animationIn="fadeIn" className="p-4 col-md-6 p-0 mb-2 bg-white tile card" style={{ overflow: 'hidden' }}>
                    <input className="w3-input" type="text" ref={i => this.txtExternalLink = i} placeholder="External Link" />
                    <input className="w3-input" type="text" ref={i => this.txtExternalLinkName = i} placeholder="Name" />
                    <button className="btn w3-blue btn-block mt-4" onClick={() => {
                        // TODO: the idea here is to add the External Link directly
                        const { match } = this.state;
                        match.ExternalLinks.push({
                            link: this.txtExternalLink.value,
                            name: this.txtExternalLinkName.value,
                            id: generateRandomId(8)
                        });
                        this.setState({ adding_video: false, match });
                    }}>Save</button>
                </Animated>}
            </div>


            <div onClick={() => this.setState({ adding_video: 1 })} className="w3-container bg-white p-3 d-flex mb-2 justify-content-center mt-2">
                <span className="font-16">+ Add Video</span>
            </div>

            <button className="btn w3-green btn-block mt-4" onClick={this.onSave}>Finalize</button>
            <div className="p-4" />
            <div className="p-4" />
        </div >)
    }

    next = (p) => {
        const { match, page } = this.state;

        // This is based on the current page
        switch (page) {
            case 1:
                this.setState({
                    match: {
                        ...match,
                        PitchConditions: this.txtPitch.value,
                        Venue: this.txtVenue.value,
                        Competition: this.txtCompetition.value,
                        WeatherConditions: this.txtWeather.value,
                        Home: { ...match.Home, Name: this.txtHomeName.value },
                        Away: { ...match.Away, Name: this.txtAwayName.value },
                        RefereePrincipal: this.txtRefereePrincipal.value,
                        RefereeAssistant1: this.txtRefereeAssistant1.value,
                        RefereeAssistant2: this.txtRefereeAssistant2.value,
                    },
                    page: p
                });
                break;
            case 2:
                this.setState({
                    match: {
                        ...match,
                        Home: { ...match.Home, Manager: this.txtHomeManager.value },
                    },
                    page: p
                });
                break;
            case 3:
                this.setState({
                    match: {
                        ...match,
                        Away: { ...match.Away, Manager: this.txtAwayManager.value },
                    },
                    page: p
                });
                break;
            case 4:
                this.setState({ page: p });
                break;
            case 5:
                this.setState({
                    match: {
                        ...match,
                        AttackingSet: this.txtAttackingSet.value,
                        DefendingSet: this.txtDefendingSet.value,
                        AttackingShape: this.txtAttackingShape.value,
                        DefendingShape: this.txtDefendingShape.value,
                        GeneralOverview: this.txtGeneralOverview.value,
                        GeneralAttack: this.txtGeneralAttack.value,
                        GeneralDefence: this.txtGeneralDefence.value,
                        GeneralStrenghts: this.txtGeneralStrenghts.value,
                        GeneralWeaknesses: this.txtGeneralWeaknesses.value,
                        GeneralQ1: this.txtGeneralQ1.value,
                        GeneralQ2: this.txtGeneralQ2.value,
                        GeneralQ3: this.txtGeneralQ3.value,
                        GeneralQ4: this.txtGeneralQ4.value,
                        GeneralQ5: this.txtGeneralQ5.value,
                        GeneralQ6: this.txtGeneralQ6.value,
                        GeneralQ7: this.txtGeneralQ7.value,
                        GeneralQ8: this.txtGeneralQ8.value,
                        GeneralQ9: this.txtGeneralQ9.value,
                        GeneralQ10: this.txtGeneralQ10.value,
                        GeneralQ11: this.txtGeneralQ11.value,
                        GeneralConclusion: this.txtGeneralConclusion.value
                    },
                    page: p
                });
                break;
            case 6:
                this.setState({ page: p });
                break;
            default: break;
        }
        // SAVE DRAFT STATE OF THE THING ?
        this.section && this.section.scrollTo && this.section.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    }

    handleChangeDate = date => {
        const { match } = this.state;
        match.Date = moment(date).format();
        match.DateString = moment(date).format('MMM Do YYYY');
        this.setState({ selectedDate: date, match });
    }

    handleChangeTime = d => {
        const { selectedTime, match } = this.state;
        match.TimeString = moment(d).format('HH:mmA');
        this.setState({ selectedTime });
    }

    renderPager = () => {
        const { page } = this.state;
        return (<div className=" w-100 bg-white" style={{ position: 'fixed', bottom: 0, borderTop: '1px solid black' }}>
            <div className="d-flex flex-row justify-content-center">
                <div className="py-1  d-flex flex-row justify-content-center" onClick={() => this.next(1)}>
                    <div style={{ width: 30, height: 30, borderRadius: 15, border: '2px solid black' }} className="align-self-center d-flex justify-content-center">
                        <span className={`align-self-center ${page === 1 ? 'font-20 blue' : 'font-14'}`}>1</span>
                    </div>
                </div>
                <div style={{ width: 20, height: 1, border: '1px solid black' }} className="align-self-center" />

                <div className="py-1  d-flex flex-row justify-content-center" onClick={() => this.next(2)}>
                    <div style={{ width: 30, height: 30, borderRadius: 15, border: '2px solid black' }} className="align-self-center d-flex justify-content-center">
                        <span className={`align-self-center ${page === 2 ? 'font-20 blue' : 'font-14'}`}>2</span>
                    </div>
                </div>
                <div style={{ width: 20, height: 1, border: '1px solid black' }} className="align-self-center" />

                <div className="py-1  d-flex flex-row justify-content-center" onClick={() => this.next(3)}>
                    <div style={{ width: 30, height: 30, borderRadius: 15, border: '2px solid black' }} className="align-self-center d-flex justify-content-center">
                        <span className={`align-self-center ${page === 3 ? 'font-20 blue' : 'font-14'}`}>3</span>
                    </div>
                </div>
                <div style={{ width: 20, height: 1, border: '1px solid black' }} className="align-self-center" />

                <div className="py-1  d-flex flex-row justify-content-center" onClick={() => this.next(4)}>
                    <div style={{ width: 30, height: 30, borderRadius: 15, border: '2px solid black' }} className="align-self-center d-flex justify-content-center">
                        <span className={`align-self-center ${page === 4 ? 'font-20 blue' : 'font-14'}`}>4</span>
                    </div>
                </div>
                <div style={{ width: 20, height: 1, border: '1px solid black' }} className="align-self-center" />

                <div className="py-1  d-flex flex-row justify-content-center" onClick={() => this.next(5)}>
                    <div style={{ width: 30, height: 30, borderRadius: 15, border: '2px solid black' }} className="align-self-center d-flex justify-content-center">
                        <span className={`align-self-center ${page === 5 ? 'font-20 blue' : 'font-14'}`}>5</span>
                    </div>
                </div>
                <div style={{ width: 20, height: 1, border: '1px solid black' }} className="align-self-center" />

                <div className="py-1  d-flex flex-row justify-content-center" onClick={() => this.next(6)}>
                    <div style={{ width: 30, height: 30, borderRadius: 15, border: '2px solid black' }} className="align-self-center d-flex justify-content-center">
                        <span className={`align-self-center ${page === 6 ? 'font-20 blue' : 'font-14'}`}>6</span>
                    </div>
                </div>


            </div>
        </div>)
    }

    renderMainInfo = () => {
        const { match, page, selectedDate } = this.state;        
        return (<div className="d-flex flex-column black align-self-start w-100 w3-container">

            <div className="w3-container p-0">
                <div className="d-flex flex-column flex-sm-row">
                    <div className="w-100">
                        <h3 className="sqwad-font text-center toUpper">Home</h3>
                        <div className="bg-white p-4 mt-2 tile card">
                            {/* HOME LOGO */}
                            <input type="file" id="fi_home" onChange={(e) => {
                                getBase64(e.target.files[0]).then(data => {
                                    const { match } = this.state;
                                    match.Home.Logo = data;
                                    this.setState({ match });
                                });
                            }} className="hide" ref={i => this.logoHome = i} />

                            <label htmlFor="fi_home" style={{ width: 160, height: 120, position: 'relative' }} className="pointer hoverable mx-auto align-self-center mt-4 d-flex flex-column">
                                <div className="cover align-self-center" style={{ width: 120, height: 120, overflow: 'hidden', border: '1px solid white', background: `url(${match.Home.Logo || '/images/logos/generic_crest.png'}) no-repeat center center` }} />
                                <i style={{ position: 'absolute', right: 10, bottom: 0 }} className="green font-20 align-self-center icon-camera" />
                            </label>

                            <label>Name</label>
                            <input className="w3-input" type="text" defaultValue={`${match.Home.Name || ''}`} ref={i => this.txtHomeName = i} />
                            <button onClick={() => {
                                const { match } = this.state;
                                match.Home.Focus = true;
                                match.Away.Focus = false;
                                this.setState({ match });
                            }} className={`sqwad-font btn btn-${match.Home.Focus ? 'success' : 'link'} w-100 toUpper mt-1`}>FOCUS</button>
                            <button className="sqwad-font btn btn-link blue w-100 toUpper">IMPORT</button>
                        </div>
                        <div className="bg-white p-4 mt-2 tile card">
                            <div className="d-flex">
                                <div className="w-100 text-center" onClick={() => {
                                    const { match } = this.state;
                                    if (match.Home.Score === 0) return;
                                    match.Home.Score--;
                                    this.setState({ match });
                                }}>
                                    <span className="font-40 blue">-</span>
                                </div>
                                <div className="w-100 text-center">
                                    <span className="font-40 blue">{match.Home.Score || 0}</span>
                                </div>
                                <div className="w-100 text-center" onClick={() => {
                                    const { match } = this.state;
                                    match.Home.Score++;
                                    this.setState({ match });
                                }}>
                                    <span className="font-40 blue">+</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w3-hide-small p-1" />
                    <div className="w-100">
                        <h3 className="sqwad-font text-center toUpper">Away</h3>
                        <div className="bg-white p-4 mt-2 tile card">

                            {/* AWAY LOGO */}
                            <input type="file" id="fi_away" onChange={(e) => {
                                getBase64(e.target.files[0]).then(data => {
                                    const { match } = this.state;
                                    match.Away.Logo = data;
                                    this.setState({ match });
                                });
                            }} className="hide" ref={i => this.logoAway = i} />

                            <label htmlFor="fi_away" style={{ width: 160, height: 120, position: 'relative' }} className="pointer hoverable mx-auto align-self-center mt-4 d-flex flex-column">
                                <div className="cover align-self-center" style={{ width: 120, height: 120, overflow: 'hidden', border: '1px solid white', background: `url(${match.Away.Logo || '/images/logos/generic_crest.png'}) no-repeat center center` }} />
                                <i style={{ position: 'absolute', right: 10, bottom: 0 }} className="green font-20 align-self-center icon-camera" />
                            </label>

                            <label>Name</label>
                            <input className="w3-input" type="text" defaultValue={`${match.Away.Name || ''}`} ref={i => this.txtAwayName = i} />
                            <button onClick={() => {
                                const { match } = this.state;
                                match.Home.Focus = false;
                                match.Away.Focus = true;
                                this.setState({ match });
                            }} className={`sqwad-font btn btn-${match.Away.Focus ? 'success' : 'link'} w-100 toUpper mt-1`}>FOCUS</button>
                            <button className="sqwad-font btn btn-link blue w-100 toUpper">IMPORT</button>
                        </div>
                        <div className="bg-white p-4 mt-2 tile card">
                            <div className="d-flex">
                                <div className="w-100 text-center" onClick={() => {
                                    const { match } = this.state;
                                    if (match.Away.Score === 0) return;
                                    match.Away.Score--;
                                    this.setState({ match });
                                }}>
                                    <span className="font-40 blue">-</span>
                                </div>
                                <div className="w-100 text-center">
                                    <span className="font-40 blue">{match.Away.Score || 0}</span>
                                </div>
                                <div className="w-100 text-center" onClick={() => {
                                    const { match } = this.state;
                                    match.Away.Score++;
                                    this.setState({ match });
                                }}>
                                    <span className="font-40 blue">+</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w3-container bg-white p-4 mt-2 tile card">
                <label>Competition</label>
                <input className="w3-input" type="text" defaultValue={`${match.Competition || ''}`} ref={i => this.txtCompetition = i} />

                <label className="mt-2">Venue</label>
                <input className="w3-input" type="text" defaultValue={`${match.Venue || ''}`} ref={i => this.txtVenue = i} />

                <div className="d-flex">
                    <div className="w-100">
                        <label className="mt-2">Kick off</label>
                        <DatePicker highlightDates={[]} customInput={<CustomDatePicker />} selected={selectedDate} showYearDropdown scrollableYearDropdown
                            yearDropdownItemNumber={15} onChange={this.handleChangeDate} className="w3-input black w-100"
                        />
                    </div>
                    <div className="w-100">
                        <CustomTimeScroller time={this.state.selectedTime} fnChange={this.handleChangeTime} />
                    </div>
                </div>

                <label className="mt-2">Weather Conditions</label>
                <input className="w3-input" type="text" defaultValue={`${match.WeatherConditions || ''}`} ref={i => this.txtWeather = i} />

                <label className="mt-2">Pitch Conditions</label>
                <input className="w3-input" type="text" defaultValue={`${match.PitchConditions || ''}`} ref={i => this.txtPitch = i} />

            </div>

            <h3 className="sqwad-font text-center toUpper">Match Officials</h3>

            <div className="w3-container bg-white p-4 mt-2 tile card">
                <label>Referee</label>
                <input className="w3-input" type="text" defaultValue={`${match.RefereePrincipal || ''}`} ref={i => this.txtRefereePrincipal = i} />

                <label className="mt-2">Assistant #1</label>
                <input className="w3-input" type="text" defaultValue={`${match.RefereeAssistant1 || ''}`} ref={i => this.txtRefereeAssistant1 = i} />

                <label className="mt-2">Assistant #2</label>
                <input className="w3-input" type="text" defaultValue={`${match.RefereeAssistant2 || ''}`} ref={i => this.txtRefereeAssistant2 = i} />

            </div>


            <button className="btn w3-blue btn-block mt-4" onClick={() => this.next(2)}>Continue</button>

            <div className="p-4" />
            <div className="p-4" />
        </div>)
    }

    render() {
        const { match, page } = this.state;
        return <section>
            <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="slideInRight" animationOut="slideOutRight" isVisible={this.props.player_match ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200, }} className="bg-whiteish">
                <section ref={i => this.section = i} className="d-flex flex-row w-100 p-4 ease"
                    style={{ position: 'absolute', top: 80, left: 0, bottom: 0, paddingTop: 70, overflowY: 'auto' }}>
                    {match && <section className="w3-container w3-content mx-auto w-100">

                        {page === 1 && this.renderMainInfo()}

                        {page === 2 && this.renderHomeRoster()}

                        {page === 3 && this.renderAwayRoster()}

                        {page === 4 && this.renderPlayerReviews()}

                        {page === 5 && this.renderAnalysis()}

                        {page === 6 && this.renderVideos()}

                    </section>}
                </section>

                {/* X */}
                <div style={{ position: 'fixed', top: 0, left: 0, right: 0, height: 80 }} className="bg-blue-dark bg-dark-blue d-flex flex-row">
                    <div onClick={this.props.toggle} style={{ width: 50, height: 50, borderRadius: 25 }} className="bg-gray d-flex flex-column justify-content-center align-self-center ms-4">
                        <i className="fas fa-times black font-30 align-self-center" />
                    </div>
                    <button className="ms-auto btn w3-green btn-block align-self-center px-4 me-4" style={{ borderRadius: 20 }} onClick={this.onSave}>Finalize</button>
                    {match && match.IdMatch && <button className="btn btn-link me-4" onClick={() => this.setState({ menu: !this.state.menu })}><i className="icon-more-vertical white font-20" /></ button>}
                </div>

                {match && <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeIn" animationOut='fadeOut'
                    isVisible={this.state.menu ? true : false}
                    className="bg-white card tile shadow d-flex flex-row"
                    style={{ position: 'fixed', top: 10, right: 10 }}>
                    <div className="d-flex flex-column">
                        <a target="_blank" title="PDF Version" href={`https://www.thesqwad.com/scout/match/${match.IdMatch}`} className="btn btn-default"><i className="far fa-file-pdf black" /> Download PDF</a>
                        <button onClick={() => this.props.fnshare(match)} className="btn btn-default"><i className="icon-share black" /> Share Report</button>
                        <button onClick={() => this.setState({ menu: null }, () => {
                            this.props.fndelete(match)
                        })} className="btn btn-default"><i className="fas fa-times red" /> Delete Report</button>
                    </div>
                    <button className="btn btn-default align-self-start" onClick={() => this.setState({ menu: false })}><i className="black icon-more-vertical" /></button>
                </Animated>}

                {/* PAGER */}
                {this.renderPager()}
            </Animated>
        </section>
    }
}

export default TeamMatch;