import React from 'react';
import Layout from '../layouts/claire';
import { Animated } from 'react-animated-css';
import Menu from '../home/partials/menu';
import { Link } from 'react-router';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Sport from './partials/sport';
import MenuDesktop from './partials/menu_desktop';
import MenuMobile from './partials/menu_mobile';
import CollegeProfile from './partials/college_profile';
import LineLoader from '../partials/line_loader';
import FollowLevels from './partials/follow_levels';

class Prospect extends React.Component {

    state = {
        menu_toggled: false,
        path: [],
        pickingFollowLevel: false,
        user: null,
        followee: null
    }

    // Lifecycle
    componentWillMount() {
        this.props.scout_actions && this.props.scout_actions.fetchCountries();
        this.props.scout_actions && this.props.scout_actions.fetchCommunities();
        this.props.scout_actions && this.props.scout_actions.fetchFollowLevels();
        this.props.scout_actions && this.props.scout_actions.fetchFollowees();
        this.setState({ path: _.chain(this.props.router.getCurrentLocation()).value().pathname.split('/') });
    }
    componentDidMount() {
        const { router } = this.props, { params } = router, { id } = params;
        if (id) {
            this.props.scout_actions && this.props.scout_actions.fetchUser(id);
        }
    }
    componentWillReceiveProps = nextProps => {
        if (nextProps.user) {
            this.setState({
                user: nextProps.user,
                followee: _.find(nextProps.followees || [], f => f.IdUserFollow === nextProps.user.IdUser)
            });
        }
    }
    //

    onFollow = () => {
        const { user } = this.state;
        this.props.scout_actions.follow({ IdUserFollow: user.IdUserFollow || user.IdUser });
    }
    onUnfollow = () => {
        const { user } = this.state;
        this.props.scout_actions.unfollow({ IdUserFollow: user.IdUserFollow || user.IdUser });
    }

    pickFollowLevel = (f) => {
        const { user, followee } = this.state;
        user.FollowLevel = f.Name;
        this.setState({ user });
    }

    renderAvatar = () => {
        const { user = {}, followee } = this.state, { followees } = this.props;

        let nat_1 = _.find(this.props.countries || [], c => c.CountryName === (user.Nationality1 || user.Nationality)),
            nat_2 = _.find(this.props.countries || [], c => c.CountryName === user.Nationality2);

        let soccer = _.find(user.sports, s => s.IdCommunity === 1), position = '';

        if (soccer) {
            position = `${[soccer.Position1, soccer.Position2].join(' - ')}`;
        }

        return <div className="d-flex flex-column">

            {/* FOLLOW */}
            {!user.IsArchived && _.find(followees, f => f.IdUserFollow === user.IdUser) && <button className={`btn btn-success btn-sm mx-auto mb-4`} style={{ borderRadius: 20, width: 100, border: '1px solid white' }} onClick={this.onUnfollow}>Following</button>}
            {!user.IsArchived && !_.find(followees, f => f.IdUserFollow === user.IdUser) && <button className={`btn btn-info btn-sm mx-auto mb-4`} style={{ borderRadius: 20, width: 100, border: '1px solid white' }} onClick={this.onFollow}>Follow</button>}

            <div className="align-self-center cover" style={{ background: `url(${user.UserImage}) no-repeat center center black`, width: 130, height: 130, borderRadius: 65, border: `1px solid white` }}></div>
            <label className="text-center montserrat white line1 font-20 mt-4">{user.NameFirst}</label>
            <label className="text-center montserrat white line1 font-14">{user.NameLast}</label>

            <div className="mx-auto mt-2">
                <div className="d-flex flex-row mb-2">
                    {nat_2 && <img src={nat_2.image} style={{ height: 15 }} className="me-1" />}
                    {nat_1 && <img src={nat_1.image} style={{ height: 15 }} />}
                </div>
            </div>


            {followee && <div onClick={() => this.setState({ picking_follow_level: true })}>
                <div className="d-flex flex-row btn btn-link bordered mt-2" >
                    <span className="white align-self-center">{followee.FollowLevelName || 'Prospect'}</span>
                    <i className="ms-auto fas fa-caret-down align-self-center white" />
                </div>
            </div>}

            <div className="p-3" />
        </div>
    }

    renderCommon = () => {
        const { user = {}, followee } = this.state, { follow_levels } = this.props;
        return <div className="w3-content pl-4 container pt-4">

            {user && <div className="row">

                {/* AVATAR */}
                <div className="col-sm-6 p-0 d-flex flex-row justify-content-center" style={{ position: 'relative' }}>

                    {/* MOBILE */}
                    <div className=" w3-hide-medium w3-hide-large">
                        {this.renderAvatar()}
                    </div>

                    {/* DESKTOP */}
                    <div style={{ position: 'fixed', width: 300 }} className="py-2 pr-2 w3-hide-small">
                        {this.renderAvatar()}
                    </div>
                </div>

                {/* INFO */}
                <Animated animationInDelay={50} animationIn="fadeIn" className="col-sm-6 p-0">



                    {/* SPORTS */}
                    {this.props.communities && user.sports.map((sport, index) => <Sport key={index} sport={sport} communities={this.props.communities} user={user} />)}
                   

                    {/* COLLEGE */}

                    {followee && false && <div className="w3-container bg-white p-3 card tile mb-3" onClick={() => this.setState({ picking_follow_level: true })}>
                        <label>Level</label>
                        <span className="w3-input w-100">{followee.FollowLevelName || 'Prospect'}</span>
                    </div>}

                    <CollegeProfile profile={user} followees={this.props.followees} />

                    

                    <div style={{ height: 100 }} />

                </Animated>
            </div>}


        </div>
    }
    render() {

        return (
            <Layout router={this.props.router} className="bg-blue-dark" title="SQWAD SCOUT | Prospect" form={this.props.form} form_actions={this.props.form_actions}>
                <section>
                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small" style={{ marginLeft: 200 }}>
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                        <MenuDesktop {...this.state} {...this.props} />
                    </div>
                    {/* MOBILE */}
                    <div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                        <MenuMobile {...this.state} />
                    </div>
                    <Menu {...this.state} {...this.props} />
                    <FollowLevels fn={(c) => {
                        const { followee } = this.state;
                        followee.IdFollowLevel = c.IdFollowLevel;
                        followee.FollowLevelName = c.Name;
                        // TODO: call API 
                        this.props.scout_actions.update_follow_level(followee.IdUserFollow, followee.IdFollowLevel)
                        this.setState({ picking_follow_level: false, followee });
                    }} {...this.state} {...this.props} toggle={() => this.setState({ picking_follow_level: false })} />
                    {this.props.loading && <LineLoader />}
                </section >
            </Layout>
        )
    }
}

export default Prospect;
