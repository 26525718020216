function match(state = [], action) {
    switch (action.type) {
        case 'MATCH/SET':
            return action.match;
        case 'TEAM/CLEAR':
            return null;
        case 'MATCH/CLEAR':
            return null;
        default:
            return state;
    }
}

export default match;
