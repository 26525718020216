import React from 'react';
import Layout from '../layouts/claire';
import { preload } from '../../helpers';
import { Animated } from 'react-animated-css';
import Menu from '../home/partials/menu';
import { Link } from 'react-router';
import Sport from './partials/sport';
import MenuDesktop from './partials/lead_menu_desktop';
import MenuMobile from './partials/lead_menu_mobile';
import LineLoader from '../partials/line_loader';
import moment from 'moment';
import VideoPlayer from '../scouting/video_player';

class LeadHighlights extends React.Component {
    state = {
        menu_toggled: false,
        path: []
    }
    componentWillMount() {
        this.props.scout_actions && this.props.scout_actions.fetchCountries();
        this.props.scout_actions && this.props.scout_actions.fetchCommunities();
        this.props.scout_actions && this.props.scout_actions.fetchFollowLevels();
        this.props.scout_actions && this.props.scout_actions.fetchFollowees();
        this.setState({ path: _.chain(this.props.router.getCurrentLocation()).value().pathname.split('/') });
    }
    componentDidMount() {
        const { router } = this.props, { params } = router, { id } = params;
        if (id) {
            this.props.scout_actions && this.props.scout_actions.fetchUser(id);
            this.props.scout_actions && this.props.scout_actions.fetchUserEvaluations(id);
            this.props.video_actions && this.props.video_actions.fetchVideosByUser(id)
        }
    }
    componentWillReceiveProps = nextProps => {
        if (nextProps.user && nextProps.evaluations) {
            this.setState({ user: nextProps.user, evaluations: nextProps.evaluations, videos: nextProps.videos });
        }
    }

    renderCommon = () => {
        const { videos, user, video } = this.state, { loading } = this.props;
        return <div className="w3-content pl-4 container pt-4">

            {/* YES +*/}
            {(videos && videos.length && !this.props.loading) &&
                <div className="row h-100">
                    {videos && videos.map((video, i) => {
                        if (video.url_browser) {
                            return (
                                <Animated key={i} animationInDelay={25 * i} animationIn="fadeInRightBig" className="col-md-6 p-2">
                                    <a href={video.url_browser} target="_blank">
                                        <div className="tile bg-black w-100 contain" style={{ height: 200, background: `url(${video.thumbnail}) no-repeat center center`, position: 'relative' }}>
                                            {video.url_browser && <div className="w-100 h-100 d-flex flex-row justify-content-center">
                                                <span className="align-self-center font-16 white sqwad-font">SQWAD</span>
                                            </div>}
                                            {!video.url_browser && <span style={{ position: 'absolute', bottom: 10, right: 10 }} className="white font-8">{video.durationInSeconds} SECS</span>}
                                            {video.url_browser && <span style={{ position: 'absolute', bottom: 10, left: 10 }} className="white font-8"><i className="fab fa-youtube red me-2" />External Link</span>}
                                        </div>
                                        <div className="d-flex flex-column black ms-2 mt-2 align-self-center">
                                            <span className="line1 white toBold">{video.name}</span>
                                            <span className="white font-8">{moment(video.createDate).format('MMM Do YYYY')}</span>
                                        </div>
                                    </a>
                                </Animated>)
                        } else
                            return (
                                <Animated key={i} animationInDelay={25 * i} animationIn="fadeInRightBig" className="col-md-6 p-2 pointer">
                                    <div onClick={() => this.setState({ video })} className="tile bg-black w-100 contain" style={{ height: 200, background: `url(${video.thumbnail}) no-repeat center center`, position: 'relative' }}>
                                        {video.url_browser && <div className="w-100 h-100 d-flex flex-row justify-content-center">
                                            <span className="align-self-center font-16 white sqwad-font">SQWAD</span>
                                        </div>}
                                        {!video.url_browser && <span style={{ position: 'absolute', bottom: 10, right: 10 }} className="white font-8">{video.durationInSeconds} SECS</span>}
                                        {video.url_browser && <span style={{ position: 'absolute', bottom: 10, left: 10 }} className="white font-8"><i className="fab fa-youtube red me-2" />External Link</span>}
                                    </div>
                                    <div className="d-flex flex-column black ms-2 mt-2 align-self-center">
                                        <span className="line1 white toBold">{video.name}</span>
                                        <span className="white font-8">{moment(video.createDate).format('MMM Do YYYY')}</span>
                                    </div>
                                </Animated>)
                    })}
                    <div style={{ height: 200 }} className="col-12 " />
                </div>
            }


            {/* NO */}
            {!loading && videos && !videos.length && user && <div className="d-flex w-100 flex-column justify-content-center">
                <img src="/images/icons/red_card.png" className="w-25 align-self-center mt-4" />
                <span className="white font-30 align-self-center text-center mx-4 px-4 line1 mt-4">Sorry, there are no public highlights<br />for {`${user.NameFirst} ${user.NameLast}`}</span>
            </div>}

        </div>
    }
    render() {
        const { video } = this.state;
        return (
            <Layout router={this.props.router} className="bg-blue-dark" nbs="network" title="SQWAD SCOUT | MY NETWORK" form={this.props.form} form_actions={this.props.form_actions}>
                <section>

                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small h-100" style={{ marginLeft: 200 }}>
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                        <MenuDesktop {...this.state} {...this.props} />
                    </div>
                    {/* MOBILE */}
                    <div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                        <MenuMobile {...this.state} {...this.props} />
                    </div>

                    <Menu {...this.state} {...this.props} />
                    <VideoPlayer onClip={() => { }} {...this.state} toggle={() => this.setState({ video: false })}
                        onAction={() => { }} onTag={() => { }} onShare={() => { }} />

                </section >


            </Layout>
        )
    }
}

export default LeadHighlights;
