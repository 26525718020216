import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';

export function* follow_accept_saga() {
    yield* takeEvery("FOLLOW/ACCEPT", function* (action) {
        try {
            yield call(({ idCoachProfile, idAthleteProfile }) => {
                return request.post(`${config.apiEndpoint}/api/external/follow/accept`)
                    .set('Authorization', `Bearer ${config.apiKey}`)
                    .send({ idCoachProfile, idAthleteProfile })
                    .then(data => (JSON.parse(data.text)));
            }, action);
        } catch (e) { }
    });
}

export function* follow_user_saga() {
    yield* takeEvery("FOLLOW/USER", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            yield call(({ IdUserFollow }) => {
                return request.post(`${config.apiEndpoint}/api/v5/follow/user`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .send({ IdUserFollow })
                    .then(data => (JSON.parse(data.text)));
            }, action);

            // command a reload
            yield put({ type: 'FOLLOWEES/FETCH' });
        } catch (e) { }
    });
}

export function* unfollow_user_saga() {
    yield* takeEvery("UNFOLLOW/USER", function* (action) {
        try {
            yield put({ type: 'LOADING/SET', loading: true });
            yield call(({ IdUserFollow }) => {
                return request.delete(`${config.apiEndpoint}/api/v5/follow/user/${IdUserFollow}`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))                    
                    .then(data => (JSON.parse(data.text)));
            }, action);

            // command a reload
            yield put({ type: 'FOLLOWEES/FETCH' });
        } catch (e) { }
    });
}

export function* follow_deny_saga() {
    yield* takeEvery("FOLLOW/DENY", function* (action) {
        try {
            yield call(({ idCoachProfile, idAthleteProfile }) => {
                return request.post(`${config.apiEndpoint}/api/external/follow/deny`)
                    .set('Authorization', `Bearer ${config.apiKey}`)
                    .send({ idCoachProfile, idAthleteProfile })
                    .then(data => (JSON.parse(data.text)));
            }, action);
        } catch (e) { }
    });
}

export function* follow_accept_photo_replacement_saga() {
    yield* takeEvery("PHOTO_REPLACE/ACCEPT", function* (action) {
        try {
            yield call(({ idRequestReplacement }) => {
                return request.post(`${config.apiEndpoint}/api/external/photos/replacement/accept/${idRequestReplacement}`)
                    .set('Authorization', `Bearer ${config.apiKey}`)
                    .send({})
                    .then(data => (JSON.parse(data.text)));
            }, action);
        } catch (e) { }
    });
}

export function* follow_deny_photo_replacement_saga() {
    yield* takeEvery("PHOTO_REPLACE/DENY", function* (action) {
        try {
            yield call(({ idRequestReplacement }) => {
                return request.post(`${config.apiEndpoint}/api/external/photos/replacement/deny/${idRequestReplacement}`)
                    .set('Authorization', `Bearer ${config.apiKey}`)
                    .send({})
                    .then(data => (JSON.parse(data.text)));
            }, action);
        } catch (e) { }
    });
}

export function* reference_accept_saga() {
    yield* takeEvery("REFERENCE/ACCEPT", function* (action) {
        try {
            yield call(({ idCoachReference }) => {
                return request.post(`${config.apiEndpoint}/api/external/reference/accept/${idCoachReference}`)
                    .set('Authorization', `Bearer ${config.apiKey}`)
                    .send({})
                    .then(data => (JSON.parse(data.text)));
            }, action);
        } catch (e) { }
    });
}

export function* reference_deny_saga() {
    yield* takeEvery("REFERENCE/DENY", function* (action) {
        try {
            yield call(({ idCoachReference }) => {
                return request.post(`${config.apiEndpoint}/api/external/reference/deny/${idCoachReference}`)
                    .set('Authorization', `Bearer ${config.apiKey}`)
                    .send({})
                    .then(data => (JSON.parse(data.text)));
            }, action);
        } catch (e) { }
    });
}

// Get the disclaimer of a season
export function* fetch_season_disclaimer() {
    yield* takeEvery("DISCLAIMER/FETCH", function* (action) {
      try {
        const result = yield call(idSeason => {
          return request.get(`${config.apiEndpoint}/api/v4/registration/disclaimer/${idSeason}`)
            .set('auth_token', localStorage.getItem('sportslogic.authtoken'))
            .then((data) => {
              return (JSON.parse(data.text))
            });
        }, action.idSeason);
        if (result && result.success) {
          yield put({
            type: 'DISCLAIMER/SET',
            disclaimer: result
          });
        } else {
          yield put({
            type: 'DISCLAIMER/FETCH_FAILED',
            result
          });
        }
      } catch (e) {
        yield put({
          type: 'DISCLAIMER/FETCH_FAILED',
          e
        });
      }
    });
  }