import React from 'react';
import Layout from '../layouts/claire';
import { preload } from '../../helpers';
import { Animated } from 'react-animated-css';
import Menu from '../home/partials/menu';
import { Link } from 'react-router';
import Players from './partials/players';
import Teams from './partials/teams';
import LineLoader from '../partials/line_loader';

class TeamFolder extends React.Component {
    state = {
        menu_toggled: false,
        mode: 2
    }
    componentWillMount() {
        this.props.scout_actions && this.props.scout_actions.fetchCountries();
        this.props.scout_actions && this.props.scout_actions.fetchCommunities();
        this.props.scout_actions && this.props.scout_actions.clearUser();
    }
    componentDidMount() {
        this.props.scout_actions && this.props.scout_actions.fetchFollowees();
        this.props.scout_actions && this.props.scout_actions.fetchFolders();
        const { router } = this.props, { params } = router;
        if (params.id) {
            this.props.scout_actions && this.props.scout_actions.fetchFolder(params.id);
        }
    }
    renderCommon = () => {
        const { mode } = this.state, { followees, folders, router, folder } = this.props, { params } = router;


        return <div className="w3-content pl-4 container pt-4">
            <div className="row">

                <div className="d-flex flex-row w-100">
                    <Link className="px-4 py-2 tile card w-100 d-flex flex-row w-100" to="/network">
                        <i className="far fa-folder black text-center font-30 align-self-center" />
                        <span className="line1 font-10 black text-center align-self-center ms-4">{folder ? (folder.Name || folder.FolderName) : 'ALL PLAYERS'}</span>
                        <span className="ms-auto blue font-10 align-self-center">Change</span>
                    </Link>
                    <div className="px-4 py-2 tile card d-flex flex-row ms-2">
                        <i className="icon-filter black text-center font-24 align-self-center" />
                    </div>
                </div>

                <input className="w-100 mt-3 p-2 mb-3 tile card" type="text" name="email" placeholder="Search" ref={(input) => this.txtSearch = input} />

                {/*<div className="d-flex flex-row syncopate text-center w-100">
                    <div className={`w-100 p-2 ${mode === 1 ? '' : 'bg-gray'}`} onClick={() => this.setState({ mode: 1 })}>
                        <h4 className="p-0 m-0">PLAYERS</h4>
                    </div>
                    <div className={`w-100 p-2 ${mode === 2 ? '' : 'bg-gray'}`} onClick={() => this.setState({ mode: 2 })}>
                        <h4 className="p-0 m-0">TEAMS</h4>
                    </div>
    </div>*/}
            </div>

            {folder && <Teams folder={folder} teams={this.props.teams} folders={[]} />}

            <div className="p-4" />

        </div >
    }
    render() {

        return (
            <Layout router={this.props.router} className="bg-white" nbs="network" title="SQWAD SCOUT | MY NETWORK" form={this.props.form} form_actions={this.props.form_actions}>
                <section>
                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small" style={{ marginLeft: 200 }}>
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                    </div>
                    {/* MOBILE */}
                    <div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                    </div>
                    <Menu {...this.state} {...this.props} />

                    {this.props.loading && <LineLoader />}
                </section >
            </Layout>
        )
    }
}

export default TeamFolder;
