import React from 'react';
import Layout from '../layouts/claire';
import { preload } from '../../helpers';
import { Animated } from 'react-animated-css';
import Menu from '../home/partials/menu';
import Sport from './partials/sport';
import MenuDesktop from './partials/lead_menu_desktop';
import MenuMobile from './partials/lead_menu_mobile';
import CollegeProfile from './partials/college_profile';

class Lead extends React.Component {
    state = {
        menu_toggled: false,
        path: [],
        pickingFollowLevel: false,
        user: null,
        hard_following: false,
    }

    componentWillMount() {
        this.props.scout_actions && this.props.scout_actions.fetchCountries();
        this.props.scout_actions && this.props.scout_actions.fetchCommunities();
        this.props.scout_actions && this.props.scout_actions.fetchFollowLevels();
        this.props.scout_actions && this.props.scout_actions.fetchFollowees();
        this.setState({ path: _.chain(this.props.router.getCurrentLocation()).value().pathname.split('/') });
    }
    componentDidMount() {
        const { router } = this.props, { params } = router, { id } = params;
        if (id) {
            this.props.scout_actions && this.props.scout_actions.fetchUser(id);
        }
    }
    componentWillReceiveProps = nextProps => {
        if (nextProps.user) {
            this.setState({ user: nextProps.user });
        }
    }

    pickFollowLevel = (f) => {
        const { user } = this.state;
        user.FollowLevel = f.Name;
        this.setState({ user, pickingFollowLevel: false });
    }

    onFollow = () => {
        const { user } = this.state;
        this.props.scout_actions.follow({ IdUserFollow: user.IdUserFollow || user.IdUser });
    }
    onUnfollow = () => {
        const { user } = this.state;
        this.props.scout_actions.unfollow({ IdUserFollow: user.IdUserFollow || user.IdUser });
    }

    renderAvatar = () => {
        const { user = {} } = this.state, { followees } = this.props;
        return <div className="d-flex flex-column">
            
            {/* FOLLOW */}
            {!user.IsArchived && _.find(followees, f => f.IdUserFollow === user.IdUser) && <button className={`btn btn-success btn-sm mx-auto mb-4`} style={{ borderRadius: 20, width: 100, border: '1px solid white' }} onClick={this.onUnfollow}>Following</button>}
            {!user.IsArchived && !_.find(followees, f => f.IdUserFollow === user.IdUser) && <button className={`btn btn-info btn-sm mx-auto mb-4`} style={{ borderRadius: 20, width: 100, border: '1px solid white' }} onClick={this.onFollow}>Follow</button>}
            
            {/* AVATAR */}
            <div className="align-self-center cover" style={{ background: `url(${user.UserImage || user.Image}) no-repeat center center black`, width: 130, height: 130, borderRadius: 65, border: `1px solid white` }}></div>
            <h2 className="text-center montserrat white line1">{user.NameFirst}</h2>
            <h4 className="text-center montserrat white line1">{user.NameLast}</h4>
            <div className="p-3" />
        </div>
    }

    renderCommon = () => {
        const { user = {} } = this.state;
        return <div className="w3-content pl-4 container pt-4">

            {user && <div className="row">

                {/* AVATAR */}
                <div className="col-sm-6 p-0 d-flex flex-row justify-content-center" style={{ position: 'relative' }}>

                    {/* MOBILE */}
                    <div className=" w3-hide-medium w3-hide-large">
                        {this.renderAvatar()}
                    </div>

                    {/* DESKTOP */}
                    <div style={{ position: 'fixed', width: 300 }} className="py-2 pr-2 w3-hide-small">
                        {this.renderAvatar()}
                    </div>

                </div>

                {/* INFO */}
                <Animated animationInDelay={50} animationIn="fadeIn" className="col-sm-6 p-0">

                    {/* SPORTS */}
                    {this.props.communities && user.sports.map((sport, index) => <Sport key={index} sport={sport} communities={this.props.communities} user={user} />)}

                    {/* COLLEGE */}
                    <CollegeProfile profile={user} />

                    {/* NOTES */}
                    {user.Notes && <div className="tile card d-flex flex-column mb-3 py-2 px-3" >
                        <span className="font-8">Notes</span>
                        <span>{user.Notes}</span>
                    </div>}

                    <div style={{ height: 100 }} />

                </Animated>
            </div>}


        </div>
    }
    render() {
        return (
            <Layout router={this.props.router} className="bg-blue-dark" title="SQWAD SCOUT | Prospect" form={this.props.form} form_actions={this.props.form_actions}>
                <section>

                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small" style={{ marginLeft: 200 }}>
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                        <MenuDesktop {...this.state} {...this.props} />
                    </div>

                    {/* MOBILE */}
                    <div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                        <MenuMobile {...this.state} />
                    </div>

                    <Menu {...this.state} {...this.props} />

                </section >
            </Layout>
        )
    }
}

export default Lead;
