function countries(state = [], action) {
    switch (action.type) {
        case 'COUNTRIES/SET':            
            return action.countries;
        case 'COUNTRIES/CLEAR':
            return null;
        default:
            return state;
    }
}

export default countries;
