import React from 'react';
import Layout from '../layouts/claire';
import moment from 'moment';
import { Animated } from 'react-animated-css';
import Menu from '../home/partials/menu';
import MenuDesktop from './partials/menu_family_desktop';
import MenuMobile from './partials/menu_family_mobile';
import fakeAuth from '../../fakeAuth';
import AddCoachProfile from '../home/partials/add_coach_profile';
import { generateRandomId, getBase64 } from '../../helpers';
import request from 'superagent';
import config from '../../config';
import AddFamily from './partials/add';


class FamilyMember extends React.Component {
    state = {
        menu_toggled: false,
        path: [],
        pickingFollowLevel: false,
        user: null,
        hard_following: false,
    }

    componentWillMount() {
        this.setState({ path: _.chain(this.props.router.getCurrentLocation()).value().pathname.split('/') });
        const { router } = this.props, { params } = router, { id } = params;
        if (id) {
            let user = _.find(fakeAuth.getFamily(), f => parseInt(f.IdUser, 10) === parseInt(id, 10));
            this.setState({ user });
            this.props.scout_actions && this.props.scout_actions.fetch_coach_profile(id);
        }
    }
    componentWillReceiveProps = nextProps => {
        const { router } = this.props, { params } = router, { id } = params;
        let user = _.find(fakeAuth.getFamily(), f => parseInt(f.IdUser, 10) === parseInt(id, 10));
        if (nextProps.coach_profile) {
            this.setState({ coach_profile: { ...nextProps.coach_profile, IdUser: user.IdUser }, user });
        } else {
            this.setState({ user });
        }
    }

    handleAvatar = e => {
        const { user } = this.state;
        getBase64(e.target.files[0]).then(data => {
            this.setState({ logo: data }, () => {
                // CALL API to update the picture                
                request.patch(`${config.apiEndpoint}/api/v4/family/member/partial/${user.IdUser}`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .send({
                        NameFirst: user.NameLast,
                        NameLast: user.NameFirst,
                        IdGender: user.IdGender,
                        UserImage: data,
                        doUpdateImage: true,
                        DateBirth: ((user.DateBirth.indexOf('T') !== -1) ? moment(user.DateBirth).utc() : moment(user.DateBirth, 'MM/DD/YYYY')).format('YYYY/MM/DD')
                    }).then(() => { });
            });
        });
    }


    renderAvatar = () => {
        const { user = {} } = this.state, { followees } = this.props;
        return <div className="d-flex flex-column">

            {/* AVATAR */}
            <label htmlFor="fi" style={{ width: 160, height: 120, position: 'relative' }} className="pointer hoverable align-self-center mt-4 d-flex flex-column">
                <div className="cover align-self-center" style={{ width: 120, height: 120, borderRadius: 60, overflow: 'hidden', border: '1px solid white', background: `url(${this.state.logo || user.UserImage || `/images/defaults/${user.IdGender === 2 ? 'female' : 'male'}.png`}) no-repeat center center black` }} />
                <i style={{ position: 'absolute', right: 10, bottom: 0 }} className="green font-20 align-self-center icon-camera" />
            </label>

            <div className="m-2" />
            <h2 className="text-center montserrat white line1 p-0 m-0">{user.NameFirst}</h2>
            <h4 className="text-center montserrat white line1 p-0 m-0">{user.NameLast}</h4>
            <div className="p-3" />
        </div>
    }



    renderCommon = () => {
        const { user = {}, coach_profile = {} } = this.state;
        return <div className="w3-content pl-4 container pt-4">

            <input type="file" id="fi" onChange={this.handleAvatar} className="hide" ref={i => this.avatarFU = i} />

            {user && coach_profile && <div className="row">

                {/* AVATAR */}
                <div className="col-sm-6 p-0 d-flex flex-row justify-content-center" style={{ position: 'relative' }}>

                    {/* MOBILE */}
                    <div className=" w3-hide-medium w3-hide-large">
                        {this.renderAvatar()}
                    </div>

                    {/* DESKTOP */}
                    <div style={{ position: 'fixed', width: 300 }} className="py-2 pr-2 w3-hide-small">
                        {this.renderAvatar()}
                    </div>

                </div>

                {/* INFO */}
                <Animated animationInDelay={50} animationIn="fadeIn" className="col-sm-6 p-0">

                    <div className="d-flex flex-row px-2 mb-3" onClick={() => this.setState({ creating_family: true })}>
                        <span className="white sqwad-font">BASIC INFO</span>
                        <span className="white link ms-auto">Edit</span>
                    </div>
                    <div className="tile card d-flex flex-column mb-3 py-2 px-3" >
                        <span className="font-8">DOB</span>
                        <span>{((user.DateBirth.indexOf('T') !== -1) ? moment(user.DateBirth).utc() : moment(user.DateBirth, 'MM/DD/YYYY')).format('MMM Do YYYY')}</span>
                        <span className="font-8 mt-2">Gender</span>
                        <span>{user.IdGender === 1 ? 'Male' : 'Female'}</span>
                        <span className="font-8 mt-2">Community</span>
                        <span>{coach_profile.Community || 'N/A'}</span>
                        <span className="font-8 mt-2">Email</span>
                        <span>{user.EmailAddress || coach_profile.EmailAddress || 'N/A'}</span>
                        <span className="font-8 mt-2">Phone</span>
                        <span>{coach_profile.FullPhoneNumber || `${user.CountryCode} ${user.PhoneNumber}` || 'N/A'}</span>
                    </div>
                    <div className="d-flex flex-row px-2 mb-3" onClick={() => this.setState({ adding_coach_profile: true })}>
                        <span className="white sqwad-font">COACH PROFILE</span>
                        <span className="white link ms-auto">Edit</span>
                    </div>
                    <div className="tile card d-flex flex-column mb-3 py-2 px-3" >
                        <span className="font-8">College | Academy | Organization</span>
                        <span>{coach_profile.SchoolName || 'N/A'}</span>
                        <div className="d-flex flex-row">
                            <div className="d-flex flex-column w-100">
                                <span className="font-8 mt-2">Role</span>
                                <span>{coach_profile.Role || 'N/A'}</span>
                            </div>
                            <div className="d-flex flex-column w-100">
                                <span className="font-8 mt-2">Division</span>
                                <span>{coach_profile.Division || 'N/A'}</span>
                            </div>
                        </div>

                        <span className="font-8 mt-2">Location</span>
                        <span>{coach_profile.StateName || 'N/A'}</span>
                        <span className="font-8 mt-2">Conference</span>
                        <span>{coach_profile.Conference || 'N/A'}</span>
                    </div>


                    {coach_profile && false && <div className="tile card d-flex flex-column mb-3 py-2 px-3" >
                        {_.keys(coach_profile).map((key, i) => <span key={i}>{key}: {JSON.stringify(coach_profile[key])}</span>)}
                    </div>}
                    {/**/}
                    <div style={{ height: 100 }} />

                </Animated>
            </div >}


        </div>
    }
    render() {
        const { user, coach_profile } = this.state;
        return (
            <Layout router={this.props.router} who={(user || {}).NameFirst} className="bg-blue-dark" title="SQWAD SCOUT | Prospect" form={this.props.form} form_actions={this.props.form_actions}>
                <section>
                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small" style={{ marginLeft: 200 }}>
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                        <MenuDesktop {...this.state} {...this.props} />
                    </div>
                    {/* MOBILE */}
                    <div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                        <MenuMobile {...this.state} {...this.props} />
                    </div>

                    <Menu {...this.state} {...this.props} />

                    {user && <AddFamily {...this.props} {...this.state} toggle={() => this.setState({ creating_family: false })} />}
                    {coach_profile && <AddCoachProfile {...this.props} {...this.state} toggle={() => this.setState({ adding_coach_profile: false })} />}

                </section >
            </Layout>
        )
    }
}

export default FamilyMember;
