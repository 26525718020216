import React from 'react';
import { Animated } from 'react-animated-css';
import { Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router';
import moment from 'moment';
import { generateRandomId } from '../../../helpers';

class AddCustomPlayer extends React.Component {
    state = { custom_player: null, soccer: null }
    componentWillReceiveProps = nextProps => {
        const { custom_player, communities } = nextProps;
        if (custom_player && custom_player.IdGender) {
            switch (custom_player.IdGender) {
                case 1: custom_player.Gender = 'Male'; break;
                case 2: custom_player.Gender = 'Female'; break;
                default: break;
            }
        }
        let soccer = null;
        if (communities && _.find(communities, c => c.IdCommunity === 1)) soccer = _.find(communities, c => c.IdCommunity === 1);        
        this.setState({ custom_player, soccer });        
    }

    pickGender = (f) => {
        const { custom_player } = this.state;
        custom_player.Gender = f.title;
        custom_player.IdGender = f.value;
        this.setState({ custom_player });
    }

    pickNationality = (f) => {
        const { custom_player } = this.state;
        custom_player.Nationality = f.title;
        this.setState({ custom_player });
    }

    pickAge = (f) => {
        const { custom_player } = this.state;
        custom_player.AgeGroup = f.title;
        this.setState({ custom_player });
    }

    pickPosition = (f) => {
        const { custom_player } = this.state;
        custom_player.Position = f.title;
        this.setState({ custom_player });
    }

    pickFoot = (f) => {
        const { custom_player } = this.state;
        custom_player.Foot = f.title;
        this.setState({ custom_player });
    }

    onSave = () => {        
        const { custom_player } = this.state;

        // TODO: validate at least first and last name
        this.props.fnadd && this.props.fnadd({
            ...custom_player,
            NameFirst: this.txtNameFirst.value,
            NameLast: this.txtNameLast.value,
            JerseyNumber: this.txtJerseyNumber.value,
            Club: this.txtClub.value,
            Id: custom_player.Id || generateRandomId(4)
        });
        this.props.toggle();
    }

    onRemove = () => {
        this.props.fnremove && this.props.fnremove({});
        this.props.toggle();
    }

    render() {
        const { custom_player, soccer } = this.state;
        return <section>
            <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="slideInRight" animationOut="slideOutRight" isVisible={this.props.custom_player ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200 }}>
                <section className="bg-whiteish w-100 p-4" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, paddingTop: 70, overflowY: 'auto' }}>
                    <div style={{ height: 60 }} />
                    {custom_player && <div>
                        <div className="bg-white p-2 col-md-6 col-sm-12 mx-auto p-4">
                            <label>First Name</label>
                            <input className="w3-input w-100" type="text" defaultValue={custom_player.NameFirst} ref={i => this.txtNameFirst = i} />
                            <label>Last Name</label>
                            <input className="w3-input w-100" type="text" defaultValue={custom_player.NameLast} ref={i => this.txtNameLast = i} />
                            <label>Gender</label>
                            <Dropdown isOpen={this.state.pickingGender ? true : false} toggle={() => this.setState({ pickingGender: !this.state.pickingGender })} className="w3-input w-100">
                                <DropdownToggle style={{ borderColor: 'transparent' }} className="text-left d-flex flex-row h-100 py-0 bg-white black w-100 btn btn-link" >
                                    <span className="align-self-center w-100">{custom_player.Gender || 'Select'}</span>
                                    <i className="ms-auto fas fa-caret-down align-self-center " />
                                </DropdownToggle>
                                <DropdownMenu className="w-100" style={{ overflowY: 'auto' }}>
                                    <DropdownItem onClick={() => this.pickGender()}>None</DropdownItem>
                                    <DropdownItem onClick={() => this.pickGender({ title: 'Male', value: 1 })}>Male</DropdownItem>
                                    <DropdownItem onClick={() => this.pickGender({ title: 'Female', value: 2 })}>Female</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <label>Jersey Number</label>
                            <input className="w3-input w-100" type="text" defaultValue={custom_player.JerseyNumber} ref={i => this.txtJerseyNumber = i} />
                            <label>Nationality</label>
                            <Dropdown isOpen={this.state.pickNationality ? true : false} toggle={() => this.setState({ pickNationality: !this.state.pickNationality })} className="w3-input w-100">
                                <DropdownToggle style={{ borderColor: 'transparent' }} className="text-left d-flex flex-row h-100 py-0 bg-white black w3-input w-100" >
                                    <span className="align-self-center w-100">{custom_player.Nationality || 'Select'}</span>
                                    <i className="ms-auto fas fa-caret-down align-self-center " />
                                </DropdownToggle>
                                <DropdownMenu className="w-100" style={{ overflowY: 'auto', maxHeight: 400 }}>
                                    <DropdownItem onClick={() => this.pickNationality()}>None</DropdownItem>
                                    {this.props.countries && this.props.countries.map((c, i) => <DropdownItem key={i} onClick={() => this.pickNationality({ title: c.CountryName, })}>{c.CountryName}</DropdownItem>)}
                                </DropdownMenu>
                            </Dropdown>

                            <label>Age Group</label>
                            <Dropdown isOpen={this.state.pickAge ? true : false} toggle={() => this.setState({ pickAge: !this.state.pickAge })} className="w3-input w-100">
                                <DropdownToggle style={{ borderColor: 'transparent' }} className="text-left d-flex flex-row h-100 py-0 bg-white black w3-input w-100" >
                                    <span className="align-self-center w-100">{custom_player.AgeGroup || 'Select'}</span>
                                    <i className="ms-auto fas fa-caret-down align-self-center " />
                                </DropdownToggle>
                                <DropdownMenu className="w-100" style={{ overflowY: 'auto', maxHeight: 400 }}>
                                    <DropdownItem onClick={() => this.pickAge()}>None</DropdownItem>
                                    {['U06', 'U07', 'U08', 'U06', 'U08', 'U09', 'U10', 'U11', 'U12', 'U13', 'U14', 'U15', 'U16', 'U17', 'U18', 'U19', 'U21', 'U23'].map((c, i) => <DropdownItem key={i} onClick={() => this.pickAge({ title: c, })}>{c}</DropdownItem>)}
                                </DropdownMenu>
                            </Dropdown>

                            <label>Position</label>
                            {soccer && <Dropdown isOpen={this.state.pickPosition ? true : false} toggle={() => this.setState({ pickPosition: !this.state.pickPosition })} className="w3-input w-100">
                                <DropdownToggle style={{ borderColor: 'transparent' }} className="text-left d-flex flex-row h-100 py-0 bg-white black w3-input w-100" >
                                    <span className="align-self-center w-100">{custom_player.Position || 'Select'}</span>
                                    <i className="ms-auto fas fa-caret-down align-self-center " />
                                </DropdownToggle>
                                <DropdownMenu className="w-100" style={{ overflowY: 'auto', maxHeight: 400 }}>
                                    <DropdownItem onClick={() => this.pickPosition()}>None</DropdownItem>
                                    {soccer.roles.map((c, i) => <DropdownItem key={i} onClick={() => this.pickPosition({ title: c.Name, })}>{c.Name}</DropdownItem>)}
                                </DropdownMenu>
                            </Dropdown>}

                            <label>Club</label>
                            <input className="w3-input w-100" type="text" defaultValue={custom_player.Club} ref={i => this.txtClub = i} />

                            <label>Dominant Foot</label>
                            <Dropdown isOpen={this.state.pickFoot ? true : false} toggle={() => this.setState({ pickFoot: !this.state.pickFoot })} className="w3-input w-100">
                                <DropdownToggle style={{ borderColor: 'transparent' }} className="text-left d-flex flex-row h-100 py-0 bg-white black w3-input w-100" >
                                    <span className="align-self-center w-100">{custom_player.Foot || 'Select'}</span>
                                    <i className="ms-auto fas fa-caret-down align-self-center " />
                                </DropdownToggle>
                                <DropdownMenu className="w-100" style={{ overflowY: 'auto', maxHeight: 400 }}>
                                    <DropdownItem onClick={() => this.pickFoot()}>None</DropdownItem>
                                    {['LEFT', 'RIGHT'].map((c, i) => <DropdownItem key={i} onClick={() => this.pickFoot({ title: c, })}>{c}</DropdownItem>)}
                                </DropdownMenu>
                            </Dropdown>

                            <button className={`btn btn-${this.state.saved ? 'success' : 'info'} mt-4 w-100`} type="button" onClick={this.onSave}>{this.state.saved ? 'Added' : 'Add Player'}</button>
                            <button className={`btn red btn-${this.state.saved ? 'link' : 'link'} mt-2 w-100`} type="button" onClick={this.onRemove}>{this.state.saved ? 'Remove Player' : 'Remove Player'}</button>
                            <div style={{ height: 100 }} />

                        </div>
                    </div>}
                </section>
                <div onClick={this.props.toggle} style={{ width: 50, height: 50, borderRadius: 25, position: 'fixed', top: 15, left: 15 }} className="bg-gray d-flex flex-column justify-content-center">
                    <i className="fas fa-times black font-30 align-self-center" />
                </div>
            </Animated>
        </section>
    }
}

export default AddCustomPlayer;