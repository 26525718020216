import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import _ from 'lodash';

class Team extends React.Component {
    state = {}
    componentWillMount = () => {
        const { team } = this.props;
        this.props.scout_actions && this.props.scout_actions.fecthTeam(team.IdTeamDivision);
    }    

    render() {
        const { roster, team, followees, toggle } = this.props;
        return <div>
            <div className="d-flex flex-row bg-white" style={{ borderBottom: `5px solid gray` }}>
                <i onClick={toggle} className="fas pointer fa-arrow-left font-18 black mx-4 align-self-center" />
                <div style={{ width: 150 }} className="d-flex flex-row justify-content-center p-4">
                    <div className="align-self-center bordered contains contain" style={{ borderRadius: 5, width: 100, height: 100, background: `url(${team.Logo || `/images/defaults/generic_crest.png`}) no-repeat center center white` }} />
                </div>
                <div className="w-100 d-flex flex-column justify-content-center">
                    <span className="blue font-18 montserrat toBold">{team.TeamName || team.Name}</span>
                    <span className="black font-12 montserrat">{this.props.season}</span>
                </div>
            </div>
            <section className="w3-content mx-auto ">
                <h3 className="gray syncopate mt-4">COACHES</h3>
                <div className="row w3-container">
                    {_.chain(roster).filter(u => u.IdRole === 4).value().map((user, index) =>
                        <Animated animationInDelay={50} animationIn="fadeIn" className="col-md-6 p-0 w3-cell p-1" key={index}>
                            <div className="px-4 py-3 tile card  d-flex flex-row bordered" >
                                <div className="align-self-center cover" style={{ background: `url(${user.UserImage || user.Image}) no-repeat center center black`, width: 60, height: 60, borderRadius: 30, border: `1px solid gray` }}></div>
                                <div className="d-flex flex-column black ms-4 align-self-center">
                                    <span className="line1 blue font-14">{user.NameFirst} {user.NameLast}</span>
                                    <span className="line1 font-12">{user.EmailAddress}</span>
                                    <span className="line1">{user.RoleOverrideName}</span>
                                </div>
                            </div>
                        </Animated>
                    )}
                </div>

                <h3 className="gray syncopate mt-4">PLAYERS</h3>

                <div className="row w3-container">
                    {_.chain(roster || []).filter(u => u.IdRole === 3).value().map((user, index) =>
                        <Animated animationInDelay={50} animationIn="fadeIn" className="col-md-6 col-lg-4 p-0 w3-cell p-1" key={index}>
                            <Link className="tile card bordered" to={`/${_.find(followees, r => r.IdUserFollow === user.IdUser) ? 'prospect' : 'lead'}/${user.IdUser}`} style={{ overflow: 'hidden' }}>
                                <div className="d-flex flex-row">
                                    <div className="align-self-center cover block" style={{ background: `url(${user.UserImage || user.Image}) no-repeat center center black`, width: 100, height: 90 }}>
                                        {_.find(followees, r => r.IdUserFollow === user.IdUser) && <i className="fas fa-check-circle green ms-1 font-12" />}
                                    </div>
                                    <div className="black align-self-center w-100 p-3 d-flex flex-column">
                                        <div className="d-flex flex-row">
                                            <span className="line1 align-self-center font-10 w-100">#{user.JerseyNumber}</span>
                                            <span style={{ minWidth: 100 }} className="line1 align-self-center font-10 ms-auto max-lines-1 text-right">{user.GraduationYear}</span>
                                        </div>
                                        <span className="line1 font-16 mt-1 text-center align-self-center w-100">{user.NameFirst}<br />{user.NameLast}</span>
                                    </div>
                                </div>
                                <div className={`p-1 bg-${user.Highlights.length ? 'success' : 'gray'} font-8 text-center white`}>HIGHLIGHTS</div>
                            </Link>
                        </Animated>
                    )}
                    <div className="col-12 p-4" />
                </div>
            </section>
        </div>
    }
}

export default Team;