import React from 'react';
import Layout from '../layouts/claire';
import { preload } from '../../helpers';
import { Animated } from 'react-animated-css';
import Menu from '../home/partials/menu';

class PSFA extends React.Component {
    state = {
        menu_toggled: false
    }
    renderCommon = () => {
        return (
            <div className="w3-content d-flex flex-column px-4 container pt-4">
                <img src="/images/logos/psfa.png" className="w-50 mx-auto" />
                <p className="contrast font-16 montserrat mt-4" >
                    The Professional Football Scouts Association are the leading providers of scouting and analysis courses to clubs in the UK and overseas.
                </p>
                <p className="contrast font-16 montserrat" >
                    We are the only providers featured in as many articles and programmes such as the Independent Sport, Guardian, Metro, Goal, BBC Radio, Training Ground Guru, Sky Sports and the BBC to name a few.
                </p>
                <p className="contrast font-16 montserrat" >
                    At the PFSA we specialise in educating both new and experienced football scouts and analysts, whether its your first time looking to scout for a non-league club to a full time professional at a CAT 1 Academy.
                </p>
                <p className="contrast font-16 montserrat" >
                    We have  certified and received recommendations from some of the biggest names in football including Ryan Giggs, Shaun Goater, Kelechi Iheanacho, Asmir Begovich and leading backroom figures across the globe.
                </p>
                <p className="contrast font-16 montserrat" >
                    Formed in 2010 by two (2) Premier League Scouts with decades of experience within elite talent identification. The PFSA as an association aims to liaise with all governing bodies Worldwide and through professional / non-professional leagues on behalf of all member scouts promoting best practice, raising standards both physically through our members' treatment and education.
                </p>
                <p className="contrast font-16 montserrat" >
                    The association's main aim is to set out the relevant systems and procedures on a level consistent with the introduction of the Elite Player Performance Plan (EPPP).
                </p>
                <p className="contrast font-16 montserrat" >
                    Members of the association will be committed to the association's agreed code of conduct, and the rules & regulations set out by their respective football governing bodies.
                </p>
                <p left="0" className="contrast font-30 montserrat-bold" top="40">
                    Membership
                </p>
                <p className="contrast font-16 montserrat" >
                    We are pleased to announce the arrival of a revamped membership option for our members at the Professional Football Scouts Association. Membership to the association will have two (2) separate options: The first will involve an "Associate Membership" and secondly the more advanced "PFSA Certified Membership"
                </p>
                <p left="0" className="contrast font-20 montserrat-bold" >
                    PFSA Associate Membership
                </p>
                <p className="contrast font-16 montserrat" >
                    The association membership is for PFSA Level 1 certified members or any other accreditations at such level. This particular membership option will highlight the users attachment to the PFSA and compliance with the PFSA code of conduct. It will also include numerous discounts on PFSA Scouting Courses and unique offers from our feature partners to name a few.
                </p>
                <p left="0" className="contrast font-20 montserrat-bold" >
                    PFSA Certified Membership
                </p>
                <p className="contrast font-16 montserrat" >
                    The PFSA Certified Membership is a global database of certified talent identification, opposition analysis, technical scouting and performance analysis users. In order to qualify for this membership you must be as a minimum PFSA Level 2 certified in any of the above disciplines. Benefits included are vast, including heavily discounted PFSA Scouting Courses, free webinars and workshops and unique benefits from our featured partner applications and software.
                </p>
                <p left="0" className="contrast font-30 montserrat-bold" top="40">
                    Courses
                </p>
                <p className="contrast font-16 montserrat" >
                    As the leading provider of Talent Identification, Opposition Analysis, Technical Scouting and Performance Analysis in football courses, nationally and abroad, we deliver both online and in-person courses across the UK, Europe, Australia, America, Africa and the UAE.
                </p>
                <p className="contrast font-16 montserrat" >
                    Every course is passionately delivered by a qualified, experienced tutor - including elite senior football scouts from the English Premier and Football League. We proudly join forces with numerous Premier League Clubs to deliver unprecedented Talent Identification training, while guiding and supporting thousands of association members.
                </p>
                <p className="contrast font-16 montserrat" >
                    The PFSA Education's online and practical portfolio is the only programme exclusively endorsed by Instat, one of the worlds leading video analysis platforms, CPD accredited and has been taken by some of the UK and overseas top professionals within the game while also featuring in some of the worlds leading news outlets and Universities including; UCFB - Leading Footballing University, The Guardian, Independent, BBC, The Metro, Training Ground Guru and The Sun to name a few.
                </p>
                <p left="0" className="contrast font-20 montserrat-bold" >
                    Entry / Internediate
                </p>
                <p left="0" className="contrast font-16 montserrat" >
                    Foundation Courses - 2/3 hours - Self Paced:
                </p>

                <p left="0" className="contrast font-16 montserrat">
                    • Level 1 Talent Identification in Football
                </p>
                <p left="0" className="contrast font-16 montserrat">
                    • Level 1 Opposition Analysis in Football
                </p>
                <p left="0" className="contrast font-16 montserrat">
                    • Level 1 Technical Scouting in Football
                </p>
                <p left="0" className="contrast font-16 montserrat">
                    • Level 1 Performance Analysis in Football
                </p>
                <p left="0" className="contrast font-16 montserrat">
                    • Level 1 Data Analysis in Football
                </p>
                <p left="0" className="contrast font-16 montserrat">
                    • Introduction into Football Scouting.
                </p>

                <p left="0" className="contrast font-20 montserrat-bold" >
                    Professional
                </p>
                <p left="0" className="contrast font-16 montserrat" >
                    Classroom, Zoom and Self-Paced options:
                </p>

                <p left="0" className="contrast font-16 montserrat">
                    • Level 2 Talent Identification in Football
                </p>
                <p left="0" className="contrast font-16 montserrat">
                    • Level 3 Advanced Reporting
                </p>
                <p left="0" className="contrast font-16 montserrat">
                    • Level 2 Opposition Analysis in Football
                </p>
                <p left="0" className="contrast font-16 montserrat">
                    • Level 2 Technical Analysis in Football
                </p>
                <p left="0" className="contrast font-16 montserrat">
                    • Level 2 Performance Analysis in Football
                </p>
                <p left="0" className="contrast font-16 montserrat">
                    • Level 3 Performance Analysis in Football
                </p>
                <p left="0" className="contrast font-16 montserrat">
                    • Analysis & Recruitment in Women's Football
                </p>
                <p left="0" className="contrast font-16 montserrat">
                    • PFSA Scouting Programme.
                </p>

                {!this.state.booking && <button className="btn btn-success" onClick={() => this.setState({ booking: true })}>BOOK A COURSE</button>}
                {this.state.booking && <Animated animationIn="fadeInRight" className="">
                    <hr />
                    <a href="https://thepfsa.co.uk/level-1-talent-identification" target="_blank" className="tile card p-2 mt-3 d-flex flex-row" style={{ backgroundColor: '#28153D', height: 80 }}>
                        <img src="/images/logos/psfa.png" className="h-100 w-auto" />
                        <div className="d-flex flex-column align-self-center ms-2">
                            <span className="white font-18">Training Level 1</span>
                            <span className="white">Talent Identification in Football</span>
                        </div>
                        <i className="fas fa-chevron-right white align-self-center ms-auto me-2" />
                    </a>


                    <a href="https://thepfsa.co.uk/opposition-analysis-in-football" target="_blank" className="tile card p-2 mt-3 d-flex flex-row" style={{ backgroundColor: '#28153D', height: 80 }}>
                        <img src="/images/logos/psfa.png" className="h-100 w-auto" />
                        <div className="d-flex flex-column align-self-center ms-2">
                            <span className="white font-18">Training Level 1</span>
                            <span className="white">Opposition Analysis in Football</span>
                        </div>
                        <i className="fas fa-chevron-right white align-self-center ms-auto me-2" />
                    </a>

                    <a href="https://thepfsa.co.uk/level-1-technical-scouting-in-football" target="_blank" className="tile card p-2 mt-3 d-flex flex-row" style={{ backgroundColor: '#28153D', height: 80 }}>
                        <img src="/images/logos/psfa.png" className="h-100 w-auto" />
                        <div className="d-flex flex-column align-self-center ms-2">
                            <span className="white font-18">Training Level 1</span>
                            <span className="white">Technical Scouting in Football</span>
                        </div>
                        <i className="fas fa-chevron-right white align-self-center ms-auto me-2" />
                    </a>

                    <a href="https://thepfsa.co.uk/level-1-performance-analysis" target="_blank" className="tile card p-2 mt-3 d-flex flex-row" style={{ backgroundColor: '#28153D', height: 80 }}>
                        <img src="/images/logos/psfa.png" className="h-100 w-auto" />
                        <div className="d-flex flex-column align-self-center ms-2">
                            <span className="white font-18">Training Level 1</span>
                            <span className="white">Performance Analysis in Football</span>
                        </div>
                        <i className="fas fa-chevron-right white align-self-center ms-auto me-2" />
                    </a>

                    <a href="https://thepfsa.co.uk/level-1-data-analysis-in-football" target="_blank" className="tile card p-2 mt-3 d-flex flex-row" style={{ backgroundColor: '#28153D', height: 80 }}>
                        <img src="/images/logos/psfa.png" className="h-100 w-auto" />
                        <div className="d-flex flex-column align-self-center ms-2">
                            <span className="white font-18">Training Level 1</span>
                            <span className="white">Data Analysis in Football</span>
                        </div>
                        <i className="fas fa-chevron-right white align-self-center ms-auto me-2" />
                    </a>

                    <a href="https://thepfsa.co.uk/level-2-online" target="_blank" className="tile card p-2 mt-3 d-flex flex-row" style={{ backgroundColor: '#28153D', height: 80 }}>
                        <img src="/images/logos/psfa.png" className="h-100 w-auto" />
                        <div className="d-flex flex-column align-self-center ms-2">
                            <span className="white font-18">Training Level 2</span>
                            <span className="white">Talent Identification in Football</span>
                        </div>
                        <i className="fas fa-chevron-right white align-self-center ms-auto me-2" />
                    </a>

                    <a href="https://thepfsa.co.uk/psfa-level-2-opposition-analysis-in-football" target="_blank" className="tile card p-2 mt-3 d-flex flex-row" style={{ backgroundColor: '#28153D', height: 80 }}>
                        <img src="/images/logos/psfa.png" className="h-100 w-auto" />
                        <div className="d-flex flex-column align-self-center ms-2">
                            <span className="white font-18">Training Level 2</span>
                            <span className="white">Opposition Analysis in Football</span>
                        </div>
                        <i className="fas fa-chevron-right white align-self-center ms-auto me-2" />
                    </a>

                    <a href="https://thepfsa.co.uk/level-2-performance-analysis" target="_blank" className="tile card p-2 mt-3 d-flex flex-row" style={{ backgroundColor: '#28153D', height: 80 }}>
                        <img src="/images/logos/psfa.png" className="h-100 w-auto" />
                        <div className="d-flex flex-column align-self-center ms-2">
                            <span className="white font-18">Training Level 2</span>
                            <span className="white">Performance Analysis in Football</span>
                        </div>
                        <i className="fas fa-chevron-right white align-self-center ms-auto me-2" />
                    </a>

                    <a href="https://thepfsa.co.uk/level-2-technical-scouting" target="_blank" className="tile card p-2 mt-3 d-flex flex-row" style={{ backgroundColor: '#28153D', height: 80 }}>
                        <img src="/images/logos/psfa.png" className="h-100 w-auto" />
                        <div className="d-flex flex-column align-self-center ms-2">
                            <span className="white font-18">Training Level 2</span>
                            <span className="white">Tecnical Analysis in Football</span>
                        </div>
                        <i className="fas fa-chevron-right white align-self-center ms-auto me-2" />
                    </a>


                </Animated>}
                <div className="p-4" />
                <div className="p-4" />
                <div className="p-4" />
                <div className="p-4" />
                <div className="p-4" />
                <div className="p-4" />
            </div>
        )
    }
    render() {
        const form = this.props.form_actions;
        return (
            <Layout router={this.props.router} className="bg-white" nbs="home" title="SQWAD SCOUT | PSFA" form={this.props.form} form_actions={this.props.form_actions}>
                <section>

                    <div className="w3-container w3-hide-small" style={{ marginLeft: 200 }}>
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                    </div>
                    {/* MOBILE */}
                    <div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                    </div>

                    <Menu {...this.state} {...this.props} />
                </section>
            </Layout>
        )
    }
}

export default PSFA;
