import React from 'react';
import { Link } from 'react-router';

const Logo = ({ className, who }) => {
    return (
        <Link to="/" className={`align-self-center d-flex flex-row ${className}`}>
            <span className="align-self-center white font-30 sqwad-font w3-hide-small toUpper" >{who}</span>
            <span className="align-self-center white font-14 sqwad-font w3-hide-medium w3-hide-large mr-3 toUpper" >{who}</span>
        </Link>
    );
}

export default Logo;
