import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';

class Menu extends React.Component {
    state = {
        menu_toggled: false
    }
    componentWillMount() {
        this.setState({
            menu_toggled: this.props.menu_toggled,
            path: _.chain(this.props.router.getCurrentLocation()).value().pathname.split('/'),
        });
    }
    render() {
        const { hide } = this.props;
        return <section>
            {false && <div onClick={() => this.setState({ menu_toggled: !this.state.menu_toggled })} style={{ width: 50, height: 50, borderRadius: 25, position: 'absolute', top: 65, left: 10 }} className="bg-blue-dark d-flex flex-row justify-content-center mt-4">
                <i className="fas fa-bars align-self-center white font-20" />
            </div>}
            <Animated animateOnMount={false} animationInDuration={400} animationOutDuration={400} animationIn="slideInLeft" animationOut="slideOutLeft" isVisible={true || this.state.menu_toggled} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, width: 200, paddingTop: 70 }} className="w3-hide-small">
                <section className="bg-whiteish d-flex flex-row" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, paddingTop: 70, width: 200 }}>
                    {!hide && <div className="w-100 d-flex flex-column p-2">
                        <Link to="/dashboard" className={`w-100 bg-${this.state.path.indexOf('dashboard') !== -1 ? 'blue-dark white' : 'white jeff_blue'} rounded d-flex flex-row justify-content-start`} style={{ height: 50 }}>
                            <i className="icon-home font-16 align-self-center ms-2" /><span className="syncopate font-8 align-self-center text-center ms-2">HOME</span>
                        </Link>
                        <Link to="/network" className={`w-100 bg-${this.state.path.indexOf('network') !== -1 ? 'blue-dark white' : 'white jeff_blue'} rounded d-flex flex-row justify-content-start mt-2`} style={{ height: 50 }}>
                            <i className="icon-share-2 font-16 align-self-center ms-2" /><span className="syncopate font-8 align-self-center text-center ms-2">MY NETWORK</span>
                        </Link>
                        <Link to="/scouting" className={`w-100 bg-${this.state.path.indexOf('scouting') !== -1 ? 'blue-dark white' : 'white jeff_blue'} rounded d-flex flex-row justify-content-start mt-2`} style={{ height: 50 }}>
                            <i className="icon-sliders font-16 align-self-center ms-2" /><span className="syncopate font-8 align-self-center text-center ms-2">SCOUTING</span>
                        </Link>
                        <Link to="/recruits" className={`w-100 bg-${this.state.path.indexOf('recruits') !== -1 ? 'blue-dark white' : 'white jeff_blue'} rounded d-flex flex-row justify-content-start mt-2`} style={{ height: 50 }}>
                            <i className="icon-search font-16 align-self-center ms-2" /><span className="syncopate font-8 align-self-center text-center ms-2">RECRUITS</span>
                        </Link>
                        <Link to="/scouting_events" className={`w-100 bg-${this.state.path.indexOf('recruits') !== -1 ? 'blue-dark white' : 'black white'} rounded d-flex flex-row justify-content-start mt-2`} style={{ height: 50 }}>
                            <i className="fas fa-layer-group font-16 align-self-center ms-2" /><span className="syncopate font-8 align-self-center text-center ms-2">SCOUTING EVENTS</span>
                        </Link>
                        {/**/}
                        {!this.state.toggle && <div onClick={() => this.setState({ toggle: true })} className={`w-100 rounded d-flex flex-row justify-content-center mt-2`} style={{ height: 30, backgroundColor: 'transparent' }}>
                            <span className="syncopate font-10 align-self-center text-center ms-1">+ MORE</span>
                        </div>}
                        {this.state.toggle && <section className="d-flex flex-column">
                            <Link to="/family" className={`w-100 bg-${this.state.path.indexOf('family') !== -1 ? 'blue-dark white' : 'white jeff_blue'} rounded d-flex flex-row justify-content-start mt-2`} style={{ height: 50 }}>
                                <i className="icon-users font-16 align-self-center ms-2" /><span className="syncopate font-8 align-self-center text-center ms-2">PROFILE</span>
                            </Link>
                            <a href="https://www.thesqwad.com/referral/10" target="_blank" className={`w-100 bg-white jeff_blue rounded d-flex flex-row justify-content-start mt-2`} style={{ height: 50 }}>
                                <i className="icon-share font-16 align-self-center ms-2" /><span className="syncopate font-8 align-self-center text-center ms-2">APPS</span>
                            </a>
                            {/*<Link to="/psfa" className={`w-100 rounded d-flex flex-row justify-content-start mt-2`} style={{ height: 50, backgroundColor: '#28153D' }}>
                                <img src="/images/logos/psfa.png" className="h-100 ms-2" />
                                <span className="syncopate font-8 align-self-center text-center ms-2 white">PSFA</span>
    </Link>*/}
                        </section>}
                    </div>}
                </section>
            </Animated>
        </section>
    }
}

export default Menu;