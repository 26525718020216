import React from 'react';
import { Link } from 'react-router';


const MenuDesktop = ({ path = [], router, user = {} }) => {
    return (
        <div style={{ position: 'fixed', left: 0, top: 70, width: 200, zIndex: 100, bottom: 0 }} className="py-2 px-2 bg-whiteish w3-hide-small">
            {user && <div className="d-flex flex-column">
                <a onClick={() => { router.goBack && router.goBack() }} className={`bg-${path.indexOf('nope') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-row justify-content-start`} style={{ height: 50 }}>
                    <i className="fas fa-arrow-left font-16 align-self-center ms-2" />
                    <span className="align-self-center font-8 syncopate ms-2">BACK</span>
                </a>
                <Link to={`/lead/${user.IdUser || user.IdUserFollow}`} className={`bg-${path.indexOf('lead') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-row justify-content-start mt-2`} style={{ height: 50 }}>
                    <i className="icon-info font-16 align-self-center ms-2" />
                    <span className="align-self-center font-8 syncopate ms-2">INFO</span>
                </Link>
                <Link to={`/lead_highlights/${user.IdUser || user.IdUserFollow}`} className={`bg-${path.indexOf('lead_highlights') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-row justify-content-start mt-2`} style={{ height: 50 }}>
                    <i className="icon-film-reel-1 font-16 align-self-center ms-2" />
                    <span className="align-self-center font-8 syncopate ms-2">HIGHLIGHTS</span>
                </Link>
                <Link to={`/lead_evaluations/${user.IdUser || user.IdUserFollow}`} className={`bg-${path.indexOf('lead_evaluations') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-row justify-content-start mt-2`} style={{ height: 50 }}>
                    <i className="icon-checklist font-16 align-self-center ms-2" />
                    <span className="align-self-center font-8 syncopate ms-2">EVALUATIONS</span>
                </Link>
            </div>}
        </div>
    );
}

export default MenuDesktop;
