function collaborators(state = [], action) {
    switch (action.type) {
        case 'COLLABORATORS/SET':
            return action.collaborators;
        case 'COLLABORATORS/CLEAR':
            return null;
        default:
            return state;
    }
}

export default collaborators;