import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import moment from 'moment';
import Switch from "react-switch";

class Review extends React.Component {
    state = { review: null }
    componentWillReceiveProps = nextProps => {
        const { review } = nextProps;        
        this.setState({ review });        
    }

    onSave = () => {
        const { review } = this.state;
        let payload = {
            ...review,
            Overview: this.txtOverview.value,
            TechnicalStrenghts: this.txtTechnicalStrenghts.value,
            TechnicalImprove: this.txtTechnicalImprove.value,
            TacticalStrenghts: this.txtTacticalStrenghts.value,
            TacticalImprove: this.txtTacticalImprove.value,
            PhysicalStrenghts: this.txtPhysicalStrenghts.value,
            PhysicalImprove: this.txtPhysicalImprove.value,
            MentalStrenghts: this.txtMentalStrenghts.value,
            MentalImprove: this.txtMentalImprove.value,
            Notes: this.txtNotes.value,
            EvaluationDate: moment().format(),
        }        
        this.props.scout_actions && this.props.scout_actions.saveCustomPlayerReview(payload);
        this.setState({ saved: true });

    }
    render() {
        const { review } = this.state;        
        return <section>
            <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="slideInRight" animationOut="slideOutRight" isVisible={this.state.review ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200 }}>
                {true && <section className="bg-whiteish d-flex flex-column w-100 p-4" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, paddingTop: 70, overflowY: 'auto' }}>

                {review && <form className="col-md-6 col-sm-12 align-self-center">

                        <div className="w3-card">
                            <div className="w3-container bg-white p-4">
                                <label>Overview</label>
                                <textarea className="w3-input" type="text" defaultValue={review.Overview} ref={i => this.txtOverview = i} />
                            </div>
                        </div>

                        <div className="w3-card mt-3">
                            <div className="px-2 py-1 w3-gray white">
                                <h2 className="sqwad-font m-0">TECHNICAL</h2>
                            </div>
                            <div className="w3-container bg-white p-4">
                                <label>Strenghts</label>
                                <textarea className="w3-input" type="text" defaultValue={review.TechnicalStrenghts} ref={i => this.txtTechnicalStrenghts = i} />
                                <label>Areas to Improve</label>
                                <textarea className="w3-input" type="text" defaultValue={review.TechnicalImprove} ref={i => this.txtTechnicalImprove = i} />
                            </div>
                        </div>

                        <div className="w3-card mt-3">
                            <div className="px-2 py-1 w3-gray white">
                                <h2 className="sqwad-font m-0">TACTICAL</h2>
                            </div>
                            <div className="w3-container bg-white p-4">
                                <label>Strenghts</label>
                                <textarea className="w3-input" type="text" defaultValue={review.TacticalStrenghts} ref={i => this.txtTacticalStrenghts = i} />
                                <label>Areas to Improve</label>
                                <textarea className="w3-input" type="text" defaultValue={review.TacticalImprove} ref={i => this.txtTacticalImprove = i} />
                            </div>
                        </div>

                        <div className="w3-card mt-3">
                            <div className="px-2 py-1 w3-gray white">
                                <h2 className="sqwad-font m-0">PHYSICAL</h2>
                            </div>
                            <div className="w3-container bg-white p-4">
                                <label>Strenghts</label>
                                <textarea className="w3-input" type="text" defaultValue={review.PhysicalStrenghts} ref={i => this.txtPhysicalStrenghts = i} />
                                <label>Areas to Improve</label>
                                <textarea className="w3-input" type="text" defaultValue={review.PhysicalImprove} ref={i => this.txtPhysicalImprove = i} />
                            </div>
                        </div>

                        <div className="w3-card mt-3">
                            <div className="px-2 py-1 w3-gray white">
                                <h2 className="sqwad-font m-0">PSYCHOLOGICAL | SOCIAL</h2>
                            </div>
                            <div className="w3-container bg-white p-4">
                                <label>Strenghts</label>
                                <textarea className="w3-input" type="text" defaultValue={review.MentalStrenghts} ref={i => this.txtMentalStrenghts = i} />
                                <label>Areas to Improve</label>
                                <textarea className="w3-input" type="text" defaultValue={review.MentalImprove} ref={i => this.txtMentalImprove = i} />
                            </div>
                        </div>

                        <div className="w3-card mt-3">
                            <div className="px-2 py-1 w3-gray white">
                                <h2 className="sqwad-font m-0">NEXT STEPS</h2>
                            </div>
                            <div className="w3-container bg-white p-4">

                                <div className="d-flex flex-row">
                                    <Switch onChange={() => {
                                        const { review } = this.state;
                                        review.NextSteps = 'Sign';
                                        this.setState({ review });
                                    }} checked={review.NextSteps === 'Sign' ? true : false} />
                                    <span className="align-self-center ms-2">Sign</span>
                                </div>
                                <div className="d-flex flex-row mt-2">
                                    <Switch onChange={() => {
                                        const { review } = this.state;
                                        review.NextSteps = 'Continue to monitor';
                                        this.setState({ review });
                                    }} checked={review.NextSteps === 'Continue to monitor' ? true : false} />
                                    <span className="align-self-center ms-2">Continue to monitor</span></div>
                                <div className="d-flex flex-row mt-2">
                                    <Switch onChange={() => {
                                        const { review } = this.state;
                                        review.NextSteps = 'Discard';
                                        this.setState({ review });
                                    }} checked={review.NextSteps === 'Discard' ? true : false} />
                                    <span className="align-self-center ms-2">Discard</span>
                                </div>

                                <label className="mt-2">Notes</label>
                                <textarea className="w3-input" type="text" defaultValue={review.Notes} ref={i => this.txtNotes = i} />

                            </div>
                        </div>

                        <button className={`btn btn-${this.state.saved ? 'success' : 'info'} mt-4 w-100`} type="button" onClick={this.onSave}>{this.state.saved ? 'Review saved!' : 'Save Review'}</button>
                    </form>}

                </section>}
                <div onClick={this.props.toggle} style={{ width: 50, height: 50, borderRadius: 25, position: 'fixed', top: 15, left: 15 }} className="bg-gray d-flex flex-column justify-content-center">
                    <i className="fas fa-times black font-30 align-self-center" />
                </div>
            </Animated>
        </section>
    }
}

export default Review;