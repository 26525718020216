export function fetchCountries(){
    return {
        type: 'COUNTRIES/FETCH'
    }
}

export function clearStates() {
    return {
        type: 'STATES/CLEAR'
    }
}

export function fetchStates(idCountry) {
    return {
        type: 'STATES/FETCH',
        idCountry
    }
}

export function fetch_disclaimer(idSeason) { return { type: 'DISCLAIMER/FETCH', idSeason } }