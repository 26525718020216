import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import moment from 'moment';
import Switch from "react-switch";

class ReviewReadOnly extends React.Component {

    state = { review_read_only: null }
    componentWillReceiveProps = nextProps => {
        const { review_read_only } = nextProps;
        this.setState({ review_read_only });
    }
    render() {
        const { review_read_only } = this.state;
        return <section>
            <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="slideInRight" animationOut="slideOutRight" isVisible={this.state.review_read_only ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200 }}>
                {true && <section className="bg-whiteish d-flex flex-column w-100 p-4" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, paddingTop: 70, overflowY: 'auto' }}>

                    {review_read_only && <form className="col-md-6 col-sm-12 align-self-center">

                        <div className="w3-card">
                            <div className="w3-container bg-white p-4">
                                <label>Overview</label>
                                <label className="w3-input">{review_read_only.Overview}</label>
                            </div>
                        </div>

                        <div className="w3-card mt-3">
                            <div className="px-2 py-1 w3-gray white">
                                <h2 className="sqwad-font m-0">TECHNICAL</h2>
                            </div>
                            <div className="w3-container bg-white p-4">
                                <label>Strenghts</label>
                                <label className="w3-input">{review_read_only.TechnicalStrenghts}</label>
                                <label>Areas to Improve</label>
                                <label className="w3-input">{review_read_only.TechnicalImprove}</label>
                            </div>
                        </div>

                        <div className="w3-card mt-3">
                            <div className="px-2 py-1 w3-gray white">
                                <h2 className="sqwad-font m-0">TACTICAL</h2>
                            </div>
                            <div className="w3-container bg-white p-4">
                                <label>Strenghts</label>
                                <label className="w3-input">{review_read_only.TacticalStrenghts}</label>
                                <label>Areas to Improve</label>
                                <label className="w3-input">{review_read_only.TacticalImprove}</label>
                            </div>
                        </div>

                        <div className="w3-card mt-3">
                            <div className="px-2 py-1 w3-gray white">
                                <h2 className="sqwad-font m-0">PHYSICAL</h2>
                            </div>
                            <div className="w3-container bg-white p-4">
                                <label>Strenghts</label>
                                <label className="w3-input">{review_read_only.PhysicalStrenghts}</label>
                                <label>Areas to Improve</label>
                                <label className="w3-input">{review_read_only.PhysicalImprove}</label>
                            </div>
                        </div>

                        <div className="w3-card mt-3">
                            <div className="px-2 py-1 w3-gray white">
                                <h2 className="sqwad-font m-0">PSYCHOLOGICAL | SOCIAL</h2>
                            </div>
                            <div className="w3-container bg-white p-4">
                                <label>Strenghts</label>
                                <label className="w3-input">{review_read_only.MentalStrenghts}</label>
                                <label>Areas to Improve</label>
                                <label className="w3-input">{review_read_only.MentalImprove}</label>
                            </div>
                        </div>

                        <div className="w3-card mt-3">
                            <div className="px-2 py-1 w3-gray white">
                                <h2 className="sqwad-font m-0">NEXT STEPS</h2>
                            </div>
                            <div className="w3-container bg-white p-4">
                                <label className="w3-input">{review_read_only.NextSteps}</label>
                                <label className="mt-2">Notes</label>
                                <label className="w3-input">{review_read_only.Notes}</label>
                            </div>
                        </div>
                    </form>}

                </section>}
                <div onClick={this.props.toggle} style={{ width: 50, height: 50, borderRadius: 25, position: 'fixed', top: 15, left: 15 }} className="bg-gray d-flex flex-column justify-content-center">
                    <i className="fas fa-times black font-30 align-self-center" />
                </div>
            </Animated>
        </section>
    }
}

export default ReviewReadOnly;