import _ from 'lodash';

function emails(state = [], action) {
  let index = 0;
  switch (action.type) {
    case 'EMAILS/SET':
      return action.emails;
    case 'EMAILS/CLEAR':
      return null;
    case 'EMAILS/ADD':
      return [...state, action.email];
    case 'EMAILS/DELETE':
      index = _.findIndex(state, email => {
        return email.IdEmailAddress === action.idEmailAddress;
      });
      if (index !== -1)
        return [...state.slice(0, index), ...state.slice(index + 1)];
      else return state;
    case 'EMAILS/COMPLETE':
      index = _.findIndex(state, email => {
        return email.temporalId === action.email.temporalId;
      });
      if (index !== -1)
        return [...state.slice(0, index), action.email, ...state.slice(index + 1)];
      else return state;
    case 'EMAILS/UPDATE':
      index = _.findIndex(state, email => email.IdEmailAddress === action.email.IdEmailAddress);
      if (index !== -1)
        return [...state.slice(0, index), action.email, ...state.slice(index + 1)];
      else return state;
    default:
      return state;
  }
}

export default emails;