import React from 'react';
import Layout from '../layouts/claire';
import { Animated } from 'react-animated-css';
import Menu from '../home/partials/menu';
import { Link } from 'react-router';
import Teams from './partials/teams';
import fakeAuth from '../../fakeAuth';
import FollowSeasons from './seasons/list';

class Network extends React.Component {
    state = {
        menu_toggled: false,
        mode: parseInt(localStorage.getItem('sqwadrc.network.mode') || 1, 10)
    }
    componentWillMount() {
        this.props.scout_actions && this.props.scout_actions.fetchCountries();
        this.props.scout_actions && this.props.scout_actions.fetchCommunities();
        this.props.scout_actions && this.props.scout_actions.fetchFollowees();
        this.props.scout_actions && this.props.scout_actions.fetchTeams();
        this.props.scout_actions && this.props.scout_actions.fetchFollowSeasons();
    }
    componentDidMount() {
        this.props.scout_actions && this.props.scout_actions.fetchFolders();
        this.props.scout_actions && this.props.scout_actions.clearFolder();
    }
    componentWillReceiveProps = nextProps => {
        const { folders } = nextProps;
        this.setState({ folders });
    }

    onMenu = (folder, e) => {
        e.preventDefault && e.preventDefault();
        e.stopPropagation && e.stopPropagation();
    }

    toggle_folder_menu = (index, e) => {
        e && e.preventDefault && e.preventDefault();
        e && e.stopPropagation && e.stopPropagation();

        const { folders } = this.state;

        _.chain(folders).filter(f => [3, 4, 5].indexOf(f.IdFolderType) === -1)
            .filter(f => f.IdUser === fakeAuth.getUser().IdUser)
            .sortBy('FolderName')
            .value()[index].menu =
            !_.chain(folders).filter(f => [3, 4, 5].indexOf(f.IdFolderType) === -1)
                .filter(f => f.IdUser === fakeAuth.getUser().IdUser)
                .sortBy('FolderName')
                .value()[index].menu;
        this.setState({ folders });
    }

    onEditFolder = () => {
        this.props.scout_actions && this.props.scout_actions.create_folder({
            FolderName: this.state.edit_folder_name || this.state.editing_folder.Name || this.state.editing_folder.FolderName,
            IdFolderType: this.state.editing_folder.IdFolderType,
            IdFolder: this.state.editing_folder.IdFolder
        });
        this.setState({ editing_folder: false }); // Mode 2 to switch to the folders tab
    }

    onRemoveFolder = (folder) => {
        const { folders } = this.state;
        this.setState({ folders: _.reject(folders, f => f.IdFolder === folder.IdFolder) });
        this.props.scout_actions.delete_folder(folder.IdFolder);
    }

    renderFolders = () => {
        const { mode, folders } = this.state;
        let the_folders = _.chain(folders).filter(f => [3, 4, 5].indexOf(f.IdFolderType) === -1)
            .filter(f => f.IdUser === fakeAuth.getUser().IdUser)
            .sortBy('FolderName')
            .value(),
            shared_folders = _.chain(folders).filter(f => [3, 4, 5].indexOf(f.IdFolderType) === -1)
                .filter(f => f.IdUser !== fakeAuth.getUser().IdUser)
                .sortBy('FolderName')
                .value();

        return (
            <section>
                <div className="row mt-4 mx-0">
                    <input className="w-100 p-2 mb-4 tile card" type="text" name="email" placeholder="Search" ref={(input) => this.txtSearch = input} />
                </div>
                <div className="row mx-0">

                    {/* ALL PLAYERS */}
                    <Animated animationInDelay={0} animationIn="fadeIn" className="col-sm-6 col-md-3 p-0 w3-container w3-cell p-1">
                        <Link className="w-100  py-2 tile card d-flex flex-row h-100" to={`/network/folder`}>
                            <div className="px-2 ms-auto pointer" style={{ width: 50 }} ><i className="ms-auto white icon-more-vertical" /></div>
                            <div className="d-flex flex-column justify-content-center w-100 py-2">
                                <i className="icon-folder black text-center font-70" />
                                <span className="line1 mt-2 font-10 black text-center">ALL PLAYERS</span>
                            </div>
                            <div className="px-2 ms-auto pointer" style={{ width: 50, height: 50 }}><i className="ms-auto white icon-more-vertical" /></div>
                        </Link>
                    </Animated>

                    {/* REST OF FOLDERS */}
                    {the_folders && _.chain(the_folders).value().map((folder, i) =>
                        <Animated key={i} animationInDelay={25 * i} animationIn="fadeInRight" className="col-sm-6 col-md-3 p-1 w3-container w3-cell">

                            <Link className="w-100  py-2 tile card d-flex flex-row h-100" to={`/network/folder/${folder.IdFolder}`}>
                                <div className="px-2 ms-auto pointer" style={{ width: 50 }} ><i className="ms-auto white icon-more-vertical" /></div>
                                <div className="d-flex flex-column justify-content-center w-100 py-2">
                                    <i className="icon-folder black text-center font-70" />
                                    <span className="line1 mt-2 font-10 black text-center">{folder.FolderName}</span>
                                </div>
                                <div className="px-2 ms-auto pointer" style={{ width: 50, height: 50 }} onClick={(e) => this.toggle_folder_menu(i, e)}><i className="ms-auto black icon-more-vertical" /></div>
                                {folder.shared && <div style={{ width: 30, height: 30, position: 'absolute', top: 0, left: 0 }} className="d-flex flex-row justify-content-center bg-dark-blue">
                                    <i className="white font-10 icon-user-plus align-self-center" />
                                </div>}
                            </Link>

                            <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeInUp" animationOut='fadeOut' isVisible={folder.menu ? true : false} className="bg-white card tile shadow d-flex flex-row" style={{ position: 'absolute', top: 10, right: 10 }}>
                                <button onClick={(e) => this.setState({ editing_folder: folder }, () => {
                                    this.toggle_folder_menu(i);
                                })} className="btn btn-default"><i className="icon-edit-3 black" /></button>
                                <button onClick={(e) => this.setState({ match: folder }, () => {
                                    this.toggle_folder_menu(i);
                                })} className="btn btn-default"><i className="icon-share black" /></button>

                                <button className="btn btn-default" onClick={() => this.onRemoveFolder(folder)}><i className="fas fa-times red" /></button>
                                <button className="btn btn-default" onClick={(e) => this.toggle_folder_menu(i)}><i className="black icon-more-vertical" /></button>
                            </Animated>

                        </Animated>
                    )}
                </div>

                {shared_folders && shared_folders.length > 0 && <section className="mt-4">
                    <h4>Shared Folders with you</h4>
                    <div className='row'>
                        {shared_folders && _.chain(shared_folders).value().map((folder, i) =>
                            <Animated key={i} animationInDelay={25 * i} animationIn="fadeIn" className="col-sm-6 col-md-3 p-1 w3-container w3-cell">

                                <Link className="w-100  py-2 tile card d-flex flex-row h-100" to={`/network/folder/${folder.IdFolder}`}>
                                    <div className="px-2 ms-auto pointer" style={{ width: 50 }} ><i className="ms-auto white icon-more-vertical" /></div>
                                    <div className="d-flex flex-column justify-content-center w-100 py-2">
                                        <i className="icon-folder black text-center font-70" />
                                        <span className="line1 mt-2 font-10 black text-center">{folder.FolderName}</span>
                                    </div>
                                    <div className="px-2 ms-auto pointer" style={{ width: 50, height: 50 }}><i className="ms-auto white icon-more-vertical" /></div>

                                </Link>

                            </Animated>
                        )}
                    </div>
                </section>}

                {/* EDIT FOLDER */}
                {this.state.editing_folder && <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeInUp" animationOut='fadeOut' isVisible={this.state.editing_folder ? true : false} className="bg-white card tile shadow d-flex flex-column" style={{ position: 'fixed', bottom: 20, width: 300, right: 20 }}>
                    <div className="bg-white p-4 w-100">
                        <label>Folder's Name</label>
                        <input className="w3-input w-100" type="text" defaultValue={this.state.editing_folder.Name || this.state.editing_folder.FolderName} onChange={(e) => { this.setState({ edit_folder_name: e.target.value }); }} />
                        <button className={`btn btn-${this.state.saved ? 'success' : 'info'} mt-4 w-100`} type="button" onClick={this.onEditFolder}>{this.state.saved ? 'Save Folder' : 'Save Folder'}</button>
                        <button className={`btn btn-${this.state.saved ? 'link' : 'link'} mt-1 w-100`} type="button" onClick={() => this.setState({ editing_folder: false, menu_toggled: true })}>Cancel</button>
                    </div>
                </Animated>}

                <div className="p-4" />

            </section>
        )
    }

    renderCommon = () => {
        const { mode } = this.state, { follow_seasons } = this.props;

        return <div className="w3-content px-0 container pt-4">

            {/* TABS */}
            <div className="d-flex flex-row syncopate text-center w-100">
                {/* PLAYERS */}
                <div className={`w-100 p-2 ${mode === 1 ? '' : 'bg-gray'}`} onClick={() => this.setState({ mode: 1 }, () => {
                    localStorage.setItem('sqwadrc.network.mode', 1)
                })}>
                    <h4 className="p-0 m-0">PLAYERS</h4>
                </div>

                {/* TEAMS */}
                <div className={`w-100 p-2 ${mode === 2 ? '' : 'bg-gray'}`} onClick={() => this.setState({ mode: 2 }, () => {
                    localStorage.setItem('sqwadrc.network.mode', 2)
                })}>
                    <h4 className="p-0 m-0">TEAMS</h4>
                </div>

                {/* SEASONS */}
                {follow_seasons && follow_seasons.length > 0 && <div className={`w-100 p-2 ${mode === 3 ? '' : 'bg-gray'}`} onClick={() => this.setState({ mode: 3 }, () => {
                    localStorage.setItem('sqwadrc.network.mode', 3)
                })}>
                    <h4 className="p-0 m-0">EVENTS</h4>
                </div>}
            </div>

            {mode === 1 && this.renderFolders()}

            {mode === 2 && <Teams {...this.props} />}

            {mode === 3 && <FollowSeasons {...this.props} />}

        </div>
    }
    render() {
        return (
            <Layout router={this.props.router} className="bg-white" nbs="network" title="SQWAD SCOUT | MY NETWORK" form={this.props.form} form_actions={this.props.form_actions}>
                <section>
                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small" style={{ marginLeft: 200 }}>
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                    </div>
                    {/* MOBILE */}
                    <div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                    </div>
                    <Menu {...this.state} {...this.props} />
                </section>
            </Layout>
        )
    }
}

export default Network;
