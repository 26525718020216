import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import moment from 'moment';

class Note extends React.Component {
    render() {
        const { note } = this.props;
        return <section>
            <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="slideInRight" animationOut="slideOutRight" isVisible={this.props.note ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200 }}>
                {true && <section className="bg-whiteish d-flex flex-row w-100 p-4" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, paddingTop: 70 }}>

                    {note && <div className="align-self-start d-flex flex-row justify-content-center" style={{ width: 60, height: 60, paddingLeft: 80 }}>
                        <i className="align-self-center icon-bookmark1 font-50" style={{ color: `${note.Color || 'FF0000'}` }} />
                    </div>}
                    {note && <div className="d-flex flex-column black ms-4 pl-4 align-self-start">
                        <span className="ms-4">{note.Note}</span>
                        <span className="black font-8 mt-3 ms-4">by {`${note.CreatorNameFirst} ${note.CreatorNameLast}`} @ {moment(note.EvaluationDate).format('MMM Do YYYY')}</span>
                    </div>}

                </section>}
                <div onClick={this.props.toggle} style={{ width: 50, height: 50, borderRadius: 25, position: 'fixed', top: 15, left: 15 }} className="bg-gray d-flex flex-column justify-content-center">
                    <i className="fas fa-times black font-30 align-self-center" />
                </div>
            </Animated>
        </section>
    }
}

export default Note;