import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import { generateRandomId } from '../../../helpers';
import { Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import fakeAuth from '../../../fakeAuth';
import formations from '../../../formations';
import moment from 'moment';
import Switch from "react-switch";


class PlayerReview extends React.Component {

    state = {
        bootstrapped: false,
    }

    componentWillReceiveProps = nextProps => {

    }

    onRemove = () => {

        this.props.toggle();
    }

    onSave = () => {
        const { player_review } = this.props;
        player_review.PlayerReviewed = true;
        _.extend(player_review, {
            Overview: this.txtOverview.value,
            TechnicalStrenghts: this.txtTechnicalStrenghts.value,
            TechnicalImprove: this.txtTechnicalImprove.value,
            TacticalStrenghts: this.txtTacticalStrenghts.value,
            TacticalImprove: this.txtTacticalImprove.value,
            PhysicalStrenghts: this.txtPhysicalStrenghts.value,
            PhysicalImprove: this.txtPhysicalImprove.value,
            MentalStrenghts: this.txtMentalStrenghts.value,
            MentalImprove: this.txtMentalImprove.value,
            Notes: this.txtNotes.value,
            EvaluationDate: moment().format(),
        });
        this.props.toggle();
    }

    render() {
        const { player_review } = this.props;
        return <section>
            <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="slideInRight" animationOut="slideOutRight" isVisible={this.props.player_review ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200, }}>
                <section ref={i => this.section = i} className="bg-whiteish d-flex flex-row w-100 p-4 ease" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, paddingTop: 70, overflowY: 'auto' }}>
                    {player_review && <section className="w3-container w3-content mx-auto w-100">

                        {/* PAGE 1 */}
                        <div className="d-flex flex-column black align-self-start w-100 w3-container">

                            <div className="px-4 py-3 tile card d-flex flex-row mt-2 pointer">
                                <div style={{ width: 60 }}>
                                    {player_review.JerseyNumber}
                                </div>
                                <div className="d-flex w-100">
                                    {`${player_review.NameFirst} ${player_review.NameLast}`}                                    
                                </div>

                            </div>

                            <div className="w3-card mt-3">
                                <div className="w3-container bg-white p-4">
                                    <label>Overview</label>
                                    <textarea className="w3-input" type="text" defaultValue={player_review.Overview} ref={i => this.txtOverview = i} />
                                </div>
                            </div>

                            <div className="w3-card mt-3">
                                <div className="px-2 py-1 w3-gray white">
                                    <h2 className="sqwad-font m-0">TECHNICAL</h2>
                                </div>
                                <div className="w3-container bg-white p-4">
                                    <label>Strenghts</label>
                                    <textarea className="w3-input" type="text" defaultValue={player_review.TechnicalStrenghts} ref={i => this.txtTechnicalStrenghts = i} />
                                    <label>Areas to Improve</label>
                                    <textarea className="w3-input" type="text" defaultValue={player_review.TechnicalImprove} ref={i => this.txtTechnicalImprove = i} />
                                </div>
                            </div>

                            <div className="w3-card mt-3">
                                <div className="px-2 py-1 w3-gray white">
                                    <h2 className="sqwad-font m-0">TACTICAL</h2>
                                </div>
                                <div className="w3-container bg-white p-4">
                                    <label>Strenghts</label>
                                    <textarea className="w3-input" type="text" defaultValue={player_review.TacticalStrenghts} ref={i => this.txtTacticalStrenghts = i} />
                                    <label>Areas to Improve</label>
                                    <textarea className="w3-input" type="text" defaultValue={player_review.TacticalImprove} ref={i => this.txtTacticalImprove = i} />
                                </div>
                            </div>

                            <div className="w3-card mt-3">
                                <div className="px-2 py-1 w3-gray white">
                                    <h2 className="sqwad-font m-0">PHYSICAL</h2>
                                </div>
                                <div className="w3-container bg-white p-4">
                                    <label>Strenghts</label>
                                    <textarea className="w3-input" type="text" defaultValue={player_review.PhysicalStrenghts} ref={i => this.txtPhysicalStrenghts = i} />
                                    <label>Areas to Improve</label>
                                    <textarea className="w3-input" type="text" defaultValue={player_review.PhysicalImprove} ref={i => this.txtPhysicalImprove = i} />
                                </div>
                            </div>

                            <div className="w3-card mt-3">
                                <div className="px-2 py-1 w3-gray white">
                                    <h2 className="sqwad-font m-0">PSYCHOLOGICAL | SOCIAL</h2>
                                </div>
                                <div className="w3-container bg-white p-4">
                                    <label>Strenghts</label>
                                    <textarea className="w3-input" type="text" defaultValue={player_review.MentalStrenghts} ref={i => this.txtMentalStrenghts = i} />
                                    <label>Areas to Improve</label>
                                    <textarea className="w3-input" type="text" defaultValue={player_review.MentalImprove} ref={i => this.txtMentalImprove = i} />
                                </div>
                            </div>

                            <div className="w3-card mt-3">
                                <div className="px-2 py-1 w3-gray white">
                                    <h2 className="sqwad-font m-0">NEXT STEPS</h2>
                                </div>
                                <div className="w3-container bg-white p-4">

                                    <div className="d-flex flex-row">
                                        <Switch onChange={() => {
                                            const { review } = this.state;
                                            player_review.NextSteps = 'Sign';
                                            this.setState({ review });
                                        }} checked={player_review.NextSteps === 'Sign' ? true : false} />
                                        <span className="align-self-center ms-2">Sign</span>
                                    </div>
                                    <div className="d-flex flex-row mt-2">
                                        <Switch onChange={() => {
                                            const { review } = this.state;
                                            player_review.NextSteps = 'Continue to monitor';
                                            this.setState({ review });
                                        }} checked={player_review.NextSteps === 'Continue to monitor' ? true : false} />
                                        <span className="align-self-center ms-2">Continue to monitor</span></div>
                                    <div className="d-flex flex-row mt-2">
                                        <Switch onChange={() => {
                                            const { review } = this.state;
                                            player_review.NextSteps = 'Discard';
                                            this.setState({ review });
                                        }} checked={player_review.NextSteps === 'Discard' ? true : false} />
                                        <span className="align-self-center ms-2">Discard</span>
                                    </div>

                                    <label className="mt-2">Notes</label>
                                    <textarea className="w3-input" type="text" defaultValue={player_review.Notes} ref={i => this.txtNotes = i} />

                                </div>
                            </div>

                            <button className="btn w3-blue btn-block mt-4" onClick={this.onSave}>Save Review</button>

                            <div className="p-4" />
                            <div className="p-4" />
                        </div>

                    </section>}
                </section>

                {/* X */}
                <div onClick={this.props.toggle} style={{ width: 50, height: 50, borderRadius: 25, position: 'fixed', top: 15, left: 15 }} className="bg-gray d-flex flex-column justify-content-center">
                    <i className="fas fa-times black font-30 align-self-center" />
                </div>

            </Animated>
        </section>
    }
}

export default PlayerReview;