import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as video_actions from '../redux-sagas/actions/video';
import * as form_actions from '../redux-sagas/actions/form';
import * as leads_actions from '../redux-sagas/actions/leads';
import * as team_actions from '../redux-sagas/actions/teams';
import * as family_actions from '../redux-sagas/actions/family';
import * as scout_actions from '../redux-sagas/actions/scout';
import * as geo_actions from '../redux-sagas/actions/geo';
import * as custom_pages_actions from '../redux-sagas/actions/custom_pages.js';

import Main from '../components/layouts/base.claire.js';

function mapStateToProps(state) {
    return {
        /*loggedIn: state.loggedIn,
        auth: state.auth,
        form: state.form,
        player: state.player,
        video: state.video,
        prospects: state.prospects,
        communities: state.communities,
        countries: state.countries,
        loading: state.loading,
        followees: state.followees,
        user: state.user,
        follow_levels: state.follow_levels,
        folders: state.folders,
        folder: state.folder,
        teams: state.teams,
        team: state.team,
        roster: state.roster,
        topics: state.topics,*/
        ...state
    }
}

function mapDispatchToProps(dispatch) {
    return {   
        family_actions: bindActionCreators(family_actions, dispatch),
        team_actions: bindActionCreators(team_actions, dispatch),     
        video_actions: bindActionCreators(video_actions, dispatch),
        form_actions: bindActionCreators(form_actions, dispatch),
        leads_actions: bindActionCreators(leads_actions, dispatch),
        scout_actions: bindActionCreators(scout_actions, dispatch),
        geo_actions: bindActionCreators(geo_actions, dispatch),
        custom_pages_actions: bindActionCreators(custom_pages_actions, dispatch),
    }
}

const App = connect(mapStateToProps, mapDispatchToProps)(Main);

export default App;
