import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';

import request, { search } from 'superagent';
import config from '../../../config';
import fakeAuth from '../../../fakeAuth';

class FollowSeasons extends React.Component {
    state = {
        follow_seasons: null
    }

    componentWillMount() {
        const { follow_seasons } = this.props;
        this.setState({ follow_seasons });
    }
    componentWillReceiveProps = nextProps => {
        const { follow_seasons } = nextProps;
        console.log({ follow_seasons });
        this.setState({ follow_seasons });
    }

    toggle_season_menu = (index, e) => {
        e && e.preventDefault && e.preventDefault();
        e && e.stopPropagation && e.stopPropagation();
        const { follow_seasons } = this.state, { folder } = this.props;
        follow_seasons[index].menu = !follow_seasons[index].menu;
        this.setState({ follow_seasons });
    }

    toggle_season_delete = (index, e) => {
        e.preventDefault && e.preventDefault();
        e.stopPropagation && e.stopPropagation();
        const { follow_seasons } = this.state, { folder } = this.props;
        follow_seasons[index].deleting = !follow_seasons[index].deleting;
        this.setState({ follow_seasons });
    }

    onRemove = (season) => {
        //const { follow_seasons } = this.state;
        //this.setState({ follow_seasons: _.reject(follow_seasons, t => t.IdMatch === season.IdMatch) });
        this.props.scout_actions.unfollow_match(season.IdMatch);
    }

    render() {
        const { follow_seasons } = this.state;
        return <section>

            <div className="row mx-0 mt-4 pb-4">
                {follow_seasons && follow_seasons.map((season, i) => <Animated key={season.IdSeason} animationInDelay={25 * (i + follow_seasons.length)} animationIn="fadeInUp"
                    className="col-sm-6 col-md-4 p-1 w3-container w3-cell">

                    <Link className="w-100 py-2 tile card d-flex flex-row h-100"
                        to={season.ShowAllPlayersRegistered ? `/network/season/${season.IdMatch}/${season.IdSeason}` : `/network/custom_event/${season.IdMatch}/${season.IdSeason}`}>
                        <div className="px-2 ms-auto pointer" style={{ width: 50 }} ><i className="ms-auto white icon-more-vertical" /></div>
                        <div className="d-flex flex-column justify-content-center w-100 py-2">
                            <div className="align-self-center contain contains" style={{ width: 60, height: 60, background: `url(${season.LeagueImageUrl || season.LeagueImage || '/images/logos/generic_crest.png'}) no-repeat center center` }}></div>
                            <span className="line1 font-10 black text-center mt-2">{season.SeasonName}</span>
                        </div>
                        <div className="px-2 ms-auto pointer" style={{ width: 50, height: 50 }} onClick={(e) => this.toggle_season_menu(i, e)}><i className="ms-auto black icon-more-vertical" /></div>
                    </Link>

                    <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false}
                        animationIn="fadeInUp" animationOut='fadeOut' isVisible={(season.menu && !season.deleting) ? true : false}
                        className="bg-white card tile shadow d-flex flex-row" style={{ position: 'absolute', top: 10, right: 10 }}>

                        <Link to={season.ShowAllPlayersRegistered ? `/network/season/${season.IdMatch}/${season.IdSeason}` : `/network/custom_event/${season.IdMatch}/${season.IdSeason}`} title="Details" className="btn btn-default"><i className="icon-arrow-right black" /></Link>

                        {/* TODO: */}
                        {/*<button title="Share Season"
                            onClick={(e) => {
                                //if (team.IdTeamDivision) this.setState({ brochure: team }, () => { this.toggle_season_menu(i, e); });
                                //if (team.IdMatch) this.setState({ match: team }, () => { this.toggle_season_menu(i, e); });
                            }}
                        className="btn btn-default"><i className="icon-share black" /></button>*/}

                        <button title="Unfollow Season" className="btn btn-default" onClick={(e) => this.toggle_season_delete(i, e)}>
                            <i className="fas fa-times red" />
                        </button>
                        <button className="btn btn-default" onClick={(e) => this.toggle_season_menu(i, e)}><i className="black icon-more-vertical" /></button>
                    </Animated>

                    <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false}
                        animationIn="fadeInUp" animationOut='fadeOut'
                        isVisible={season.deleting ? true : false} className="bg-white card tile shadow d-flex flex-row"
                        style={{ position: 'absolute', top: 10, right: 10 }}>
                        <span className="black align-self-center px-2">Confirm remove?</span>
                        <button className="btn btn-default" onClick={() => this.onRemove(season)}><i className="black fas fa-check" /></button>
                        <button title="Remove Season" className="btn btn-default" onClick={(e) => this.toggle_season_delete(i, e)}><i className="fas fa-times black" /></button>
                    </Animated>

                </Animated>
                )}

            </div>



        </section>
    }
}

export default FollowSeasons;