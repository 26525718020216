function scout_match_Reducer(state = [], action) {
    switch (action.type) {
        case 'SCOUT/MATCH/SET':                 
            return action.match;
        case 'SCOUT/MATCH/CLEAR':
            return null;
        default:
            return state;
    }
}

export default scout_match_Reducer;
