function subscriptions(state = [], action) {
    switch (action.type) {
        case 'SUBSCRIPTIONS/SET':            
            return action.subscriptions;
        case 'SUBSCRIPTIONS/CLEAR':
            return null;
        default:
            return state;
    }
}

export default subscriptions;
