import React from 'react';
import Layout from '../layouts/claire';
import { preload } from '../../helpers';
import { Animated } from 'react-animated-css';
import Menu from '../home/partials/menu';
import { Link } from 'react-router';
import Players from './partials/players';
import Teams from './partials/teams';
import LineLoader from '../partials/line_loader';

class Folder extends React.Component {
    state = {
        menu_toggled: false,
        mode: 1
    }
    componentWillMount() {
        this.props.scout_actions && this.props.scout_actions.fetchCountries();
        this.props.scout_actions && this.props.scout_actions.fetchCommunities();
        this.props.scout_actions && this.props.scout_actions.clearUser();
    }
    componentDidMount() {
        this.props.scout_actions && this.props.scout_actions.fetchFollowees();
        this.props.scout_actions && this.props.scout_actions.fetchFolders();
        const { router } = this.props, { params } = router;
        if (params.id) {
            this.props.scout_actions && this.props.scout_actions.fetchFolder(params.id);
        }
    }
    renderCommon = () => {
        const { mode } = this.state, { followees, folders, router, folder = {} } = this.props, { params } = router;

        return <div className="w3-content px-0 container pt-4">
            <div className="p-0">

                <div className="d-flex flex-row w-100">
                    <Link className="px-4 py-2 tile card w-100 d-flex flex-row w-100" to="/network">
                        <i className="far fa-folder black text-center font-30 align-self-center" />
                        <span className="line1 font-10 black text-center align-self-center ms-4">{folder ? (folder.Name || folder.FolderName) : 'ALL PLAYERS'}</span>
                        <span className="ms-auto blue font-10 align-self-center">Change</span>
                    </Link>                    
                </div>

                <input className="w-100 mt-3 p-2 mb-3 tile card" type="text" name="email" placeholder="Search" onChange={e => {
                    this.setState({ search_text: e.target.value });
                }} ref={(input) => this.txtSearch = input} />

            </div>

            {mode === 1 && followees && <Players {...this.props} {...this.state} />}
            {mode === 2 && false && <Teams {...this.props} />}

            <div className="p-4" />

        </div >
    }
    render() {

        return (
            <Layout router={this.props.router} className="bg-white" nbs="network" title="SQWAD SCOUT | MY NETWORK" form={this.props.form} form_actions={this.props.form_actions}>
                <section>
                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small" style={{ marginLeft: 200 }}>
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                    </div>
                    {/* MOBILE */}
                    <div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                    </div>
                    <Menu {...this.state} {...this.props} />

                    {this.props.loading && <LineLoader />}
                </section >
            </Layout>
        )
    }
}

export default Folder;
