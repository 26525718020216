import React from 'react';
import { Link } from 'react-router';


const MenuFamilyDesktop = ({ path = [], router, user = {} }) => {
    return (
        <div style={{ position: 'fixed', left: 0, top: 70, bottom: 0, width: 200, zIndex: 100 }} className="py-2 px-2 bg-whiteish  w3-hide-small">
            <div className="d-flex flex-column">
                <a onClick={() => { router.goBack && router.goBack() }} className={`bg-${path.indexOf('nope') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-row justify-content-start`} style={{ height: 50 }}>
                    <i className="fas fa-arrow-left font-10 align-self-center ms-2" />
                    <span className="align-self-center font-8 syncopate ms-2">BACK</span>
                </a>
                <section className="d-flex flex-column">
                    <Link to={`/family`} className={`bg-${path.indexOf('family') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-row justify-content-start mt-2`} style={{ height: 50 }}>
                        <i className="icon-users font-20 align-self-center ms-2" />
                        <span className="align-self-center font-8 syncopate ms-2">MEMBERS</span>
                    </Link>
                    <Link to={`/family_contact`} className={`bg-${path.indexOf('family_contact') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-row justify-content-start mt-2`} style={{ height: 50 }}>
                        <i className="icon-phone-call font-20 align-self-center ms-2" />
                        <span className="align-self-center font-8 syncopate ms-2">CONTACT</span>
                    </Link>
                    <Link to={`/family_info`} className={`bg-${path.indexOf('family_info') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-row justify-content-start mt-2`} style={{ height: 50 }}>
                        <i className="icon-hybrid font-20 align-self-center ms-2" />
                        <span className="align-self-center font-8 syncopate ms-2">ACCOUNT INFO</span>
                    </Link>
                    {/*<Link to={`/family_restore`} className={`bg-${path.indexOf('family_restore') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-row justify-content-start mt-2`} style={{ height: 50 }}>
                        <i className="icon-followers font-20 align-self-center ms-2" />
                        <span className="align-self-center font-6 syncopate ms-2">RESTORE MEMBER</span>
    </Link>                    */}
                </section>
            </div>
        </div>
    );
}

export default MenuFamilyDesktop;
