import React from 'react';
import { Link } from 'react-router';

const MenuMobile = ({ path = [], user = {}, router }) => {
    return (
        <div style={{ position: 'fixed', bottom: 0, left: 10, right: 10, borderTopRightRadius: 5, borderTopLeftRadius: 5, height: 65 }} className="py-2 px-2 bg-whiteish w3-hide-medium w3-hide-large">
            {user && <div className="d-flex flex-row w-100 justify-content-center">
                {!user.IdMatch && <section className="d-flex flex-row w-100 justify-content-center">
                    <a onClick={() => { router.goBack && router.goBack() }} className={`bg-${path.indexOf('nope') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-row justify-content-start`} style={{ height: 50, width: 'auto' }}>
                        <i className="fas fa-arrow-left font-10 align-self-center ms-2" />
                        <span className="align-self-center font-8 syncopate mx-2">BACK</span>
                    </a>
                    <Link to={`/prospect/${user.IdUser || user.IdUserFollow}`} className={`bg-${path.indexOf('prospect') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-column justify-content-center w-100 align-self-center`} style={{ height: 50 }}>
                        <i className="icon-info font-16 align-self-center" />
                        <span className="align-self-center font-8">Info</span>
                    </Link>
                    <Link to={`/prospect_highlights/${user.IdUser || user.IdUserFollow}`} className={`bg-${path.indexOf('prospect_highlights') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-column justify-content-center w-100 align-self-center`} style={{ height: 50 }}>
                        <i className="icon-film-reel-1 font-16 align-self-center" />
                        <span className="align-self-center font-8">Highlight</span>
                    </Link>
                    <Link to={`/prospect_evaluations/${user.IdUser || user.IdUserFollow}`} className="bg-white rounded d-flex flex-column justify-content-center w-100 align-self-center" style={{ height: 50 }}>
                        <i className="icon-checklist font-16 jeff_blue align-self-center" />
                        <span className="align-self-center black font-8">Evaluation</span>
                    </Link>
                    {/*<Link to="/prospect_calendar" className="bg-white rounded d-flex flex-column justify-content-center w-100 align-self-center" style={{ height: 50 }}>
                        <i className="icon-calendar font-16 jeff_blue align-self-center" />
                        <span className="align-self-center black font-8">Upcoming</span>
    </Link>*/}
                </section>}

                {user.IdMatch && <section className="d-flex flex-row w-100 justify-content-center">
                    <a onClick={() => { router.goBack && router.goBack() }} className={`bg-${path.indexOf('nope') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-row justify-content-start`} style={{ height: 50, width: 'auto' }}>
                        <i className="fas fa-arrow-left font-10 align-self-center ms-2" />
                        <span className="align-self-center font-8 syncopate mx-2">BACK</span>
                    </a>
                    <Link to={`/custom_player/${user.IdMatch}`} className={`bg-${path.indexOf('custom_player') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-column justify-content-center w-100 align-self-center`} style={{ height: 50 }}>
                        <i className="icon-info font-16 align-self-center" />
                        <span className="align-self-center font-8">INFO</span>
                    </Link>
                    <Link to={`/custom_player_evaluations/${user.IdMatch}`} className={`bg-${path.indexOf('custom_player_evaluations') !== -1 ? 'dark-blue white' : 'white jeff_blue'} rounded d-flex flex-column justify-content-center w-100 align-self-center`} style={{ height: 50 }}>
                        <i className="icon-checklist font-16 align-self-center" />
                        <span className="align-self-center font-8">EVALUATIONS</span>
                    </Link>
                </section>}
            </div>}
        </div>
    );
}

export default MenuMobile;
