import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import _ from 'lodash';

class TeamQR extends React.Component {
    state = {}
    componentWillMount = () => {
        const { team, season } = this.props;
    }

    render() {
        const { team, followees, toggle, season, view } = this.props;
        return <div>
            <div className="d-flex flex-row bg-white" style={{ borderBottom: `5px solid gray` }}>
                <i onClick={toggle} className="fas pointer fa-arrow-left font-18 black mx-4 align-self-center" />
                <div style={{ width: 150 }} className="d-flex flex-row justify-content-center p-4">
                    <div className="align-self-center bordered contains contain" style={{ borderRadius: 5, width: 100, height: 100, background: `url(${team.Logo || `/images/defaults/generic_crest.png`}) no-repeat center center white` }} />
                </div>
                <div className="w-100 d-flex flex-column justify-content-center">
                    <span className="blue font-18 montserrat toBold">{team.TeamName || team.Name}</span>
                    <span className="black font-12 montserrat">{this.props.season}</span>
                </div>
                <button onClick={view} type="button" className="montserrat ms-auto me-4 btn btn-link align-self-center black"><i className="far fa-eye" /> View</button>
            </div>
            <div className="d-flex flex-row w3-content mx-auto">
                <div className="p-4  w-100 d-flex flex-column justify-content-center">
                    <div className="align-self-center bordered contains contain align-self-center" style={{ borderRadius: '50%', width: 200, height: 200, background: `url(${team.Logo || `/images/defaults/generic_crest.png`}) no-repeat center center white` }} />
                    <span className="font-30 black montserrat align-self-center">{team.Name}</span>
                    <span className="font-20 black montserrat align-self-center">{season}</span>
                </div>
                <div className="p-4  w-100 d-flex flex-column justify-content-center">
                    <div className="align-self-center bordered contains contain align-self-center" style={{ width: 300, height: 300, background: `url(${team.QR || `/images/defaults/generic_crest.png`}) no-repeat center center white` }} />
                    <span className="font-10 black montserrat align-self-center line1 mt-4">Scan this code with</span>
                    <span className="font-14 black sqwad-font align-self-center line1">SQWAD SCOUT APP</span>
                    <span className="font-10 black montserrat align-self-center line1">For digital transfer</span>
                </div>
            </div>
        </div>
    }
}

export default TeamQR;