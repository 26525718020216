import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';

class MenuNotes extends React.Component {
    render() {
        return <section>
            <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="slideInLeft" animationOut="slideOutLeft" isVisible={this.props.menu_toggled} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200 }}>
                <section className="bg-whiteish d-flex flex-column justify-content-center w-100" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, paddingTop: 70 }}>
                    <Link to="/scouting_evaluations" style={{ width: 250 }} className="bg-gray px-4 py-2 rounded align-self-center d-flex flex-column">
                        <i className="black align-self-center icon-git-pull-request font-30" />
                        <span className="font-12 black syncopate align-self-center line1 mt-1">EVALUATION</span>
                        <span className="font-10 black align-self-center">by Position</span>
                    </Link>
                    <Link to="/scouting_notes" style={{ width: 250 }} className="bg-gray px-4 py-2 rounded align-self-center d-flex flex-column mt-3">
                        <i className="black align-self-center icon-bookmark1 font-30" />
                        <span className="font-12 black syncopate align-self-center line1 mt-1">NOTES</span>
                        <span className="font-10 black align-self-center">New</span>
                    </Link>
                    <Link to="/scouting_attributes" style={{ width: 250 }} className="bg-gray px-4 py-2 rounded align-self-center d-flex flex-column mt-3">
                        <i className="black align-self-center icon-recreational font-30" />
                        <span className="font-12 black syncopate align-self-center line1 mt-1">ATTRIBUTES</span>
                        <span className="font-10 black align-self-center">graph</span>
                    </Link>
                    <Link to="/scouting_reviews" style={{ width: 250 }} className="bg-gray px-4 py-2 rounded align-self-center d-flex flex-column mt-3">
                        <i className="black align-self-center icon-bar-chart font-30" />
                        <span className="font-12 black syncopate align-self-center line1 mt-1">PLAYER REVIEW</span>
                        <span className="font-10 black align-self-center">Attach</span>
                    </Link>
                </section>
                <div onClick={this.props.toggle} style={{ width: 50, height: 50, borderRadius: 25, position: 'fixed', top: 15, left: 15 }} className="bg-gray d-flex flex-column justify-content-center">
                    <i className="fas fa-arrow-left black font-30 align-self-center" />
                </div>
            </Animated>
        </section>
    }
}

export default MenuNotes;