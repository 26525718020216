function followees(state = [], action) {
    switch (action.type) {
        case 'FOLLOWEES/SET':            
            return action.users;
        case 'FOLLOWEES/CLEAR':
            return null;
        default:
            return state;
    }
}

export default followees;
