import _ from 'lodash';
function video_Reducer(state = [], action) {
    switch (action.type) {
        case 'VIDEO/SET':
            return action.video;
        case 'VIDEO/CLEAR':
            return null;
        default:
            return state;
    }
}

export default video_Reducer;
