import React from 'react';
import { fix_phone_numbers, EDUCATIONAL_SYSTEMS } from '../../../helpers';

const CollegeProfile = ({ profile, followees }) => {
    fix_phone_numbers(profile);
    let hidden = true;
    if (_.find(followees, f => f.IdUserFollow === profile.IdUser) && !_.find(followees, f => f.IdUserFollow === profile.IdUser).Blocked) {
        hidden = false;
    }    

    let educational_system = _.find(EDUCATIONAL_SYSTEMS, s => s.value === profile.EducationalSystem) || _.first(EDUCATIONAL_SYSTEMS);    

    return (
        <section>
            {/* ACADEMIC */}
            <span className="sqwad-font font-18 white">ACADEMIC PERFORMANCE</span>
            <div className="tile card d-flex flex-row mb-3 py-3 px-3" >
                <div className="w-75 d-flex flex-column">
                    <span className="font-8">Graduation Year</span>
                    <span>{profile.GraduationYear}</span>
                    <span className="font-8 mt-2">H.S./Prep</span>
                    <span>{profile.SchoolName}</span>
                    <span className="font-8 mt-2">Club</span>
                    <span>{profile.ClubName}</span>
                </div>
                {educational_system.system === 1 && <div className="w-25 d-flex flex-column">
                    <span className="font-8">GPA</span>
                    <span>{profile.GPA || 'N/A'}</span>
                    <span className="font-8 mt-2">SAT</span>
                    <span>{profile.SAT || 'N/A'}</span>
                    <span className="font-8 mt-2">ACT</span>
                    <span>{profile.ACT || 'N/A'}</span>
                </div>}
                {educational_system.system === 2 && <div className="w-25 d-flex flex-column">
                    <span className="font-8">GCSE English</span>
                    <span>{profile.GCSEEnglish || 'N/A'}</span>
                    <span className="font-8 mt-2">GCSE Math</span>
                    <span>{profile.GCSEMath || 'N/A'}</span>
                    <span className="font-8 mt-2">SAT</span>
                    <span>{profile.SAT || 'N/A'}</span>
                    <span className="font-8 mt-2">BTEC</span>
                    <span>{profile.BTEC || profile.BETC || 'N/A'}</span>
                    <span className="font-8 mt-2">Play in USA</span>
                    <span>{profile.PlayInUSA ? 'YES' : 'NO'}</span>
                </div>}
            </div>
            {/* NOTES */}
            {profile.Notes && <div className="tile card d-flex flex-column mb-3 py-2 px-3" >
                <span className="font-8">Notes</span>
                <span>{profile.Notes}</span>
            </div>}

            {/* CONTACT INFO */}
            <span className="sqwad-font font-18 white">CONTACT INFORMATION</span>
            <div className="tile card d-flex flex-column mb-3 py-2 px-3" >
                <span className="font-8 mt-2">Email</span>
                <span>{(profile.IsPublic && !hidden) ? profile.EmailAddress : '*************'}</span>
                <span className="font-8 mt-2">Phone</span>
                <span>{(profile.IsPublic && !hidden) ? profile.PhoneNumber_Format : '***********'}</span>
            </div>
        </section>

    );
}

export default CollegeProfile;

