import React from 'react';

class SelectableUser extends React.Component {

    state = {
        user: null
    }

    componentWillMount() {
        this.setState({ user: this.props.user });
    }    

    render() {
        const { user } = this.state;
        return (<div className={`d-flex flex-row w-100 p-2 ${!user.Token ? 'bg-gray' : ' bg-white'}`} style={{ borderBottom: `1px solid #ccc` }}>

            <div className="user-avatar-rounded cover align-self-centger"
                style={{ background: ['url(', user.UserImage, '?full=80) no-repeat center center black'].join('') }} />

            <div className="d-flex flex-column align-self-center idented justify-content-start">
                <span className="font-14 line1 no-margin no-padding ">{[user.NameFirst, user.NameLast].join(' ')}</span>
                {/*(user.IsLeagueAdmin || user.IsAdmin) && <span className="blue no-padding no-margin text-left">Club Admin</span>*/}
            </div>

            {!user.Token && <i className="fa ms-auto fa-exclamation-triangle red margin-right-half align-self-center" />}            
        </div>)
    }
}

export default SelectableUser;