function communities(state = [], action) {
    switch (action.type) {
        case 'COMMUNITIES/SET':            
            return action.communities;
        case 'COMMUNITIES/CLEAR':
            return null;
        default:
            return state;
    }
}

export default communities;
