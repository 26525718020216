function roster_Reducer(state = [], action) {
    switch (action.type) {
        case 'ROSTER/SET':            
            return action.roster;
        case 'ROSTER/CLEAR':
            return null;
        default:
            return state;
    }
}

export default roster_Reducer;
