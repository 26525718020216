import React from 'react';
import Layout from '../layouts/claire';
import { Animated } from 'react-animated-css';
import Menu from '../home/partials/menu';
import { Link } from 'react-router';
import moment from 'moment';
import LineLoader from '../partials/line_loader';
import ShareMatch from '../network/partials/share';

import ReviewReadOnly from '../network/partials/review_read_only';
import PlayerMatch from '../network/partials/player_match';
import PlayerOnMatch from '../network/partials/player_on_match';
import PlayerOnPitch from '../network/partials/player_on_pitch';
import PlayersForReview from '../network/partials/players_for_review';
import PlayerReview from '../network/partials/player_review';

import { default_player_match } from '../../helpers';
import fakeAuth from '../../fakeAuth';
import MoveToFolder from '../network/partials/move_to_folder';
import request from 'superagent';
import config from '../../config';

class PlayerMatches extends React.Component {
    state = {
        menu_toggled: false,
        mode: parseInt(localStorage.getItem('sqwadrc.scouting.mode') || 1, 10)
    }

    componentWillMount() {
        this.props.scout_actions && this.props.scout_actions.fetchCountries();
        this.props.scout_actions && this.props.scout_actions.fetchCommunities();
        this.props.scout_actions && this.props.scout_actions.fetchPenalties(1);
        this.props.scout_actions && this.props.scout_actions.fetchFollowLevels();
        this.props.scout_actions && this.props.scout_actions.fetchFollowees();

        this.setState({ path: _.chain(this.props.router.getCurrentLocation()).value().pathname.split('/') });
    }
    componentDidMount = () => {
        this.props.scout_actions.fetch_player_matches();
        this.props.scout_actions.fetch_folders();
    }
    componentWillReceiveProps = nextProps => {
        const { notes, players, folders, penalties } = nextProps;       

        this.setState({
            players,
            penalties,
            notes: _.filter(notes, n => !n.IdFolder),
            folders: _.filter(folders, f => f.IdFolderType === 4)
        });
    }
    componentWillUnmount = () => {
        this.props.scout_actions.clear_notes();
    }
    //    

    onRemoveFolder = (folder) => {
        const { folders } = this.state;
        this.setState({ folders: _.reject(folders, f => f.IdFolder === folder.IdFolder) });
        this.props.scout_actions.delete_folder(folder.IdFolder);
    }
    onSaveFolder = () => {
        if (!this.txtFolderName.value && !this.state.folder_name) {
            alert(`Please put folder's name`);
            return;
        }
        this.props.scout_actions && this.props.scout_actions.create_folder({
            FolderName: this.txtFolderName.value || this.state.folder_name, IdFolderType: 4
        });
        this.setState({ adding_folder: false, mode: 3 }); // Mode 2 to switch to the folders tab
    }
    onDelete = (IdMatch) => {
        const { notes } = this.state;
        this.setState({ notes: _.reject(notes, e => e.IdMatch === IdMatch) });
        this.props.scout_actions && this.props.scout_actions.delete_match(IdMatch);
    }
    onPlayerOnMatch = (player) => {
        this.setState({ player_on_match: player });
    }
    onPlayerOnPitch = (roster, i, formation) => {
        this.setState({ roster_on_pitch: roster, position: i });
    }
    onPlayersForReview = (match) => {
        this.setState({ rosters_for_review: match });
    }
    onPlayerReview = (player) => {
        this.setState({ player_review: player, rosters_for_review: null });
    }
    onSavePlayer = (player) => {
        // TODO
    }
    onImport = () => {

        const { notes_link } = this.state;
        //https://www.thesqwad.com/scout/match/86B0ACBF-06AA-4F07-B130-3E04A5CC37F7
        if (notes_link.indexOf('://www.thesqwad.com/scout/match') !== -1) {

            let [IdMatch] = notes_link.split('/').reverse();
            this.setState({ searching: true, invalid_link: false }, () => {
                request.get(`${config.apiEndpoint}/api/v6/scout/match/${IdMatch}`)
                    .set('auth_token', localStorage.getItem('sqwadrc.authtoken'))
                    .then(({ body }) => {
                        if (body.match && body.match.ReportType === 2) {
                            this.setState({ found: body.match, searching: false });
                        } else {
                            this.setState({ invalid_link: true, searching: false });
                        }
                    });
            });

        } else {
            this.setState({ invalid_link: true, searching: false });
        }
    }
    onCopy = () => {
        const { found } = this.state;
        this.props.scout_actions.save_match({
            ...found,
            IdMatch: null,
            EvaluationDate: moment(),
            IdUser: fakeAuth.getUser().IdUser
        });
        this.setState({ importing: false, found: null });
    }

    toggle_player_menu = index => {
        const { players } = this.state;
        let [whens, no_whens] = _.chain(players)
            .reject(u => u.IdFolder)
            .partition(u => u.when)
            .value();
        [
            ..._.chain(whens)
                .sortBy(u => u.when || u.EvaluationDate || u.Date)
                .value()
                .reverse(),
            ..._.chain(no_whens)
                .value()
        ][index].menu = ![
            ..._.chain(whens)
                .sortBy(u => u.when || u.EvaluationDate || u.Date)
                .value()
                .reverse(),
            ..._.chain(no_whens)
                .value()
        ][index].menu;
        this.setState({ players });
    }
    toggle_folder_menu = (index, e) => {
        e && e.preventDefault && e.preventDefault();
        e && e.stopPropagation && e.stopPropagation();

        const { folders } = this.state;
        _.chain(folders).value()[index].menu =
            !_.chain(folders).value()[index].menu;
        this.setState({ folders });
    }
    toggle_evaluation_menu = index => {
        const { notes } = this.state;
        _.chain(notes)
            .filter(n => n.Home && n.Away)
            .filter(n => {
                if (this.state.search_text)
                    return `${n.Title}${n.Home.Name}${n.Away.Name}`.toLowerCase().indexOf(this.state.search_text.toLowerCase()) !== -1;
                return true;
            })
            .sortBy(s => s.EvaluationDate || s.Date)
            .tap(arr => { arr.reverse(); })
            .value()[index].menu =
            !_.chain(notes)
                .filter(n => n.Home && n.Away)
                .filter(n => {
                    if (this.state.search_text)
                        return `${n.Title}${n.Home.Name}${n.Away.Name}`.toLowerCase().indexOf(this.state.search_text.toLowerCase()) !== -1;
                    return true;
                })
                .sortBy(s => s.EvaluationDate || s.Date)
                .tap(arr => { arr.reverse(); })
                .value()[index].menu;

        this.setState({ notes });
    }

    renderTabs = () => {
        const { mode, notes, folders, players } = this.state;

        let [whens, no_whens] = _.chain(players)
            .reject(u => u.IdFolder)
            .partition(u => u.when)
            .value();

        return <div className="row">
            <div className="d-flex p-0 syncopate text-center w-100">
                <Link className="pe-4 align-self-center" to="/scouting">
                    <i className="fas fa-arrow-left" />
                </Link>
                <div className={`w-100 pointer p-2 ${mode === 1 ? '' : 'bg-gray'}`} onClick={() => this.setState({ mode: 1 }, () => {
                    localStorage.setItem('sqwadrc.scouting.mode', 1)
                })}>
                    <h5 className="p-0 m-0 font-12">MATCHES ({(notes || []).length})</h5>
                </div>

                <div className={`w-100 pointer p-2 ${mode === 2 ? '' : 'bg-gray'}`} onClick={() => this.setState({ mode: 2 }, () => {
                    localStorage.setItem('sqwadrc.scouting.mode', 2)
                })}>
                    <h5 className="p-0 m-0 font-12">PLAYERS ({[
                        ..._.chain(whens)
                            .sortBy(u => u.when || u.EvaluationDate || u.Date)
                            .value()
                            .reverse(),
                        ..._.chain(no_whens)
                            .value()
                    ].length})</h5>
                </div>

                <div className={`w-100 pointer p-2 ${mode === 3 ? '' : 'bg-gray'}`} onClick={() => this.setState({ mode: 3 }, () => {
                    localStorage.setItem('sqwadrc.scouting.mode', 3)
                })}>
                    <h5 className="p-0 m-0 font-12">FOLDERS ({(folders || []).length})</h5>
                </div>
            </div>
        </div>
    }
    renderCommon = () => {
        const { mode, notes, players, folders } = this.state, { loading } = this.props;

        let filtered_notes = _.chain(notes)
            .filter(n => n.Home && n.Away)
            .filter(n => {
                if (this.state.search_text)
                    return `${n.Title}${n.Home.Name}${n.Away.Name}`.toLowerCase().indexOf(this.state.search_text.toLowerCase()) !== -1;
                return true;
            })
            .sortBy(s => s.EvaluationDate || s.Date)
            .tap(arr => { arr.reverse(); })
            .value();

        let filtered_folders = _.chain(folders)
            .filter(n => {
                if (this.state.search_text_folders)
                    return `${n.Name || n.FolderName}`.toLowerCase().indexOf(this.state.search_text_folders.toLowerCase()) !== -1;
                return true;
            })
            .value();

        let [whens, no_whens] = _.chain(players)
            .reject(u => u.IdFolder)
            .partition(u => u.when)
            .value();

        return <div className="w3-content px-4 container pt-4">

            {this.renderTabs()}

            {/* NOTES */}
            {mode === 1 && <section>

                {/* SEARCH */}
                <div className="row mt-4">
                    <input className="w-100 p-2 mb-4 tile card" type="text" name="email" placeholder="Search" onChange={(e) => { this.setState({ search_text: e.target.value }); }} />
                </div>
                <div className="w3-container-fluid">

                    {/* YES +*/}
                    {filtered_notes && filtered_notes.length > 0 && <div className="row h-100">
                        {_.chain(filtered_notes).value().map((evaluation, index) => {
                            return <Animated key={index} animationInDelay={25 * index} animationIn="fadeInRightBig" className="col-md-6 p-0 mt-2">
                                <div className="pl-4 py-3 tile card d-flex flex-row mx-1 pointer h-100">

                                    <div onClick={() => this.setState({ player_match: evaluation })} className="w-100 d-flex flex-row">

                                        {/* HOME */}
                                        <div className="w-50  text-center">
                                            <div className="blue toUpper sqwad-font">Home</div>
                                            <div className="mx-auto cover" style={{ width: 60, height: 60, background: `url(${evaluation.Home.Image || evaluation.Home.TeamImage || evaluation.Home.Logo || '/images/logos/generic_crest.png'}?full=80) no-repeat center center` }} />
                                            <div className="max-lines line1 font-8 mt-2 mx-2 toBold">{evaluation.Home.Name || 'TBD'}</div>
                                            <div className="font-10">{_.filter(evaluation.Home.Roster, r => r.PlayerReviewed).length}<i className="ms-1 icon-user-check" /></div>
                                        </div>

                                        {/* MIDDLE */}
                                        <div className="w-100  text-center">
                                            <div className="font-8 max-lines-1">{evaluation.Title || '-'}</div>
                                            <div className="font-30">{evaluation.Home.Score || 0}-{evaluation.Away.Score || 0}</div>
                                            <div className="font-10">{evaluation.Date ? moment(evaluation.Date).utc().format('ddd, MMM Do YYYY') : 'Time: TBD'}</div>
                                            <div className="toBold font-8">{evaluation.Venue || 'Location: TBD'}</div>
                                            <div>{evaluation.TimeString || 'Time: TBD'}</div>
                                            <div className="font-8">Updated {moment(evaluation.EvaluationDate || evaluation.Date).format('MMM Do YYYY')}</div>
                                            <div className={`font-8 ${evaluation.ISCOPY ? 'blue' : 'white'}`}>(Scanned)</div>
                                        </div>

                                        {/* AWAY */}
                                        <div className="w-50  text-center">
                                            <div className="blue toUpper sqwad-font">Away</div>
                                            <div className="mx-auto cover" style={{ width: 60, height: 60, background: `url(${evaluation.Away.Image || evaluation.Away.TeamImage || evaluation.Away.Logo || '/images/logos/generic_crest.png'}?full=80) no-repeat center center` }} />
                                            <div className="max-lines line1 font-8 mt-2 mx-2 toBold">{evaluation.Away.Name || 'TBD'}</div>
                                            <div className="font-10">{_.filter(evaluation.Away.Roster, r => r.PlayerReviewed).length}<i className="ms-1 icon-user-check" /></div>
                                        </div>
                                    </div>

                                    {/* MENU DOTS */}
                                    <div className="px-2 ms-auto pointer" style={{ width: 50 }} onClick={() => this.toggle_evaluation_menu(index)}><i className="ms-auto black icon-more-vertical" /></div>

                                    {/* MENU */}
                                    <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeIn" animationOut='fadeOut' isVisible={evaluation.menu ? true : false}
                                        className="bg-white card tile shadow d-flex flex-row" style={{ position: 'absolute', top: 8, right: 9 }}>
                                        <div className="d-flex flex-column">
                                            <a target="_blank" title="PDF Version" href={`https://www.thesqwad.com/scout/match/${evaluation.IdMatch}`} className="btn btn-default"><i className="far fa-file-pdf black" /> Download PDF</a>
                                            {evaluation.IdUser === fakeAuth.getUser().IdUser && <button onClick={() => this.setState({ move_to_folder: evaluation, folder_type: 4 }, () => {
                                                this.toggle_evaluation_menu(index);
                                            })} className="btn btn-default"><i className="icon-folder-plus black" /> Move to folder</button>}
                                        </div>
                                        <div className="d-flex flex-column">
                                            <button onClick={() => this.setState({ match: evaluation }, () => {
                                                this.toggle_evaluation_menu(index);
                                            })} className="btn btn-default"><i className="icon-share black" /> Share Report</button>
                                            <button onClick={() => this.onDelete(evaluation.IdMatch)} className="btn btn-default"><i className="fas fa-times red" /> Delete Report</button>
                                        </div>
                                        <button className="btn btn-default align-self-start" onClick={() => this.toggle_evaluation_menu(index)}><i className="black icon-more-vertical" /></button>
                                    </Animated>

                                </div>

                                {/* EXTERNAL VIDEO */}
                                {evaluation.ExternalLinks && evaluation.ExternalLinks.length > 0 && <i className="fab fa-youtube red me-2" style={{ position: 'absolute', bottom: 10, right: 10 }} />}
                            </Animated>
                        })}
                        <div className="col-12 p-4" />
                    </div>}

                    {/* NO */}
                    {!loading && filtered_notes && !filtered_notes.length && <div className="d-flex w-100 flex-column justify-content-center">
                        <img src="/images/icons/red_card.png" className="w-25 align-self-center mt-4" />
                        <span className="white font-30 align-self-center text-center mx-4 px-4 line1 mt-4">Sorry, you have no notes in this area</span>
                    </div>}

                </div>

            </section>}

            {/* PLAYERS */}
            {mode === 2 && <section>
                <div className="row mt-4">
                    <input className="w-100 p-2 mb-4 tile card" type="text" name="email" placeholder="Search" ref={(input) => this.txtSearch = input} />
                </div>
                <div>
                    {/* YES +*/}
                    {!loading && players && players.length > 0 && <div className="row h-100 ">

                        {[
                            ..._.chain(whens)
                                .sortBy(u => u.when || u.EvaluationDate || u.Date)
                                .value()
                                .reverse(),
                            ..._.chain(no_whens)
                                .value()
                        ].map((evaluation, index) => {
                            return <Animated key={index} animationInDelay={25 * index} animationIn="fadeInRightBig" className="col-md-6 p-0 mt-2">
                                <div className="pl-4 py-3 tile card d-flex flex-row mx-1 pointer">
                                    <div className="w-100 d-flex flex-row ms-3">

                                        <div onClick={() => this.setState({ review_read_only: evaluation })} className="align-self-center d-flex flex-row justify-content-center cover" style={{ width: 50, height: 50, borderRadius: 25, background: `url(${evaluation.UserImage || '/images/defaults/male.png'}?full=80) no-repeat center center black` }} />

                                        <div onClick={() => this.setState({ review_read_only: evaluation })} className="w-75 d-flex flex-column black ms-2 align-self-center">
                                            <span className="line1 font-16">{`${evaluation.NameFirst} ${evaluation.NameLast}`}</span>
                                            <span className="max-lines-1 font-10 line1 mt-1">{evaluation.against}</span>
                                            <div className="font-8 blue">Reviewd on {moment(evaluation.when || evaluation.EvaluationDate || evaluation.Date).format('MMM Do YYYY')}</div>
                                        </div>

                                    </div>
                                    <div className="px-2 ms-auto pointer" style={{ width: 50 }}><i className="ms-auto white icon-more-vertical" /></div>
                                    <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeInUp" animationOut='fadeOut' isVisible={evaluation.menu ? true : false} className="bg-white card tile shadow d-flex flex-row" style={{ position: 'absolute', top: 10, right: 0 }}>
                                        <button onClick={() => this.setState({ review_read_only: evaluation }, () => {
                                            this.toggle_player_menu(index);
                                        })} className="btn btn-default"><i className="icon-arrow-right black" /></button>
                                        <button onClick={() => this.setState({ match: evaluation }, () => {
                                            this.toggle_player_menu(index);
                                        })} className="btn btn-default"><i className="icon-share black" /></button>
                                        <button onClick={() => this.onDelete(evaluation.IdMatch)} className="btn btn-default"><i className="fas fa-times red" /></button>
                                        <button className="btn btn-default" onClick={() => this.toggle_player_menu(index)}><i className="black icon-more-vertical" /></button>
                                    </Animated>
                                </div>
                            </Animated>
                        })}

                        <div className="col-12 p-4" />
                    </div>}

                    {/* NO */}
                    {!loading && players && !players.length && <div className="d-flex w-100 flex-column justify-content-center">
                        <img src="/images/icons/red_card.png" className="w-25 align-self-center mt-4" />
                        <span className="font-30 align-self-center text-center mx-4 px-4 line1 mt-4">Sorry, you have no notes in this area</span>
                    </div>}
                </div>
            </section>}

            {/* FOLDERS */}
            {mode === 3 && <section>
                <div className="row mt-4">
                    <input className="w-100 p-2 mb-4 tile card" type="text" name="email" placeholder="Search" onChange={(e) => { this.setState({ search_text_folders: e.target.value }); }} />
                </div>
                <div className="row">

                    {/* YES */}
                    {filtered_folders && filtered_folders.map((folder, i) =>
                        <Animated key={i} animationInDelay={25 * i} animationIn="fadeInUp" className="col-sm-6 col-md-3 p-1 w3-container w3-cell">

                            <Link className="w-100  py-2 tile card d-flex flex-row h-100" to={`/pm_folder/${folder.IdFolder}`}>
                                <div className="px-2 ms-auto pointer" style={{ width: 50 }} ><i className="ms-auto white icon-more-vertical" /></div>
                                <div className="d-flex flex-column justify-content-center w-100 py-2">
                                    <i className="icon-folder black text-center font-70" />
                                    <span className="line1 mt-2 font-10 black text-center">{folder.Name || folder.FolderName}</span>
                                </div>
                                <div className="px-2 ms-auto pointer" style={{ width: 50, height: 50 }} onClick={(e) => this.toggle_folder_menu(i, e)}><i className="ms-auto black icon-more-vertical" /></div>
                                {folder.shared && <div style={{ width: 30, height: 30, position: 'absolute', top: 0, left: 0 }} className="d-flex flex-row justify-content-center bg-dark-blue">
                                    <i className="white font-10 icon-user-plus align-self-center" />
                                </div>}
                            </Link>

                            <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeInUp" animationOut='fadeOut' isVisible={folder.menu ? true : false} className="bg-white card tile shadow d-flex flex-row" style={{ position: 'absolute', top: 10, right: 10 }}>
                                <button onClick={(e) => this.setState({ editing_folder: folder }, () => {
                                    this.toggle_folder_menu(i);
                                })} className="btn btn-default"><i className="icon-edit-3 black" /></button>
                                <button onClick={(e) => this.setState({ match: folder }, () => {
                                    this.toggle_folder_menu(i);
                                })} className="btn btn-default"><i className="icon-share black" /></button>
                                <button className="btn btn-default" onClick={() => this.onRemoveFolder(folder)}><i className="fas fa-times red" /></button>
                                <button className="btn btn-default" onClick={(e) => this.toggle_folder_menu(i)}><i className="black icon-more-vertical" /></button>
                            </Animated>

                        </Animated>
                    )}

                    {/* NO */}
                    {!loading && filtered_folders && !filtered_folders.length && <div className="d-flex w-100 flex-column justify-content-center">
                        <img src="/images/icons/red_card.png" className="w-25 align-self-center mt-4" />
                        <span className="font-30 align-self-center text-center mx-4 px-4 line1 mt-4">Sorry, you have no<br />folders in this area</span>
                    </div>}

                    <div style={{ height: 200 }} className="col-12 " />
                </div>
            </section>}

            {/* ADD FAB */}
            <div onClick={() => this.setState({ menu_toggled: !this.state.menu_toggled })} style={{ width: 50, height: 50, borderRadius: 25, overflow: 'hidden', position: 'fixed', bottom: 20, right: 20 }} className="">
                <div className="bg-info d-flex flex-column justify-content-center h-100 w-100">
                    <i className="fas fa-plus white font-30 align-self-center" />
                </div>
            </div>

            {/* 1st MENU */}
            <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeInUp" animationOut='fadeOut' isVisible={this.state.menu_toggled ? true : false} className="bg-white card tile shadow d-flex flex-column" style={{ position: 'fixed', bottom: 20, right: 20 }}>
                <button className="btn btn-default" onClick={() => this.setState({ player_match: default_player_match(fakeAuth.getUser()), menu_toggled: false, mode: 1 })}>
                    <div className="d-flex flex-column">
                        <i className="icon-plus black font-30" />
                        <span>Report</span>
                    </div>
                </button>
                <button className="btn btn-default" onClick={() => this.setState({ adding_folder: true, menu_toggled: false, mode: 3 })}>
                    <div className="d-flex flex-column">
                        <i className="icon-folder black font-30" />
                        <span>Folder</span>
                    </div>
                </button>
                <button className="btn btn-default" onClick={() => this.setState({ importing: true, menu_toggled: false, mode: 1 })}>
                    <div className="d-flex flex-column">
                        <i className="icon-arrow-down-circle black font-30" />
                        <span>Import</span>
                    </div>
                </button>
                <div className="align-self-center mb-2 mt-2" onClick={() => this.setState({ menu_toggled: !this.state.menu_toggled })} style={{ width: 50, height: 50, borderRadius: 25, overflow: 'hidden' }}>
                    <div className="bg-info d-flex flex-column justify-content-center h-100 w-100">
                        <i className="fas fa-times white font-30 align-self-center" />
                    </div>
                </div>
            </Animated>

            {/* ADD FOLDER */}
            <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeInUp" animationOut='fadeOut' isVisible={this.state.adding_folder ? true : false} className="bg-white card tile shadow d-flex flex-column" style={{ position: 'fixed', bottom: 20, width: 300, right: 20 }}>
                <div className="bg-white p-4 w-100">
                    <label>Folder's Name</label>
                    <input className="w3-input w-100" type="text" onChange={(e) => { this.setState({ folder_name: e.target.value }); }} ref={i => this.txtFolderName = i} />
                    <button className={`btn btn-${this.state.saved ? 'success' : 'info'} mt-4 w-100`} type="button" onClick={this.onSaveFolder}>{this.state.saved ? 'Save Folder' : 'Save Folder'}</button>
                    <button className={`btn btn-${this.state.saved ? 'link' : 'link'} mt-1 w-100`} type="button" onClick={() => this.setState({ adding_folder: false, menu_toggled: true })}>Cancel</button>
                </div>
            </Animated>

            {/* EDIT FOLDER */}
            {this.state.editing_folder && <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeInUp" animationOut='fadeOut' isVisible={this.state.editing_folder ? true : false} className="bg-white card tile shadow d-flex flex-column" style={{ position: 'fixed', bottom: 20, width: 300, right: 20 }}>
                <div className="bg-white p-4 w-100">
                    <label>Folder's Name</label>
                    <input className="w3-input w-100" type="text" defaultValue={this.state.editing_folder.Name || this.state.editing_folder.FolderName} onChange={(e) => { this.setState({ edit_folder_name: e.target.value }); }} />
                    <button className={`btn btn-${this.state.saved ? 'success' : 'info'} mt-4 w-100`} type="button" onClick={this.onEditFolder}>{this.state.saved ? 'Save Folder' : 'Save Folder'}</button>
                    <button className={`btn btn-${this.state.saved ? 'link' : 'link'} mt-1 w-100`} type="button" onClick={() => this.setState({ editing_folder: false, menu_toggled: true })}>Cancel</button>
                </div>
            </Animated>}

            {/* IMPORT */}
            <Animated animationInDuration={200} animationOutDuration={200} animateOnMount={false} animationIn="fadeInUp" animationOut='fadeOut' isVisible={this.state.importing ? true : false} className="bg-white card tile shadow d-flex flex-column" style={{ position: 'fixed', bottom: 20, width: 350, right: 20 }}>
                <div className="bg-white p-4 w-100">
                    {!this.state.invalid_link && <label>Search by Shared Link</label>}
                    {this.state.invalid_link && <label className="red">Invalid Link</label>}
                    <input className="w3-input w-100" type="text" onChange={(e) => { this.setState({ notes_link: e.target.value }); }} />
                    <button className={`btn btn-${this.state.saved ? 'success' : 'info'} mt-4 w-100`} type="button" onClick={this.onImport}>{this.state.saved ? 'Import' : 'Import'}</button>
                    <button className={`btn btn-${this.state.saved ? 'link' : 'link'} mt-1 w-100`} type="button" onClick={() => this.setState({ importing: false, menu_toggled: true })}>Cancel</button>
                    {this.state.found &&
                        <section>

                            <div className="pl-4 py-3 tile card d-flex flex-row mx-1 pointer h-100">
                                <div className="w-100 d-flex flex-row ">
                                    <div className="w-50  text-center">
                                        <div className="blue toUpper sqwad-font">Home</div>
                                        <div className="mx-auto cover" style={{ width: 60, height: 60, background: `url(${this.state.found.Home.Image || this.state.found.Home.TeamImage || this.state.found.Home.Logo || '/images/logos/generic_crest.png'}?full=80) no-repeat center center` }} />
                                        <div className="max-lines line1 font-8 mt-2 mx-2 toBold">{this.state.found.Home.Name || 'TBD'}</div>
                                        <div className="font-10">{_.filter(this.state.found.Home.Roster, r => r.PlayerReviewed).length}<i className="ms-1 icon-user-check" /></div>
                                    </div>
                                    <div className="w-100  text-center">
                                        <div className="font-8 max-lines-1">{this.state.found.Title || '-'}</div>
                                        <div className="font-30">{this.state.found.Home.Score || 0}-{this.state.found.Away.Score || 0}</div>
                                        <div className="font-10">{this.state.found.Date ? moment(this.state.found.Date).utc().format('ddd, MMM Do YYYY') : 'Time: TBD'}</div>
                                        <div className="toBold font-8">{this.state.found.Venue || 'Location: TBD'}</div>
                                        <div>{this.state.found.TimeString || 'Time: TBD'}</div>
                                        <div className="font-8">Updated {moment(this.state.found.EvaluationDate || this.state.found.Date).format('MMM Do YYYY')}</div>
                                        <div className={`font-8 ${this.state.found.ISCOPY ? 'blue' : 'white'}`}>(Scanned)</div>
                                    </div>
                                    <div className="w-50  text-center">
                                        <div className="blue toUpper sqwad-font">Away</div>
                                        <div className="mx-auto cover" style={{ width: 60, height: 60, background: `url(${this.state.found.Away.Image || this.state.found.Away.TeamImage || this.state.found.Away.Logo || '/images/logos/generic_crest.png'}?full=80) no-repeat center center` }} />
                                        <div className="max-lines line1 font-8 mt-2 mx-2 toBold">{this.state.found.Away.Name || 'TBD'}</div>
                                        <div className="font-10">{_.filter(this.state.found.Away.Roster, r => r.PlayerReviewed).length}<i className="ms-1 icon-user-check" /></div>
                                    </div>
                                </div>
                            </div>

                            <button className={`btn btn-${this.state.saved ? 'success' : 'success'} mt-4 w-100`} type="button" onClick={this.onCopy}>{this.state.saved ? 'Get a Copy' : 'Get a Copy'}</button>
                        </section>}
                </div>
            </Animated>

        </div>
    }
    render() {
        return (
            <Layout router={this.props.router} className="bg-white" nbs="network" title="SQWAD SCOUT | SCOUTING" form={this.props.form} form_actions={this.props.form_actions}>
                <section>
                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small" style={{ marginLeft: 200 }}>
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                    </div>
                    {/* MOBILE */}
                    <div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
                    </div>
                    <Menu {...this.state} {...this.props} />

                    <PlayerMatch pr={this.onPlayerReview} pfr={this.onPlayersForReview} pom={this.onPlayerOnMatch} pop={this.onPlayerOnPitch} {...this.state}
                        scout_actions={this.props.scout_actions} toggle={() => this.setState({ player_match: false })}
                        fnshare={(match) => this.setState({ match })}
                        fndelete={(match) => this.setState({ player_match: null }, () => {
                            this.onDelete(match.IdMatch);
                        })} />
                    <PlayerOnMatch ok={this.onSavePlayer} {...this.state} scout_actions={this.props.scout_actions} toggle={() => this.setState({ player_on_match: false })} />
                    <PlayerOnPitch {...this.state} scout_actions={this.props.scout_actions} toggle={() => this.setState({ roster_on_pitch: false })} />
                    <PlayersForReview pr={this.onPlayerReview} {...this.state} scout_actions={this.props.scout_actions} toggle={() => this.setState({ rosters_for_review: false })} />
                    <PlayerReview {...this.state} scout_actions={this.props.scout_actions} toggle={() => this.setState({ player_review: false })} />
                    <ShareMatch {...this.state} scout_actions={this.props.scout_actions} toggle={() => this.setState({ match: false })} />
                    <MoveToFolder {...this.state} scout_actions={this.props.scout_actions} toggle={() => this.setState({ move_to_folder: false })} />
                    <ReviewReadOnly {...this.state} scout_actions={this.props.scout_actions} toggle={() => this.setState({ review_read_only: false })} />

                    {this.props.loading && <LineLoader />}


                </section>
            </Layout>
        )
    }
}

export default PlayerMatches;
