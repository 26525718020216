import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import { Bar, Radar } from 'react-chartjs-2';
import moment from 'moment';

const options = {
    responsive: true,
    maintainAspectRatio: false,
    title: { display: false },
    legend: { display: false },
    ticks: { beginAtZero: true },
};

class Attributes extends React.Component {
    state = { data: null }

    componentWillReceiveProps = nextProps => {
        const { attributes } = nextProps;
        if (attributes) {            
            const data = {
                labels: _.map(attributes.Topics, t => t.TopicName),//['Topic 1', 'Topic 2', 'Topic 3', 'Green', 'Purple', 'Orange'],
                datasets: [
                    {
                        label: `${attributes.NameFirst}'s Evaluation ${moment(attributes.EvaluationDate.split('T')[0], 'YYYY-MM-DD').format('MMM Do YYYY')}`,
                        data: _.map(attributes.Topics, t => t.Value.toFixed(1)), //[10, 9, 3, 5, 2, 3],
                        backgroundColor: _.map(attributes.Topics, t => '#013469')
                    },
                ],
            };

            this.setState({ boostrapped: true, data });
        }
    }
    render() {
        const { attributes } = this.props, { data } = this.state;
        return <section>
            <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="slideInRight" animationOut="slideOutRight" isVisible={this.props.attributes ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200 }}>
                <section className="bg-whiteish d-flex flex-column w-100 p-4" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, paddingTop: 70, overflowY: 'auto' }}>
                    <div className="w3-container w3-content mx-auto">
                        {attributes && data && <div className="align-self-center row">
                            <div style={{ height: 400 }} className="col-md-6 col-sm-12">
                                <Bar data={data} options={{
                                    ...options, scales: {
                                        y: {
                                            min: 0,
                                            max: 10
                                        }
                                    }
                                }} />
                            </div>

                            <div style={{ height: 400 }} className="col-md-6 col-sm-12">
                                <Radar data={data} options={options} />
                            </div>

                            {attributes.Topics && attributes.Topics.map && attributes.Topics.map((topic, i) =>
                                <div className="d-flex flex-column mt-4 col-12" key={i}>
                                    <div className="d-flex flex-row">
                                        <span className="font-16 montserrat">{topic.TopicName}</span>
                                        <span className="font-16 montserrat-bold toBold ms-auto">{topic.Value.toFixed(1)}</span>
                                    </div>
                                    {topic.SubTopics.map((sub, j) => <div className="d-flex flex-row" key={j}>
                                        <span className="font-10 montserrat ms-4">{sub.TopicName}</span>
                                        <span className="font-10 montserrat-bold toBold ms-auto">{sub.Value.toFixed(1)}</span>
                                    </div>)}
                                    <div style={{ height: 1, backgroundColor: 'gray' }} className="w-100" />
                                </div>
                            )}
                            <div className="d-flex flew-row mt-4 col-12">
                                <span className="font-30 montserrat toBold ms-auto">Avg. {attributes.average.toFixed(1)}</span>
                            </div>
                        </div>}
                    </div>
                </section>
                <div onClick={this.props.toggle} style={{ width: 50, height: 50, borderRadius: 25, position: 'fixed', top: 15, left: 15 }} className="bg-gray d-flex flex-column justify-content-center">
                    <i className="fas fa-times black font-30 align-self-center" />
                </div>
            </Animated>
        </section>
    }
}

export default Attributes;