import React from 'react';
import { Animated } from 'react-animated-css';
import { Link } from 'react-router';
import moment from 'moment';
import { generateRandomId } from '../../../helpers';
import { Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import fakeAuth from '../../../fakeAuth';
import formations from '../../../formations';


class PlayerOnPitch extends React.Component {

    state = {
        bootstrapped: false,
    }    

    onRemove = () => {        
        this.props.toggle();
    }

    onNone = () => {
        const { roster_on_pitch, position } = this.props;
        roster_on_pitch.forEach((u) => {
            if (u.PitchPosition === position) u.PitchPosition = null;
        });        
        this.props.toggle();
    }

    onSave = (player, index) => {
        const { roster_on_pitch, position } = this.props;
        roster_on_pitch.forEach((u, i) => {
            if (u.PitchPosition === position) u.PitchPosition = null;
            if (i === index) u.PitchPosition = position;
        });        
        this.props.toggle();
    }

    render() {
        const { roster_on_pitch, position } = this.props;
        return <section>
            <Animated animateOnMount={false} animationInDuration={250} animationOutDuration={250} animationIn="slideInRight" animationOut="slideOutRight" isVisible={this.props.roster_on_pitch ? true : false} style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, paddingTop: 70, zIndex: 200, }}>
                <section ref={i => this.section = i} className="bg-whiteish d-flex flex-row w-100 p-4 ease" style={{ position: 'absolute', top: 0, left: 0, bottom: 0, paddingTop: 70, overflowY: 'auto' }}>
                    {roster_on_pitch && <section className="w3-container w3-content mx-auto w-100">

                        {/* PAGE 1 */}
                        <div className="d-flex flex-column black align-self-start w-100 w3-container">
                            <div className="w3-container bg-white ">
                                <h3 className="gray syncopate mt-4">PLAYERS</h3>
                                <div className="px-4 py-1 tile card d-flex flex-row mt-2 w3-blue white">
                                    <div style={{ width: 60 }}>
                                        No
                                    </div>
                                    <div className="w-100 toUpper">
                                        Player's Name
                                    </div>
                                </div>

                                <div className="px-4 py-3 tile card d-flex flex-row mt-2 pointer" onClick={() => this.onNone()}>
                                    <div className="d-flex w-100">
                                        None
                                    </div>
                                </div>

                                {_.chain(roster_on_pitch).filter(u => u.Id).value().map((player, index) => {
                                    return <div className="px-4 py-3 tile card d-flex flex-row mt-2 pointer" key={index} onClick={() => this.onSave(player, index)}>
                                        <div style={{ width: 60 }}>
                                            {player.JerseyNumber}
                                        </div>
                                        <div className="d-flex w-100">
                                            {`${player.NameFirst} ${player.NameLast}`}
                                            <i className={`ms-auto align-self-center fas fa-check ${player.PitchPosition === position ? 'green' : (player.PitchPosition ? 'whiteish' : 'white')}`}></i>
                                        </div>

                                    </div>
                                })}

                                <div className="p-2" />
                            </div>
                            <div className="p-4" />
                            <div className="p-4" />
                        </div>

                    </section>}
                </section>

                {/* X */}
                <div onClick={this.props.toggle} style={{ width: 50, height: 50, borderRadius: 25, position: 'fixed', top: 15, left: 15 }} className="bg-gray d-flex flex-column justify-content-center">
                    <i className="fas fa-times black font-30 align-self-center" />
                </div>

            </Animated>
        </section>
    }
}

export default PlayerOnPitch;