import { call, put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import config from '../../config';
import request from 'superagent';

export function* getRoster() {
    yield* takeEvery("ROSTER/FETCH", function* (action) {
        try {
            const result = yield call(({ idTeamDivision }) => {
                return request.get(`${config.apiEndpoint}/api/external/teams/roster/${idTeamDivision}`)
                    .set('Authorization', `Bearer ${config.apiKey}`)
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {                
                const { roster, team, colors } = result;                
                team.colors = colors;
                yield put({ type: 'ROSTER/SET', roster });
                yield put({ type: 'TEAM/SET', team })

            } else {
                yield put({
                    type: 'ROSTER/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'ROSTER/FETCH_FAILED',
                e
            });
        }
    });
}

export function* getRosterMaster() {
    yield* takeEvery("ROSTER/MASTER/FETCH", function* (action) {
        try {
            const result = yield call(({ idTeam }) => {                
                return request.get(`${config.apiEndpoint}/api/external/teams/master_roster/${idTeam}`)
                    .set('Authorization', `Bearer ${config.apiKey}`)
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {                
                const { roster, team, colors } = result;                
                team.colors = colors;
                yield put({ type: 'ROSTER/SET', roster });
                yield put({ type: 'TEAM/SET', team })

            } else {
                yield put({
                    type: 'ROSTER/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'ROSTER/FETCH_FAILED',
                e
            });
        }
    });
}

//
export function* getPlayerProfile() {
    yield* takeEvery("PLAYER/PROFILE/FETCH", function* (action) {
        try {
            const result = yield call(({ idUser }) => {
                return request.get(`${config.apiEndpoint}/api/external/users/profile/${idUser}`)
                    .set('Authorization', `Bearer ${config.apiKey}`)
                    .then(data => (JSON.parse(data.text)));
            }, action);

            if (result && result.success) {                
                const { user } = result;                                
                yield put({ type: 'USER/SET', user });
            } else {
                yield put({
                    type: 'PLAYER/PROFILE/FETCH_FAILED',
                    result
                });
            }
        } catch (e) {
            yield put({
                type: 'PLAYER/PROFILE/FETCH_FAILED',
                e
            });
        }
    });
}
